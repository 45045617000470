export const enum AppleArtistDetailType {
  Name = 0,

  Image = 1,

  Url = 2,

};

export const enum ApprovalState {
  None = 0,

  Requested = 1,

  Approved = 2,

};

export const enum ArtistRoles {
  Contributor = 2,

  Circus_Artist = 3,

  Comedian = 4,

  Conductor = 5,

  DJ = 6,

  Featuring = 7,

  Key_Talent = 8,

  Main_Artist = 9,

  Mixing_Artist = 10,

  Original_Artist = 11,

  Performer = 12,

  Project_Artist = 13,

  Remixed_Artist = 14,

  With = 15,

  Soloist = 16,

  Remixer = 17,

};

export const enum AspNetRoles {
  TenantAdmin = 1,

  CloudAdmin = 9,

  DevUser = 10,

  ImpersonateUser = 11,

};

export const enum AssetTypeGroups {
  Image = 1,

  MIDI = 2,

  SheetMusic = 3,

  Software = 4,

  SoundRecording = 5,

  Text = 6,

  Video = 7,

  UserDefinedResource = 8,

};

export const enum AssetTypes {
  AudioStem = 1,

  MusicalWorkReadalongSoundRecording = 2,

  MusicalWorkSoundRecording = 3,

  NonMusicalWorkReadalongSoundRecording = 4,

  NonMusicalWorkSoundRecording = 5,

  SpokenWordSoundRecording = 6,

  LongFormMusicalWorkVideo = 7,

  LongFormNonMusicalWorkVideo = 8,

  ShortFormMusicalWorkVideo = 9,

  ShortFormNonMusicalWorkVideo = 10,

  BackCoverImage = 11,

  BookletBackImage = 12,

  BookletFrontImage = 13,

  DocumentImage = 14,

  FrontCoverImage = 15,

  Icon = 16,

  Logo = 17,

  Photograph = 18,

  Poster = 19,

  TrayImage = 20,

  Unknown = 21,

  VideoScreenCapture = 22,

  Wallpaper = 23,

  Caption = 24,

  EBook = 25,

  LinerNotes = 26,

  LyricText = 27,

  NonInteractiveBooklet = 28,

  TextDocument = 29,

  InteractiveBooklet = 30,

  MusicalWorkBasedGame = 31,

  NonMusicalWorkBasedGame = 32,

  Screensaver = 33,

  AppleMotionArtSquare = 37,

  AppleMotionArtPortrait = 38,

};

export const enum CommercialModels {
  AdvertisementSupportedModel = 1,

  AsPerContract = 2,

  DeviceFeeModel = 3,

  FreeOfChargeModel = 4,

  PayAsYouGoModel = 5,

  PerformanceRoyaltiesModel = 6,

  RightsClaimModel = 7,

  SubscriptionModel = 8,

  Unknown = 9,

  UserDefined = 10,

};

export const enum CompilationTypes {
  InternalCompilation = 1,

  NonInternalCompilation = 2,

  NotCompiled = 3,

};

export const enum DeliveryStates {
  Cancelled = 1,

  Failed = 2,

  Finished = 3,

  Queued = 4,

  Uploading = 7,

  Converting = 8,

  Pending = 11,

  Downloading = 12,

  BuildXML = 13,

};

export const enum DeliveryTypes {
  ADD = 1,

  UPDATEALL = 2,

  UPDATEXML = 3,

  DELETE = 4,

  TAKEDOWN = 5,

};

export const enum FormatTypeGroups {
  Album = 1,

  Single = 2,

  Video = 3,

  Merchandise = 4,

  Image = 9,

  Game = 10,

};

export const enum FormatTypes {
  Artist_Album = 4,

  /**Artist Single (Multiple Tracks)*/
  Artist_Single_Multiple_Tracks_ = 5,

  Artist_EP = 6,

  Mixed_Media_Artist_Album = 7,

  Box_Set = 8,

  Compilation_Album = 9,

  Classical_Album = 10,

  Premium_Music_Video = 11,

  Lyric_Video = 12,

  Packshot_Video = 13,

  Digital_Concert_Video = 14,

  Digital_Feature_Film = 15,

  Music_Video_Trailer = 16,

  Promo_Video = 17,

  Non_Music_Video_Trailer = 18,

  Merchandise = 19,

  Compilation_Single = 20,

  Compilation_EP = 21,

  Book = 22,

  CD_Album = 23,

  /**12" Album*/
  _12_Album = 24,

  /**CD/DVD Album*/
  CD_DVD_Album = 25,

  /**CD/Blu-Ray Album*/
  CD_Blu_Ray_Album = 26,

  /**CD/DVD/Blu-Ray Album*/
  CD_DVD_Blu_Ray_Album = 27,

  /**10" Album*/
  _10_Album = 29,

  Enhanced_CD_Album = 30,

  Cassette_Album = 31,

  CD_Single = 32,

  /**7" Single*/
  _7_Single = 33,

  /**10" Single*/
  _10_Single = 34,

  /**12" Single*/
  _12_Single = 35,

  CD_EP = 36,

  Super_Audio_CD = 37,

  Cassette_Single = 38,

  DVD = 39,

  /**Blu-Ray*/
  Blu_Ray = 40,

  /**Blu-Ray/DVD*/
  Blu_Ray_DVD = 41,

  Ringtone = 42,

  Immersive_Audio_Artist_Album = 43,

  /**Immersive Audio Artist Single (Multiple Tracks)*/
  Immersive_Audio_Artist_Single_Multiple_Tracks_ = 44,

  Immersive_Audio_Artist_EP = 45,

  Single_Track_Release = 46,

  Immersive_Single_Track_Release = 47,

};

export const enum Gender {
  Female = 1,

  Male = 2,

  /**Non-binary*/
  Non_binary = 3,

  Unknown = 4,

};

export const enum ImageRoles {
  Profile = 1,

  Banner = 2,

};

export const enum LogLevelEnum {
  Debug = 0,

  Info = 1,

  Warn = 2,

  Error = 3,

  Critical = 4,

};

export const enum MusicalWorkRoles {
  Adapter = 1,

  Architect = 2,

  Arranger = 3,

  Author = 4,

  AuthorInQuotations = 5,

  AuthorOfAfterword = 6,

  Compiler = 7,

  Composer = 8,

  ComposerLyricist = 9,

  Conceptor = 10,

  Creator = 11,

  DialogueAuthor = 12,

  Dissertant = 13,

  Engraver = 14,

  Etcher = 15,

  Journalist = 16,

  LandscapeArchitect = 17,

  Librettist = 18,

  Lithographer = 19,

  Lyricist = 20,

  MetalEngraver = 21,

  NonLyricAuthor = 22,

  PlateMaker = 23,

  Playwright = 24,

  Reporter = 25,

  Reviewer = 26,

  Rubricator = 27,

  ScreenplayAuthor = 28,

  Sculptor = 29,

  SubArranger = 30,

  SubLyricist = 31,

  Translator = 32,

  Woodcutter = 33,

  WoodEngraver = 34,

  WriterOfAccompanyingMaterial = 35,

  BookPublisher = 36,

  CopyrightClaimant = 37,

  CopyrightHolder = 38,

  MusicPublisher = 39,

  NewspaperPublisher = 40,

  OriginalPublisher = 41,

  PeriodicalPublisher = 42,

  SubPublisher = 43,

  SubstitutedPublisher = 44,

  Unknown = 45,

};

export const enum ParentalWarningTypes {
  Explicit = 1,

  ExplicitContentEdited = 2,

  NoAdviceAvailable = 3,

  NotExplicit = 4,

  Unknown = 5,

};

export const enum PartTypeCategories {
  Manufacturing = 1,

  Production = 2,

  Other = 3,

};

export const enum PermissionIdType {
  FormatIDs = 0,

  LabelIDs = 1,

  ParentLabelIDs = 2,

  ClientIDs = 3,

  TenantIDs = 4,

};

export const enum PhysicalFormatDimensions {
  CD_in_Standard_Three_Part_Jewel_Case_with_Booklet = 1,

  Double_CD_in_Standard_Three_Part_Jewel_Case_with_Booklet = 2,

  /**12" Vinyl in Standard 3mm Sleeve*/
  _12_Vinyl_in_Standard_3mm_Sleeve = 3,

  /**2 x 12" Vinyl in Standard 3mm Sleeve*/
  _2_x_12_Vinyl_in_Standard_3mm_Sleeve = 4,

  /**2 x 12" Vinyl in Gatefold 5mm Sleeve*/
  _2_x_12_Vinyl_in_Gatefold_5mm_Sleeve = 5,

  Cassette_in_Standard_Case = 6,

  /**7" Vinyl in Standard Sleeve*/
  _7_Vinyl_in_Standard_Sleeve = 7,

  /**10" Vinyl in Standard Sleeve*/
  _10_Vinyl_in_Standard_Sleeve = 8,

  DVD_in_Standard_Case = 9,

  Double_DVD_in_Standard_Case = 10,

  BluRay_in_Standard_Case = 11,

  Double_BluRay_in_Standard_Case = 12,

  CD_in_Slimline_Case = 13,

  /**CD in 5" Wallet*/
  CD_in_5_Wallet = 14,

  /**Double CD in 5" Wallet*/
  Double_CD_in_5_Wallet = 15,

  _1984Rec001LP = 16,

};

export const enum PixelTypes {
  X_Pixel_Id = 1,

  Snapchat_Pixel_Id = 2,

  Meta_Pixel_Id = 3,

  Google_Analytics_Id = 4,

  TikTok_Pixel_Id = 5,

  Reddit_Pixel_Id = 6,

  Google_Tag_Manager_Pixel_Id = 7,

};

export const enum Platforms {
  Anthology = 1,

  iTunes = 2,

  Amazon_Music = 3,

  Spotify = 5,

  Rdio = 6,

  Deezer = 7,

  Beatport = 8,

  Tekked = 9,

  Vidzone = 10,

  _7Digital = 11,

  DDEX = 13,

  Juno_Download = 14,

  Medianet = 15,

  eMusic = 16,

  Napster = 17,

  KDigital = 18,

  Love_DA = 19,

  TrackItDown = 20,

  Google_Play = 22,

  Shazam = 23,

  Millward_Brown = 24,

  PPL = 25,

  Catco = 26,

  Omnifone = 27,

  Tidal = 28,

  MixRadio = 29,

  Simfy_Africa = 30,

  Zvooq = 31,

  Qobuz = 32,

  /**nuhbe.com*/
  nuhbe_com = 33,

  Groove = 36,

  Label_Worx = 37,

  YouTube_Music = 38,

  /**Christianbook.com*/
  Christianbook_com = 40,

  Pandora = 43,

  SoundCloud = 44,

  Freshwater_Worship = 45,

  KKbox = 46,

  David_C_Cook = 47,

  Findspire = 48,

  Slacker_Radio = 49,

  YouTube_Art_Track_Videos = 50,

  Absolute_Archive = 51,

  SoundExchange = 52,

  JioSaavn = 53,

  Neurotic_Media = 54,

  Downtown = 55,

  Yonder = 56,

  HMV = 57,

  LaMusica = 58,

  Technics_TRACKS = 59,

  /**Sainsbury's Entertainment*/
  Sainsbury_s_Entertainment = 60,

  Akazoo = 61,

  Apple_Music = 62,

  HTC_One = 63,

  Kobo = 64,

  /**Last.FM*/
  Last_FM = 65,

  Mach = 66,

  Me2u_Music = 67,

  Mi_Tracks = 69,

  Mixette = 70,

  MusicQubed = 71,

  Onkyo = 72,

  OpenLIVE = 73,

  Overmax = 74,

  Raku = 75,

  ROK_Mobile = 76,

  Tonspion = 77,

  TriPlay = 78,

  Tunewiki = 79,

  The_Overflow = 80,

  Bleep_Labelworx = 81,

  Digital_Tunes = 82,

  Digitally_Imported = 83,

  /**DJShop.de*/
  DJShop_de = 84,

  HardDanceMP3 = 85,

  /**Hardstyle.com*/
  Hardstyle_com = 86,

  Pulselocker = 87,

  Pyro = 88,

  Traxsource_via_LabelWorx = 89,

  /**Tunes4DJs.com*/
  Tunes4DJs_com = 90,

  Wasabeat = 91,

  What_People_Play = 92,

  Flipagram = 94,

  BPI_Antipiracy = 96,

  PPCA = 97,

  Abramus = 98,

  SIMIM = 99,

  SENA = 100,

  Gramo = 101,

  SCPP = 102,

  Audible_Magic = 105,

  iHeartMedia = 108,

  Qanawat = 109,

  SiriusXM = 110,

  United_Media_Agency = 111,

  SYB = 112,

  Traxsource = 114,

  Soundtrack_Your_Brand = 115,

  Bleep = 116,

  YouTube_ContentID = 117,

  Cherry_Red_Library = 118,

  AWA = 119,

  Dubset = 123,

  Muso = 124,

  Facebook_Audio_Fingerprint = 125,

  Facebook_Audio_Library = 126,

  Netease = 127,

  Alibaba_Music_Group = 128,

  TempPackshotVideos = 129,

  Tencent = 130,

  Facebook = 133,

  YouTube = 134,

  AMI_Entertainment = 135,

  Gramex = 137,

  ItsRight = 138,

  Connect = 139,

  Yandex = 140,

  Naim_Fuga = 141,

  ZPAV = 142,

  Prophone = 143,

  IMAGIA = 144,

  Sampra = 145,

  IPF = 146,

  IFPI = 147,

  /**Lick'd*/
  Lick_d = 149,

  Intergram = 150,

  Zapraf = 151,

  Mahasz = 152,

  Tesla = 154,

  /**BMAT (ARM Only)*/
  BMAT_ARM_Only_ = 155,

  Gracenote = 156,

  HMV_Music_Japan = 157,

  KDDI = 158,

  /**J:Com*/
  J_Com = 159,

  Boomplay = 160,

  TikTok = 161,

  Project_M = 162,

  Bytedance = 163,

  Curve_Royalties = 164,

  BMAT = 165,

  LaIPA = 166,

  Music_Island = 167,

  Anghami = 168,

  PlayNetwork = 169,

  Amazon_Music_Video = 170,

  Facebook_Video = 171,

  YouTube_Music_Video = 172,

  Bandcamp = 173,

  Elatel = 174,

  Lyric_Video_Creator = 175,

  GVL = 176,

  Ruance_Lyric_Video_Creation = 177,

  /**Facebook Video Streaming + Library*/
  Facebook_Video_Streaming_Library = 178,

  Facebook_Video_Fingerprint = 179,

  ACRCloud = 180,

  Soundmouse = 181,

  PPI = 183,

  EFY = 184,

  GRAMEX_DK = 185,

  VPL = 186,

  Jaxsta = 187,

  Mood_Media = 188,

  Feed_Media_Group = 189,

  Peloton = 190,

  Adaptr = 191,

  Mixcloud = 192,

  Joox = 193,

  SberZvuk = 194,

  Musicmap = 195,

  Claro_Musica = 196,

  Prazor = 197,

  Amazon_3D_Audio = 198,

  AllRock = 200,

  Cantine = 201,

  /**Feelment (Maestro Pro)*/
  Feelment_Maestro_Pro_ = 202,

  Grandpad_Download = 203,

  Grandpad_Radio_Stream = 204,

  HomeWAV = 205,

  Jazzed = 206,

  Musicube = 207,

  Musixmatch = 208,

  NEC = 209,

  PEX = 210,

  Roxi_Music = 211,

  Snap = 212,

  Soundhound = 213,

  Soundtrack = 214,

  /**Stationhead - Downloads*/
  Stationhead_Downloads = 215,

  Trebel_Music = 216,

  Tunesat = 217,

  Xite = 218,

  Canva = 219,

  Beats_Music = 304,

  Electric_Jukebox = 404,

  Guvera = 405,

  i_am_plus = 406,

  /**Pulselocker (Delete)*/
  Pulselocker_Delete_ = 407,

  Resso = 408,

  Saavn = 409,

  Triller = 410,

  Vevo = 411,

  Weyv = 412,

  MCPS = 413,

  LSG = 415,

  Bucks_Music = 416,

  Nuuday = 417,

  Top_Music = 418,

  AGEDI = 419,

  Awal = 501,

  Ingrooves = 502,

  Kudos = 503,

  Universal_Distribution_UK = 516,

  Universal_Distribution_Eire = 517,

  Proper_Music_Distribution_UK = 518,

  Proper_Music_Distribution_International = 519,

  Music_Glue = 520,

  iTunes_Immersive = 521,

  Apple_Music_Immersive = 522,

  Tidal_Immersive_Audio = 524,

  Trebel = 526,

  Muzu = 527,

  Plastic_Head_Music_Distribution = 528,

  Townsend_Music = 529,

  Twitch = 531,

  Wimp = 532,

  Dial = 533,

  Universal_Music_Digital_Services = 534,

  Xbox = 535,

  Merlin = 536,

  Believe = 537,

  Indie_Mobile = 538,

  Universal_Music_Digital_International = 539,

  YouTube_Subscription = 540,

  YouTube_Ad_Revenue = 541,

  Pinterest = 546,

};

export const enum PolicyGroups {
  Monetise = 1,

  Block = 2,

  Track = 3,

  Mute = 4,

};

export const enum PriceTierTypes {
  Track = 1,

  Single = 2,

  Album = 3,

  Video = 4,

  Concert = 5,

  Film = 6,

};

export const enum RateCategories {
  Physical_Distribution = 1,

  Digital_Distribution = 2,

  Neighbouring_Rights = 3,

  Other = 4,

};

export const enum RechargeCategories {
  Manufacture = 1,

  Recharge = 2,

  Other_Sales = 3,

};

export const enum ReleaseDealReleaseDateTypes {
  DealStartDateTime = 1,

  DealEndDateTime = 2,

  ReleaseDateTime = 3,

  ReleaseDisplayStartDateTime = 4,

  TrackListingPreviewStartDateTime = 5,

  PreOrderReleaseDateTime = 6,

  PreOrderPreviewDateTime = 7,

  CoverArtPreviewStartDateTime = 8,

  ClipPreviewStartDateTime = 9,

};

export const enum ReleaseDealTypes {
  Main_Deal = 1,

  Territory_Exclusive = 2,

  Platform_Exclusive = 3,

  Track_Preview_Deal = 4,

  /**Pre-Order*/
  Pre_Order = 5,

  Price_Intervals = 6,

  Territory_Pre_Order = 7,

  Timed_Release = 8,

  Track_Deal = 9,

};

export const enum ReleaseResourceTypes {
  PrimaryResource = 1,

  SecondaryResource = 2,

};

export const enum ReleaseTypes {
  Album = 1,

  AlertToneRelease = 2,

  AsPerContract = 3,

  AudioBookRelease = 4,

  AudioDramaRelease = 5,

  BackCoverImageRelease = 6,

  BookletBackImageRelease = 7,

  BookletFrontImageRelease = 8,

  BookletRelease = 9,

  Bundle = 10,

  ClassicalAlbum = 11,

  ClassicalDigitalBoxedSet = 12,

  ClassicalMultimediaAlbum = 13,

  ConcertVideo = 14,

  DigitalBoxSetRelease = 15,

  DjMix = 16,

  Documentary = 17,

  Drama = 18,

  DramaticoMusicalVideoRelease = 19,

  EBookRelease = 20,

  EP = 21,

  Episode = 22,

  FeatureFilm = 23,

  KaraokeRelease = 24,

  LiveEventVideo = 25,

  LogoRelease = 26,

  LongFormMusicalWorkVideoRelease = 27,

  LongFormNonMusicalWorkVideoRelease = 28,

  LyricSheetRelease = 29,

  MultimediaAlbum = 30,

  MultimediaDigitalBoxedSet = 31,

  MultimediaSingle = 32,

  MusicalWorkBasedGameRelease = 33,

  NonMusicalWorkBasedGameRelease = 34,

  PlayList = 35,

  RingbackToneRelease = 36,

  RingtoneRelease = 37,

  Season = 38,

  Series = 39,

  SheetMusicRelease = 40,

  ShortFilm = 41,

  Single = 42,

  SingleResourceRelease = 43,

  StemBundle = 44,

  UserDefined = 45,

  VideoAlbum = 46,

  VideoMastertoneRelease = 47,

  VideoSingle = 48,

  WallpaperRelease = 49,

};

export const enum ResourceType {
  AccessLevel = 0,

  AgeCertification = 1,

  ArtistRole = 2,

  AssetInfoId = 3,

  AssetType = 4,

  BankTransactionType = 5,

  BusinessMusicalWorkRole = 6,

  ClientSettings = 7,

  ColorSpace = 8,

  CommercialModel = 9,

  ContributorId = 10,

  ContributorRole = 11,

  Currency = 12,

  DealType = 13,

  FormatStatusTags = 14,

  FormatType = 15,

  FormatTypeGroup = 16,

  Gender = 17,

  Genre = 18,

  Instrument = 19,

  Label = 20,

  Language = 21,

  McpslicenceType = 22,

  MetadataType = 23,

  MusicalWorkType = 24,

  ParentalType = 25,

  PartType = 26,

  PartTypeCategory = 27,

  Permission = 28,

  PhysicalFormatDimension = 29,

  PhysicalFormatMapping = 30,

  Platform = 31,

  PlatformBusinessType = 32,

  PlatformDeliveryAssetType = 33,

  PlatformDeliveryServerType = 34,

  PlatformDisplayGroup = 35,

  PolicyType = 36,

  Price = 37,

  Publisher = 38,

  PublisherRole = 39,

  Rate = 40,

  RechargeCategory = 41,

  RegionCode = 42,

  ResourceType = 43,

  RightsControllerType = 44,

  SaasProduct = 45,

  Service = 46,

  SupplierAddressType = 47,

  SupplierContactType = 48,

  TaxType = 49,

  TaxTypeOption = 50,

  TeamMemberRole = 51,

  Territory = 52,

  TicketsCategory = 53,

  TicketsPartner = 54,

  TicketsPriority = 55,

  TicketsProduct = 56,

  TicketsRating = 57,

  TicketsStatus = 58,

  TicketsTag = 59,

  TicketsUser = 60,

  Timezone = 61,

  UseType = 62,

  WriterRole = 63,

};

export const enum ResourceTypes {
  Official_Website = 1,

  BBC_Music_Id = 2,

  Discogs_Id = 3,

  /**Lyrics URL (Delete)*/
  Lyrics_URL_Delete_ = 4,

  Wikipedia_Id = 5,

  SongKick_Id = 6,

  /**SongKick Id (Delete)*/
  SongKick_Id_Delete_ = 7,

  /**SongKick Tour End Date (DELETE)*/
  SongKick_Tour_End_Date_DELETE_ = 8,

  Music_Brainz_Id = 9,

  YouTube_Channel_Name = 10,

  /**Youtube Password Artist (Delete)*/
  Youtube_Password_Artist_Delete_ = 11,

  YouTube_Channel_Id = 12,

  /**Facebook User Name Artist (Delete)*/
  Facebook_User_Name_Artist_Delete_ = 13,

  Facebook_Id = 14,

  Facebook_Page_Name = 15,

  X_Id = 16,

  Instagram_Id = 17,

  ISNI_Id = 20,

  Spotify_Id = 21,

  Apple_Id = 22,

  Deezer_Id = 23,

  PPL_Performer_Id = 24,

  IPI_Id = 27,

  SoundCloud_Id = 28,

  SCPP_Label_Id = 29,

  LinkedIn_Id = 31,

  /**LinkedIn (Individual) (Delete)*/
  LinkedIn_Individual_Delete_ = 33,

  /**Publisher IPI (Delete)*/
  Publisher_IPI_Delete_ = 35,

  Meta_Pixel_Id = 36,

  Google_Pixel_Id = 37,

  TikTok_Pixel_Id = 38,

  Snapchat_Pixel_Id = 39,

  X_Pixel_Id = 40,

  Reddit_Pixel_Id = 41,

  Amazon_Affiliate_Link = 42,

  Apple_Affiliate_Link = 43,

  Music_Glue_Store = 47,

  YouTube_ArtistId = 51,

  TikTok_Profile = 52,

  Amazon_ASIN = 53,

  Pandora_ArtistId = 54,

  LastFM_ArtistId = 55,

  BandsinTown_Id = 57,

  Genius_Lyrics_Artist_Id = 58,

  MusixMatch_ArtistId = 59,

  IPD_Id = 61,

  MCPS_IPA_Id = 62,

  Meta_Artist_Id = 63,

};

export const enum RightsControllerTypes {
  OriginalOwner = 1,

  SuccessorInTitle = 2,

  ExclusiveLicensee = 3,

};

export const enum SaaSProducts {
  Video_Catalogue = 1,

  Slinky = 2,

  Neighbouring_Rights = 3,

  Radio_Play = 4,

  Publishing_Administrator = 5,

  Podcast = 6,

  Client_FTP = 7,

};

export const enum SlinkyBuildType {
  Full = 0,

  Update = 1,

  Landing = 2,

};

export const enum SlinkyRequestType {
  None = 0,

  Title = 1,

  Format = 2,

};

export const enum StatusTags {
  Draft = 1,

  In_Review = 2,

  Queued = 3,

  Not_Out_Yet = 4,

  In_Market = 5,

  Removed = 6,

  Delivery_Issue = 7,

  Open_Ticket = 8,

  Needs_Approval = 10,

  _2_weeks_left = 11,

};

export const enum SupplierContactTypes {
  Orders = 1,

  Accounts = 2,

  Sales = 3,

  Quotations = 4,

};

export const enum TeamMemberRoles {
  Deal_Creator = 1,

  Label_Manager = 2,

  Digital_Submissions = 3,

  Digital_Marketing = 4,

  Neighbouring_Rights = 5,

  Production = 6,

  Publishing = 7,

  Development = 8,

  Finance = 9,

  Financial_Reporting = 10,

  Sync = 11,

  /**Slinky & PreSave*/
  Slinky_PreSave = 12,

  Physical_Submissions = 13,

  /**Digital Retail & Promotion*/
  Digital_Retail_Promotion = 14,

  Rights_Administration = 15,

};

export const enum TicketCategories {
  Digital_Product_Request = 1,

  Product_Creation_Issue = 2,

  Technical_Portal_Issue = 3,

  Registration_Issue = 5,

  Physical_Product_Request = 6,

  Agreement_Query = 7,

  Client_Reporting_Query = 8,

  Finance_Query = 9,

  Physical_Stock_Query = 10,

  Label_Manager_Query = 11,

  Management_Query = 12,

  Asset_Request = 13,

  Work_Request = 14,

  Contributor_Request = 15,

  Slinky_Request = 19,

};

export const enum TicketEvents {
  Add_comment = 2,

  Mail_to_agent = 3,

  Mail_to_customer = 4,

  Mail_to_group = 5,

  Mail_to_last_collaborator = 6,

  Mark_as_spam = 7,

  Assign_to_agent = 8,

  Set_Priority_as = 9,

  Set_Status_as = 10,

  Set_Tag_as = 11,

  Set_Ticket_Type_as = 12,

  Ticket_Created = 16,

  Comment_Created = 17,

  Ticket_Closed = 18,

  Collaborator_Added = 19,

};

export const enum TicketProductTypes {
  FormatId = 1,

  AssetId = 2,

  ContributorId = 3,

  WorkId = 4,

  SlinkyId = 5,

  LabelId = 6,

  ParentLabelId = 8,

  ClientId = 9,

  FormatAssetId = 10,

  TenantId = 11,

};

export const enum TicketStatus {
  Open = 1,

  Pending = 2,

  Answered = 3,

  Resolved = 4,

  Closed = 5,

};

export const enum TitleTypes {
  AbbreviatedDisplayTitle = 1,

  AlternativeTitle = 2,

  DisplayTitle = 3,

  FirstLineOfText = 4,

  FormalTitle = 5,

  GroupingTitle = 6,

  IncorrectTitle = 7,

  MisspelledTitle = 8,

  OriginalTitle = 9,

  SearchTitle = 10,

  SortingTitle = 11,

  TitleAsPart = 12,

  TitleWithoutPunctuation = 13,

  TranslatedTitle = 14,

  Unknown = 15,

  UserDefined = 16,

};

export const enum UserPermissionAccessLevels {
  Tenant = 1,

  Client = 3,

  Parent_Label = 7,

  Label = 15,

  /**Artist & Label*/
  Artist_Label = 31,

  Publisher_Group = 35,

  Infrrd_Publisher_Group_with_Id = 39,

  Infrrd_Publisher_Group_no_Id = 47,

  Publisher = 99,

  Inferred_Publisher = 103,

  /**Inferred Writer & Publisher*/
  Inferred_Writer_Publisher = 111,

  /**Writer & Publisher*/
  Writer_Publisher = 115,

};

export const enum XmlType {
  All = 1,

  Main = 2,

  Mead = 3,

  Pie = 4,

};

