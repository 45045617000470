import { memo, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { routeTree } from '../../routeMeta';
import ErrorBoundary from '../shared/error-boundary/ErrorBoundary';
import RouteGuard from './RouteGuard';

import useLocationMeta from '@src/hooks/useLocationMeta';
import { PageLayouts, useUiSlice } from '@src/services/layoutService';
import AudioPlayer from '../shared/audio-player/AudioPlayer';
import SnackbarWrapper from '../shared/snackbar-wrapper/SnackbarWrapper';
import Dashboard from './dashboard/Dashboard';

const LayoutMaster = () => {
  return (
    <>
      <LayoutWatcher />
      <Dashboard>
        <AnimatedOutlet />
      </Dashboard>
      <AudioPlayer />
      <SnackbarWrapper />
    </>
  );
};

const AnimatedOutlet = memo(() => {
  const location = useLocationMeta();
  const route = useRoutes(routeTree);

  return (
    <TransitionGroup className={'animateContainer'}>
      <CSSTransition key={location.topLevel} nodeRef={location.meta.ref} timeout={300} classNames="page" unmountOnExit>
        <div ref={location.meta.ref}>
          <ErrorBoundary>
            <RouteGuard routeMeta={location}>{route}</RouteGuard>
          </ErrorBoundary>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
});

const LayoutWatcher = () => {
  const ui = useUiSlice();
  const location = useLocationMeta();

  useEffect(() => {
    const lo = location.meta.layout ?? PageLayouts.dashboard;
    if (lo !== ui.layout) ui.actions.setLayout(lo);
  }, [location.meta.layout, ui.layout, ui.actions]);

  return <></>;
};

export default LayoutMaster;
