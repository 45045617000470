import { OperatingSystem } from '@capacitor/device';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import MainSearch, { SearchGroupId } from '@src/components/layout/MainSearch';
import environment from '@src/environment';
import { PageLayouts, useUiSlice } from '@src/services/layoutService';
import { useEffect, useState } from 'react';
import { MdClose, MdFullscreen, MdMinimize } from 'react-icons/md';
import AccountAvatar from '../../shared/account-avatar/AccountAvatar';
import './ElectronHeader.scss';

const ElectronHeader = () => {
  const [os, setOs] = useState<OperatingSystem>();

  const closeApp = () => {
    (window as any).electron.ipcRenderer.closeWindow();
  };

  const minimize = () => {
    (window as any).electron.ipcRenderer.minimize();
  };

  const toggleMaximize = () => {
    (window as any).electron.ipcRenderer.toggleMaximize();
  };

  const ui = useUiSlice();
  const fullScreen = ui.layout === PageLayouts.fullscreen;

  useEffect(() => {
    environment.runtime.deviceInfo.then((res) => {
      setOs(res.operatingSystem);
    });
  }, []);

  return (
    <Paper className="electron-header" id={'electronHeader'} elevation={3} sx={{ height: ui.topBarElectronHeight }}>
      <Stack className={'electron-header-inner'}>
        <Box>
          {os === 'mac' && (
            <div className="example focus">
              <Stack className="traffic-lights" direction={'row'}>
                <button className="traffic-light traffic-light-close" id="close" onClick={closeApp}></button>
                <button className="traffic-light traffic-light-minimize" id="minimize" onClick={minimize}></button>
                <button className="traffic-light traffic-light-maximize" id="maximize" onClick={toggleMaximize}></button>
              </Stack>
            </div>
          )}
        </Box>
        <Box className="electron-draggable"></Box>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} gap={15}>
          {!fullScreen && (
            <Stack direction={'row'} className={'electron-header-right'} gap={3}>
              <Box flex={1}>
                <MainSearch
                  isTabs
                  allowedTypes={[SearchGroupId.Artists, SearchGroupId.Labels, SearchGroupId.Releases, SearchGroupId.Tracks, SearchGroupId.Slinkys]}
                />
              </Box>
              <AccountAvatar />
            </Stack>
          )}
          {os === 'windows' && (
            <Stack direction={'row'} alignItems={'center'} gap={1} pr={1}>
              <IconButton className="window-button" id="minimize" onClick={minimize}>
                <MdMinimize />
              </IconButton>
              <IconButton className="window-button" id="maximize" onClick={toggleMaximize}>
                <MdFullscreen />
              </IconButton>
              <IconButton className="window-button" id="close" onClick={closeApp}>
                <MdClose />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ElectronHeader;
