import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputProps,
  MenuItem,
  RadioGroup,
  SelectProps,
  Stack,
  Switch,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { ReactElement, useState } from 'react';
import { Control, RefCallBack, useController, useFormContext } from 'react-hook-form';
import { SegmentedControl, SegmentedControlProps } from '../segment-control/SegmentedControl';
import { TextFieldWrapper, TextFieldWrapperProps } from '../text-field-wrapper/TextFieldWrapper';

import { ButtonWrapper, CircleIconButton, SlinkyBadge } from '@anthology/shared/src/components';
import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { SelectListVm } from '@src/api/anthologyApi';
import { allFileTypes } from '@src/constants/fileTypes';
import { useTennantCurrency } from '@src/hooks/useTennantCurrency';
import { UploadDestinations } from '@src/services/blobService';
import { format, parseISO } from 'date-fns';
import { BsChevronDown, BsEye, BsEyeSlash, BsPercent, BsPlus, BsTrash, BsX } from 'react-icons/bs';
import ChipList from '../chip-list/ChipList';
import { ChipListProps } from '../chip-list/ChipListProps';
import { DialogWrapper } from '../dialog/DialogWrapper';
import ImageUploader from '../file-uploader/test/ImageUploader';
import FileViewer from '../filer-viewer/FileViewer';
import HelpTooltip from '../help-tooltip/HelpTooltip';
import RadioGroupWrapper from '../radio-group-wrapper/RadioGroupWrapper';
import { RadioGroupWrapperProps } from '../radio-group-wrapper/RadioGroupWrapperProps';
import AutoCompleteInput, { AutoCompleteInputProps } from './AutoCompleteInput';
import FormGroupSelect, { FormGroupSelectProps } from './FormGroupSelect';
import style from './FormHook.module.scss';
import TimeStampSelector from './TimeStampSelector/TimeStampSelector';

export type HookFormRenderProps = {
  helperText?: string;
  error?: boolean;
  onBlur: (...event: any[]) => void;
  onChange: (...event: any[]) => void;
  name: string;
  inputRef: RefCallBack;
  value: any;
};

export type MicroHookFormProps = {
  name: string;
  control?: Control<any>;
  defaultValue?: any;
  onChange?: (...event: any[]) => void;
  onBlur?: () => void;
  valueTransform?: (x: any) => any;
};

export type HookFormProps = {
  children?: (x: HookFormRenderProps) => ReactElement;
  render?: (x: HookFormRenderProps) => ReactElement;
  required?: boolean;
} & MicroHookFormProps;

export type SwitchProps = {
  size?: 'small' | 'medium' | undefined;
};

export default function FormHook({ name, control, defaultValue, children, render, onBlur, onChange, valueTransform }: HookFormProps) {
  const ctx = useFormContext();
  const ctrl = control ?? ctx.control;

  const {
    field: { onBlur: hookOnBlur, onChange: hookOnChange, value, ref: inputRef },
    fieldState: { error },
  } = useController({ name, control: ctrl, defaultValue });

  const errInfo = !!error ? { helperText: error?.message ?? ' ', error: true } : {};

  const cmbChange = (x: any) => {
    if (valueTransform && x) {
      const value = 'target' in x ? valueTransform(x.target.value) : valueTransform(x);
      x = 'target' in x ? { currentTarget: { value }, target: { value } } : value;
    }
    hookOnChange(x);
    onChange?.(x);
  };
  const cmbBlur = () => {
    hookOnBlur();
    onBlur?.();
  };
  const renderProps: HookFormRenderProps = { onBlur: cmbBlur, onChange: cmbChange, name, inputRef, value, ...errInfo };

  if (!!render) {
    return render(renderProps);
  }

  return children!(renderProps);
}

export function FormHookHidden({ name, control, defaultValue, onBlur, onChange, valueTransform }: MicroHookFormProps) {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange }}>{({ value, ...hook }) => <Input type="hidden" {...hook} value={value ?? ''} />}</FormHook>
  );
}

const numericTransform = (value: any) => (value ? parseInt(value) : null);

export function FormHookText({
  name,
  control,
  defaultValue,
  onBlur,
  onChange,
  valueTransform,
  valueFormatter,
  conditionalInputProps,
  ...rest
}: MicroHookFormProps & TextFieldWrapperProps & { valueFormatter?: (value: any) => any; conditionalInputProps?: (value: string) => InputProps }) {
  const isNumber = rest.type === 'number' || rest.inputProps?.itemType === 'number';
  defaultValue = defaultValue !== undefined ? defaultValue : isNumber ? null : '';
  if (isNumber && !valueTransform) {
    valueTransform = numericTransform;
  }
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ value, ...hook }) => (
        <TextFieldWrapper
          {...rest}
          {...hook}
          value={valueFormatter ? valueFormatter(value) : value ?? ''}
          InputProps={{ ...rest.InputProps, ...conditionalInputProps?.(value) }}
        />
      )}
    </FormHook>
  );
}

export function FormHookPassword({ name = 'password', label = 'Password', variant = 'standard', ...rest }: MicroHookFormProps & TextFieldWrapperProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <FormHookText
      name={name}
      label={label}
      variant={variant}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <BsEyeSlash /> : <BsEye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

export type FormHookRadioProps = {
  children?: React.ReactNode;
  label?: string;
};

export function FormHookRadio({ name, control, defaultValue, onBlur, onChange, valueTransform, children, label }: MicroHookFormProps & FormHookRadioProps) {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange }}>
      {({ value, error, inputRef, helperText, ...hook }) => (
        <FormControl {...hook}>
          {label && <FormLabel>{label}</FormLabel>}
          <RadioGroup>
            <Stack direction={'row'}>{children}</Stack>
          </RadioGroup>
          {error && (
            <FormHelperText sx={{ marginLeft: 0 }} error={!!error}>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </FormHook>
  );
}

export function FormHookDropDown({ name, control, defaultValue, onBlur, onChange, valueTransform, ...rest }: MicroHookFormProps & AutoCompleteInputProps<any>) {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ helperText, error, ...hook }) => (
        <AutoCompleteInput
          {...rest}
          inputProps={{ ...rest.inputProps, helperText: helperText ?? rest.inputProps?.helperText, error, placeholder: rest.placeholder }}
          {...hook}
        />
      )}
    </FormHook>
  );
}

export function FormHookSegment({ name, control, defaultValue, onBlur, onChange, valueTransform, ...rest }: MicroHookFormProps & SegmentedControlProps) {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ inputRef, ...hook }) => <SegmentedControl {...rest} {...hook} />}
    </FormHook>
  );
}

export function FormHookSwitch({
  name,
  control,
  defaultValue,
  onBlur,
  onChange,
  valueTransform,
  label,
  helpTooltip,
  ...rest
}: Partial<FormControlLabelProps> & MicroHookFormProps & SwitchProps & { helpTooltip?: string }) {
  const labelTypography = label && <Typography variant="labelLarge">{label}</Typography>;
  const labelContent = labelTypography ? (
    helpTooltip ? (
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Box>{labelTypography}</Box>
        <HelpTooltip title={helpTooltip} />
      </Stack>
    ) : (
      labelTypography
    )
  ) : null;
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ value, ...hook }) => (
        <FormControlLabel {...(rest as any)} sx={{ width: 'fit-content' }} control={<Switch {...hook} checked={!!value} />} label={labelContent} />
      )}
    </FormHook>
  );
}

export function FormHookCheckbox({
  name,
  control,
  defaultValue,
  onBlur,
  onChange,
  valueTransform,
  label,
  ...rest
}: Partial<FormControlLabelProps> & MicroHookFormProps & CheckboxProps) {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ value, ...hook }) => (
        <FormControlLabel {...(rest as any)} control={<Checkbox {...rest} {...hook} checked={!!value} />} label={label ? label : null} />
      )}
    </FormHook>
  );
}

type FormHookDatePickerProps = {
  textFieldProps?: Partial<TextFieldWrapperProps>;
  fullWidth?: boolean; //convienience to override value in textFieldProps
} & MicroHookFormProps &
  Partial<DatePickerProps<Date>>;

export function FormHookDatePicker({
  name,
  control,
  defaultValue,
  onBlur,
  onChange,
  valueTransform,
  textFieldProps,
  fullWidth = true,
  ...rest
}: FormHookDatePickerProps) {
  const [open, setOpen] = useState(false);

  const getDate = (val: any): string => {
    try {
      return typeof val === 'string' ? format(new Date(val), 'EEE MMM d, yyyy') : format(val, 'EEE MMM d, yyyy');
    } catch {
      return '';
    }
  };

  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ value, inputRef, ...hook }: HookFormRenderProps) => {
        return (
          <Stack>
            <Stack direction={'row'} gap={1} marginBottom={'5px'}>
              <DatePicker
                open={open}
                onClose={() => setOpen(false)}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    ...hook,
                    fullWidth: true,
                    size: 'small',
                    required: textFieldProps?.required,
                    color: textFieldProps?.helperText ? 'warning' : 'primary',
                    focused: !!textFieldProps?.helperText,
                    InputProps: { readOnly: true },
                    onClick: () => setOpen(true),
                  },
                }}
                inputRef={inputRef}
                value={typeof value == 'string' ? parseISO(value) : value}
                {...rest}
                {...hook}
              />
              {!textFieldProps?.required && (value || !fullWidth) && (
                <IconButton onClick={() => hook.onChange(null)} sx={{ visibility: value ? 'visible' : 'hidden' }}>
                  <BsX />
                </IconButton>
              )}
              {textFieldProps?.helpTooltip && <HelpTooltip title={textFieldProps?.helpTooltip} />}
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="caption" marginLeft={1} color={'text.secondary'}>
                {value && getDate(value)}
              </Typography>
              {textFieldProps?.helperText && (
                <>
                  &nbsp; - &nbsp;
                  <Typography variant="caption" color={'warning.main'}>
                    {textFieldProps?.helperText}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        );
      }}
    </FormHook>
  );
}

type FormHookDateTimePickerProps = {
  textFieldProps?: Partial<TextFieldWrapperProps>;
  fullWidth?: boolean; //convienience to override value in textFieldProps
} & MicroHookFormProps &
  Partial<DateTimePickerProps<Date>>;

export function FormHookDateTimePicker({
  name,
  control,
  defaultValue,
  onBlur,
  onChange,
  valueTransform,
  textFieldProps,
  fullWidth = true,
  ...rest
}: FormHookDateTimePickerProps) {
  const getDate = (val: any): string => {
    try {
      return typeof val === 'string' ? format(new Date(val), 'EEE MMM d yyyy, HH:mm') : format(val, 'EEE MMM d yyyy, HH:mm');
    } catch {
      return '';
    }
  };
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ value, inputRef, ...hook }: HookFormRenderProps) => {
        return (
          <Stack>
            <Stack direction={'row'} gap={1} marginBottom={'5px'}>
              <DateTimePicker
                format="dd/MM/yyyy HH:mm"
                slotProps={{ textField: { ...hook, fullWidth: true, size: 'small' } }}
                inputRef={inputRef}
                value={typeof value == 'string' ? parseISO(value) : value}
                {...rest}
                {...hook}
              />
              {!textFieldProps?.required && value && (
                <IconButton onClick={() => hook.onChange(null)}>
                  <BsX />
                </IconButton>
              )}
              {textFieldProps?.helpTooltip && <HelpTooltip title={textFieldProps?.helpTooltip} />}
            </Stack>
            <Typography variant="caption" marginLeft={1} color={'text.secondary'}>
              {value && getDate(value)}
            </Typography>
          </Stack>
        );
      }}
    </FormHook>
  );
}

export const FormHookGroupSelect = ({
  name,
  control,
  defaultValue,
  onBlur,
  onChange,
  valueTransform,
  isRequired,
  ...rest
}: MicroHookFormProps & FormGroupSelectProps) => {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange }}>
      {({ helperText, error, ...hook }) => <FormGroupSelect {...rest} {...hook} helperText={helperText} isRequired={isRequired} error={error} />}
    </FormHook>
  );
};

export const FormHookTimeStamp = ({ name, control, defaultValue, onBlur, onChange, valueTransform, ...rest }: MicroHookFormProps) => {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ value, ...hook }) => <TimeStampSelector {...rest} {...hook} value={value} />}
    </FormHook>
  );
};

export const FormHookColorPicker = ({ name, label, ...rest }: MicroHookFormProps & TextFieldWrapperProps) => {
  return (
    <Stack direction={'row'} className={style.colorPicker}>
      <FormHookText name={name} type="color" helperText={''} {...rest} />
      <BsChevronDown />
      <Typography variant="labelMedium">{label}</Typography>
    </Stack>
  );
};

export const FormHookPercentage = ({ name, label, ...rest }: MicroHookFormProps & TextFieldWrapperProps) => {
  return (
    <FormHookText
      defaultValue={null}
      name={name}
      label={label}
      inputProps={{
        type: 'number',
        max: 100,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <BsPercent />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export const FormHookCurrency = ({
  currencySymbol,
  highlightNegativeValue,
  ...rest
}: MicroHookFormProps & TextFieldWrapperProps & { currencySymbol?: string; highlightNegativeValue?: boolean }) => {
  const { data: tennantCurrency } = useTennantCurrency();
  return (
    <FormHookText
      valueTransform={(value) => (!value ? null : value.replace(/[^0-9.-]/g, ''))}
      {...rest}
      InputProps={{
        ...(rest.InputProps ?? {}),
        startAdornment: <InputAdornment position="start">{currencySymbol || tennantCurrency?.description || '£'}</InputAdornment>,
      }}
      conditionalInputProps={(value) => ({
        endAdornment: highlightNegativeValue && parseInt(value as string) < 0 ? <SlinkyBadge label="Please note, this is a credit." color="error" /> : <></>,
      })}
    />
  );
};

export const FormHookSelect = ({
  name,
  label,
  id,
  options,
  control,
  defaultValue,
  fullWidth,
  isRequired,
  size,
  onBlur,
  onChange,
  valueTransform,
  SelectProps,
  ...rest
}: Partial<FormControlLabelProps> &
  Partial<FormControlProps> &
  Partial<TextFieldProps> &
  Partial<SelectProps> &
  MicroHookFormProps & { options: SelectListVm[]; fullWidth?: boolean; isRequired?: boolean }) => {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform, fullWidth, isRequired, size }}>
      {({ ...hook }) => (
        <FormHookText {...rest} {...hook} select label={label} SelectProps={SelectProps}>
          {options.map((option) => (
            <MenuItem value={option?.id} key={option?.id}>
              {option?.text}
            </MenuItem>
          ))}
        </FormHookText>
      )}
    </FormHook>
  );
};

export const FormHookRadioGroup = ({
  name,
  label,
  options,
  control,
  defaultValue,
  fullWidth,
  row,
  gap,
  onBlur,
  onChange,
  valueTransform,
}: Partial<FormControlLabelProps> & MicroHookFormProps & RadioGroupWrapperProps) => {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ inputRef, ...hook }) => (
        <FormControl fullWidth={fullWidth}>
          <FormLabel>{label}</FormLabel>
          <RadioGroupWrapper options={options} defaultValue={defaultValue} row={row} {...hook} sx={{ gap: gap || 1 }} />
        </FormControl>
      )}
    </FormHook>
  );
};

export const FormHookChipList = ({
  name,
  control,
  defaultValue,
  fullWidth,
  size,
  onBlur,
  onChange,
  valueTransform,
  chipColor,
  label,
}: Partial<FormControlLabelProps> & Partial<FormControlProps> & MicroHookFormProps & Partial<ChipListProps> & { fullWidth?: boolean }) => {
  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange, valueTransform }}>
      {({ inputRef, ...hook }) => (
        <FormControl fullWidth={fullWidth} size={size}>
          <ChipList {...hook} textFieldProps={{ size: size }} chipColor={chipColor} label={label} />
        </FormControl>
      )}
    </FormHook>
  );
};

export const FormHookAttachment = ({
  name,
  bucket,
  control,
  defaultValue,
  onBlur,
  onChange,
  accept = allFileTypes.map((type) => `.${type}`).join(','),
  maxSizeMb,
}: Partial<FormControlLabelProps> & Partial<FormControlProps> & MicroHookFormProps & { bucket: UploadDestinations; accept?: string; maxSizeMb?: number }) => {
  const [showAddAttachment, setShowAddAttachment] = useState(false);

  return (
    <FormHook {...{ name, control, defaultValue, onBlur, onChange }}>
      {({ inputRef, ...hook }) => {
        const onChange = (s: any) => {
          hook.onChange(s as string);
          setShowAddAttachment(false);
        };
        const url = hook.value;
        return (
          <>
            <Stack alignItems={'flex-end'} mb={2}>
              {url ? (
                <Tooltip title={url}>
                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Box className="clickable" onClick={() => setShowAddAttachment(true)}>
                      <FileViewer url={url} size="small" />
                    </Box>
                    <Box>
                      <CircleIconButton onClick={() => hook.onChange?.(null)}>
                        <BsX />
                      </CircleIconButton>
                    </Box>
                  </Stack>
                </Tooltip>
              ) : (
                <ButtonWrapper size="small" color="inherit" startIcon={<BsPlus />} onClick={() => setShowAddAttachment(true)}>
                  Add attachment
                </ButtonWrapper>
              )}
            </Stack>
            <DialogWrapper title={'Attachment'} open={showAddAttachment} onClose={() => setShowAddAttachment(false)} maxWidth={'lg'}>
              {url ? (
                <Stack gap={3} mt={3}>
                  <Stack alignItems={'flex-end'}>
                    <ButtonWrapper color="inherit" startIcon={<BsTrash />} onClick={() => hook.onChange?.(null)}>
                      Remove/Replace
                    </ButtonWrapper>
                  </Stack>
                  <FileViewer url={hook.value} size="full" />
                </Stack>
              ) : (
                <Stack gap={2} mt={2}>
                  <Typography color={'text.secondary'} variant="bodyLarge">
                    If an attachment is larger than 50mb then please send a link to a shared drive or drop box in the reply
                  </Typography>
                  <ImageUploader
                    accept={accept}
                    onChange={onChange}
                    uploaderOptions={{ destinationId: bucket }}
                    sx={{ height: '480px', width: '850px' }}
                    maxSizeMb={maxSizeMb}
                  />
                </Stack>
              )}
            </DialogWrapper>
          </>
        );
      }}
    </FormHook>
  );
};
