import { useDebouncedCallback, useHookableRef, useResizeObserver, UseResizeObserverCallback } from '@react-hookz/web';
import { MutableRefObject, useState } from 'react';

export function useMeasureThrottle<T extends Element>(throttleMs = 0, enabled = true): [DOMRectReadOnly | undefined, MutableRefObject<T | null>] {
  const [element, setElement] = useState<T | null>(null);
  const [measures, setMeasures] = useState<DOMRectReadOnly | undefined>(undefined);

  const elementRef = useHookableRef<T | null>(null, (v) => {
    setElement(v);
    return v;
  });

  const cb = useDebouncedCallback(((e) => setMeasures(e.contentRect)) as UseResizeObserverCallback, [setMeasures], throttleMs);

  useResizeObserver(element, cb, enabled);

  return [measures, elementRef];
}
