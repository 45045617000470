import { RootState, store } from '../features/store';
import { EqualityFn, shallowEqual } from 'react-redux';
import { Unsubscribe } from '@reduxjs/toolkit';

/**
 * Adds a change listener. It will be called any time an action is
 * dispatched and the new state is different to last state according to equalityFn
 *
 * @param selector A function to extract a slice (or any key) from the store.
 * @param equalityFn Optional specify how to compare states, defaults to shallow compare.
 * @param callImmediate Should the callback be called with initial state?.
 * @param listener A callback to be invoked on every change.
 * @returns A function to unsubscribe.
 */
export const subscribeToSlice: <Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn: EqualityFn<Selected> | undefined,
  callImmediate: boolean,
  listener: (newState: Selected, oldState: Selected) => void
) => Unsubscribe = (selector, equalityFn, callImmediate, listener) => {
  const eq = equalityFn ?? ((a, b) => shallowEqual(a, b));
  let lastState = selector(store.getState());

  const unsub = store.subscribe(() => {
    const state = selector(store.getState());
    if (!eq(state, lastState)) {
      listener(state, lastState);
      lastState = state;
    }
  });

  if (callImmediate) {
    listener(lastState, undefined as any);
  }

  return unsub;
};
