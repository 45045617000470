import { anthologyApi } from '@api/anthologyApi';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { unstable_batchedUpdates } from 'react-dom';
import { createReduxHistoryContext } from 'redux-first-history';
import storage from 'redux-persist/lib/storage';
import environment from '../environment';
import authSlice from './authSlice';
import themeSlice from './themeSlice';

import { mockApi } from '@api/mockApiBase';
import { slinkyApi } from '@api/slinkyApi';
import diagnosticsService from '@services/diagnosticsService';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import configSlice from './configSlice';
import createSlice from './createSlice';
import slinkySlice from './slinkySlice';
import uiSlice from './uiSlice';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  batch: unstable_batchedUpdates,
  reduxTravelling: true,
  savePreviousLocations: 2,
});

const rootReducer = combineReducers({
  [anthologyApi.reducerPath]: anthologyApi.reducer,
  [slinkyApi.reducerPath]: slinkyApi.reducer,
  [mockApi.reducerPath]: mockApi.reducer,
  auth: authSlice,
  router: routerReducer,
  theme: themeSlice,
  config: configSlice,
  create: createSlice,
  slinky: slinkySlice,
  ui: uiSlice,
});

//const persistSlices = isDev ? ['auth'] : ['auth', 'theme', 'config'];
const persistSlices = ['auth', 'theme'];
//const persistSlices = ['auth'];

const persistConfig = {
  key: 'root',
  storage,
  version: environment.autoSourceHash as any,
  whitelist: persistSlices,
  migrate: (state, currentVersion) => {
    if ((currentVersion as any) !== environment.autoSourceHash) {
      diagnosticsService.warn(`Version update ${currentVersion} to ${environment.autoSourceHash} - Clearing state`);
      return Promise.resolve({} as any);
    }
    return Promise.resolve(state);
  },
} as PersistConfig<any>;

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer) as typeof rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(anthologyApi.middleware)
      .concat(slinkyApi.middleware)
      .concat(mockApi.middleware)
      .concat(routerMiddleware);
  },
  devTools: environment.enableReduxDevTools && {
    maxAge: 200,
    trace: false,
    // actionsDenylist: 'api/.*'
  },
});

export type RootState = ReturnType<typeof store.getState>;
export const storePersistor = persistStore(store);
export const history = createReduxHistory(store);
