import { Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

const IconList = ({ array, renderIcon, max, sx }: { array: any[]; renderIcon: (item: any) => ReactNode; max: number; sx?: SxProps }) => {
  const additional = array.length - max;
  return (
    <Stack direction={'row'} gap={0.5} alignItems={'center'} sx={sx}>
      {array.slice(0, max).map((o) => (
        <Tooltip title={o.text}>
          <Stack alignItems={'center'} justifyContent={'center'}>
            {renderIcon(o)}
          </Stack>
        </Tooltip>
      ))}
      <Typography variant="bodyLarge" color={'text.secondary'} pl={2}>
        {array.length > max ? `+${additional}` : ''}
      </Typography>
    </Stack>
  );
};

export default IconList;
