import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LayoutMaster from '@src/components/layout/LayoutMaster';
import configService from '@src/services/configService';
import React, { useMemo } from 'react';
import { Provider, useSelector } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';
import MobileAppWrapper from './components/mobile/mobile-app-wrapper/MobileAppWrapper';
import environment, { isDev } from './environment';
import { RootState, history, store, storePersistor } from './features/store';
import { useEffectOnce } from './hooks/useEffectOnce';
import authService from './services/authService';
import diagnosticsService from './services/diagnosticsService';
import notificationService from './services/notificationService';
import themeService from './services/themeService';
import updateService from './services/updateService';
import './utils/global';
import { initMobileApp } from './utils/mobileApp';

//setup
diagnosticsService.init();
notificationService.init();
updateService.init();

diagnosticsService.info(`Dev mode is ${isDev} api server is at ${environment.apiRoot}`);
LicenseInfo.setLicenseKey(environment.MuiLicenceKey);

export const AppRoot = () => {
  initMobileApp();

  if (environment.runtime.isMobileApp) {
    return (
      <MobileAppWrapper>
        <EntireApp />
      </MobileAppWrapper>
    );
  }

  return <EntireApp />;
};

const EntireApp = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={storePersistor}>
          <AppWithRedux />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

const AppWithRedux = () => {
  useEffectOnce(() => {
    //one time setup of services THAT REQUIRE REDUX store hydration
    configService.init();
    themeService.init();
    authService.refreshPermissions();
  });

  const { theme } = useSelector((state: RootState) => state.theme);
  const themeOptions = useMemo(() => themeService.getTheme(theme.customDomain, theme.isDark), [theme]);

  return (
    <CssVarsProvider theme={themeOptions as any} defaultMode="light">
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <HistoryRouter history={history}>
          <AppWithTheme />
        </HistoryRouter>
      </LocalizationProvider>
    </CssVarsProvider>
  );
};

const AppWithTheme = () => {
  return <LayoutMaster />;
};
