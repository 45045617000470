import { capitaliseInitial } from '@anthology/shared/src/utils/string';
import { Chip, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { ChipListProps } from './ChipListProps';

const ChipList = ({ value, onChange, textFieldProps, chipColor, inputRef, label }: ChipListProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const onChangeInput = (newValue: string, isBlur?: boolean) => {
    const itemArray = newValue.split(',');
    if (itemArray.length > 1 || (isBlur && newValue.trim().length > 0)) {
      const l = itemArray.length;
      setInputValue(itemArray[l - 1]);
      setTimeout(() => {
        const newTextArray = isBlur ? [itemArray[0]] : itemArray.slice(0, l - 1);
        const newChips = newTextArray.map((chip) => capitaliseInitial(chip.toLowerCase().trim()));
        onChange(_.uniq([...(value || []), ...newChips]));
      });
    } else {
      setInputValue(newValue);
    }
  };

  const deleteChip = (chipId: string) => {
    if (!chipId) return;
    const updatedChips = value.filter((chip) => chip !== chipId);
    onChange(updatedChips);
  };

  const Chips = () => (
    <Stack direction={'row'} gap={1} mr={1.25} flexWrap={'wrap'} py={1}>
      {value &&
        value.map((chip) => <Chip label={chip} key={chip} sx={{ backgroundColor: chipColor }} onDelete={() => deleteChip(chip)} size={textFieldProps?.size} />)}
    </Stack>
  );

  return (
    <Stack gap={1}>
      {label && (
        <Typography variant="labelMedium" color={'text.secondary'}>
          {label}
        </Typography>
      )}
      <TextField
        InputProps={{ startAdornment: <Chips /> }}
        inputProps={{
          pattern: '[a-z0-9-]',
        }}
        inputRef={inputRef}
        placeholder="Tags, separated by commas..."
        value={inputValue}
        onChange={(e) => onChangeInput(e.currentTarget.value)}
        onBlur={(e) => onChangeInput(e.currentTarget.value, true)}
        {...textFieldProps}
        sx={{ '.MuiInputBase-root': { flexWrap: 'wrap', flex: 1, input: { flex: 1 } } }}
      />
    </Stack>
  );
};

export default ChipList;
