import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { Avatar, SxProps } from '@mui/material';
import { getNameInitials, useAuth } from '@src/services/authService';
import { stringToColour } from '@src/utils/helpers';
import { readableColor } from 'color2k';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissionRoleCheck } from '../permission-guard/PermissionGuard';

const AccountAvatar = ({
  isLarge,
  names,
  userId,
  colourless,
  sx,
}: {
  isLarge?: boolean;
  names?: string[];
  userId?: number;
  colourless?: boolean;
  sx?: SxProps;
}) => {
  const isMobileLayout = useCssBreakpoint('sm');
  const size = isLarge ? 85 : 35;
  const auth = useAuth();
  const avt = useMemo(() => {
    const fullName = names ? (names.length === 1 ? names[0] : `${names[0]} ${names[1]}`) : auth.actions.getFullName();
    const col = auth.auth.isSignedIn ? stringToColour(fullName ?? '') : 'black';
    const initials = names ? (names.length > 1 ? `${names[0][0]}${names[1][0]}` : getNameInitials(names[0])) : auth.actions.getInitials();
    return { initials: initials, color: col, contrast: readableColor(col) };
  }, [auth.actions, auth.auth.isSignedIn, names]);

  const isUserAdmin = usePermissionRoleCheck(['profileadmin.useradmin']);

  const nav = useNavigate();
  const avatarClick = () => {
    nav(isMobileLayout ? '/appSettings/' : `/account/${userId}`);
  };

  const colourProps = colourless
    ? {
        color: '#ffffff',
        background: '#1b1c29',
      }
    : {};

  return (
    <Avatar
      className={`Avatar ${isUserAdmin ? 'clickable' : ''}`}
      onClick={isUserAdmin ? avatarClick : undefined}
      sx={{ bgcolor: avt.color, color: avt.contrast, height: size, width: size, ...colourProps, ...sx }}
    >
      {avt.initials}
    </Avatar>
  );
};

export default AccountAvatar;
