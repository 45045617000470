import { CommonIcons } from '@src/constants/commonIcons';
import { ListRowColumn } from '../list-row/ListRowProps';

export const CatalogueColumns: ListRowColumn[] = [
  { field: 'packshot', isImg: true, width: 40 },
  { field: 'title', width: 275, isBold: true, title: 'Title' },
  { field: 'caption', width: 180, isBold: true, breakPoint: 'md', title: 'Artist' },
  { field: 'label', width: 150, breakPoint: 'xl', title: 'Label' },
  { field: 'date', width: 100, breakPoint: 'lg', title: 'Release' },
  { field: 'digitalPhysical', width: 25, breakPoint: 'md', title: 'Format' },
  { field: 'formatGroup', width: 25, breakPoint: 'md' },
  { field: 'status', width: 60, isBadge: true, breakPoint: 'lg' },
  { field: 'secondaryStatus', width: 20, breakPoint: 'lg' },
  { field: 'upc', width: 150, isCopy: true, valueFormatter: (value: any) => `UPC: ${value}`, isExtra: true, key: 'upc' },
  { field: 'catNo', width: 225, isCopy: true, valueFormatter: (value) => `CAT#: ${value}`, isExtra: true, key: 'catNo' },
];

export const DigitalPhysical = {
  Digital: {
    id: 'true',
    label: 'Digital',
    icon: CommonIcons.ProductDigital.Regular,
  },
  Physical: {
    id: 'false',
    label: 'Physical',
    icon: CommonIcons.ProductPhysical.Regular,
  },
};
