import { ImageWrapper } from '@anthology/shared/src/components';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, colors } from '@mui/material';
import { Stack } from '@mui/system';
import NavItem from '@src/components/layout/nav-item/NavItem';
import { memo, useCallback } from 'react';
import './SideNavigation.scss';

import { setSideMenuState } from '@src/features/uiSlice';
import { mainRoutes, subMenuRoutes } from '@src/routeMeta';

import { useDispatch } from 'react-redux';
import { MainSearchModal } from '../MainSearch';

import NavballMenu from '../navball-menu/NavballMenu';

import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import environment from '@src/environment';
import { useAllowedRoutes } from '@src/hooks/useAllowedRoutes';
import { useLogo } from '@src/hooks/useLogo';
import { useUiSlice } from '@src/services/layoutService';
import { BsX } from 'react-icons/bs';
import { MdMenu } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const SideNavigation = () => {
  const ui = useUiSlice();
  const dispatch = useDispatch();
  const isMobileLayout = useCssBreakpoint('sm');
  const closeMobileSideNav = () => {
    if (isMobileLayout) {
      dispatch(setSideMenuState({ isOpen: false }));
    }
  };

  return ui.isSideBarOpen ? (
    <Stack className="NavContainer">
      <Box className="Nav" bgcolor={'background.paper'} width={ui.sideBarWidth}>
        <SideMenuContent></SideMenuContent>
      </Box>
      <Box className="NavBackdrop" onClick={closeMobileSideNav} />
    </Stack>
  ) : (
    <></>
  );
};

export const SideMenuContent = memo(() => {
  const ui = useUiSlice();

  const primaryNavs = useAllowedRoutes(mainRoutes.filter((route) => route.navListItem));
  const secondaryNavs = useAllowedRoutes(subMenuRoutes);
  const iconSize = 24;

  return (
    <Stack justifyContent={'space-between'} height={`100%`}>
      <Stack gap={3} p={1}>
        <SideBarHeader />
        <NavballMenu isCollapsed={ui.isSideBarCollapsed} />
        <Stack gap={2} pt={2}>
          {primaryNavs.map((item) => (
            <NavItem item={item} hideIcon key={item.path} isCollapsed={ui.isSideBarCollapsed} iconSize={iconSize} />
          ))}
        </Stack>
      </Stack>
      <Box gap={1} borderTop={'solid 1px'} borderColor={colors.grey[800]}>
        {!environment.runtime.isElectron && (
          <Stack py={1.5} gap={1}>
            {secondaryNavs.map((item) => (
              <NavItem item={item} key={item.path} isCollapsed={ui.isSideBarCollapsed} iconSize={iconSize} />
            ))}
          </Stack>
        )}
        {/* <Box borderTop={'solid 1px'} borderColor={colors.grey[800]}>
          <ThemeSwitcherComponent isCollapsed={ui.isSideBarCollapsed} />
        </Box> */}
      </Box>
    </Stack>
  );
});

export const SideBarHeader = memo(({ isTop }: { isTop?: boolean }) => {
  const ui = useUiSlice();
  const nav = useNavigate();

  const dispatch = useDispatch();

  const isElectronMode = environment.runtime.isElectron;

  const isCollapsed = ui.isSideBarCollapsed;

  const isMobileLayout = useCssBreakpoint('sm');

  const showLogo = (!isCollapsed && !isMobileLayout) || isTop;
  const logo = useLogo();

  const handleToggle = useCallback(() => {
    if (ui.isSideBarFloating) {
      dispatch(setSideMenuState({ isOpen: !ui.isSideBarOpen }));
    } else {
      dispatch(setSideMenuState({ isCollapsed: !ui.isSideBarCollapsed }));
    }
  }, [dispatch, ui.isSideBarCollapsed, ui.isSideBarFloating, ui.isSideBarOpen]);

  return !environment.runtime.isElectron ? (
    <Stack
      direction={'row'}
      gap={0.8}
      alignItems={'center'}
      justifyContent={isElectronMode ? 'center' : isCollapsed ? 'center' : 'justify-start'}
      width={'100%'}
    >
      {isMobileLayout ? <></> : (!ui.isSideBarFloating || isTop) && (!isMobileLayout || isTop) && <IconButton onClick={handleToggle}>{<MdMenu />}</IconButton>}
      {showLogo && (
        <Stack mr={2} flexGrow={1} onClick={() => nav('/')} className="clickable">
          <ImageWrapper src={logo} fullWidth={ui.topBarHeight === 0 && !isTop} height={isTop ? ui.topBarHeight * 0.6 : undefined} fit={'contain'} />
        </Stack>
      )}
      {isTop && <MainSearchModal>{(so, io) => <IconButton onClick={() => so(true)}>{io ? <BsX /> : <SearchIcon />}</IconButton>}</MainSearchModal>}
    </Stack>
  ) : (
    <></>
  );
});

export default SideNavigation;
