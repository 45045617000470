import { forwardRef, useRef, useEffect, useMemo } from 'react';

const ForwardBodyScrollEvents = forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & { scrollParent?: HTMLElement }>(
  ({ onScroll, children, scrollParent }, forwardedRef) => {
    const { hostListner, hostElement } = useMemo(() => {
      if (scrollParent && (scrollParent as any) !== window) {
        return { hostListner: scrollParent, hostElement: scrollParent };
      }

      return { hostListner: window, hostElement: document.documentElement };
    }, [scrollParent]);

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const bodyScroll = (e: any) => {
        if (!(onScroll instanceof Function)) {
          return;
        }

        const { clientWidth, clientHeight, scrollLeft, scrollTop, scrollHeight, scrollWidth } = hostElement;

        if (onScroll != null) {
          onScroll({
            currentTarget: {
              clientHeight,
              clientWidth,
              scrollLeft,
              addEventListener: () => {},
              removeEventListener: () => {},
              dispatchEvent: () => false,
              scrollTop: scrollTop - (containerRef.current ? containerRef.current.getBoundingClientRect().top + scrollTop : 0),
              scrollHeight,
              scrollWidth,
            },
          } as unknown as React.UIEvent<HTMLElement>);
        }
      };

      hostListner.addEventListener('scroll', bodyScroll);
      return () => hostListner.removeEventListener('scroll', bodyScroll);
    }, [onScroll, hostListner, hostElement]);

    if (forwardedRef != null && !(forwardedRef instanceof Function)) {
      forwardedRef.current = hostElement;
    }

    return (
      <div ref={containerRef} style={{ position: 'relative' }}>
        {children}
      </div>
    );
  }
);

export default ForwardBodyScrollEvents;
