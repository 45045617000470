import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { RadioGroupWrapperProps } from './RadioGroupWrapperProps';

const RadioGroupWrapper = ({ defaultValue, row, options, gap, title, onChange, ...props }: RadioGroupWrapperProps) => {
  // Allow for boolean options
  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    const isBoolean = options.some((option) => option.id === true);
    const updatedValue = isBoolean ? value === 'true' : value;
    onChange?.(
      {
        ...event,
        target: {
          ...event.target,
          value: updatedValue,
        },
      },
      updatedValue
    );
  };
  return (
    <Stack>
      {title && (
        <Typography variant="labelLarge" mb={2}>
          {title}
        </Typography>
      )}
      <RadioGroup defaultValue={defaultValue} row={row} {...props} sx={{ gap: 3 }} onChange={onChangeRadio}>
        {options.map((option) => {
          const label = (
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              {option.icon && <Box>{option.icon({ size: 30, style: { marginLeft: 20 } })}</Box>}
              <Stack ml={0.5}>
                <Typography variant="h5">{option.text}</Typography>
                {option.description && (
                  <Typography variant="sub5" color="text.secondary">
                    {option.description}
                  </Typography>
                )}
              </Stack>
            </Stack>
          );
          return <FormControlLabel key={option.id.toString()} value={option.id} control={<Radio />} label={label} />;
        })}
      </RadioGroup>
    </Stack>
  );
};

export default RadioGroupWrapper;
