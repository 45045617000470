import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LoginResponseViewModel } from '@api/anthologyApi';

type AuthState = {
  user?: LoginResponseViewModel;
  isSignedIn: boolean;
  isError: boolean;
  expiresAt: string;
};

const init: AuthState = {
  user: undefined,
  isSignedIn: false,
  isError: false,
  expiresAt: new Date(1900, 1, 1).toISOString(),
};

const slice = createSlice({
  name: 'auth',
  initialState: init,
  reducers: {
    authSetCredentials: (state, { payload }: PayloadAction<LoginResponseViewModel>) => {
      if (payload.token) {
        const exp = new Date();
        exp.setSeconds(exp.getSeconds() + payload.expiresIn!);
        state.user = payload;
        state.isSignedIn = true;
        state.isError = false;
        state.expiresAt = exp.toISOString();
      } else {
        throw new Error('Reducer was passed invalid user model');
      }
    },
    authSignOut: (state) => {
      state.user = undefined;
      state.isSignedIn = false;
      state.isError = false;
    },
    authIsError: (state, { payload }: PayloadAction<boolean>) => {
      state.isError = payload;
    },
  },
});

export const { authSetCredentials, authSignOut } = slice.actions;

export default slice.reducer;
