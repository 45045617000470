import { Breakpoint, Breakpoints, createTheme } from '@mui/system';

import { themeCssBreakpoints } from '@anthology/shared/src/constants/theme';
import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { SimpleEmitter } from '@anthology/shared/src/utils/helpers';
import { RootState, store } from '@src/features/store';
import { setBottomMenuState, setCurrentScreenSize, setPrivateUiState, setSideMenuState, setTopMenuState } from '@src/features/uiSlice';
import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import diagnosticService from './diagnosticsService';

export enum PageLayouts {
  dashboard,
  fullscreen,
}

export interface MediaEvent {
  previousSize: Breakpoint;
  currentSize: Breakpoint;
  width: number;
}

export const UiElementSizes = {
  sideBarFullPx: 200,
  sideBarCollapasedPx: 60,
  topBarFullPx: 100,
  topBarPaddingX: 3,
  topBarPaddingY: 0,
  topBarXsFullPx: 50,
  topBarNavFullPx: 70,
  topBarElectronFullPx: 55,
  bottomBarFullPx: 65,
  dashboardPaddingPx: 30,
  dashboardPaddingTopPx: 40,
  topBarNonSearchContentWidth: 155,
};

export class LayoutServiceClass {
  state = () => store.getState().ui;

  breakpoints: Breakpoints;
  orderedBreakpoints: Breakpoint[];
  mediaEvent = new SimpleEmitter<MediaEvent>();

  headerPortalLeft: any;
  headerPortalCentre: any;
  dashboardElement: any;

  appHasFocus!: boolean;
  activityTimeoutRef!: number;

  activityTimeoutMs = 30000;

  constructor() {
    this.breakpoints = createTheme({ breakpoints: { values: themeCssBreakpoints } }).breakpoints;
    this.orderedBreakpoints = _.sortBy(Object.entries(themeCssBreakpoints), (x) => x[1]).map((x) => x[0]) as any;
    this.mediaEvent.subscribe((e) => this.onResize(e));
    window.addEventListener('resize', (e) => this.handleResize(e));
    window.addEventListener('load', (e) => this.handleResize(e));

    window.addEventListener('blur', (e) => this.userActivity(false));
    window.addEventListener('focus', (e) => this.userActivity(true));
    window.addEventListener('online', (e) => this.userActivity(true));
    window.addEventListener('mousedown', (e) => this.userActivity(true));
    window.addEventListener('mouseover', (e) => this.userActivity(true));

    this.userActivity(true);
  }

  userActivity(setActive = true) {
    const before = this.appHasFocus;
    if (!setActive) {
      this.appHasFocus = false;
    } else {
      this.appHasFocus = true;
      this.activityTimeoutRef && window.clearTimeout(this.activityTimeoutRef);
      this.activityTimeoutRef = window.setTimeout(() => this.userActivity(false), this.activityTimeoutMs);
    }

    if (this.appHasFocus !== before) {
      diagnosticService.info(`App has ${this.appHasFocus ? 'gained' : 'lost'} focus`);
    }
  }

  isLessThanEqual(key: Breakpoint): boolean {
    return this.breakpoints.values[this.state().currentSize] <= this.breakpoints.values[key];
  }

  isGreaterThanEqual(key: Breakpoint): boolean {
    return this.breakpoints.values[this.state().currentSize] >= this.breakpoints.values[key];
  }

  onResize(event: MediaEvent): void {
    this.setUiState();
  }

  setUiState(): void {
    let s = { isOpen: true, isCollapsed: false, isFloating: false };

    if (this.isLessThanEqual('md')) {
      s = { isOpen: true, isCollapsed: true, isFloating: false };
    }

    if (this.isLessThanEqual('sm')) {
      s = { isOpen: false, isCollapsed: false, isFloating: true };
    }

    if (this.state().layout === PageLayouts.fullscreen) {
      s = { isOpen: false, isCollapsed: false, isFloating: false };
    }

    store.dispatch(setSideMenuState(s));
  }

  setLayout(layout: PageLayouts) {
    store.dispatch(setPrivateUiState({ layout: layout }));
    store.dispatch(setTopMenuState(layout === PageLayouts.dashboard));
    store.dispatch(setBottomMenuState(layout !== PageLayouts.fullscreen));
    this.setUiState();
  }

  public handleResize(e: Event) {
    const ww = window.innerWidth;
    let cs = _.find(this.orderedBreakpoints, (bp) => ww < this.breakpoints.values[bp]) ?? this.orderedBreakpoints.at(-1)!;
    const lastSize = this.state().currentSize;

    if (cs === lastSize) return; //nothing todo
    store.dispatch(setCurrentScreenSize(cs));

    this.mediaEvent.emit({
      currentSize: cs,
      previousSize: lastSize,
      width: this.breakpoints.values[cs],
    });
  }

  public currentUiSizes() {
    const ui = this.state();

    const sw = ui.isSideBarCollapsed ? UiElementSizes.sideBarCollapasedPx : UiElementSizes.sideBarFullPx;
    const svw = ui.isSideBarOpen && !ui.isSideBarFloating ? sw : 0;

    const isMobile = this.isLessThanEqual('sm');

    const topBarHeight = ui.isTopBarOpen ? (isMobile ? UiElementSizes.topBarXsFullPx : UiElementSizes.topBarFullPx) : 0;
    const dashboardPadding = ui.layout === PageLayouts.fullscreen ? 0 : isMobile ? UiElementSizes.dashboardPaddingPx / 2 : UiElementSizes.dashboardPaddingPx;
    const dashboardTopPadding = ui.layout === PageLayouts.dashboard ? (isMobile ? topBarHeight + 10 : UiElementSizes.dashboardPaddingTopPx) : 0;

    return {
      sideBarWidth: sw,
      sideBarMarginWidth: svw,
      topBarHeight: topBarHeight,
      topBarNavFullPx: UiElementSizes.topBarNavFullPx,
      topBarElectronHeight: UiElementSizes.topBarElectronFullPx,
      bottomBarHeight: ui.isBottomBarOpen && isMobile ? UiElementSizes.bottomBarFullPx : 0,
      dashboardPadding: dashboardPadding,
      dashboardTopPadding: dashboardTopPadding,
    };
  }
}

export const layoutService = new LayoutServiceClass();

export function useUiSlice() {
  const ui = useSelector((state: RootState) => state.ui);
  const mobile = useCssBreakpoint('sm');

  const s = useMemo(
    () => ({
      ...ui,
      ...layoutService.currentUiSizes(),
      uiConstants: UiElementSizes,
      isMobileLayout: mobile,
      actions: layoutService,
    }),
    [ui, mobile]
  );

  return s;
}
