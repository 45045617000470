import { emptySlinkyApi as api } from './slinkyApiBase';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiProductSlinkyRequestState: build.query<GetApiProductSlinkyRequestStateApiResponse, GetApiProductSlinkyRequestStateApiArg>({
      query: (queryArg) => ({ url: `/api/Product/SlinkyRequestState`, params: { formatId: queryArg } }),
    }),
    postApiProductRequestSlinky: build.mutation<PostApiProductRequestSlinkyApiResponse, PostApiProductRequestSlinkyApiArg>({
      query: (queryArg) => ({ url: `/api/Product/RequestSlinky`, method: 'POST', body: queryArg }),
    }),
    getApiSlinkyBuildFromFormat: build.query<GetApiSlinkyBuildFromFormatApiResponse, GetApiSlinkyBuildFromFormatApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/BuildFromFormat`, params: { formatId: queryArg.formatId, titleSlinky: queryArg.titleSlinky } }),
    }),
    getApiSlinkySlinkySyncInfo: build.query<GetApiSlinkySlinkySyncInfoApiResponse, GetApiSlinkySlinkySyncInfoApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/SlinkySyncInfo`, params: { formatId: queryArg } }),
    }),
    getApiSlinkyGetSlinkySocialDefaultsByArtist: build.query<
      GetApiSlinkyGetSlinkySocialDefaultsByArtistApiResponse,
      GetApiSlinkyGetSlinkySocialDefaultsByArtistApiArg
    >({
      query: (queryArg) => ({ url: `/api/Slinky/GetSlinkySocialDefaultsByArtist`, params: { contributorId: queryArg } }),
    }),
    getApiSlinkyFindBySlinkyId: build.query<GetApiSlinkyFindBySlinkyIdApiResponse, GetApiSlinkyFindBySlinkyIdApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/Find/${queryArg}` }),
    }),
    postApiSlinkySlinky: build.mutation<PostApiSlinkySlinkyApiResponse, PostApiSlinkySlinkyApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/Slinky`, method: 'POST', body: queryArg }),
    }),
    putApiSlinkySlinkyBySlinkyId: build.mutation<PutApiSlinkySlinkyBySlinkyIdApiResponse, PutApiSlinkySlinkyBySlinkyIdApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/Slinky/${queryArg.slinkyId}`, method: 'PUT', body: queryArg.slinkyVm }),
    }),
    deleteApiSlinkySlinkyBySlinkyId: build.mutation<DeleteApiSlinkySlinkyBySlinkyIdApiResponse, DeleteApiSlinkySlinkyBySlinkyIdApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/Slinky/${queryArg}`, method: 'DELETE' }),
    }),
    getApiSlinkyGetStandardCtas: build.query<GetApiSlinkyGetStandardCtasApiResponse, GetApiSlinkyGetStandardCtasApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/GetStandardCtas`, params: { languageId: queryArg } }),
    }),
    putApiSlinkyUpdateLive: build.mutation<PutApiSlinkyUpdateLiveApiResponse, PutApiSlinkyUpdateLiveApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/UpdateLive`, method: 'PUT', params: { slinkyId: queryArg.slinkyId, isLive: queryArg.isLive } }),
    }),
    getApiSlinkyUrlExtensionExists: build.query<GetApiSlinkyUrlExtensionExistsApiResponse, GetApiSlinkyUrlExtensionExistsApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/UrlExtensionExists`, params: { urlExtension: queryArg.urlExtension, slinkyId: queryArg.slinkyId } }),
    }),
    getApiSlinkyGetConnectedArtists: build.query<GetApiSlinkyGetConnectedArtistsApiResponse, GetApiSlinkyGetConnectedArtistsApiArg>({
      query: (queryArg) => ({ url: `/api/Slinky/GetConnectedArtists`, params: { slinkyId: queryArg } }),
    }),
    getApiSlinkyCatalogueSearch: build.query<GetApiSlinkyCatalogueSearchApiResponse, GetApiSlinkyCatalogueSearchApiArg>({
      query: (queryArg) => ({
        url: `/api/SlinkyCatalogue/Search`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSlinkyCatalogueMinMaxSlinkyClicks: build.query<GetApiSlinkyCatalogueMinMaxSlinkyClicksApiResponse, GetApiSlinkyCatalogueMinMaxSlinkyClicksApiArg>({
      query: () => ({ url: `/api/SlinkyCatalogue/MinMaxSlinkyClicks` }),
    }),
    getApiSlinkyDestinationFindByDestinationId: build.query<
      GetApiSlinkyDestinationFindByDestinationIdApiResponse,
      GetApiSlinkyDestinationFindByDestinationIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyDestination/Find/${queryArg}` }),
    }),
    postApiSlinkyDestinationDestination: build.mutation<PostApiSlinkyDestinationDestinationApiResponse, PostApiSlinkyDestinationDestinationApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyDestination/Destination`, method: 'POST', body: queryArg }),
    }),
    putApiSlinkyDestinationDestinationByDestinationId: build.mutation<
      PutApiSlinkyDestinationDestinationByDestinationIdApiResponse,
      PutApiSlinkyDestinationDestinationByDestinationIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyDestination/Destination/${queryArg.destinationId}`, method: 'PUT', body: queryArg.destinationVm }),
    }),
    deleteApiSlinkyDestinationDestinationByDestinationId: build.mutation<
      DeleteApiSlinkyDestinationDestinationByDestinationIdApiResponse,
      DeleteApiSlinkyDestinationDestinationByDestinationIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyDestination/Destination/${queryArg}`, method: 'DELETE' }),
    }),
    getApiSlinkyResourceDestinationCallToActions: build.query<
      GetApiSlinkyResourceDestinationCallToActionsApiResponse,
      GetApiSlinkyResourceDestinationCallToActionsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/DestinationCallToActions` }),
    }),
    getApiSlinkyResourceCallToActionGroups: build.query<GetApiSlinkyResourceCallToActionGroupsApiResponse, GetApiSlinkyResourceCallToActionGroupsApiArg>({
      query: () => ({ url: `/api/SlinkyResource/CallToActionGroups` }),
    }),
    getApiSlinkyResourcePixels: build.query<GetApiSlinkyResourcePixelsApiResponse, GetApiSlinkyResourcePixelsApiArg>({
      query: () => ({ url: `/api/SlinkyResource/Pixels` }),
    }),
    getApiSlinkyResourceTrackingTagKeys: build.query<GetApiSlinkyResourceTrackingTagKeysApiResponse, GetApiSlinkyResourceTrackingTagKeysApiArg>({
      query: () => ({ url: `/api/SlinkyResource/TrackingTagKeys` }),
    }),
    getApiSlinkyResourceDestinations: build.query<GetApiSlinkyResourceDestinationsApiResponse, GetApiSlinkyResourceDestinationsApiArg>({
      query: () => ({ url: `/api/SlinkyResource/Destinations` }),
    }),
    getApiSlinkyResourceDestinationTypes: build.query<GetApiSlinkyResourceDestinationTypesApiResponse, GetApiSlinkyResourceDestinationTypesApiArg>({
      query: () => ({ url: `/api/SlinkyResource/DestinationTypes` }),
    }),
    getApiSlinkyResourceSocialMediaPlatforms: build.query<GetApiSlinkyResourceSocialMediaPlatformsApiResponse, GetApiSlinkyResourceSocialMediaPlatformsApiArg>({
      query: () => ({ url: `/api/SlinkyResource/SocialMediaPlatforms` }),
    }),
    getApiSlinkyResourceTerritories: build.query<GetApiSlinkyResourceTerritoriesApiResponse, GetApiSlinkyResourceTerritoriesApiArg>({
      query: () => ({ url: `/api/SlinkyResource/Territories` }),
    }),
    getApiSlinkyStatsLandingPageBySlinkyUrlExt: build.query<
      GetApiSlinkyStatsLandingPageBySlinkyUrlExtApiResponse,
      GetApiSlinkyStatsLandingPageBySlinkyUrlExtApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyStats/LandingPage/${queryArg}` }),
    }),
    getApiSlinkyStatsLandingPageByIdBySlinkyId: build.query<
      GetApiSlinkyStatsLandingPageByIdBySlinkyIdApiResponse,
      GetApiSlinkyStatsLandingPageByIdBySlinkyIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyStats/LandingPageById/${queryArg}` }),
    }),
    postApiSlinkyStatsRecordInteractionEvent: build.mutation<
      PostApiSlinkyStatsRecordInteractionEventApiResponse,
      PostApiSlinkyStatsRecordInteractionEventApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyStats/RecordInteractionEvent`, method: 'POST', body: queryArg }),
    }),
    getApiSlinkyStatsHeadline: build.query<GetApiSlinkyStatsHeadlineApiResponse, GetApiSlinkyStatsHeadlineApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyStats/Headline`, params: { slinkyId: queryArg } }),
    }),
    getApiSlinkyStatsClickThroughs: build.query<GetApiSlinkyStatsClickThroughsApiResponse, GetApiSlinkyStatsClickThroughsApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyStats/ClickThroughs`, params: { slinkyId: queryArg } }),
    }),
    getApiSlinkyStatsDestinations: build.query<GetApiSlinkyStatsDestinationsApiResponse, GetApiSlinkyStatsDestinationsApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyStats/Destinations`, params: { slinkyId: queryArg } }),
    }),
    getApiSlinkyStatsSources: build.query<GetApiSlinkyStatsSourcesApiResponse, GetApiSlinkyStatsSourcesApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyStats/Sources`, params: { slinkyId: queryArg } }),
    }),
    getApiSlinkyStatsGeographyCountries: build.query<GetApiSlinkyStatsGeographyCountriesApiResponse, GetApiSlinkyStatsGeographyCountriesApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyStats/GeographyCountries`, params: { slinkyId: queryArg } }),
    }),
    getApiSlinkyStatsGeographyCities: build.query<GetApiSlinkyStatsGeographyCitiesApiResponse, GetApiSlinkyStatsGeographyCitiesApiArg>({
      query: (queryArg) => ({ url: `/api/SlinkyStats/GeographyCities`, params: { slinkyId: queryArg } }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as slinkyApi };
export type GetApiProductSlinkyRequestStateApiResponse = /** status 200 Success */ AcSlinkyRequestState;
export type GetApiProductSlinkyRequestStateApiArg = number;
export type PostApiProductRequestSlinkyApiResponse = unknown;
export type PostApiProductRequestSlinkyApiArg = AcRequestSlinky;
export type GetApiSlinkyBuildFromFormatApiResponse = /** status 200 Success */ AcknowledgeActionInt32;
export type GetApiSlinkyBuildFromFormatApiArg = {
  formatId?: number;
  titleSlinky?: boolean;
};
export type GetApiSlinkySlinkySyncInfoApiResponse = /** status 200 Success */ SlinkyVm;
export type GetApiSlinkySlinkySyncInfoApiArg = number;
export type GetApiSlinkyGetSlinkySocialDefaultsByArtistApiResponse = /** status 200 Success */ UspGetSlinkySocialDefaultsResult[];
export type GetApiSlinkyGetSlinkySocialDefaultsByArtistApiArg = number;
export type GetApiSlinkyFindBySlinkyIdApiResponse = /** status 200 Success */ SlinkyVm;
export type GetApiSlinkyFindBySlinkyIdApiArg = number;
export type PostApiSlinkySlinkyApiResponse = /** status 200 Success */ number;
export type PostApiSlinkySlinkyApiArg = SlinkyVm;
export type PutApiSlinkySlinkyBySlinkyIdApiResponse = /** status 200 Success */ number;
export type PutApiSlinkySlinkyBySlinkyIdApiArg = {
  slinkyId: number;
  slinkyVm: SlinkyVm;
};
export type DeleteApiSlinkySlinkyBySlinkyIdApiResponse = unknown;
export type DeleteApiSlinkySlinkyBySlinkyIdApiArg = number;
export type GetApiSlinkyGetStandardCtasApiResponse = /** status 200 Success */ string[];
export type GetApiSlinkyGetStandardCtasApiArg = number;
export type PutApiSlinkyUpdateLiveApiResponse = /** status 200 Success */ number;
export type PutApiSlinkyUpdateLiveApiArg = {
  slinkyId?: number;
  isLive?: boolean;
};
export type GetApiSlinkyUrlExtensionExistsApiResponse = unknown;
export type GetApiSlinkyUrlExtensionExistsApiArg = {
  urlExtension?: string;
  slinkyId?: number;
};
export type GetApiSlinkyGetConnectedArtistsApiResponse = /** status 200 Success */ UpsGetSlinkyConnectedArtistsResult[];
export type GetApiSlinkyGetConnectedArtistsApiArg = number;
export type GetApiSlinkyCatalogueSearchApiResponse = /** status 200 Success */ SlinkyCatalogueVm[];
export type GetApiSlinkyCatalogueSearchApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSlinkyCatalogueMinMaxSlinkyClicksApiResponse = /** status 200 Success */ {
  [key: string]: number;
};
export type GetApiSlinkyCatalogueMinMaxSlinkyClicksApiArg = void;
export type GetApiSlinkyDestinationFindByDestinationIdApiResponse = /** status 200 Success */ DestinationVm;
export type GetApiSlinkyDestinationFindByDestinationIdApiArg = number;
export type PostApiSlinkyDestinationDestinationApiResponse = /** status 200 Success */ number;
export type PostApiSlinkyDestinationDestinationApiArg = DestinationVm;
export type PutApiSlinkyDestinationDestinationByDestinationIdApiResponse = /** status 200 Success */ number;
export type PutApiSlinkyDestinationDestinationByDestinationIdApiArg = {
  destinationId: number;
  destinationVm: DestinationVm;
};
export type DeleteApiSlinkyDestinationDestinationByDestinationIdApiResponse = unknown;
export type DeleteApiSlinkyDestinationDestinationByDestinationIdApiArg = number;
export type GetApiSlinkyResourceDestinationCallToActionsApiResponse = /** status 200 Success */ CallToActionVm[];
export type GetApiSlinkyResourceDestinationCallToActionsApiArg = void;
export type GetApiSlinkyResourceCallToActionGroupsApiResponse = /** status 200 Success */ CallToActionGroupVm[];
export type GetApiSlinkyResourceCallToActionGroupsApiArg = void;
export type GetApiSlinkyResourcePixelsApiResponse = /** status 200 Success */ PixelType[];
export type GetApiSlinkyResourcePixelsApiArg = void;
export type GetApiSlinkyResourceTrackingTagKeysApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiSlinkyResourceTrackingTagKeysApiArg = void;
export type GetApiSlinkyResourceDestinationsApiResponse = /** status 200 Success */ DestinationVm[];
export type GetApiSlinkyResourceDestinationsApiArg = void;
export type GetApiSlinkyResourceDestinationTypesApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiSlinkyResourceDestinationTypesApiArg = void;
export type GetApiSlinkyResourceSocialMediaPlatformsApiResponse = /** status 200 Success */ SocialMediaPlatform[];
export type GetApiSlinkyResourceSocialMediaPlatformsApiArg = void;
export type GetApiSlinkyResourceTerritoriesApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiSlinkyResourceTerritoriesApiArg = void;
export type GetApiSlinkyStatsLandingPageBySlinkyUrlExtApiResponse = /** status 200 Success */ SlinkyVm;
export type GetApiSlinkyStatsLandingPageBySlinkyUrlExtApiArg = string;
export type GetApiSlinkyStatsLandingPageByIdBySlinkyIdApiResponse = /** status 200 Success */ SlinkyVm;
export type GetApiSlinkyStatsLandingPageByIdBySlinkyIdApiArg = number;
export type PostApiSlinkyStatsRecordInteractionEventApiResponse = unknown;
export type PostApiSlinkyStatsRecordInteractionEventApiArg = InteractionEventVm;
export type GetApiSlinkyStatsHeadlineApiResponse = /** status 200 Success */ CompactJsonTableSlinkyStatsQuerySlinkyStatsHeadline;
export type GetApiSlinkyStatsHeadlineApiArg = number;
export type GetApiSlinkyStatsClickThroughsApiResponse = /** status 200 Success */ CompactJsonTableSlinkyStatsQuerySlinkyStatsTimeline;
export type GetApiSlinkyStatsClickThroughsApiArg = number;
export type GetApiSlinkyStatsDestinationsApiResponse = /** status 200 Success */ CompactJsonTableSlinkyStatsQuerySlinkyStatsDestination;
export type GetApiSlinkyStatsDestinationsApiArg = number;
export type GetApiSlinkyStatsSourcesApiResponse = /** status 200 Success */ CompactJsonTableSlinkyStatsQuerySlinkyStatsSource;
export type GetApiSlinkyStatsSourcesApiArg = number;
export type GetApiSlinkyStatsGeographyCountriesApiResponse = /** status 200 Success */ CompactJsonTableSlinkyStatsQuerySlinkyStatsLocation;
export type GetApiSlinkyStatsGeographyCountriesApiArg = number;
export type GetApiSlinkyStatsGeographyCitiesApiResponse = /** status 200 Success */ CompactJsonTableSlinkyStatsQuerySlinkyStatsLocation;
export type GetApiSlinkyStatsGeographyCitiesApiArg = number;
export type SlinkyRequestType = 0 | 1 | 2;
export type AcSlinkyRequestState = {
  canRequest?: SlinkyRequestType;
  requestedTitle?: boolean;
};
export type AcRequestSlinky = {
  formatId?: number;
  requestType?: number;
  userId?: number;
};
export type AcknowledgeActionInt32 = {
  message?: string | null;
  isError?: boolean;
  value?: number;
};
export type LookAndFeelVm = {
  coverArtImageUrl?: string | null;
  favIconUrl?: string | null;
};
export type MediaPlayerVm = {
  playerId?: number | null;
  platformLinkId?: number;
  mediaPlayerUrl?: string | null;
  sequence?: number;
  previewStart?: number;
  isActive?: boolean;
  position?: string | null;
  displayStartDateTime?: string | null;
  displayEndDateTime?: string | null;
  displayDateIsUtc?: boolean;
};
export type CallToActionVm = {
  destinationCallToActionId?: number | null;
  callToActionGroupLinkId?: number | null;
  languageLinkId?: number;
  languageIso3?: string | null;
  callToAction?: string | null;
  isPreReleaseCta?: boolean | null;
  isPostReleaseCta?: boolean | null;
};
export type LinkTerritoryGroupVm = {
  slinkyDestinationLinkTerritorieId?: number | null;
  territoryGroupId?: number | null;
  isVisible?: boolean;
  isUtcdateTime?: boolean | null;
  preOrderDateTime?: string | null;
  releaseDateTime?: string;
  postLinkIdentifierOrUrl?: string | null;
  preLinkIdentifierOrUrl?: string | null;
  preReleaseCtas?: CallToActionVm[] | null;
  postReleaseCtas?: CallToActionVm[] | null;
  territoryIds?: number[] | null;
};
export type LinkCustomSequenceVm = {
  territoryLinkId?: number;
  sequence?: number;
  isHidden?: boolean;
};
export type LinkVm = {
  slinkyDestinationLinkId?: number | null;
  sequence?: number;
  formatId?: number | null;
  isVisible?: boolean;
  isDeleted?: boolean;
  destinationLinkId?: number;
  destinationName?: string | null;
  destinationImageUrl?: string | null;
  territoryGroups?: LinkTerritoryGroupVm[] | null;
  customTerritorySequences?: LinkCustomSequenceVm[] | null;
};
export type TitleVm = {
  slinkyTitleId?: number | null;
  languageLinkId?: number | null;
  titleText?: string | null;
  subTitleText?: string | null;
  isDefault?: boolean;
};
export type PixelVm = {
  pixelId?: number | null;
  slinkyToPixelId?: number | null;
  pixelIdentifier?: string | null;
  pixelTypeLinkId?: number;
  slinkyAccountLinkId?: number | null;
  labelLinkId?: number | null;
  contributorLinkId?: number | null;
  anthologyResourceTypeLinkId?: number | null;
};
export type SocialVm = {
  socialMediaLinkId?: number | null;
  socialMediaPlatformLinkId?: number;
  socialMediaIdentifier?: string | null;
  sequence?: number;
  platformName?: string | null;
  platformLogoUrl?: string | null;
  pLatformPromptText?: string | null;
  anthologyResourceTypeLinkId?: number | null;
};
export type TrackingTagVm = {
  tagId?: number | null;
  keyId?: number;
  value?: string | null;
};
export type FormatVm = {
  formatId?: number | null;
  title?: string | null;
  subTitle?: string | null;
  displayArtist?: string | null;
  formatType?: string | null;
  isDigital?: boolean;
  customFormatDescription?: string | null;
};
export type ArtistDestinationIdentifierVm = {
  artistDestinationIdentifierId?: number | null;
  platformLinkId?: number | null;
  artistDestinationIdentifier?: string | null;
};
export type ArtistVm = {
  artistId?: number | null;
  displayName?: string | null;
  anthologyContributorId?: number | null;
  artistIsni?: string | null;
  artistIdentifiers?: ArtistDestinationIdentifierVm[] | null;
  isFormatArtist?: boolean | null;
};
export type SlinkyVm = {
  userId?: number | null;
  slinkyId?: number | null;
  contributorId?: number | null;
  labelId?: number | null;
  trackingTag?: string | null;
  slinkyUrlextension?: string | null;
  isLive?: boolean;
  formatLinkId?: number | null;
  slinkyAccountLinkId?: number | null;
  skipOnSingleVisibleLink?: boolean;
  addAllFormatsFromTitleGroup?: boolean | null;
  requestOriginTerritoryIso2?: string | null;
  requestOriginTerritoryId?: number | null;
  lookAndFeel?: LookAndFeelVm;
  mediaPlayers?: MediaPlayerVm[] | null;
  links?: LinkVm[] | null;
  titles?: TitleVm[] | null;
  pixels?: PixelVm[] | null;
  socials?: SocialVm[] | null;
  tags?: TrackingTagVm[] | null;
  relatedFormats?: FormatVm[] | null;
  artists?: ArtistVm[] | null;
};
export type UspGetSlinkySocialDefaultsResult = {
  resourcePrefix?: string | null;
  socialIdentifier?: string | null;
  contributorName?: string | null;
  defaultType?: string | null;
  resourceTypeId?: number | null;
  resourceName?: string | null;
};
export type UpsGetSlinkyConnectedArtistsResult = {
  slinkyId?: number;
  artistId?: number;
  displayName?: string | null;
  antholologyContributorId?: number | null;
  artistISNI?: string | null;
  artistDestinationIdentifier?: string | null;
  destinationName?: string | null;
};
export type SlinkyCatalogueVm = {
  slinkyId?: number;
  titleText?: string | null;
  subTitleText?: string | null;
  coverArtImageUrl?: string | null;
  links?: number | null;
  minReleaseDate?: string | null;
  slinkyUrlextension?: string | null;
  isLive?: boolean;
  formatLinkId?: number | null;
  contributorId?: number | null;
  labelId?: number | null;
  clicks?: number;
  createdOn?: string;
  addAllFormatsFromTitleGroup?: boolean;
};
export type DestinationTemplateUrlVm = {
  destinationTemplateUrlid?: number | null;
  destinationTemplateUrl1?: string | null;
  isDefault?: boolean;
};
export type DestinationVm = {
  destinationId?: number | null;
  destinationName?: string | null;
  destinationImageUrl?: string | null;
  destinationImageWidth?: number | null;
  destinationImageHeight?: number | null;
  defaultPreReleaseCallToActionGroupLinkId?: number | null;
  defaultPostReleaseCallToActionGroupLinkId?: number | null;
  sequence?: number;
  platformId?: number | null;
  destinationTypeLinkId?: number;
  clickthroughId?: string | null;
  allowAffiliateLink?: boolean;
  isActive?: boolean | null;
  destinationTemplateUrls?: DestinationTemplateUrlVm[] | null;
};
export type CallToActionGroupVm = {
  callToActionGroupId?: number | null;
  groupName?: string | null;
  isStardard?: boolean;
  isPreReleaseCta?: boolean | null;
  isPostReleaseCta?: boolean | null;
  destinationCallToActions?: CallToActionVm[] | null;
};
export type PixelType = {
  pixelTypeId?: number;
  pixelType1?: string | null;
  pixelTypeDisplayName?: string | null;
  pixelSubTypeLinkId?: number;
  isActive?: boolean;
  iconName?: string | null;
  validateAgainst?: string | null;
  anthologyResourceTypeLinkId?: number | null;
};
export type SelectListVm = {
  id?: number;
  alternativeId?: number;
  boolVal?: boolean;
  text?: string | null;
  sequence?: number | null;
  type?: string | null;
  description?: string | null;
  permissionLevelRank?: number | null;
  isoCode?: string | null;
  rate?: number | null;
  selected?: boolean | null;
  grouping?: string | null;
  groupingId?: number;
  isDigital?: boolean | null;
  isAudio?: boolean | null;
  isPreferred?: boolean | null;
  imageUrl?: string | null;
  color?: string | null;
};
export type SocialMediaPlatform = {
  socialMediaPlatformId?: number;
  platformName?: string | null;
  platformLogoUrl?: string | null;
  platformPrefix?: string | null;
  exampleValue?: string | null;
  socialMediaHandlePrefix?: string | null;
  promptTextKey?: string | null;
  anthologyResourceTypeLinkId?: number | null;
  defaultSequence?: number;
};
export type InteractionEventVm = {
  slinkyId: number;
  userHash?: string | null;
  destinationId: number;
  slinkyDestinationTerritory?: string | null;
  slinkyDestinationId: number;
  landingEventGuid?: string | null;
};
export type SlinkyStatsQuerySlinkyStatsHeadline = {
  slinkyLinkId?: number | null;
  landingEvents?: number | null;
  destinationClicks?: number | null;
  uniqueVisitors?: number | null;
  referralVisits?: number | null;
  clickThroughRate?: number | null;
};
export type CompactJsonTableSlinkyStatsQuerySlinkyStatsHeadline = {
  rowModel?: SlinkyStatsQuerySlinkyStatsHeadline;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type SlinkyStatsQuerySlinkyStatsTimeline = {
  slinkyLinkId?: number | null;
  eventDate?: string | null;
  landingEvents?: number | null;
  destinationClicks?: number | null;
  uniqueVisitors?: number | null;
  referralVisits?: number | null;
  clickThroughRate?: number | null;
};
export type CompactJsonTableSlinkyStatsQuerySlinkyStatsTimeline = {
  rowModel?: SlinkyStatsQuerySlinkyStatsTimeline;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type SlinkyStatsQuerySlinkyStatsDestination = {
  slinkyLinkId?: number | null;
  eventDate?: string | null;
  destinationName?: string | null;
  destinationId?: number | null;
  interactionType?: string | null;
  landingEvents?: number | null;
  destinationClicks?: number | null;
  uniqueVisitors?: number | null;
  referralVisits?: number | null;
  clickThroughRate?: number | null;
};
export type CompactJsonTableSlinkyStatsQuerySlinkyStatsDestination = {
  rowModel?: SlinkyStatsQuerySlinkyStatsDestination;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type SlinkyStatsQuerySlinkyStatsSource = {
  slinkyLinkId?: number | null;
  domainFrom?: string | null;
  landingEvents?: number | null;
  destinationClicks?: number | null;
  uniqueVisitors?: number | null;
  referralVisits?: number | null;
  clickThroughRate?: number | null;
};
export type CompactJsonTableSlinkyStatsQuerySlinkyStatsSource = {
  rowModel?: SlinkyStatsQuerySlinkyStatsSource;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type SlinkyStatsQuerySlinkyStatsLocation = {
  slinkyLinkId?: number | null;
  country_iso_code?: string | null;
  country_name?: string | null;
  city_name?: string | null;
  landingEvents?: number | null;
  destinationClicks?: number | null;
  uniqueVisitors?: number | null;
  referralVisits?: number | null;
  clickThroughRate?: number | null;
};
export type CompactJsonTableSlinkyStatsQuerySlinkyStatsLocation = {
  rowModel?: SlinkyStatsQuerySlinkyStatsLocation;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export const {
  useGetApiProductSlinkyRequestStateQuery,
  usePostApiProductRequestSlinkyMutation,
  useGetApiSlinkyBuildFromFormatQuery,
  useGetApiSlinkySlinkySyncInfoQuery,
  useGetApiSlinkyGetSlinkySocialDefaultsByArtistQuery,
  useGetApiSlinkyFindBySlinkyIdQuery,
  usePostApiSlinkySlinkyMutation,
  usePutApiSlinkySlinkyBySlinkyIdMutation,
  useDeleteApiSlinkySlinkyBySlinkyIdMutation,
  useGetApiSlinkyGetStandardCtasQuery,
  usePutApiSlinkyUpdateLiveMutation,
  useGetApiSlinkyUrlExtensionExistsQuery,
  useGetApiSlinkyGetConnectedArtistsQuery,
  useGetApiSlinkyCatalogueSearchQuery,
  useGetApiSlinkyCatalogueMinMaxSlinkyClicksQuery,
  useGetApiSlinkyDestinationFindByDestinationIdQuery,
  usePostApiSlinkyDestinationDestinationMutation,
  usePutApiSlinkyDestinationDestinationByDestinationIdMutation,
  useDeleteApiSlinkyDestinationDestinationByDestinationIdMutation,
  useGetApiSlinkyResourceDestinationCallToActionsQuery,
  useGetApiSlinkyResourceCallToActionGroupsQuery,
  useGetApiSlinkyResourcePixelsQuery,
  useGetApiSlinkyResourceTrackingTagKeysQuery,
  useGetApiSlinkyResourceDestinationsQuery,
  useGetApiSlinkyResourceDestinationTypesQuery,
  useGetApiSlinkyResourceSocialMediaPlatformsQuery,
  useGetApiSlinkyResourceTerritoriesQuery,
  useGetApiSlinkyStatsLandingPageBySlinkyUrlExtQuery,
  useGetApiSlinkyStatsLandingPageByIdBySlinkyIdQuery,
  usePostApiSlinkyStatsRecordInteractionEventMutation,
  useGetApiSlinkyStatsHeadlineQuery,
  useGetApiSlinkyStatsClickThroughsQuery,
  useGetApiSlinkyStatsDestinationsQuery,
  useGetApiSlinkyStatsSourcesQuery,
  useGetApiSlinkyStatsGeographyCountriesQuery,
  useGetApiSlinkyStatsGeographyCitiesQuery,
} = injectedRtkApi;
