import { CircleIconButton } from '@anthology/shared/src/components';
import { SelectListVm } from '@api/anthologyApi';
import { ExpandLess } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import IconList from '../icon-list/IconList';
import Title from '../title/Title';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const filterArray = (arr1: number[], arr2: number[]) => {
  const filtered = arr1.filter((el) => {
    return arr2.indexOf(el) === -1;
  });
  return filtered;
};

export type FormGroupSelectVariant = 'default' | 'tall';

export type FormGroupSelectProps = {
  AllLabel: string;
  label: string;
  data: SelectListVm[];
  dataSelected?: number[];
  onChange?: (values: any) => void;
  helperText?: any;
  error?: boolean;
  isRequired?: boolean;
  barColor?: 'background.paper' | 'background.default';
  groupSelectVariant?: FormGroupSelectVariant;
  renderIcon?: (row: any) => ReactNode;
  fallbackIcon?: ReactNode;
  allIcon?: ReactNode;
  summaryLimit?: number;
} & SelectProps;

export const getGroupSelectRenderValue = (selected: number[], formGroupSelectProps?: Partial<FormGroupSelectProps>) => {
  const { renderIcon, allIcon, data, groupSelectVariant, AllLabel, summaryLimit } = formGroupSelectProps || {};

  if (!data || !selected) return '--';

  if (selected.length === 0) {
    return 'No Selection';
  }

  if (selected.length === data.length) {
    return (
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        {allIcon}
        <Typography>{AllLabel || 'All selected'}</Typography>
      </Stack>
    );
  }

  if (renderIcon) {
    const allSelectedItems = data.filter((o) => selected.includes(o.id || 0));
    const max = summaryLimit || 10;
    return <IconList sx={{ mt: groupSelectVariant === 'tall' ? 0.5 : 0 }} array={allSelectedItems} renderIcon={renderIcon} max={max} />;
  }

  let selectedText = data
    .filter((o) => selected.includes(o.id ?? 1))
    .map((o) => o.text)
    .slice(0, summaryLimit || 3)
    .join(', ');
  if (selected.length > 3) {
    selectedText += ` +${selected.length - 3}`;
  }
  return selectedText;
};

const FormGroupSelect = ({
  name,
  label,
  AllLabel,
  defaultValue = [],
  data,
  dataSelected = [],
  onChange,
  helperText,
  error,
  isRequired,
  barColor = 'background.paper',
  disabled = false,
  groupSelectVariant = 'default',
  renderIcon,
  fallbackIcon,
  allIcon,
  summaryLimit,
  ...rest
}: FormGroupSelectProps) => {
  const [selected, setSelected] = React.useState<number[]>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [accordionGroup, setAccordionGroup] = React.useState<string>('');

  const theme = useTheme();
  const componentRef = useRef();
  const optionGroups = useMemo(() => {
    return _.uniqBy(
      data.map((o: SelectListVm) => o.grouping ?? '').filter((o) => o),
      (e: any) => e
    );
  }, [data]);

  useEffect(() => {
    const validIds = data.map((o) => o.id ?? -1);
    setSelected(dataSelected.filter((o) => validIds.includes(o)));
  }, [data, dataSelected]);

  function getRenderValue() {
    return getGroupSelectRenderValue(selected, { data, renderIcon, allIcon, summaryLimit } as FormGroupSelectProps);
  }

  const optionSelected = (id: number) => {
    const arr = selected.indexOf(id) > -1 ? selected.filter((n) => n !== id) : [...selected, id];
    setSelected(arr);
    onChange?.(arr);
  };

  const selectAllToggle = () => {
    if (selected.length === data.length) {
      setSelected([]);
      onChange?.([]);
    } else {
      const selectAll = data.map((d) => d.id ?? 0);
      setSelected(selectAll);
      onChange?.(selectAll);
    }
  };

  const groupSelected = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, group: string, allSelected: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedList = [...selected];
    let newSelectedList: number[] = [];
    const groupList = data.filter((n) => n.grouping === group).map((o) => o.id ?? -1);
    if (allSelected) {
      newSelectedList = filterArray(selectedList, groupList);
    } else {
      newSelectedList = _.uniqBy(_.concat(selectedList, groupList), (e) => e);
    }
    setSelected(newSelectedList);
    onChange?.(newSelectedList);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionGroup(isExpanded ? panel : '');
  };

  const isTall = groupSelectVariant === 'tall';

  return (
    <Box mb={isTall ? (expanded ? 7 : 1) : 0}>
      <Title title={label} sx={{ marginBottom: -3 }} />
      <FormControl fullWidth size="small" error={error} required={isRequired}>
        <Stack gap={isTall ? 3 : 0}>
          <Select
            error={error}
            name={name}
            multiple
            input={<OutlinedInput label={label} error={error} />}
            renderValue={getRenderValue}
            MenuProps={MenuProps}
            value={selected}
            ref={componentRef}
            {...rest}
            sx={{ visibility: 'hidden' }}
          ></Select>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 1,
              bgcolor: barColor,
              padding: 2,
              border: error ? `1px solid ${theme.palette.error.main}` : '',
              cursor: 'pointer',
              gap: 1,
            }}
            onClick={() => {
              if (disabled === false) {
                setExpanded(!expanded);
              }
            }}
          >
            {AllLabel && (
              <>
                <Checkbox
                  checked={selected.length === data.length}
                  disabled={disabled}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectAllToggle();
                  }}
                />
                <ListItemText primary={AllLabel} />
              </>
            )}
            <Box flexBasis={'40%'}>{getRenderValue()}</Box>
            <Box sx={{ marginLeft: 4 }}>
              <CircleIconButton
                onClick={() => {
                  console.log(disabled);
                  if (disabled === false) {
                    setExpanded(!expanded);
                  }
                }}
                sx={{ justifyContent: 'flex-end' }}
              >
                {expanded ? <ExpandLess /> : <ExpandMoreIcon />}
              </CircleIconButton>
            </Box>
          </Stack>
          {expanded && (
            <>
              {optionGroups.map((group: string, index: number) => {
                const indeterminate = data.filter((o) => o.grouping === group).some((d) => selected.includes(d.id ?? -1));
                const allIncluded = data.filter((o) => o.grouping === group).every((d) => selected.includes(d.id ?? -1));
                return (
                  <Accordion
                    key={`${group}-${index}`}
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{ bgcolor: barColor, boxShadow: isTall ? 'none' : undefined }}
                    expanded={group === accordionGroup}
                    disabled={disabled}
                    onChange={handleChange(group)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${group}-content`}
                      id={`${group}-header`}
                      sx={{
                        '. MuiAccordionSummary-root': { '::before': { display: isTall ? 'none' : 'auto' } },
                      }}
                    >
                      <Typography sx={{ alignItems: 'center' }} variant={isTall ? 'h4' : undefined}>
                        <Checkbox
                          checked={allIncluded}
                          indeterminate={!allIncluded && indeterminate}
                          inputProps={{ 'aria-label': 'controlled' }}
                          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            groupSelected(e, group, allIncluded);
                          }}
                        />
                        {group}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {data
                        .filter((o) => o.grouping === group)
                        .sort((a: any, b: any) => (a.text < b.text ? -1 : 1))
                        .map((item: SelectListVm) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            onClick={() => optionSelected(item.id ?? -1)}
                            sx={{ minWidth: '200px', width: '25%', overflow: 'hidden' }}
                          >
                            <Checkbox checked={selected.indexOf(item.id ?? -1) > -1} />
                            {renderIcon && (
                              <Stack ml={0.5} mr={1.5} justifyContent={'center'}>
                                {renderIcon(item)}
                              </Stack>
                            )}
                            <Typography
                              className={'oneLineTruncate'}
                              color={'text.secondary'}
                              variant={isTall ? 'bodyLarge' : 'caption'}
                              title={label}
                              lineHeight={'15px'}
                            >
                              {item.text}
                            </Typography>
                          </MenuItem>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </Stack>
      </FormControl>
      {helperText && (
        <FormHelperText error sx={{ marginLeft: '0' }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormGroupSelect;
