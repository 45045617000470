import { SelectListVm, anthologyApi } from '@src/api/anthologyApi';
import { useEffect, useMemo, useState } from 'react';
import { KnownTypes, configSetItem } from '../features/configSlice';
import { store } from '../features/store';

class configServiceClass {
  state = () => store.getState().config;
  init() {}

  getConfig(type: KnownTypes): Promise<SelectListVm[]> {
    return new Promise((resolve, reject) => {
      const existing = this.state().enum[type];
      if (existing) {
        resolve(existing);
      } else {
        store.dispatch(anthologyApi.endpoints.getApiUtilityGetResource.initiate(type)).then((x: any) => {
          if (x.data) {
            store.dispatch(configSetItem({ key: type, items: x.data }));
            resolve(x.data);
          } else {
            reject('no data');
          }
        });
      }
    });
  }

  getConfigFromCache(type: KnownTypes): SelectListVm[] {
    return this.state().enum[type];
  }
}

export interface FixedSelectListVm extends SelectListVm {
  id: number;
  text: string;
}

const configService = new configServiceClass();
export default configService;

export const useConfigItem = <T extends KnownTypes>(...e: T[]): { [K in T]: FixedSelectListVm[] } & { allReady: boolean } => {
  const [allReady, setAllReady] = useState(false);

  useEffect(() => {
    Promise.all(e.map((x) => configService.getConfig(x))).then(() => setAllReady(true));
  }, [e]);

  const res = useMemo(
    () => ({
      allReady,
      ...(Object.fromEntries(e.map((x) => [x, configService.getConfigFromCache(x)])) as any),
    }),
    [e, allReady]
  );

  return res;
};
