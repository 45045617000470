import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routeLookup, RouteMeta } from '../routeMeta';
import type { Location } from '@remix-run/router';

/**
 * A wrapper for react router useLocation that adds internal metadata about the route
 *
 * @returns location (from useLocation), meta (from our metadata), topLevel (the first url segment).
 */
export type LocationWithMeta = {
  location: Location;
  meta: RouteMeta;
  topLevel: string;
};

const useLocationMeta: () => LocationWithMeta = () => {
  const location = useLocation();
  const res: LocationWithMeta = useMemo(
    () => ({
      location,
      meta: routeLookup(location.pathname),
      topLevel: location.pathname.split('/')[1],
    }),
    [location]
  );
  return res;
};

export default useLocationMeta;
