import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import log from '@services/diagnosticsService';
import environment from '../environment';
import { RootState } from '../features/store';
export type AssetTitleLocaleLanguage = {
  userId: number;
  title: string;
};

export const mockApi = createApi({
  reducerPath: 'mockApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.apiRoot + '/',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.user?.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    searchPosts: builder.query<AssetTitleLocaleLanguage[], string>({
      query: (queryArg) => ({ url: `/api/search/SearchTracksTemp`, params: { term: queryArg } }),
    }),
    genericDummyPost: builder.mutation<any, any>({
      queryFn: async (queryArg: any) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            log.info(queryArg);
            resolve({
              data: 'done',
            });
          }, 1000);
        });
      },
    }),
  }),
});

export const { useSearchPostsQuery } = mockApi;
