import { Chip, Menu, MenuItem, styled } from "@mui/material";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { SlinkyBadgeProps } from "./SlinkyBadgeProps";

const sizeMap = {
  small: "20px",
  medium: "28px",
};

const paddingMap = {
  small: "2px 8px",
  medium: "2.5px 8px",
};

const labelMap = {
  small: { fontSize: "10px", fontWeight: 600, lineHeight: "16px" },
  medium: { fontSize: "14px", fontWeight: 600, lineHeight: "23px" },
};

const StyledChip = styled(Chip)<SlinkyBadgeProps>(
  ({ size = "small", variant, theme, color }) => ({
    "&.MuiChip-root": {
      height: sizeMap[size],
      ...(variant === "outlined" && {
        border: `0.25px solid ${theme.palette.grey[500]}`,
      }),
      ...(color === "default" && {
        backgroundColor: theme.palette.text.primary,
      }),
      minHeight: "20px",
      padding: paddingMap[size],
      borderRadius: "2px",
      "& .MuiChip-label": {
        padding: 0,
        ...labelMap[size],
        ...(variant === "outlined" && { color: theme.palette.text.secondary }),
      },
      "& .MuiChip-icon": {
        color: "inherit",
        ...labelMap[size],
        ...(variant === "outlined" && { color: theme.palette.text.secondary }),
        marginRight: "5px",
        marginLeft: "0px",
      },
    },
  })
);

const SlinkyBadge = (props: SlinkyBadgeProps) => {
  const { options, selectedOptionId, onClickOption, ...rest } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    rest.onClick && rest.onClick(event);
  };
  const handleClose = (optionId?: number) => {
    setAnchorEl(null);
    optionId && onClickOption && onClickOption(optionId);
  };
  const showOptions = !!options && options.length > 0;
  const dropdownOptions =
    options && options.filter((option) => option.id !== selectedOptionId);
  return (
    <>
      <StyledChip
        {...rest}
        onClick={
          props.onClick || showOptions ? (e) => handleClick(e) : undefined
        }
        icon={
          showOptions ? (
            <FaChevronDown size={10} style={{ margin: 0 }} />
          ) : (
            <></>
          )
        }
        sx={{ ...props.sx, flexDirection: "row-reverse", gap: 1 }}
      />
      {dropdownOptions && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {dropdownOptions
            .filter((option) => !option.disabled)
            .map((option) => (
              <MenuItem onClick={() => handleClose(option.id)} key={option.id}>
                {dropdownOptions.some((option) => option.color) ? (
                  <SlinkyBadge label={option.text} color={option.color} />
                ) : (
                  option.text
                )}
              </MenuItem>
            ))}
        </Menu>
      )}
    </>
  );
};

export default SlinkyBadge;
