import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Slide, Stack, Typography } from '@mui/material';
import { useSafeAreaInsets } from '@src/hooks/useSafeArea';
import { useUiSlice } from '@src/services/layoutService';
import { ReactElement, ReactNode, Ref, forwardRef } from 'react';
import { TransitionProps } from 'react-transition-group/Transition';
import style from './DialogWrapper.module.scss';
export interface DialogWrapperProps extends Omit<DialogProps, 'title'> {
  title: JSX.Element | string;
  titleRightContent?: ReactNode;
  action?: ReturnType<typeof DialogActions>;
  isTransparent?: boolean;
  onClose?: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogWrapper = ({ children, action, title, titleRightContent, onClose, easyClose, ...props }: DialogWrapperProps & { easyClose?: boolean }) => {
  const isMobileLayout = useCssBreakpoint('sm');

  const onCloseDialog = () => {
    if ((isMobileLayout || easyClose) && onClose) {
      onClose();
    }
  };

  const paperProps = isMobileLayout
    ? {
        sx: {
          position: 'absolute',
          bottom: 0,
          width: '100%',
          margin: 0,
        },
      }
    : {};

  const safeAreaInsets = useSafeAreaInsets();
  const ui = useUiSlice();
  return (
    <Dialog
      {...props}
      open={props.open ?? false}
      scroll={'paper'}
      TransitionComponent={isMobileLayout ? Transition : undefined}
      PaperProps={paperProps}
      onClose={onCloseDialog}
    >
      <DialogTitle mt={2} marginRight={4}>
        <Stack direction={isMobileLayout ? 'row-reverse' : 'row'} justifyContent={isMobileLayout ? 'flex-end' : 'space-between'} alignItems={'center'} gap={3}>
          <Stack direction={'row'} gap={3} alignItems={'center'} justifyContent={'space-between'} flex={1} pr={isMobileLayout ? 0 : 5}>
            <Typography variant={isMobileLayout ? 'h3' : 'h2'}>{title}</Typography>
            {titleRightContent}
          </Stack>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              disableTouchRipple
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Stack>
      </DialogTitle>
      <DialogContent className={style.dialogContent}>{children}</DialogContent>
      {!!action && (
        <Box p={2} sx={{ pb: isMobileLayout ? `${safeAreaInsets.bottom + ui.dashboardPadding}px` : '' }}>
          {action}
        </Box>
      )}
    </Dialog>
  );
};
