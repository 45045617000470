import { CircleIconButton } from '@anthology/shared/src/components';
import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { Badge, Box } from '@mui/material';
import { useEventListener } from '@react-hookz/web';
import AccountAvatar from '@src/components/shared/account-avatar/AccountAvatar';
import NotificationAvatar from '@src/components/shared/notification-avatar/NotificationAvatar';
import environment from '@src/environment';
import { useSafeAreaInsets } from '@src/hooks/useSafeArea';
import { useUiSlice } from '@src/services/layoutService';
import { useNotificationService } from '@src/services/notificationService';
import { useRef, useState } from 'react';
import { BsBellFill, BsGearFill } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './MobileTopMenu.module.scss';

const MobileTopMenu = () => {
  const isMobileLayout = useCssBreakpoint('sm');
  const ui = useUiSlice();
  const note = useNotificationService();
  const nav = useNavigate();

  // Portal

  const containerLeft = useRef(null);
  const containerCentre = useRef(null);
  if (isMobileLayout) {
    ui.actions.headerPortalLeft = containerLeft;
    ui.actions.headerPortalCentre = containerCentre;
  }

  // Scrolling

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  useEventListener(ui.actions.dashboardElement?.current, 'scroll', (e: any) => {
    const position = e.target.scrollTop;
    if (scrollPosition && position > scrollPosition) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    setScrollPosition(position);
  });
  const isScrolled = scrollPosition > 300;

  const location = useLocation();
  const notifiationsPath = '/notifications';
  const appSettingsPath = '/appSettings';
  const hiddenSx = { opacity: 0, pointerEvents: 'none' };
  const isNotifcationRoute = location.pathname === notifiationsPath;
  const isAppSettingsRoute = location.pathname === appSettingsPath;
  const safeAreaInsets = useSafeAreaInsets();
  const isMobileApp = environment.runtime.isMobileApp;

  return isMobileLayout && ui.isTopBarOpen ? (
    <Box
      className={`${style.mobileTopMenu} ${isOpen && style.mobileTopMenuOpen} ${isScrolled && style.mobileTopMenuScrolled} ${
        isMobileApp && style.mobileTopMenuApp
      }`}
      pt={`${safeAreaInsets.top}px`}
    >
      <Box className={style.mobileTopMenuInner} minHeight={ui.topBarHeight}>
        <Box ref={containerLeft} className={style.portal} />
        <Box ref={containerCentre} className={`${style.portal} ${style.portalCentre}`} />
        <Box className={style.mobileTopMenuDefaultIconsContainer}>
          <CircleIconButton onClick={() => nav(notifiationsPath)} sx={isNotifcationRoute ? hiddenSx : {}}>
            <Badge color="error" badgeContent={note.unreadCount}>
              <BsBellFill size={21} />
            </Badge>
          </CircleIconButton>
          <CircleIconButton onClick={() => nav('/appSettings')} sx={isAppSettingsRoute ? hiddenSx : {}}>
            <Badge badgeContent={<BsGearFill />}>
              <AccountAvatar sx={{ width: 27, height: 27, fontSize: 15 }} />
            </Badge>
          </CircleIconButton>
        </Box>
        <Box className={style.notificationAvatarContainer}>
          <NotificationAvatar />
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default MobileTopMenu;
