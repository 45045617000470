import { CircleIconButton } from '@anthology/shared/src/components';
import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { InputAdornment, Stack, TextField } from '@mui/material';
import environment from '@src/environment';
import { RootState } from '@src/features/store';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { BsSearch, BsX } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import style from './SearchInput.module.scss';
import { SearchInputProps } from './SearchInputProps';

const SearchInput = ({ value, onInput, onChange, sx, autoCollapseRange, defaultWidth, isOpen, onClear, ...props }: SearchInputProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputDelayed = useCallback(
    debounce((text) => {
      onInput?.(text);
    }, 500),
    [onInput]
  );

  const currentTheme = useSelector((state: RootState) => state.theme.theme);
  const { isDark } = currentTheme;
  const [isFocussed, setIsFocussed] = useState(false);

  const isElectron = environment.runtime.isElectron;
  const wideWidth = isElectron ? '40vw' : '700px';
  const isMobileLayout = useCssBreakpoint('sm');

  const widthIsOpenValue = (isOpen = undefined ? isFocussed : isOpen);
  const width =
    props?.inputProps?.sx?.width || autoCollapseRange
      ? (widthIsOpenValue || value) && !isMobileLayout
        ? autoCollapseRange![1] || wideWidth
        : autoCollapseRange![0]
      : wideWidth;

  // Clear

  const handleClickClear = (e: any) => {
    e.stopPropagation();
    onClear?.();
  };

  return (
    <TextField
      {...props}
      onInput={(event) => onInputDelayed(event)}
      onChange={(event) => onChange && onChange(event)}
      placeholder={props.placeholder || 'Search something...'}
      inputRef={props?.InputProps?.ref}
      onFocus={() => setIsFocussed(true)}
      onBlur={() => setIsFocussed(false)}
      InputProps={{
        ...props?.InputProps,
        startAdornment: (
          <InputAdornment position="start" sx={{ px: 1, ml: '-2px', pointerEvents: 'none' }}>
            <BsSearch />
          </InputAdornment>
        ),
        endAdornment: onClear ? (
          <Stack className={style.fadeIn}>
            <CircleIconButton onClick={handleClickClear}>
              <BsX size={30} />
            </CircleIconButton>
          </Stack>
        ) : undefined,
        sx: {
          color: 'text.secondary',
          ...props?.inputProps?.sx,
        },
        value: value,
      }}
      size={props.size || 'small'}
      className={style.searchField}
      sx={{
        backgroundColor: isDark ? 'transparent' : 'background.dialog',
        transition: 'all ease-in 0.33s',
        maxWidth: width,
        width: defaultWidth || '100%',
        ...sx,
      }}
    />
  );
};

export default SearchInput;
