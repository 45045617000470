import { Breakpoint, useMediaQuery } from "@mui/material";
import { isNumber } from "lodash";
import { themeCssBreakpoints } from "../constants/theme/cssBreakpoints";

export const useCssBreakpoint = (breakpoint: Breakpoint | number) => {
  const px =
    (isNumber(breakpoint) ? breakpoint : themeCssBreakpoints[breakpoint]) -
    0.05;
  return useMediaQuery(`@media (max-width:${px}px)`);
};
