import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { StatusBar } from '@capacitor/status-bar';
import { NavigationBar } from '@mauricewegner/capacitor-navigation-bar';
import environment from '@src/environment';
import { store } from '@src/features/store';
import { setIsKeyboardOpen } from '@src/features/uiSlice';

export const initMobileApp = () => {
  if (environment.runtime.isMobileApp) {
    if (window.outerWidth < 1000) {
      ScreenOrientation.lock({ orientation: 'portrait' });
    }
    StatusBar.setOverlaysWebView({ overlay: true });
    if (environment.runtime.isAndroid) {
      NavigationBar.setTransparency({ isTransparent: true });
    }
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
    if (environment.runtime.isMobileApp) {
      Keyboard.addListener('keyboardWillShow', () => {
        store.dispatch(setIsKeyboardOpen(true));
      });

      Keyboard.addListener('keyboardWillHide', () => {
        store.dispatch(setIsKeyboardOpen(false));
      });
    }
  }
};
