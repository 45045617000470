import notificationService from '@src/services/notificationService';

export const showMessage = (message: string, isError?: boolean) => {
  notificationService.postFlash(message, isError ? 'error' : 'success');
};

export const extractError = (resp: any): string | undefined => {
  if (!resp) return undefined;
  const validationErrors = resp?.data?.validationErrors;

  const err = resp?.data?.exceptionMessage;

  if (!!err) {
    return err;
  }

  if (validationErrors) {
    if (validationErrors.length === 1) {
      return validationErrors[0].reason;
    } else {
      return 'Multiple validation errors';
    }
  }
  return (
    resp.exceptionMessage ??
    resp.error?.data?.responseException?.exceptionMessage ??
    resp.error?.data?.exceptionMessage ??
    resp.error?.message ??
    resp.error?.error ??
    (resp.isError || resp.error?.data?.isError ? resp.message || 'Error' : null)
  );
};

export const requestWithSnackbar = <T>(
  trigger: Promise<T>,
  successMessage?: string,
  errorMessage?: string,
  onSuccess?: () => void,
  onError?: () => void
): Promise<T> => {
  return trigger
    .then((res) => {
      const errMsg = extractError(res);
      if (errMsg) {
        showMessage((errorMessage || 'Error').replaceAll('{error}', errMsg), true);
        onError && onError();
      } else {
        if (successMessage) {
          showMessage(successMessage);
          onSuccess && onSuccess();
        }
      }
      return res;
    })
    .catch((e) => {
      if (errorMessage) {
        showMessage(errorMessage.replaceAll('{error}', extractError(e) ?? JSON.stringify(e)), true);
      }
      return e;
    });
};

export const requestWithErrorHandling = <T>(trigger: Promise<T>, errorMessage?: string): Promise<T> => {
  return requestWithSnackbar(trigger, '', errorMessage || 'Error saving data');
};
