import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

const HelpTooltip = ({ title, isTextFieldWrapper }: { title: string | React.ReactNode; isTextFieldWrapper?: boolean }) => {
  return (
    <Tooltip title={title}>
      <IconButton size="small" sx={isTextFieldWrapper ? { right: '-45px', position: 'absolute' } : []}>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default HelpTooltip;
