import { LoginResponseViewModel, PermissionIdType, anthologyApi, useGetApiAccountGetPermsOnEntityQuery } from '@api/anthologyApi';
import { authSetCredentials, authSignOut } from '../features/authSlice';
import { RootState, store } from '../features/store';

import _ from 'lodash';
import { RouteMeta } from '../routeMeta';

import { useSelector } from 'react-redux';

import { slinkyApi } from '@src/api/slinkyApi';
import { differenceInSeconds, parseISO } from 'date-fns';
import { useMemo } from 'react';
import cacheService from './cacheService';

const localStoreUserKey = 'userData';
const useLocalStore = false;

class authServiceClass {
  state = () => store.getState().auth;

  async login(username: string, password: string): Promise<LoginResponseViewModel> {
    const x = await store.dispatch(
      anthologyApi.endpoints.postApiAccountLogin.initiate({
        email: username,
        password,
      })
    );

    return this.validateLoginResponse(x);
  }

  async loginAsUser(username: string): Promise<LoginResponseViewModel> {
    const x = await store.dispatch(
      anthologyApi.endpoints.postApiAccountLoginAsUser.initiate({
        email: username,
        password: '',
      })
    );

    return this.validateLoginResponse(x);
  }

  async refreshPermissions(): Promise<LoginResponseViewModel> {
    const auth = this.state();

    if (!auth.isSignedIn) return {} as any;

    const x = await store.dispatch(anthologyApi.endpoints.getApiAccountGetUserPerms.initiate(undefined as any));

    const d = this.validateLoginResponse(x, false);

    const um = _.merge({}, auth.user, { user: d });
    this.setUserData(um);
    return um;
  }

  validateLoginResponse(x: any, setUserData: boolean = true) {
    if (!!x.error) {
      throw new Error(x.error.data?.responseException?.exceptionMessage ?? x.error.message ?? JSON.stringify(x.error));
    }

    if (setUserData) {
      this.setUserData(x.data);
      this.clearCaches();
    }
    return x.data;
  }

  logout() {
    if (useLocalStore) localStorage.removeItem(localStoreUserKey);
    store.dispatch(authSignOut());
    this.clearCaches();
  }

  clearCaches() {
    store.dispatch(anthologyApi.util.resetApiState());
    store.dispatch(slinkyApi.util.resetApiState());
    cacheService.clearAll();
  }

  autoLogin(): void {
    const loadedUser: LoginResponseViewModel = JSON.parse(localStorage.getItem(localStoreUserKey) as any);
    if (loadedUser && loadedUser.token) {
      this.setUserData(loadedUser);
    }
  }

  canAccessRoute(meta: RouteMeta) {
    let parentMeta: RouteMeta | undefined = meta;

    //walk up all parent paths to check all rules pass
    while (parentMeta) {
      if (!this.checkServiceRules(parentMeta.authRules)) return false;
      parentMeta = parentMeta.parent;
    }

    return true;
  }

  checkServiceRules(rules: string[] | undefined) {
    //if no rules assigned or public assume pass
    if (!rules || !rules.length || rules.includes('public')) {
      return true;
    }

    const auth = this.state();

    if (!auth.isSignedIn) {
      return false;
    }

    // each rule in list is treated as OR, if any pass return true
    for (const r of rules) {
      //each token in rule separated by [,|+&] is treated as AND - all must pass
      const matchAll = r
        .split(/[+|,&]/)
        .filter((x) => x !== '')
        .map((x) => x.toLowerCase().trim());

      if (matchAll.filter((s) => this.checkSingleRule(s, auth.user!)).length === matchAll.length) {
        return true;
      }
    }

    //if none of the rules passed the check fails
    return false;
  }

  private checkSingleRule(rule: string, auth: LoginResponseViewModel): boolean {
    rule = (rule ?? '').replaceAll(' ', '').toLowerCase();
    if (rule.length === 0 || rule === 'public') {
      return true; // if no rules assume true
    }

    const perms = auth.user?.userPermissions;

    if (!perms) {
      return false; // if no permissions assume false
    }

    let cleanRule = rule;

    if (rule.startsWith('>')) {
      // assume we are comparing access tier

      cleanRule = rule.replace('>', '').replace('=', '');

      if (perms.accessTiers?.map((x) => x.toLowerCase()).includes(cleanRule)) {
        return true;
      }

      //console.warn('Unknown access tier ' + cleanRule);
      return false;
    }

    if (!cleanRule.includes('.')) {
      if (perms.roles?.map((x) => x.toLowerCase()).includes(cleanRule)) {
        return true;
      }
    }

    if (perms.maxServices?.map((x) => x.toLowerCase().replaceAll(' ', '')).includes(cleanRule)) {
      return true;
    }

    //console.warn('Unknown role or service ' + cleanRule);
    return false;
  }

  public setUserData(userModel: LoginResponseViewModel) {
    if (useLocalStore) saveUserDataLocalStorage(userModel);
    store.dispatch(authSetCredentials(userModel));
  }

  public checkTokenExpired() {
    const state = this.state();

    if (!state.expiresAt) {
      return true;
    }

    const exp = parseISO(state.expiresAt);
    const now = new Date();
    const diff = differenceInSeconds(exp, now);

    return diff < 0;
  }

  public getFullName() {
    const user = store.getState().auth.user?.user;

    if (!user) {
      return undefined;
    }

    let name = ((user?.firstname ?? '') + ' ' + (user?.lastName ?? '')).trim();
    name = _.isEmpty(name) ? user.username ?? user.email ?? '' : name;
    return _.isEmpty(name) ? undefined : name;
  }

  public getInitials() {
    const name = this.getFullName();
    return getNameInitials(name ?? '');
  }
}

export function saveUserDataLocalStorage(userModel: LoginResponseViewModel) {
  localStorage.setItem(localStoreUserKey, JSON.stringify(userModel));
}

export const getNameInitials = (name: string) => {
  if (!name) {
    return undefined;
  }

  const parts = name.split(' ');

  if (parts.length > 1) {
    return (parts[0][0] + parts.at(-1)![0]).toUpperCase();
  }

  return parts[0].slice(0, 2).toUpperCase();
};

const authService = new authServiceClass();

export const useAuth = () => {
  const auth = useSelector((state: RootState) => state.auth);

  return {
    auth,
    actions: authService,
  };
};

export const useCheckGeneralPermission = (rules: string[]) => {
  const auth = useAuth();

  return useMemo(() => auth.actions.checkServiceRules(rules), [auth, rules]);
};

export const useCheckAnyObjectPermission = (entityType: PermissionIdType, entityId: number, rules: string[]) => {
  const { data: res } = useGetApiAccountGetPermsOnEntityQuery({ entityType, entityId });

  return useMemo(() => !!res && rules.some((x) => res[x]), [res, rules]);
};

export default authService;
