import { ButtonWrapper } from '@anthology/shared/src/components';
import { Box, Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';
import environment from '../../../environment';

interface Props {
  children?: ReactNode;
}

interface State {
  isError: boolean;
  error: Error | undefined;
}

const keyWord = 'dynamically';

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    isError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(err: Error): State {
    // Update state so the next render will show the fallback UI.
    return { isError: true, error: err };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    //logErrorToMyService(error, errorInfo);
  }

  public componentDidUpdate(prevProps: any, prevState: any) {}

  public defaultView() {
    return (
      <>
        <h1>Sorry.. there was an error</h1>
        <p>
          <>
            {this.state.error!.name} <br />
            {this.state.error!.cause}
            <br />
            {this.state.error!.message}
          </>
        </p>
        <img src={`${environment.CDNRoot}/static/broken-record-breaking-records.gif`} alt="error"></img>
      </>
    );
  }

  public moduleImportView() {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box textAlign={'center'}>
          <Box>
            <Typography variant="title">Website Updated!</Typography>
          </Box>
          <Box height={'420px'} width={'600px'} margin={'0 auto'}>
            <img src={`${environment.CDNRoot}/static/logos/websiteupdate.jpg`} alt="" height={'100%'} width={'100%'} style={{ objectFit: 'contain' }} />
          </Box>
          <Box marginBottom={1}>
            <Typography>Hey There, the page you tried to navigate to has changed. Simply press the button</Typography>
          </Box>
          <Box>
            <ButtonWrapper variant="contained" onClick={() => window.location.reload()}>
              Reload!
            </ButtonWrapper>
          </Box>
        </Box>
      </Box>
    );
  }

  public render() {
    if (this.state.isError) {
      if (this.state.error?.name.includes(keyWord) || this.state.error?.message.includes(keyWord)) {
        return this.moduleImportView();
      }
      return this.defaultView();
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
