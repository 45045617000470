import diagnosticsService from '@services/diagnosticsService';
import environment from '@src/environment';
import notificationService from './notificationService';

export class UpdateServiceClass {
  log = diagnosticsService;
  note = notificationService;
  serverVersion = {
    autoVersion: environment.autoVersion,
    autoGitHash: environment.autoGitHash,
    autoSourceHash: environment.autoSourceHash,
    autoBuildDate: environment.autoBuildDate,
  };

  constructor() {
    this.doCheck();
    setInterval(() => {
      this.doCheck();
    }, 60000);
  }

  init() {}

  doCheck() {
    fetch(`${environment.clientRoot}/version.json?ts=${new Date().valueOf()}`, { method: 'GET' })
      .then((resp) => resp.json())
      .then((v) => {
        if (v.autoSourceHash === this.serverVersion.autoSourceHash) {
          return;
        } else {
          this.log.warn('New client version ' + this.serverVersion.autoBuildDate + '>' + v.autoBuildDate);
          this.serverVersion = v;
          if (environment.runtime.isWeb) {
            this.note.postNotification({
              noteProps: {
                message: `**Update Available!**\n\nVersion ${v.autoVersion}.${v.autoGitHash.substr(0, 8)} of Anthology is out, refresh your page to get it!`,
                severity: 'success',
                icon: 'upgrade',
              },
              flashForSec: 30,
              expiresInSec: 600,
            });
          }
        }
      })
      .catch();
  }
}

const updateService = new UpdateServiceClass();

export default updateService;
