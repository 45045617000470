import { useGetApiUtilityGetDomainSettingsQuery } from '@src/api/anthologyApi';
import { useConfigItem } from '@src/services/configService';
import { useMemo } from 'react';

export const useTennantCurrency = () => {
  const config = useConfigItem('currency');
  const { data, isLoading, isError } = useGetApiUtilityGetDomainSettingsQuery();
  const currency = config.currency && config.currency.find((currency) => currency.id === data?.tenantCurrencyLinkId);
  return useMemo(
    () => ({
      data: currency,
      isLoading: isLoading || !config.allReady,
      isError: isError,
    }),
    [config.allReady, currency, isError, isLoading]
  );
};
