import { PermissionIdType } from '@src/api/types/autoEnum';
import { useAuth, useCheckAnyObjectPermission, useCheckGeneralPermission } from '@src/services/authService';
import { ReactNode, useMemo } from 'react';

type PermissionGuardGeneralProps = {
  rules: string[];
  children: ReactNode;
  else?: ReactNode;
};

type PermissionGuardProps = {
  entityType?: PermissionIdType;
  entityId?: number;
} & PermissionGuardGeneralProps;

type PermissionGuardObjectProps = {
  entityType: PermissionIdType;
  entityId: number;
} & PermissionGuardGeneralProps;

export const IfElse = (props: { condition: boolean; children: ReactNode; else?: ReactNode }) => {
  return <> {props.condition ? props.children : props.else}</>;
};

const PermissionGuardGeneral = (props: PermissionGuardGeneralProps) => {
  const allow = useCheckGeneralPermission(props.rules);
  return <> {allow ? props.children : props.else}</>;
};

const PermissionGuardObject = (props: PermissionGuardObjectProps) => {
  const allow = useCheckAnyObjectPermission(props.entityType, props.entityId, props.rules);
  return <> {allow ? props.children : props.else}</>;
};

export const usePermissionRoleCheck = (rules: string[]) => {
  const { auth } = useAuth();

  const allow = useMemo(() => {
    const myroles = (auth.user?.user?.userPermissions?.roles ?? []).map((x: any) => x.toLowerCase());
    return rules.some((x) => myroles.includes(x));
  }, [auth, rules]);

  return allow;
};

export const PermissionRoleCheck = (props: PermissionGuardGeneralProps) => {
  const allow = usePermissionRoleCheck(props.rules);

  return <> {allow ? props.children : props.else}</>;
};

const PermissionGuard = (props: PermissionGuardProps) => {
  return !!props.entityId ? (
    <PermissionGuardObject {...(props as any)} />
  ) : (
    <PermissionGuardGeneral rules={props.rules} children={props.children} else={props.else} />
  );
};

export default PermissionGuard;
