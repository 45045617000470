import { SideMenuContent } from '@src/components/layout/nav/SideNavigation';
import { setSideMenuState } from '@src/features/uiSlice';
import { PageLayouts, useUiSlice } from '@src/services/layoutService';
import { Resizable } from 're-resizable';
import { useDispatch } from 'react-redux';
import style from './ElectronMenu.module.scss';

const ElectronMenu = ({ onChangeWidth }: { onChangeWidth?: (width: number) => void }) => {
  const ui = useUiSlice();
  const dispatch = useDispatch();
  const onChangeElectronMenuWidth = (width: number) => {
    dispatch(setSideMenuState({ isCollapsed: width < 160 }));
    onChangeWidth?.(width);
  };

  return ui.layout !== PageLayouts.fullscreen ? (
    <Resizable
      className={style.electronMenu}
      defaultSize={{ width: ui.sideBarMarginWidth, height: `calc(100vh - ${ui.topBarHeight})` }}
      minWidth={60}
      maxWidth={350}
      onResize={(e, dir, ref, delta) => onChangeElectronMenuWidth(ref.offsetWidth)}
    >
      <SideMenuContent />
    </Resizable>
  ) : (
    <></>
  );
};

export default ElectronMenu;
