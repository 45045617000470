import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

const FormatImg = ({ imgUrl, formatId, style }: { imgUrl: string; formatId?: number; style?: CSSProperties }) => {
  const nav = useNavigate();
  const onClick = () => {
    nav(`/catalogue/formats/format-detail/${formatId}`);
  };
  return <img src={imgUrl} className={formatId ? 'clickable' : ''} alt="" style={{ borderRadius: '10%', ...style }} onClick={formatId ? onClick : undefined} />;
};

export default FormatImg;
