import { RouteMeta } from '@src/routeMeta';
import { useAuth } from '@src/services/authService';
import { hookDependsOn } from '@src/utils/helpers';
import { useMemo } from 'react';

export const useAllowedRoutes = (routes: RouteMeta[]) => {
  const { actions, auth } = useAuth();

  const allowed = useMemo(() => {
    hookDependsOn(auth.user);

    if (!routes) {
      return [];
    }

    function routeFilter(r: RouteMeta[]): RouteMeta[] {
      return r.filter((o) => actions.canAccessRoute(o)).map((x) => ({ ...x, children: routeFilter(x.children ?? []) }));
    }

    return routeFilter(routes);
  }, [routes, actions, auth.user]);

  return allowed;
};
