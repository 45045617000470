import { addSeconds } from 'date-fns';
import _ from 'lodash';

export interface CacheItem<T> {
  expires: number;
  data: Promise<T>;
}

class cacheServiceClass {
  private _cache: { [key: string]: CacheItem<any> } = {};

  public get<T>(key: string) {
    if (this.hasExpired(key)) {
      return null;
    }
    return this._cache[key].data.then((x) => _.cloneDeep(x) as T);
  }

  public set(key: string, factory: () => Promise<any>, durationSeconds: number = 60) {
    this._cache[key] = {
      expires: addSeconds(new Date(), durationSeconds).valueOf(),
      data: factory(),
    };
  }

  public getOrSet<T>(key: string, factory: () => Promise<T>, durationSeconds: number = 60) {
    if (!this.hasExpired(key)) {
      return this.get(key) as Promise<T>;
    }

    this.set(key, factory, durationSeconds);
    return this.get(key) as Promise<T>;
  }

  public clear(key: string) {
    try {
      delete this._cache[key];
    } catch {}
  }

  public clearAll() {
    this._cache = {};
  }

  private hasExpired(key: string) {
    const item = this._cache[key];
    if (!item || !item.expires) {
      return true;
    }

    return new Date().valueOf() - item.expires > 0;
  }
}

const cacheService = new cacheServiceClass();

// const fac = () => {
//   return new Promise((r) => {
//     setTimeout(() => {
//       console.log('did work');
//       r(1);
//     }, 2000);
//   });
// };

// cacheService.getOrSet('test', () => fac()).then((x) => console.log(x));
// cacheService.getOrSet('test', () => fac()).then((x) => console.log(x));
// cacheService.getOrSet('test', () => fac()).then((x) => console.log(x));
// cacheService.getOrSet('test', () => fac()).then((x) => console.log(x));
// cacheService.getOrSet('test', () => fac()).then((x) => console.log(x));

export default cacheService;
