import { BottomNavigation, BottomNavigationAction, Stack } from '@mui/material';
import { useUiSlice } from '@src/services/layoutService';

import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { useSafeAreaInsets } from '@src/hooks/useSafeArea';
import { mainRoutes, RouteMeta } from '@src/routeMeta';

import { useAudioService } from '@anthology/shared/src/services';
import { capitaliseInitial } from '@anthology/shared/src/utils/string';
import AccountAvatar from '@src/components/shared/account-avatar/AccountAvatar';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './MobileMenu.module.scss';

const MobileMenu = () => {
  const [selectedIndex, setSelctedIndex] = useState<number | null>();
  const menuItems = mainRoutes.filter((route: RouteMeta) => route.isMobileMenuItem);
  const nav = useNavigate();
  const location = useLocation();
  const ui = useUiSlice();
  const audio = useAudioService(false);
  const safeAreaInsets = useSafeAreaInsets();
  const height = ui.bottomBarHeight;
  const isMobileLayout = useCssBreakpoint('sm');

  useEffect(() => {
    if (location.pathname === '/') {
      setSelctedIndex(0);
    } else {
      const selectedIndex = menuItems.findIndex((route) => location.pathname.indexOf(route.path) === 1);
      setSelctedIndex(selectedIndex);
    }
  }, [location.pathname, menuItems]);

  const updateRoute = (index: number) => {
    // Update selected item with immediete effect
    setSelctedIndex(index);
    // Update navigation
    const newItem = menuItems[index];
    nav(newItem.path);
  };

  return isMobileLayout && ui.isBottomBarOpen && !ui.isKeyboardOpen ? (
    <Stack className={style.mobileMenuContainer}>
      <Stack height={height}>
        <BottomNavigation showLabels value={selectedIndex} className={style.mobileMenu}>
          {menuItems.map((route, index) => (
            <BottomNavigationAction
              sx={{
                pt: '10px',
                gap: '2px',
                backgroundColor: audio.state.playerVisible ? 'var(--mui-palette-background-default)' : undefined,
              }}
              key={route.path}
              onClick={() => updateRoute(index)}
              label={capitaliseInitial(route.labelXs || route.label)}
              icon={route.icon ? <route.icon size={22} /> : route.path === 'more' ? <AccountAvatar sx={{ height: 22, width: 22 }} /> : <></>}
            />
          ))}
        </BottomNavigation>
      </Stack>
      <Stack height={`${safeAreaInsets.bottom}px`} />
    </Stack>
  ) : (
    <></>
  );
};

export default MobileMenu;
