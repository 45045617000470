import { CircleIconButton, MarkDownView } from '@anthology/shared/src/components';
import { getArtistImgUrl, getPackshotUrl } from '@anthology/shared/src/utils/fileLocations';
import { Box, Stack, Typography } from '@mui/material';
import ArtistImg from '@src/components/shared/artist-img/ArtistImg';
import FormatImg from '@src/components/shared/format-img/FormatImg';
import { MiniSessionViewer } from '@src/pages/financial/digital/UploadReportWithProgress';
import { Notification, NotificationAlertIcons, useNotificationService } from '@src/services/notificationService';
import { formatDistance } from 'date-fns';
import React from 'react';
import { BsCircleFill, BsX } from 'react-icons/bs';
import style from './NotificationItem.module.scss';

const NotificationItem = ({ notification, variant = 'notification' }: { notification: Notification; variant?: 'notification' | 'snackbar' }) => {
  const note = useNotificationService();
  const IconComp = (NotificationAlertIcons as any)[notification.icon!] ?? NotificationAlertIcons.default;
  const { id, message, createTime, severity, meta, isBackendNote } = notification;
  const { formatImageGuid, artistImageGuid } = meta || {};
  const formatImageUrl = formatImageGuid && getPackshotUrl(formatImageGuid);
  const artistImageUrl = artistImageGuid && getArtistImgUrl(artistImageGuid);
  const isSnackbar = variant === 'snackbar';
  const imageSize = isSnackbar ? 40 : 55;
  const iconSize = isSnackbar ? 15 : 20;
  const imageStyle = { width: imageSize, height: imageSize, minWidth: imageSize, minHeight: imageSize };

  // Delete

  const deleteNotification = () => {
    note.delete(notification.id);
  };
  return (
    <Box className={style.notification}>
      <Stack
        direction={'row'}
        gap={2}
        alignItems={'center'}
        sx={{ opacity: notification.isRead && notification.isBackendNote ? 0.75 : 1 }}
        onClick={() => note.markRead(id)}
        flex={1}
      >
        <Stack className={style.notificationImage}>
          {formatImageUrl ? (
            <FormatImg imgUrl={formatImageUrl} style={imageStyle} />
          ) : artistImageUrl ? (
            <ArtistImg imgUrl={artistImageUrl} style={imageStyle} />
          ) : (
            <IconComp color={(severity as any) || 'info'} size={imageSize} />
          )}
          {formatImageUrl && (
            <Stack className={style.notificationIcon}>
              <IconComp color={(severity as any) || 'info'} size={iconSize} />
            </Stack>
          )}
        </Stack>
        <Stack gap={isSnackbar ? 0.25 : 1}>
          <Typography
            variant={isSnackbar ? 'sub5' : 'sub4'}
            color="text.secondary"
            m={0}
            sx={{
              '*[href], *[href]:hover': { color: 'inherit', textDecoration: 'none' },
              '.wmde-markdown > p': { whiteSpace: 'initial' },
            }}
          >
            <MarkDownView source={message} />
          </Typography>
          {notification.meta?.sessionid && (
            <Box mt={2}>
              <MiniSessionViewer sessionid={notification.meta.sessionid} ultraMini={true}></MiniSessionViewer>
            </Box>
          )}
          {isBackendNote && (
            <Typography variant="labelMedium" color={'primary.main'}>
              {formatDistance(new Date(createTime), new Date(), { addSuffix: true })}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Stack className={style.notifiationRight}>
        {!isSnackbar && (
          <Stack className={style.notificationUnread} sx={{ visibility: notification.isRead ? 'hidden' : 'visible' }}>
            <BsCircleFill color="var(--mui-palette-primary-dark)" size={14} />
          </Stack>
        )}
        <Box className={`${style.notificationDelete} ${isSnackbar && style.notificationDeleteSnackbar}`}>
          <CircleIconButton onClick={deleteNotification}>
            <BsX size={30} />
          </CircleIconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default NotificationItem;
