import {
  CDNRoot,
  avatarBlankDark,
  avatarBlankLight,
  defaultErrorImage,
} from "../../constants/urls";

type PackshotSize ="xs" | "sm" | "md" | "lg" | "xl";
type ContributorImageSize = "xs" | "sm" | "md" | "lg" | "xl";

const getPackshotUrl = (
  guid?: string,
  size?: PackshotSize,
  errorImage?: string,
  cropped?: true
) =>
  guid
    ? `${CDNRoot}/preview/image/${size || "sm"}_${cropped? 'crop':'full'}_webp/${guid.toLowerCase()}.webp`
    : errorImage ?? defaultErrorImage;

const getAudioUrl = (fileName: string) => {
  return `${CDNRoot}/preview/audio/full_mp3/${fileName}.mp3`;
};

const getArtistImgUrl = (
  guid: string,
  size?: ContributorImageSize,
  isDarkMode?: boolean
) => {
  return guid
    ? `${CDNRoot}/profiles/artists/${size || "xs"}/${guid?.toLowerCase() ?? ""}.webp`
    : isDarkMode
      ? avatarBlankDark
      : avatarBlankLight;
};

export { getArtistImgUrl, getAudioUrl, getPackshotUrl };
export type { ContributorImageSize, PackshotSize };
