import { Link, LinkProps } from 'react-router-dom';

const LinkWrapper = ({ disabled, children, inheritColor, ...rest }: LinkProps & { disabled?: boolean; inheritColor?: boolean }) => {
  if (disabled) {
    return <>{children}</>;
  } else {
    return (
      <Link {...rest} style={{ ...rest?.style, color: inheritColor ? 'inherit' : rest.style?.color }}>
        {children}
      </Link>
    );
  }
};

export default LinkWrapper;
