import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, RadioGroupProps, RadioProps, styled, useRadioGroup } from '@mui/material';

export type SegmentedControlProps = RadioGroupProps & {
  size?: 'large' | 'medium' | 'small';
  dialogcolor?: 'default' | 'paper';
};

export type SegmentedControlItemProps = RadioProps;

const getContainerSize = (size: 'large' | 'medium' | 'small') => {
  switch (size) {
    case 'large':
      return '58px';
    case 'medium':
      return '50px';
    case 'small':
      return '36px';
  }
};

const getChildrenSize = (size: 'large' | 'medium' | 'small') => {
  switch (size) {
    case 'large':
      return '48px';
    case 'medium':
      return '40px';
    case 'small':
      return '26px';
  }
};

const getPadding = (size: 'large' | 'medium' | 'small') => {
  switch (size) {
    case 'large':
      return '12px';
    case 'medium':
      return '8px 12px';
    case 'small':
      return '2px 12px';
  }
};

const StyledRadioGroup = styled(RadioGroup)<SegmentedControlProps>(({ size = 'medium', dialogcolor = 'paper', theme, ...rest }) => ({
  '&.MuiFormGroup-root': {
    background: dialogcolor === 'paper' ? theme.palette.background.paper : theme.palette.background.default,
    height: getContainerSize(size),
    minHeight: '36px',
    borderRadius: '40px',

    '& .MuiFormControlLabel-root': {
      boxSizing: 'border-box',
      height: getChildrenSize(size),
      minHeight: '26px',
      borderRadius: '40px',
      padding: getPadding(size),
      margin: '5px',
      minWidth: '80px',
      justifyContent: 'center',
      transition: 'all .5s',
      '& .MuiButtonBase-root': {
        display: 'none',
      },
    },
  },
}));

interface StyledFormControlLabelProps extends Omit<FormControlLabelProps, 'control'> {}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel control={<Radio />} {...props} />)(({ theme, checked }) => ({
  '&.MuiFormControlLabel-root': {
    color: checked ? theme.palette?.background.default : theme.palette?.text.primary,
    backgroundColor: checked ? theme.palette?.text.primary : 'transparent', // Brand White
  },
}));

export const SegmentedControlItem = (props: StyledFormControlLabelProps) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
};

export const SegmentedControl = ({ dialogcolor, ...rest }: SegmentedControlProps) => {
  return (
    <StyledRadioGroup row dialogcolor={dialogcolor} {...rest}>
      {rest.children}
    </StyledRadioGroup>
  );
};
