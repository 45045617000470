import { Stack, Typography } from '@mui/material';
import AccountAvatar from '@src/components/shared/account-avatar/AccountAvatar';
import { useAuth } from '@src/services/authService';

const UserSummary = () => {
  const auth = useAuth();
  const user = auth?.auth?.user?.user;
  return (
    <Stack gap={2} direction={'row'} alignItems={'center'} sx={{ px: 2.5, pt: 1, pb: 1.5 }}>
      <AccountAvatar sx={{ height: 35, width: 35 }} />
      <Stack gap={0.25}>
        <Typography variant="labelLarge">
          {user?.firstname} {user?.lastName}
        </Typography>
        <Typography variant="labelSmall">{user?.email}</Typography>
      </Stack>
    </Stack>
  );
};

export default UserSummary;
