import loadable from '@loadable/component';
import _ from 'lodash';
import React, { createRef, ElementType, RefObject } from 'react';

import {
  BsBellFill,
  BsBoxes,
  BsBoxSeamFill,
  BsCode,
  BsCreditCard,
  BsCurrencyExchange,
  BsDiagram2,
  BsFileBarGraph,
  BsFileRuledFill,
  BsFillBarChartFill,
  BsLightbulb,
  BsList,
  BsNewspaper,
  BsPersonCircle,
  BsReceipt,
  BsSearch,
  BsShopWindow,
  BsSpeedometer,
  BsTicketDetailed,
  BsTruck,
} from 'react-icons/bs';
import { FaPeopleGroup } from 'react-icons/fa6';
import { MdAdminPanelSettings, MdLibraryMusic } from 'react-icons/md';
import { Location, RouteObject } from 'react-router-dom';
import { anthologyApi } from './api/anthologyApi';
import { TicketStatus } from './api/types/autoEnum';
import { CommonIcons } from './constants/commonIcons';
import { store } from './features/store';
import { PageLayouts } from './services/layoutService';
import notificationService from './services/notificationService';

export interface RouteMeta {
  label: string;
  labelXs?: string;
  labelForChild?: string;
  path: string;
  element: ElementType;
  icon?: React.ElementType;
  children?: RouteMeta[];
  layout?: PageLayouts;
  authRules?: string[];
  navListItem?: boolean;
  apiAuth?: (location: Location, meta: RouteMeta) => Promise<boolean>;
  fullPath?: string;
  parent?: RouteMeta;
  ref?: RefObject<any>;
  isMobileMenuItem?: boolean;
  getNotificationCount?: () => Promise<number>;
  description?: string;
  isMore?: boolean;
  featureLabel?: string;
}

export const mainRoutes: RouteMeta[] = [
  {
    label: 'home',
    path: '',
    element: loadable(() => import('./pages/home/HomePage')),
    layout: PageLayouts.dashboard,
  },
  {
    label: 'insights',
    path: 'insights',
    element: loadable(() => import('./pages/insights/InsightsPage')),
    icon: BsFillBarChartFill,
    navListItem: true,
    authRules: ['catalogue.view'],
    isMobileMenuItem: true,
    layout: PageLayouts.dashboard,
    children: [
      {
        label: 'Analytics',
        navListItem: true,
        path: 'analytics',
        element: loadable(() => import('./pages/insights/analytics/Analytics')),
      },
      {
        label: 'Insider',
        navListItem: true,
        path: 'insider',
        icon: BsNewspaper,
        element: loadable(() => import('./pages/insights/insider/Insider')),
      },
    ],
  },
  {
    label: 'catalogue',
    path: 'catalogue',
    element: loadable(() => import('./pages/catalogue/CataloguePage')),
    icon: MdLibraryMusic,
    navListItem: true,
    authRules: ['catalogue.view'],
    isMobileMenuItem: true,
    children: [
      {
        label: 'Releases',
        labelForChild: 'All formats',
        navListItem: true,
        path: 'formats',
        element: loadable(() => import('./pages/catalogue/formats/FormatsPage')),
        children: [
          {
            label: 'browse',
            path: 'browse',
            element: loadable(() => import('./pages/catalogue/formats/browse/BrowsePage')),
          },
          {
            label: 'format detail',
            path: 'format-detail/:id',
            element: loadable(() => import('./pages/catalogue/formats/format-detail/FormatDetailPage')),
          },
        ],
      },
      {
        label: 'Schedule',
        path: 'schedule',
        navListItem: true,
        element: loadable(() => import('./pages/catalogue/formats/release-schedule/ReleaseSchedulePage')),
      },
      {
        label: 'Assets',
        labelForChild: 'All Assets',
        navListItem: true,
        path: 'assets',
        element: loadable(() => import('./pages/catalogue/assets/AssetsPage')),
        icon: CommonIcons.Track.Fill,
        children: [
          {
            label: 'assets browse',
            path: 'browse',
            element: loadable(() => import('./pages/catalogue/assets/browse-assets/BrowseAssetsPage')),
          },
          {
            label: 'assets detail',
            path: 'asset-detail/:assetId',
            element: loadable(() => import('./pages/catalogue/assets/asset-detail/AssetDetailPage')),
          },
          {
            label: 'ownership conflicts',
            path: 'ownership-conflicts',
            element: loadable(() => import('./pages/catalogue/assets/ownership-conflicts/AssetOwnershipConflictsPage')),
          },
        ],
      },
      {
        label: 'Slinky',
        labelForChild: 'All Slinkys',
        path: 'slinkys',
        element: loadable(() => import('./pages/services/slinkyOverview/SlinkyOverviewPage')),
        navListItem: true,
        authRules: ['slinky.view'],
        icon: CommonIcons.Slinky.Regular,
        children: [
          {
            label: 'slinky details',
            path: 'slinky-details/:id',
            element: loadable(() => import('./pages/slinky-details/SlinkyDetails')),
          },
          {
            label: 'Slinky Admin',
            path: 'slinky-admin',
            element: loadable(() => import('./pages/services/slinkyOverview/slinkyAdmin/SlinkyAdminPage')),
            authRules: ['slinky.slinkyadmin'],
          },
        ],
      },
    ],
  },
  {
    label: 'search',
    path: 'search',
    element: loadable(() => import('./pages/search/SearchPage')),
    icon: BsSearch,
    isMobileMenuItem: true,
  },

  // {
  //   label: 'financial',
  //   path: 'financial',
  //   element: loadable(() => import('./pages/financial/FinancialPage')),
  //   icon: CommonIcons.Finance.Fill,
  //   navListItem: true,
  //   authRules: ['clientreporting.view+devuser'],
  //   isMobileMenuItem: true,
  //   children: [
  //     {
  //       label: 'all',
  //       path: 'all',
  //       element: loadable(() => import('./pages/financial/client-dashboard/FinanceClientDashboard')),
  //
  //     },
  //
  //
  //     // {
  //     //   label: 'Summary',
  //     //   path: 'summary',
  //     //   element: loadable(() => import('./pages/financial/client-dashboard/summary/FinanceSummary')),
  //     //
  //     //   navListItem: true,
  //     // },
  //   ],
  // },
  {
    label: 'royalties',
    path: 'royalties',
    layout: PageLayouts.dashboard,
    element: loadable(() => import('./pages/services/ServicesPage')),
    navListItem: true,
    authRules: ['cloudadmin', 'devuser'],
    // isMobileMenuItem: true,
    icon: BsCurrencyExchange,
    children: [
      {
        label: 'Analytics',
        featureLabel: 'Royalties',
        path: 'analytics',
        navListItem: true,
        element: loadable(() => import('./pages/royalties/FinanceTrends/FinanceTrendsPage')),
      },
      {
        label: 'Reports',
        path: 'reports',
        element: loadable(() => import('./pages/financial/client-dashboard/reports/FinanceReports')),
        navListItem: true,
        icon: BsFileBarGraph,
      },
    ],
  },
  {
    label: 'orders',
    path: 'orders',
    layout: PageLayouts.dashboard,
    element: loadable(() => import('./pages/services/ServicesPage')),
    navListItem: true,
    icon: BsBoxSeamFill,
    authRules: ['orders.view'],
    children: [
      {
        label: 'Order History',
        path: 'orders',
        labelForChild: 'All orders',
        element: loadable(() => import('./pages/orders/OrdersPage')),
        navListItem: true,
        icon: BsTruck,
        authRules: ['orders.view'],

        children: [
          {
            label: 'orders browse',
            path: 'browse',
            element: loadable(() => import('./pages/orders/browse/BrowseOrdersPage')),
          },
          {
            label: 'order detail',
            path: 'order-detail/:id',
            element: loadable(() => import('./pages/orders/order-detail/OrderDetailPage')),
          },
        ],
      },
      {
        label: 'Recharges',
        path: 'recharges',
        element: loadable(() => import('./pages/recharges/RechargesPage')),
        navListItem: true,
        authRules: ['orders.view'],
        icon: BsCreditCard,
        children: [
          {
            label: 'recharges browse',
            path: 'browse',
            element: loadable(() => import('./pages/recharges/browse/BrowseRechargesPage')),
          },
          {
            label: 'recharge detail',
            path: 'recharge-detail/:id',
            element: loadable(() => import('./pages/recharges/recharge-detail/RechargeDetailPage')),
          },
        ],
      },
      {
        label: 'Other Sales',
        path: 'other-sales',
        element: loadable(() => import('./pages/other-sales/OtherSalesPage')),
        navListItem: true,
        authRules: ['orders.view'],
        icon: BsCreditCard,

        children: [
          {
            label: 'recharges browse',
            path: 'browse',
            element: loadable(() => import('./pages/other-sales/browse/BrowseOtherSalesPage')),
          },
          {
            label: 'other sale detail',
            path: 'other-sale-detail/:id',
            element: loadable(() => import('./pages/other-sales/other-sales-detail/OtherSalesDetailPage')),
          },
        ],
      },
      {
        label: 'Direct Sales',
        path: 'directsales',
        element: loadable(() => import('./pages/direct-sale/DirectSalePage')),
        navListItem: true,
        layout: PageLayouts.dashboard,
        icon: BsReceipt,
        authRules: ['orders.view'],
        children: [
          {
            label: 'direct sales browse',
            path: 'browse',
            element: loadable(() => import('./pages/direct-sale/browse/BrowseDirectSalesPage')),
          },
          {
            label: 'direct sale detail',
            path: 'direct-sale-detail/:id',
            element: loadable(() => import('./pages/direct-sale/direct-sale-detail/DirectSaleDetailPage')),
          },
        ],
      },
      {
        label: 'suppliers',
        labelForChild: 'All Suppliers',
        path: 'suppliers',
        element: loadable(() => import('./pages/suppliers/SuppliersPage')),
        authRules: ['orders.view'],
        icon: BsShopWindow,

        children: [
          {
            label: 'supplier',
            path: 'supplier-detail/:id',
            element: loadable(() => import('./pages/suppliers/supplier-detail/SupplierDetailPage')),
            children: [
              {
                label: 'supplier analytics',
                path: 'analytics',
                element: loadable(() => import('./pages/suppliers/supplier-detail/SupplierDetailPage')),
              },
            ],
          },
        ],
        navListItem: true,
      },
      {
        label: 'physical stock',
        path: 'physical-stock',
        labelForChild: 'Physical Stock',
        element: loadable(() => import('./pages/stock/StockPage')),
        navListItem: true,
        layout: PageLayouts.dashboard,
        authRules: ['orders.view'],
        icon: BsBoxes,
      },
    ],
  },

  {
    label: 'Reporting',
    path: 'reporting',
    element: loadable(() => import('./pages/services/ServicesPage')),
    navListItem: true,
    icon: BsFileRuledFill,
    authRules: ['clientreporting.view'],
    children: [
      {
        label: 'dashboard',
        path: 'dashboard',
        element: loadable(() => import('./pages/financial/dashboard/FinanceDash')),
        navListItem: true,
        authRules: ['clientreporting.view'],
        icon: BsSpeedometer,
      },
      {
        label: 'digital reports',
        path: 'digital',
        element: loadable(() => import('./pages/financial/digital/DigitalReportingPage')),
        navListItem: true,
        authRules: ['clientreporting.view'],
        icon: CommonIcons.ProductDigital.Regular,
      },
      {
        label: 'CMO reports',
        path: 'cmo-reporting',
        element: loadable(() => import('./pages/financial/cmo-reporting/CmoReportingPage')),
        navListItem: true,
        authRules: ['clientreporting.view'],
        icon: BsDiagram2,
      },
    ],
  },
  {
    label: 'profiles',
    path: 'profiles',
    icon: FaPeopleGroup,
    navListItem: true,
    element: loadable(() => import('./pages/services/ServicesPage')),
    authRules: ['catalogue.view'],
    children: [
      {
        label: 'labels',
        labelForChild: 'All labels',
        path: 'labels',
        element: loadable(() => import('./pages/profiles/labels/LabelsPage')),
        icon: CommonIcons.Label.Regular,
        children: [
          {
            label: 'label detail',
            path: 'label-detail/:id',
            element: loadable(() => import('./pages/profiles/labels/label-detail/LabelDetailPage')),
            children: [
              {
                label: 'label analytics',
                path: 'analytics',
                element: loadable(() => import('./pages/profiles/labels/label-detail/LabelDetailPage')),
              },
            ],
          },
          {
            label: 'parent label detail',
            path: 'parent-label-detail/:id',
            element: loadable(() => import('./pages/profiles/labels/parent-label-detail/ParentLabelDetailPage')),
            children: [
              {
                label: 'parent label analytics',
                path: 'analytics',
                element: loadable(() => import('./pages/profiles/labels/parent-label-detail/ParentLabelDetailPage')),
              },
            ],
          },
          {
            label: 'client detail',
            path: 'client-detail/:id',
            element: loadable(() => import('./pages/profiles/labels/client-detail/ClientDetailPage')),
            children: [
              {
                label: 'client analytics',
                path: 'analytics',
                element: loadable(() => import('./pages/profiles/labels/client-detail/ClientDetailPage')),
              },
            ],
          },
        ],
        navListItem: true,
      },
      {
        label: 'contributors',
        path: 'contributors',
        element: loadable(() => import('./pages/profiles/contributors/ContributorsPage')),
        icon: CommonIcons.Artist.Fill,
        children: [
          {
            label: 'contributor detail',
            path: 'contributor-detail/:id',
            element: loadable(() => import('./pages/profiles/contributors/contributor-detail/ContributorDetailPage')),
          },
          {
            label: 'links',
            path: 'contributor-links/:id',
            element: loadable(() => import('./pages/profiles/contributors/contributor-detail/contributor-links/ContributorLinks')),
          },
        ],
        navListItem: true,
      },
      // {
      //   label: 'users',
      //   path: 'users',
      //   element: loadable(() => import('./pages/profiles/ProfilesPage')),
      //   children: [{
      //     label: 'user detail',
      //     path: 'user-detail/:id',
      //     element: loadable(() => import('./pages/profiles/profile-detail/ProfileDetailPage')),
      //   }],
      //   navListItem: true,
      //   layout: PageLayouts.dashboard,
      // },
    ],
  },
  // {
  //   label: 'tools',
  //   path: 'tools',
  //   element: loadable(() => import('./pages/services/ServicesPage')),
  //   icon: FaToolbox,
  //   navListItem: true,
  //   authRules: ['slinky.view', 'delivery.validate'],
  //   children: [
  // {
  //   label: 'browse',
  //   path: 'browse',
  //   element: loadable(() => import('./pages/services/browse/BrowseServicesPage')),
  // },

  // {
  //   label: 'delivery',
  //   path: 'delivery',
  //   element: loadable(() => import('./pages/delivery/DeliveryPage')),
  //   icon: FaLocationArrow,
  //   navListItem: true,
  //   authRules: ['delivery.validate'],
  // },
  //   ],
  // },
  {
    label: 'admin',
    path: 'admin',
    element: loadable(() => import('./pages/cloudAdmin/cloudAdminPage')),
    icon: MdAdminPanelSettings,
    navListItem: true,
    authRules: ['cloudadmin', 'profileadmin.useradmin', 'devuser'],
    children: [
      {
        label: 'users',
        path: 'users',
        element: loadable(() => import('./pages/settings/users/UsersPage')),
        navListItem: true,
        authRules: ['profileadmin.useradmin'],
        children: [
          {
            label: 'user',
            path: 'account/:id?',
            element: loadable(() => import('./pages/account/accountPage')),
          },
        ],
      },
      {
        label: 'Pending users',
        path: 'pendingusers',
        element: loadable(() => import('./pages/settings/users-pending/UsersPendingPage')),
        navListItem: true,
        authRules: ['profileadmin.useradmin+cloudadmin'],
      },
      {
        label: 'Platforms',
        path: 'platforms',
        element: loadable(() => import('./pages/platform-admin/PlatformsPage')),
        navListItem: true,
        children: [
          {
            label: 'Browse',
            path: 'browse',
            element: loadable(() => import('./pages/platform-admin/platform-list/PlatformAdminListPage')),
          },
          {
            label: 'Platform details',
            path: 'platform-detail/:id',
            element: loadable(() => import('./pages/platform-admin/platform-details/PlatformAdminDetailsPage')),
          },
          {
            label: 'Platform delivery group details',
            path: 'platform-delivery-group-detail/:id',
            element: loadable(() => import('./pages/platform-admin/platform-delivery-group-details/PlatformDeliveryGroupDetails')),
          },
        ],
        // #####
        // TODO:
        //authRules: ['platform.view'],
        // #####
      },

      {
        label: 'auditor',
        path: 'auditor',
        element: loadable(() => import('./pages/cloudAdmin/auditor/auditorPage')),
        authRules: ['cloudadmin'],
        navListItem: true,
      },
      {
        label: 'dispatcher',
        path: 'dispatcher',
        element: loadable(() => import('./pages/cloudAdmin/dispatcher/dispatcherPage')),
        navListItem: true,
        authRules: ['cloudadmin'],
      },
      {
        label: 'deliveryman',
        path: 'deliveryman',
        element: loadable(() => import('./pages/cloudAdmin/delivery/deliveryManPage')),
        navListItem: true,
        authRules: ['cloudadmin'],
      },
      {
        label: 'tools',
        path: 'devtools',
        element: loadable(() => import('./pages/cloudAdmin/tools/AdminToolsPage')),
        authRules: ['cloudadmin'],
        navListItem: true,
      },
      {
        label: 'xml compare',
        path: 'compare',
        element: loadable(() => import('./pages/cloudAdmin/xml-compare/xmlComparePage')),
        navListItem: true,
        authRules: ['devuser'],
      },
      {
        label: 'firewall Config',
        path: 'firewallconfig',
        element: loadable(() => import('./pages/cloudAdmin/firewallConfig/FirewallConfigPage')),
        navListItem: true,
        authRules: ['cloudadmin'],
      },
    ],
  },
  {
    path: 'more',
    label: 'more',
    element: loadable(() => import('./pages/more/MorePage')),
    isMobileMenuItem: true,
    icon: BsList,
  },
];

export const otherRoutes: RouteMeta[] = [
  {
    label: 'login',
    path: 'login',
    element: loadable(() => import('./pages/login/LoginPage')),
    layout: PageLayouts.fullscreen,
    authRules: ['public'],
  },
  {
    label: 'reset',
    path: 'reset',
    element: loadable(() => import('./pages/login/ResetPassword')),
    layout: PageLayouts.fullscreen,
    authRules: ['public'],
  },
  {
    label: '',
    path: 'slinkypublic/:id',
    element: loadable(() => import('./pages/slinky/fanfacing/SlinkyPublic')),
    navListItem: false,
    authRules: ['public'],
    layout: PageLayouts.fullscreen,
  },
  {
    label: 'error',
    path: '*',
    element: loadable(() => import('./pages/ErrorPage')),
    navListItem: false,
    layout: PageLayouts.fullscreen,
    authRules: ['public'],
  },
  {
    label: 'platforms',
    path: 'platforms',
    authRules: ['catalogue.view'],
    element: loadable(() => import('./pages/platforms/PlatformsPage')),
    children: [
      {
        label: 'platform browse',
        path: 'browse',
        element: loadable(() => import('./pages/platforms/browse-platforms/BrowsePlatformsPage')),
      },
      {
        label: 'platform detail',
        path: 'platform-detail/:platformId',
        element: loadable(() => import('./pages/platforms/platform-detail/PlatformDetailPage')),
      },
    ],
  },
  {
    label: 'territories',
    path: 'territories',
    authRules: ['catalogue.view'],
    element: loadable(() => import('./pages/territories/TerritoriesPage')),

    children: [
      {
        label: 'territory browse',
        path: 'browse',
        element: loadable(() => import('./pages/territories/browse-territories/BrowseTerritoriesPage')),
      },
      {
        label: 'territory detail',
        path: 'territory-detail/:territoryId',
        element: loadable(() => import('./pages/territories/territory-detail/TerritoryDetailPage')),
      },
    ],
  },
  {
    label: 'notifications',
    path: 'notifications',
    element: loadable(() => import('./pages/notifications/NotificationsPage')),
    isMobileMenuItem: true,
    icon: BsBellFill,
    getNotificationCount: () => {
      return Promise.resolve(notificationService.unreadCount);
    },
  },
  {
    path: 'appSettings',
    label: 'App settings',
    element: loadable(() => import('./pages/appSettings/AppSettingsPage')),
    children: [
      {
        label: 'info',
        path: 'info',
        element: loadable(() => import('./pages/appSettings/info/AppInfoPage')),
      },
    ],
    isMobileMenuItem: true,
    icon: BsList,
  },
];

export const avatarRoutes: RouteMeta[] = [
  {
    label: 'edit account',
    path: 'account/:id?',
    element: loadable(() => import('./pages/account/accountPage')),
    icon: BsPersonCircle,
    navListItem: true,
  },
];

export const subMenuRoutes: RouteMeta[] = [
  // {
  //   label: 'settings',
  //   path: 'settings',
  //   element: loadable(() => import('./pages/settings/SettingsPage')),
  //   icon: BsGear,
  //   navListItem: true,
  //   children: [
  //   ],
  // },
  {
    label: 'tickets',
    path: 'tickets',
    element: loadable(() => import('./pages/tickets/TicketsPage')),
    navListItem: true,
    icon: BsTicketDetailed,
    getNotificationCount: () => {
      // Needs updating to handle by role type
      return store
        .dispatch(
          anthologyApi.endpoints.getApiTicketGetTickets.initiate({
            filterBy: `statusLinkId in (${TicketStatus.Open}, ${TicketStatus.Answered}, ${TicketStatus.Pending}, ${TicketStatus.Resolved})`,
            limit: 51,
          })
        )
        .then((res) => {
          return res?.data?.length || 0;
        });
    },
  },
  {
    label: 'help',
    path: 'knowledge_base',
    element: loadable(() => import('./pages/knowledge/KnowledgeBasePage')),
    icon: BsLightbulb,
    navListItem: true,

    children: [
      {
        label: 'article',
        path: 'article/:id',
        element: loadable(() => import('./pages/knowledge/ArticlePage')),
      },
    ],
  },
  {
    label: 'dev',
    path: 'dev',
    element: loadable(() => import('./pages/dev/DevPage')),
    icon: BsCode,
    navListItem: true,
    authRules: ['devuser'],
  },
];

export const slinkyRoute: RouteMeta = {
  label: 'slinky',
  path: 'slinky',
  element: loadable(() => import('./pages/slinky/MasterPage')),
  navListItem: false,
  authRules: ['slinky.view'],
  children: [
    {
      label: 'customise',
      path: 'customise/:id',
      element: loadable(() => import('./pages/slinky/customise/CustomisePage')),
      navListItem: true,
    },
    {
      label: 'links',
      path: 'links/:id',
      element: loadable(() => import('./pages/slinky/links/LinksPage')),
      navListItem: true,
    },
    {
      label: 'tracking',
      path: 'tracking/:id',
      element: loadable(() => import('./pages/slinky/tracking/TrackingPage')),
      navListItem: true,
    },
  ],
};

export const productCreateRoute: RouteMeta = {
  label: 'create',
  path: 'create',
  element: loadable(() => import('./pages/create/MasterPage')),
  navListItem: false,
  authRules: ['catalogue.edit'],
  apiAuth: (location: Location, meta: RouteMeta) => {
    const last = location.pathname.split('/').pop() ?? '-1';
    const paramId = parseInt(last);
    return store.dispatch(anthologyApi.endpoints.getApiProductIsApprovedOrQueuedById.initiate(paramId)).then((res: any) => {
      return new Promise((resolve, reject) => {
        if ('error' in res) {
          resolve(false);
        } else {
          const appr = res.data.approved ?? false;
          const del = res.data.delivered ?? false;
          const sub = res.data.digitalSub ?? false;
          resolve(appr || del ? sub : true);
        }
      });
    });
  },
  children: [
    {
      label: 'Immersive',
      path: 'immersive/:id',
      element: loadable(() => import('./pages/create/immer/ImmersivePage')),
      navListItem: false,
    },
    {
      label: 'Basic',
      path: 'basic/:id',
      element: loadable(() => import('./pages/create/basics/page/BasicPage')),
      navListItem: true,
    },
    {
      label: 'Release',
      path: 'release/:id',
      element: loadable(() => import('./pages/create/deals/page/DealPage')),
      navListItem: true,
    },
    {
      label: 'Tracks',
      path: 'tracks/:id',
      element: loadable(() => import('./pages/create/tracks/TracksPage')),
      navListItem: true,

      children: [
        {
          label: 'track',
          path: 'track/:trackId',
          element: loadable(() => import('./pages/create/tracks/track/page/TrackPage')),
          navListItem: false,
        },
      ],
    },
    {
      label: 'Extras',
      path: 'extras/:id',
      element: loadable(() => import('./pages/create/extras/page/ExtrasPage')),
      navListItem: true,
    },
    {
      label: 'Review',
      path: 'review/:id',
      element: loadable(() => import('./pages/create/review/ReviewPage')),
      navListItem: true,
    },
  ],
};

const anthologyRoutes: RouteMeta[] = [...mainRoutes, ...otherRoutes, ...subMenuRoutes, ...avatarRoutes, slinkyRoute, productCreateRoute];

const processRouteTree = (routes: RouteMeta[]) => {
  const flatRouteDictionary: { [key: string]: RouteMeta } = {};
  const routeTreeStruct = walkRoutes(routes);

  function walkRoutes(routes: RouteMeta[], path = '', parent: RouteMeta | undefined = undefined): RouteObject[] {
    return routes.map((r) => {
      r.fullPath = path + '/' + r.path;
      r.ref = createRef(); // uniqueRef for animation
      r.parent = parent;
      flatRouteDictionary[pathToId(r.fullPath)] = r;
      return {
        path: r.path,
        element: React.createElement(r.element),
        children: r.children ? walkRoutes(r.children, r.fullPath, r) : undefined,
      } as RouteObject;
    });
  }

  return { flatRouteDictionary, routeTreeStruct };
};

const pathToId = (path: string) => {
  const parts = path.split('/').filter((x) => x.length > 0 && x[0] !== ':');
  return _.join(parts, '.');
};

const { flatRouteDictionary, routeTreeStruct } = processRouteTree(anthologyRoutes);

export const routeTree = routeTreeStruct;

const errorMeta = anthologyRoutes.find((x) => x.path === '*');

export const routeLookup = (path: string) => {
  const id = pathToId(path).split('.');
  for (let index = id.length; index > 0; index--) {
    const lkup = flatRouteDictionary[id.slice(0, index).join('.')];
    if (lkup) {
      return lkup!;
    }
  }

  return errorMeta!;
};

export const allRoutes = [...mainRoutes, ...subMenuRoutes, ...avatarRoutes];
