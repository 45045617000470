import { CircleIconButton } from '@anthology/shared/src/components';
import { Badge } from '@mui/material';
import { useNotificationService } from '@src/services/notificationService';
import { BsCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import AccountAvatar from '../account-avatar/AccountAvatar';

const NotificationAvatar = () => {
  const note = useNotificationService();
  const nav = useNavigate();
  return (
    <CircleIconButton sx={{ p: 0 }} onClick={() => nav('/appSettings/')}>
      <Badge badgeContent={note.unreadCount ? <BsCircleFill color="var(--mui-palette-error-main)" /> : <></>}>
        <AccountAvatar sx={{ height: 28, width: 28, fontSize: 15 }} />
      </Badge>
    </CircleIconButton>
  );
};

export default NotificationAvatar;
