import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Stack, StackProps, Tooltip, Typography } from '@mui/material';

type TitleProps = {
  title: string;
  helper?: string;
} & StackProps;

export const Title = ({ title, helper, ...rest }: TitleProps) => {
  return (
    <Stack direction={'row'} alignItems={'center'} {...rest}>
      <Typography variant="sub3">{title}</Typography>
      {helper && (
        <Tooltip title={helper}>
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default Title;
