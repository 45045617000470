import { emptyAnthologyApi as api } from './anthologyApiBase';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAccountUserKnownAddresses: build.query<GetApiAccountUserKnownAddressesApiResponse, GetApiAccountUserKnownAddressesApiArg>({
      query: (queryArg) => ({ url: `/api/Account/UserKnownAddresses`, params: { userId: queryArg } }),
    }),
    getApiAccountPersonalDetails: build.query<GetApiAccountPersonalDetailsApiResponse, GetApiAccountPersonalDetailsApiArg>({
      query: (queryArg) => ({ url: `/api/Account/PersonalDetails`, params: { userId: queryArg } }),
    }),
    putApiAccountPersonalDetails: build.mutation<PutApiAccountPersonalDetailsApiResponse, PutApiAccountPersonalDetailsApiArg>({
      query: (queryArg) => ({ url: `/api/Account/PersonalDetails`, method: 'PUT', body: queryArg }),
    }),
    putApiAccountActiveStatus: build.mutation<PutApiAccountActiveStatusApiResponse, PutApiAccountActiveStatusApiArg>({
      query: (queryArg) => ({ url: `/api/Account/ActiveStatus`, method: 'PUT', params: { active: queryArg } }),
    }),
    getApiAccountGetPersonalUiSettings: build.query<GetApiAccountGetPersonalUiSettingsApiResponse, GetApiAccountGetPersonalUiSettingsApiArg>({
      query: () => ({ url: `/api/Account/GetPersonalUiSettings` }),
    }),
    putApiAccountSetPersonalUiSetting: build.mutation<PutApiAccountSetPersonalUiSettingApiResponse, PutApiAccountSetPersonalUiSettingApiArg>({
      query: (queryArg) => ({ url: `/api/Account/SetPersonalUiSetting`, method: 'PUT', body: queryArg }),
    }),
    postApiAccountLoginAsUser: build.mutation<PostApiAccountLoginAsUserApiResponse, PostApiAccountLoginAsUserApiArg>({
      query: (queryArg) => ({ url: `/api/Account/LoginAsUser`, method: 'POST', body: queryArg }),
    }),
    postApiAccountLogin: build.mutation<PostApiAccountLoginApiResponse, PostApiAccountLoginApiArg>({
      query: (queryArg) => ({ url: `/api/Account/Login`, method: 'POST', body: queryArg }),
    }),
    postApiAccountRequestRefreshToken: build.mutation<PostApiAccountRequestRefreshTokenApiResponse, PostApiAccountRequestRefreshTokenApiArg>({
      query: (queryArg) => ({ url: `/api/Account/RequestRefreshToken`, method: 'POST', params: { refreshToken: queryArg } }),
    }),
    getApiAccountGetUserPerms: build.query<GetApiAccountGetUserPermsApiResponse, GetApiAccountGetUserPermsApiArg>({
      query: (queryArg) => ({ url: `/api/Account/GetUserPerms`, params: { userId: queryArg } }),
    }),
    getApiAccountGetPermsOnEntity: build.query<GetApiAccountGetPermsOnEntityApiResponse, GetApiAccountGetPermsOnEntityApiArg>({
      query: (queryArg) => ({ url: `/api/Account/GetPermsOnEntity`, params: { entityType: queryArg.entityType, entityId: queryArg.entityId } }),
    }),
    getApiAdminGetSyncHealth: build.query<GetApiAdminGetSyncHealthApiResponse, GetApiAdminGetSyncHealthApiArg>({
      query: () => ({ url: `/api/Admin/GetSyncHealth` }),
    }),
    getApiAdminGetFireWallSettings: build.query<GetApiAdminGetFireWallSettingsApiResponse, GetApiAdminGetFireWallSettingsApiArg>({
      query: () => ({ url: `/api/Admin/GetFireWallSettings` }),
    }),
    postApiAdminAddUpdateFireWallItem: build.mutation<PostApiAdminAddUpdateFireWallItemApiResponse, PostApiAdminAddUpdateFireWallItemApiArg>({
      query: (queryArg) => ({ url: `/api/Admin/AddUpdateFireWallItem`, method: 'POST', body: queryArg }),
    }),
    postApiAdminDeleteFireWallItem: build.mutation<PostApiAdminDeleteFireWallItemApiResponse, PostApiAdminDeleteFireWallItemApiArg>({
      query: (queryArg) => ({ url: `/api/Admin/DeleteFireWallItem`, method: 'POST', body: queryArg }),
    }),
    postApiAdminPushFireWallConfigToAzure: build.mutation<PostApiAdminPushFireWallConfigToAzureApiResponse, PostApiAdminPushFireWallConfigToAzureApiArg>({
      query: () => ({ url: `/api/Admin/PushFireWallConfigToAzure`, method: 'POST' }),
    }),
    getApiAdminWhoIsOnline: build.query<GetApiAdminWhoIsOnlineApiResponse, GetApiAdminWhoIsOnlineApiArg>({
      query: () => ({ url: `/api/Admin/WhoIsOnline` }),
    }),
    getApiAdminGet2FaStatus: build.query<GetApiAdminGet2FaStatusApiResponse, GetApiAdminGet2FaStatusApiArg>({
      query: () => ({ url: `/api/Admin/Get2FaStatus` }),
    }),
    postApiAdminRequestShortTerm2FaCode: build.mutation<PostApiAdminRequestShortTerm2FaCodeApiResponse, PostApiAdminRequestShortTerm2FaCodeApiArg>({
      query: () => ({ url: `/api/Admin/RequestShortTerm2FaCode`, method: 'POST' }),
    }),
    postApiAdminValidateShortTerm2FaCode: build.mutation<PostApiAdminValidateShortTerm2FaCodeApiResponse, PostApiAdminValidateShortTerm2FaCodeApiArg>({
      query: (queryArg) => ({ url: `/api/Admin/ValidateShortTerm2FaCode`, method: 'POST', body: queryArg }),
    }),
    getApiAdminListSecrets: build.query<GetApiAdminListSecretsApiResponse, GetApiAdminListSecretsApiArg>({
      query: (queryArg) => ({ url: `/api/Admin/ListSecrets`, params: { tenantid: queryArg } }),
    }),
    getApiAdminShowSecret: build.query<GetApiAdminShowSecretApiResponse, GetApiAdminShowSecretApiArg>({
      query: (queryArg) => ({ url: `/api/Admin/ShowSecret`, params: { key: queryArg.key, tenantid: queryArg.tenantid } }),
    }),
    postApiAdminSaveSecret: build.mutation<PostApiAdminSaveSecretApiResponse, PostApiAdminSaveSecretApiArg>({
      query: (queryArg) => ({ url: `/api/Admin/SaveSecret`, method: 'POST', body: queryArg.cloudSecretString, params: { tenantid: queryArg.tenantid } }),
    }),
    getApiAnalyticsInsightsTimeline: build.query<GetApiAnalyticsInsightsTimelineApiResponse, GetApiAnalyticsInsightsTimelineApiArg>({
      query: (queryArg) => ({
        url: `/api/Analytics/InsightsTimeline`,
        params: {
          dataset: queryArg.dataset,
          dimension: queryArg.dimension,
          sub_dimension: queryArg.subDimension,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          limit: queryArg.limit,
          sub_limit: queryArg.subLimit,
          client_ids: queryArg.clientIds,
          parent_label_ids: queryArg.parentLabelIds,
          label_ids: queryArg.labelIds,
          artist_ids: queryArg.artistIds,
          format_ids: queryArg.formatIds,
          asset_ids: queryArg.assetIds,
          royalty_types: queryArg.royaltyTypes,
          territories: queryArg.territories,
          platform_ids: queryArg.platformIds,
          sort_by: queryArg.sortBy,
          date_dimension: queryArg.dateDimension,
          for_all_clients: queryArg.forAllClients,
        },
      }),
    }),
    getApiAnalyticsInsightsLeaderboards: build.query<GetApiAnalyticsInsightsLeaderboardsApiResponse, GetApiAnalyticsInsightsLeaderboardsApiArg>({
      query: (queryArg) => ({
        url: `/api/Analytics/InsightsLeaderboards`,
        params: {
          dataset: queryArg.dataset,
          dimension: queryArg.dimension,
          sub_dimension: queryArg.subDimension,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          limit: queryArg.limit,
          sub_limit: queryArg.subLimit,
          client_ids: queryArg.clientIds,
          parent_label_ids: queryArg.parentLabelIds,
          label_ids: queryArg.labelIds,
          artist_ids: queryArg.artistIds,
          format_ids: queryArg.formatIds,
          asset_ids: queryArg.assetIds,
          royalty_types: queryArg.royaltyTypes,
          territories: queryArg.territories,
          platform_ids: queryArg.platformIds,
          sort_by: queryArg.sortBy,
          date_dimension: queryArg.dateDimension,
          for_all_clients: queryArg.forAllClients,
        },
      }),
    }),
    getApiAnalyticsClientReportingArticlesSummaryByClientLabel: build.query<
      GetApiAnalyticsClientReportingArticlesSummaryByClientLabelApiResponse,
      GetApiAnalyticsClientReportingArticlesSummaryByClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_ArticlesSummaryByClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingCmoSummaryRevenue: build.query<
      GetApiAnalyticsClientReportingCmoSummaryRevenueApiResponse,
      GetApiAnalyticsClientReportingCmoSummaryRevenueApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_CMO_SummaryRevenue`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingCmoSummaryRevenueByClientLabel: build.query<
      GetApiAnalyticsClientReportingCmoSummaryRevenueByClientLabelApiResponse,
      GetApiAnalyticsClientReportingCmoSummaryRevenueByClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_CMO_SummaryRevenueByClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingDigitalSummary: build.query<
      GetApiAnalyticsClientReportingDigitalSummaryApiResponse,
      GetApiAnalyticsClientReportingDigitalSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Digital_Summary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingDigitalSummaryClientLabel: build.query<
      GetApiAnalyticsClientReportingDigitalSummaryClientLabelApiResponse,
      GetApiAnalyticsClientReportingDigitalSummaryClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Digital_SummaryClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingDirectSalesSummary: build.query<
      GetApiAnalyticsClientReportingDirectSalesSummaryApiResponse,
      GetApiAnalyticsClientReportingDirectSalesSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_DirectSales_Summary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingDirectSalesSummaryClientLabel: build.query<
      GetApiAnalyticsClientReportingDirectSalesSummaryClientLabelApiResponse,
      GetApiAnalyticsClientReportingDirectSalesSummaryClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_DirectSales_SummaryClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingOtherSalesSummary: build.query<
      GetApiAnalyticsClientReportingOtherSalesSummaryApiResponse,
      GetApiAnalyticsClientReportingOtherSalesSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_OtherSales_Summary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingOtherSalesSummaryClientLabel: build.query<
      GetApiAnalyticsClientReportingOtherSalesSummaryClientLabelApiResponse,
      GetApiAnalyticsClientReportingOtherSalesSummaryClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_OtherSales_SummaryClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingPhysicalArticlesSummary: build.query<
      GetApiAnalyticsClientReportingPhysicalArticlesSummaryApiResponse,
      GetApiAnalyticsClientReportingPhysicalArticlesSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Physical_Articles_Summary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingPhysicalNotificationFormatsUnLinkedAllTime: build.query<
      GetApiAnalyticsClientReportingPhysicalNotificationFormatsUnLinkedAllTimeApiResponse,
      GetApiAnalyticsClientReportingPhysicalNotificationFormatsUnLinkedAllTimeApiArg
    >({
      query: () => ({ url: `/api/Analytics/ClientReporting_Physical_Notification_FormatsUnLinkedAllTime` }),
    }),
    getApiAnalyticsClientReportingPhysicalSalesReturnsConsignClientReporting: build.query<
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignClientReportingApiResponse,
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignClientReportingApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Physical_SalesReturnsConsign_ClientReporting`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummary: build.query<
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryApiResponse,
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Physical_SalesReturnsConsign_Summary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabel: build.query<
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelApiResponse,
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Physical_SalesReturnsConsign_SummaryByClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelFormat: build.query<
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelFormatApiResponse,
      GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelFormatApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Analytics/ClientReporting_Physical_SalesReturnsConsign_SummaryByClientLabelFormat`,
        params: { month_to_report: queryArg },
      }),
    }),
    getApiAnalyticsClientReportingPhysicalUniversalInvoiceSummary: build.query<
      GetApiAnalyticsClientReportingPhysicalUniversalInvoiceSummaryApiResponse,
      GetApiAnalyticsClientReportingPhysicalUniversalInvoiceSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Physical_Universal_InvoiceSummary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingRechargesSummary: build.query<
      GetApiAnalyticsClientReportingRechargesSummaryApiResponse,
      GetApiAnalyticsClientReportingRechargesSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Recharges_Summary`, params: { month_to_report: queryArg } }),
    }),
    getApiAnalyticsClientReportingRechargesSummaryClientLabel: build.query<
      GetApiAnalyticsClientReportingRechargesSummaryClientLabelApiResponse,
      GetApiAnalyticsClientReportingRechargesSummaryClientLabelApiArg
    >({
      query: (queryArg) => ({ url: `/api/Analytics/ClientReporting_Recharges_SummaryClientLabel`, params: { month_to_report: queryArg } }),
    }),
    getApiAssetRefreshAssetConflictStatus: build.query<GetApiAssetRefreshAssetConflictStatusApiResponse, GetApiAssetRefreshAssetConflictStatusApiArg>({
      query: (queryArg) => ({ url: `/api/Asset/RefreshAssetConflictStatus`, body: queryArg }),
    }),
    getApiCatalogueGetFormatIngestionNotes: build.query<GetApiCatalogueGetFormatIngestionNotesApiResponse, GetApiCatalogueGetFormatIngestionNotesApiArg>({
      query: (queryArg) => ({ url: `/api/Catalogue/GetFormatIngestionNotes`, params: { formatId: queryArg } }),
    }),
    getApiCatalogueExportCatalogue: build.query<GetApiCatalogueExportCatalogueApiResponse, GetApiCatalogueExportCatalogueApiArg>({
      query: (queryArg) => ({
        url: `/api/Catalogue/ExportCatalogue`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiCatalogueGetFormatDetail: build.query<GetApiCatalogueGetFormatDetailApiResponse, GetApiCatalogueGetFormatDetailApiArg>({
      query: (queryArg) => ({ url: `/api/Catalogue/GetFormatDetail`, params: { formatId: queryArg } }),
    }),
    getApiCatalogueGetRecentlyViewedFormats: build.query<GetApiCatalogueGetRecentlyViewedFormatsApiResponse, GetApiCatalogueGetRecentlyViewedFormatsApiArg>({
      query: (queryArg) => ({ url: `/api/Catalogue/GetRecentlyViewedFormats`, params: { limit: queryArg } }),
    }),
    getApiCatalogueGetFormatContributors: build.query<GetApiCatalogueGetFormatContributorsApiResponse, GetApiCatalogueGetFormatContributorsApiArg>({
      query: (queryArg) => ({ url: `/api/Catalogue/GetFormatContributors`, params: { formatId: queryArg } }),
    }),
    getApiClientReportingListDeletedReports: build.query<GetApiClientReportingListDeletedReportsApiResponse, GetApiClientReportingListDeletedReportsApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/ListDeletedReports`, params: { startDate: queryArg } }),
    }),
    postApiClientReportingDeleteFinanceReport: build.mutation<
      PostApiClientReportingDeleteFinanceReportApiResponse,
      PostApiClientReportingDeleteFinanceReportApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/DeleteFinanceReport`, method: 'POST', body: queryArg }),
    }),
    postApiClientReportingRecoverDeletedReport: build.mutation<
      PostApiClientReportingRecoverDeletedReportApiResponse,
      PostApiClientReportingRecoverDeletedReportApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/RecoverDeletedReport`, method: 'POST', body: queryArg }),
    }),
    postApiClientReportingSaveUpdatedClientMapping: build.mutation<
      PostApiClientReportingSaveUpdatedClientMappingApiResponse,
      PostApiClientReportingSaveUpdatedClientMappingApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/SaveUpdatedClientMapping`, method: 'POST', body: queryArg }),
    }),
    postApiClientReportingSaveFinanceReportMeta: build.mutation<
      PostApiClientReportingSaveFinanceReportMetaApiResponse,
      PostApiClientReportingSaveFinanceReportMetaApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/SaveFinanceReportMeta`, method: 'POST', body: queryArg }),
    }),
    postApiClientReportingSetFinanceReportReadyState: build.mutation<
      PostApiClientReportingSetFinanceReportReadyStateApiResponse,
      PostApiClientReportingSetFinanceReportReadyStateApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/SetFinanceReportReadyState`, method: 'POST', body: queryArg }),
    }),
    getApiClientReportingSearchReportMatchData: build.query<
      GetApiClientReportingSearchReportMatchDataApiResponse,
      GetApiClientReportingSearchReportMatchDataApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/SearchReportMatchData`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiClientReportingGetReportIngestionStatus: build.query<
      GetApiClientReportingGetReportIngestionStatusApiResponse,
      GetApiClientReportingGetReportIngestionStatusApiArg
    >({
      query: () => ({ url: `/api/ClientReporting/GetReportIngestionStatus` }),
    }),
    getApiClientReportingGetPlatformsAcceptingReportUploads: build.query<
      GetApiClientReportingGetPlatformsAcceptingReportUploadsApiResponse,
      GetApiClientReportingGetPlatformsAcceptingReportUploadsApiArg
    >({
      query: () => ({ url: `/api/ClientReporting/GetPlatformsAcceptingReportUploads` }),
    }),
    postApiClientReportingLaunchImportJob: build.mutation<PostApiClientReportingLaunchImportJobApiResponse, PostApiClientReportingLaunchImportJobApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/LaunchImportJob`, method: 'POST', body: queryArg }),
    }),
    getApiClientReportingDigitalPendingReports: build.query<
      GetApiClientReportingDigitalPendingReportsApiResponse,
      GetApiClientReportingDigitalPendingReportsApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/DigitalPendingReports`, params: { category: queryArg } }),
    }),
    getApiClientReportingReportingSimulate: build.query<GetApiClientReportingReportingSimulateApiResponse, GetApiClientReportingReportingSimulateApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/ReportingSimulate`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingDigitalDataByFile: build.query<GetApiClientReportingDigitalDataByFileApiResponse, GetApiClientReportingDigitalDataByFileApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/DigitalDataByFile`, params: { metaId: queryArg.metaId, src: queryArg.src } }),
    }),
    getApiClientReportingDigitalUnmatchedData: build.query<
      GetApiClientReportingDigitalUnmatchedDataApiResponse,
      GetApiClientReportingDigitalUnmatchedDataApiArg
    >({
      query: () => ({ url: `/api/ClientReporting/DigitalUnmatchedData` }),
    }),
    getApiClientReportingDigitalBiggestChanges: build.query<
      GetApiClientReportingDigitalBiggestChangesApiResponse,
      GetApiClientReportingDigitalBiggestChangesApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/DigitalBiggestChanges`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingDigitalTotalsSheet: build.query<GetApiClientReportingDigitalTotalsSheetApiResponse, GetApiClientReportingDigitalTotalsSheetApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/DigitalTotalsSheet`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingDigitalPlatformProblemSummary: build.query<
      GetApiClientReportingDigitalPlatformProblemSummaryApiResponse,
      GetApiClientReportingDigitalPlatformProblemSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/DigitalPlatformProblemSummary`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingDigitalSalesClientSummary: build.query<
      GetApiClientReportingDigitalSalesClientSummaryApiResponse,
      GetApiClientReportingDigitalSalesClientSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/DigitalSalesClientSummary`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingDigitalDataByClient: build.query<GetApiClientReportingDigitalDataByClientApiResponse, GetApiClientReportingDigitalDataByClientApiArg>({
      query: (queryArg) => ({
        url: `/api/ClientReporting/DigitalDataByClient`,
        params: { clientId: queryArg.clientId, month_to_report: queryArg.monthToReport },
      }),
    }),
    getApiClientReportingCmoDataByClient: build.query<GetApiClientReportingCmoDataByClientApiResponse, GetApiClientReportingCmoDataByClientApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/CmoDataByClient`, params: { clientId: queryArg.clientId, month_to_report: queryArg.monthToReport } }),
    }),
    getApiClientReportingCmoClientSummary: build.query<GetApiClientReportingCmoClientSummaryApiResponse, GetApiClientReportingCmoClientSummaryApiArg>({
      query: () => ({ url: `/api/ClientReporting/CmoClientSummary` }),
    }),
    getApiClientReportingCmoUnmatchedData: build.query<GetApiClientReportingCmoUnmatchedDataApiResponse, GetApiClientReportingCmoUnmatchedDataApiArg>({
      query: () => ({ url: `/api/ClientReporting/CmoUnmatchedData` }),
    }),
    postApiClientReportingExecuteReportingJob: build.mutation<
      PostApiClientReportingExecuteReportingJobApiResponse,
      PostApiClientReportingExecuteReportingJobApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/ExecuteReportingJob`, method: 'POST', params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingGetReportingStatus: build.query<GetApiClientReportingGetReportingStatusApiResponse, GetApiClientReportingGetReportingStatusApiArg>({
      query: () => ({ url: `/api/ClientReporting/GetReportingStatus` }),
    }),
    getApiClientReportingPhysicalSummary: build.query<GetApiClientReportingPhysicalSummaryApiResponse, GetApiClientReportingPhysicalSummaryApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/PhysicalSummary`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingSuggestedClientPayment: build.query<
      GetApiClientReportingSuggestedClientPaymentApiResponse,
      GetApiClientReportingSuggestedClientPaymentApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/SuggestedClientPayment`, params: { month_to_report: queryArg } }),
    }),
    getApiClientReportingInvoiceSummaryChart: build.query<GetApiClientReportingInvoiceSummaryChartApiResponse, GetApiClientReportingInvoiceSummaryChartApiArg>({
      query: (queryArg) => ({
        url: `/api/ClientReporting/InvoiceSummaryChart`,
        params: { month_to_report: queryArg.monthToReport, client_id: queryArg.clientId },
      }),
    }),
    getApiClientReportingClientAgreementSummary: build.query<
      GetApiClientReportingClientAgreementSummaryApiResponse,
      GetApiClientReportingClientAgreementSummaryApiArg
    >({
      query: (queryArg) => ({ url: `/api/ClientReporting/ClientAgreementSummary`, params: { agreement_id: queryArg } }),
    }),
    getApiClientReportingDownloadSummaryZip: build.query<GetApiClientReportingDownloadSummaryZipApiResponse, GetApiClientReportingDownloadSummaryZipApiArg>({
      query: (queryArg) => ({ url: `/api/ClientReporting/DownloadSummaryZip`, params: { month_to_report: queryArg } }),
    }),
    getApiContributorGetSpotifyArtistId: build.query<GetApiContributorGetSpotifyArtistIdApiResponse, GetApiContributorGetSpotifyArtistIdApiArg>({
      query: (queryArg) => ({ url: `/api/Contributor/GetSpotifyArtistId`, params: { name: queryArg } }),
    }),
    getApiContributorFetchAppleArtistByName: build.query<GetApiContributorFetchAppleArtistByNameApiResponse, GetApiContributorFetchAppleArtistByNameApiArg>({
      query: (queryArg) => ({ url: `/api/Contributor/FetchAppleArtistByName`, params: { name: queryArg } }),
    }),
    getApiContributorGetExistingAppleArtistsByName: build.query<
      GetApiContributorGetExistingAppleArtistsByNameApiResponse,
      GetApiContributorGetExistingAppleArtistsByNameApiArg
    >({
      query: (queryArg) => ({ url: `/api/Contributor/GetExistingAppleArtistsByName`, params: { name: queryArg } }),
    }),
    postApiContributorCreateAppleArtist: build.mutation<PostApiContributorCreateAppleArtistApiResponse, PostApiContributorCreateAppleArtistApiArg>({
      query: (queryArg) => ({ url: `/api/Contributor/CreateAppleArtist`, method: 'POST', body: queryArg }),
    }),
    postApiContributorCreateContributor: build.mutation<PostApiContributorCreateContributorApiResponse, PostApiContributorCreateContributorApiArg>({
      query: (queryArg) => ({ url: `/api/Contributor/CreateContributor`, method: 'POST', body: queryArg }),
    }),
    getApiContributorValidateResource: build.query<GetApiContributorValidateResourceApiResponse, GetApiContributorValidateResourceApiArg>({
      query: (queryArg) => ({ url: `/api/Contributor/ValidateResource`, params: { id: queryArg.id, text: queryArg.text } }),
    }),
    getApiContributorCanEditAppleArtistInformation: build.query<
      GetApiContributorCanEditAppleArtistInformationApiResponse,
      GetApiContributorCanEditAppleArtistInformationApiArg
    >({
      query: () => ({ url: `/api/Contributor/CanEditAppleArtistInformation` }),
    }),
    getApiContributorGetContributorGuids: build.query<GetApiContributorGetContributorGuidsApiResponse, GetApiContributorGetContributorGuidsApiArg>({
      query: (queryArg) => ({ url: `/api/Contributor/GetContributorGuids`, params: { cId: queryArg } }),
    }),
    postApiDeliveryQueueJobForPlatform: build.mutation<PostApiDeliveryQueueJobForPlatformApiResponse, PostApiDeliveryQueueJobForPlatformApiArg>({
      query: (queryArg) => ({ url: `/api/Delivery/QueueJobForPlatform`, method: 'POST', body: queryArg }),
    }),
    postApiDeliveryStartDeliveryImmediately: build.mutation<PostApiDeliveryStartDeliveryImmediatelyApiResponse, PostApiDeliveryStartDeliveryImmediatelyApiArg>({
      query: (queryArg) => ({ url: `/api/Delivery/StartDeliveryImmediately`, method: 'POST', body: queryArg }),
    }),
    postApiDeliveryIngestXmlFile: build.mutation<PostApiDeliveryIngestXmlFileApiResponse, PostApiDeliveryIngestXmlFileApiArg>({
      query: () => ({ url: `/api/Delivery/IngestXmlFile`, method: 'POST' }),
    }),
    getApiDeliveryGetFormatDeliveryHistory: build.query<GetApiDeliveryGetFormatDeliveryHistoryApiResponse, GetApiDeliveryGetFormatDeliveryHistoryApiArg>({
      query: (queryArg) => ({ url: `/api/Delivery/GetFormatDeliveryHistory`, params: { formatId: queryArg } }),
    }),
    getApiDeliveryGetDeliveryJobInfo: build.query<GetApiDeliveryGetDeliveryJobInfoApiResponse, GetApiDeliveryGetDeliveryJobInfoApiArg>({
      query: () => ({ url: `/api/Delivery/GetDeliveryJobInfo` }),
    }),
    postApiDeliveryGetXmlZip: build.mutation<PostApiDeliveryGetXmlZipApiResponse, PostApiDeliveryGetXmlZipApiArg>({
      query: (queryArg) => ({ url: `/api/Delivery/GetXmlZip`, method: 'POST', body: queryArg }),
    }),
    postApiDeliveryGetXmlFile: build.mutation<PostApiDeliveryGetXmlFileApiResponse, PostApiDeliveryGetXmlFileApiArg>({
      query: (queryArg) => ({ url: `/api/Delivery/GetXmlFile`, method: 'POST', body: queryArg }),
    }),
    postApiDeliveryGetXmlJson: build.mutation<PostApiDeliveryGetXmlJsonApiResponse, PostApiDeliveryGetXmlJsonApiArg>({
      query: (queryArg) => ({ url: `/api/Delivery/GetXmlJson`, method: 'POST', body: queryArg }),
    }),
    getApiDeliverySearchFormatDeliveryInfo: build.query<GetApiDeliverySearchFormatDeliveryInfoApiResponse, GetApiDeliverySearchFormatDeliveryInfoApiArg>({
      query: (queryArg) => ({
        url: `/api/Delivery/SearchFormatDeliveryInfo`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiDeliveryGetFormatDeliverySummary: build.query<GetApiDeliveryGetFormatDeliverySummaryApiResponse, GetApiDeliveryGetFormatDeliverySummaryApiArg>({
      query: () => ({ url: `/api/Delivery/GetFormatDeliverySummary` }),
    }),
    getApiDeliveryAddressSearchDeliveryAddresses: build.query<
      GetApiDeliveryAddressSearchDeliveryAddressesApiResponse,
      GetApiDeliveryAddressSearchDeliveryAddressesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DeliveryAddress/SearchDeliveryAddresses`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiDeliveryAddressGetDeliveryAddressByDeliveryAddressId: build.query<
      GetApiDeliveryAddressGetDeliveryAddressByDeliveryAddressIdApiResponse,
      GetApiDeliveryAddressGetDeliveryAddressByDeliveryAddressIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/DeliveryAddress/GetDeliveryAddress/${queryArg}` }),
    }),
    postApiDeliveryAddressCreateDeliveryAddress: build.mutation<
      PostApiDeliveryAddressCreateDeliveryAddressApiResponse,
      PostApiDeliveryAddressCreateDeliveryAddressApiArg
    >({
      query: (queryArg) => ({ url: `/api/DeliveryAddress/CreateDeliveryAddress`, method: 'POST', body: queryArg }),
    }),
    putApiDeliveryAddressUpdateDeliveryAddressByDeliveryAddressId: build.mutation<
      PutApiDeliveryAddressUpdateDeliveryAddressByDeliveryAddressIdApiResponse,
      PutApiDeliveryAddressUpdateDeliveryAddressByDeliveryAddressIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DeliveryAddress/UpdateDeliveryAddress/${queryArg.deliveryAddressId}`,
        method: 'PUT',
        body: queryArg.deliveryAddressVm,
      }),
    }),
    deleteApiDeliveryAddressDeleteDeliveryAddressByDeliveryAddressId: build.mutation<
      DeleteApiDeliveryAddressDeleteDeliveryAddressByDeliveryAddressIdApiResponse,
      DeleteApiDeliveryAddressDeleteDeliveryAddressByDeliveryAddressIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/DeliveryAddress/DeleteDeliveryAddress/${queryArg}`, method: 'DELETE' }),
    }),
    getApiDirectSaleSearchDirectSales: build.query<GetApiDirectSaleSearchDirectSalesApiResponse, GetApiDirectSaleSearchDirectSalesApiArg>({
      query: (queryArg) => ({
        url: `/api/DirectSale/SearchDirectSales`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiDirectSaleGetDirectSaleByDirectSaleId: build.query<
      GetApiDirectSaleGetDirectSaleByDirectSaleIdApiResponse,
      GetApiDirectSaleGetDirectSaleByDirectSaleIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/DirectSale/GetDirectSale/${queryArg}` }),
    }),
    postApiDirectSaleCreateDirectSale: build.mutation<PostApiDirectSaleCreateDirectSaleApiResponse, PostApiDirectSaleCreateDirectSaleApiArg>({
      query: (queryArg) => ({ url: `/api/DirectSale/CreateDirectSale`, method: 'POST', body: queryArg }),
    }),
    putApiDirectSaleUpdateDirectSaleByDirectSaleId: build.mutation<
      PutApiDirectSaleUpdateDirectSaleByDirectSaleIdApiResponse,
      PutApiDirectSaleUpdateDirectSaleByDirectSaleIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/DirectSale/UpdateDirectSale/${queryArg.directSaleId}`, method: 'PUT', body: queryArg.directSaleVm }),
    }),
    deleteApiDirectSaleDeleteDirectSaleByDirectSaleId: build.mutation<
      DeleteApiDirectSaleDeleteDirectSaleByDirectSaleIdApiResponse,
      DeleteApiDirectSaleDeleteDirectSaleByDirectSaleIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/DirectSale/DeleteDirectSale/${queryArg}`, method: 'DELETE' }),
    }),
    getApiDirectSaleExportDirectSales: build.query<GetApiDirectSaleExportDirectSalesApiResponse, GetApiDirectSaleExportDirectSalesApiArg>({
      query: (queryArg) => ({
        url: `/api/DirectSale/ExportDirectSales`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate, clientId: queryArg.clientId },
      }),
    }),
    getApiDirectSaleGetTenantDefaultCurrency: build.query<GetApiDirectSaleGetTenantDefaultCurrencyApiResponse, GetApiDirectSaleGetTenantDefaultCurrencyApiArg>({
      query: () => ({ url: `/api/DirectSale/GetTenantDefaultCurrency` }),
    }),
    getApiNewsGetNews: build.query<GetApiNewsGetNewsApiResponse, GetApiNewsGetNewsApiArg>({
      query: (queryArg) => ({
        url: `/api/News/GetNews`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiNotificationGetNotifications: build.query<GetApiNotificationGetNotificationsApiResponse, GetApiNotificationGetNotificationsApiArg>({
      query: (queryArg) => ({ url: `/api/Notification/GetNotifications`, params: { afterTime: queryArg.afterTime, userId: queryArg.userId } }),
    }),
    postApiNotificationMarkNoteAsRead: build.mutation<PostApiNotificationMarkNoteAsReadApiResponse, PostApiNotificationMarkNoteAsReadApiArg>({
      query: (queryArg) => ({ url: `/api/Notification/MarkNoteAsRead`, method: 'POST', body: queryArg }),
    }),
    postApiNotificationDeleteNotes: build.mutation<PostApiNotificationDeleteNotesApiResponse, PostApiNotificationDeleteNotesApiArg>({
      query: (queryArg) => ({ url: `/api/Notification/DeleteNotes`, method: 'POST', body: queryArg }),
    }),
    postApiNotificationAnnounce: build.mutation<PostApiNotificationAnnounceApiResponse, PostApiNotificationAnnounceApiArg>({
      query: (queryArg) => ({ url: `/api/Notification/Announce`, method: 'POST', body: queryArg }),
    }),
    getApiOrderSearchOrders: build.query<GetApiOrderSearchOrdersApiResponse, GetApiOrderSearchOrdersApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/SearchOrders`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiOrderGetOrderByOrderId: build.query<GetApiOrderGetOrderByOrderIdApiResponse, GetApiOrderGetOrderByOrderIdApiArg>({
      query: (queryArg) => ({ url: `/api/Order/GetOrder/${queryArg}` }),
    }),
    getApiOrderGetOrderSummaryForFormatByFormatId: build.query<
      GetApiOrderGetOrderSummaryForFormatByFormatIdApiResponse,
      GetApiOrderGetOrderSummaryForFormatByFormatIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Order/GetOrderSummaryForFormat/${queryArg}` }),
    }),
    postApiOrderCreateOrder: build.mutation<PostApiOrderCreateOrderApiResponse, PostApiOrderCreateOrderApiArg>({
      query: (queryArg) => ({ url: `/api/Order/CreateOrder`, method: 'POST', body: queryArg.orderVm, params: { sendEmail: queryArg.sendEmail } }),
    }),
    putApiOrderUpdateOrderByOrderId: build.mutation<PutApiOrderUpdateOrderByOrderIdApiResponse, PutApiOrderUpdateOrderByOrderIdApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/UpdateOrder/${queryArg.orderId}`,
        method: 'PUT',
        body: queryArg.orderVm,
        params: { sendEmail: queryArg.sendEmail },
      }),
    }),
    putApiOrderOverwriteOrderByOrderId: build.mutation<PutApiOrderOverwriteOrderByOrderIdApiResponse, PutApiOrderOverwriteOrderByOrderIdApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/OverwriteOrder/${queryArg.orderId}`,
        method: 'PUT',
        body: queryArg.orderVm,
        params: { sendEmail: queryArg.sendEmail },
      }),
    }),
    deleteApiOrderDeleteOrderByOrderId: build.mutation<DeleteApiOrderDeleteOrderByOrderIdApiResponse, DeleteApiOrderDeleteOrderByOrderIdApiArg>({
      query: (queryArg) => ({ url: `/api/Order/DeleteOrder/${queryArg}`, method: 'DELETE' }),
    }),
    putApiOrderCancelOrderByOrderId: build.mutation<PutApiOrderCancelOrderByOrderIdApiResponse, PutApiOrderCancelOrderByOrderIdApiArg>({
      query: (queryArg) => ({ url: `/api/Order/CancelOrder/${queryArg.orderId}`, method: 'PUT', params: { sendEmail: queryArg.sendEmail } }),
    }),
    postApiOrderSendOrderEmail: build.mutation<PostApiOrderSendOrderEmailApiResponse, PostApiOrderSendOrderEmailApiArg>({
      query: (queryArg) => ({ url: `/api/Order/SendOrderEmail`, method: 'POST', params: { orderId: queryArg } }),
    }),
    getApiOrderSearchRecharges: build.query<GetApiOrderSearchRechargesApiResponse, GetApiOrderSearchRechargesApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/SearchRecharges`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiOrderSearchOtherSales: build.query<GetApiOrderSearchOtherSalesApiResponse, GetApiOrderSearchOtherSalesApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/SearchOtherSales`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiOrderGetRechargeByRechargeId: build.query<GetApiOrderGetRechargeByRechargeIdApiResponse, GetApiOrderGetRechargeByRechargeIdApiArg>({
      query: (queryArg) => ({ url: `/api/Order/GetRecharge/${queryArg}` }),
    }),
    postApiOrderCreateRecharge: build.mutation<PostApiOrderCreateRechargeApiResponse, PostApiOrderCreateRechargeApiArg>({
      query: (queryArg) => ({ url: `/api/Order/CreateRecharge`, method: 'POST', body: queryArg }),
    }),
    putApiOrderUpdateRecharge: build.mutation<PutApiOrderUpdateRechargeApiResponse, PutApiOrderUpdateRechargeApiArg>({
      query: (queryArg) => ({ url: `/api/Order/UpdateRecharge`, method: 'PUT', body: queryArg }),
    }),
    deleteApiOrderDeleteRechargeByRechargeId: build.mutation<
      DeleteApiOrderDeleteRechargeByRechargeIdApiResponse,
      DeleteApiOrderDeleteRechargeByRechargeIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Order/DeleteRecharge/${queryArg}`, method: 'DELETE' }),
    }),
    getApiOrderExportOrders: build.query<GetApiOrderExportOrdersApiResponse, GetApiOrderExportOrdersApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/ExportOrders`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate, clientId: queryArg.clientId },
      }),
    }),
    getApiOrderExportRecharges: build.query<GetApiOrderExportRechargesApiResponse, GetApiOrderExportRechargesApiArg>({
      query: (queryArg) => ({
        url: `/api/Order/ExportRecharges`,
        params: { category: queryArg.category, startDate: queryArg.startDate, endDate: queryArg.endDate, clientId: queryArg.clientId },
      }),
    }),
    getByPartTypeId: build.query<GetByPartTypeIdApiResponse, GetByPartTypeIdApiArg>({
      query: (queryArg) => ({ url: `/${queryArg}` }),
    }),
    putByPartTypeId: build.mutation<PutByPartTypeIdApiResponse, PutByPartTypeIdApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.partTypeId}`,
        method: 'PUT',
        params: {
          description: queryArg.description,
          partTypeCategoryLinkId: queryArg.partTypeCategoryLinkId,
          numberOfItems: queryArg.numberOfItems,
          carriesAudio: queryArg.carriesAudio,
        },
      }),
    }),
    deleteByPartTypeId: build.mutation<DeleteByPartTypeIdApiResponse, DeleteByPartTypeIdApiArg>({
      query: (queryArg) => ({ url: `/${queryArg}`, method: 'DELETE' }),
    }),
    getApiPhysicalStockGetPhysicalStockSummaryLatest: build.query<
      GetApiPhysicalStockGetPhysicalStockSummaryLatestApiResponse,
      GetApiPhysicalStockGetPhysicalStockSummaryLatestApiArg
    >({
      query: () => ({ url: `/api/PhysicalStock/GetPhysicalStockSummaryLatest` }),
    }),
    getApiPhysicalStockGetPhysicalStockReceivedSummary: build.query<
      GetApiPhysicalStockGetPhysicalStockReceivedSummaryApiResponse,
      GetApiPhysicalStockGetPhysicalStockReceivedSummaryApiArg
    >({
      query: () => ({ url: `/api/PhysicalStock/GetPhysicalStockReceivedSummary` }),
    }),
    getApiPlatformGetRatesForPlatformExclusives: build.query<
      GetApiPlatformGetRatesForPlatformExclusivesApiResponse,
      GetApiPlatformGetRatesForPlatformExclusivesApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/GetRatesForPlatformExclusives`, params: { platformId: queryArg } }),
    }),
    postApiPlatformAddDefaultPlatformToClientsParentLabels: build.mutation<
      PostApiPlatformAddDefaultPlatformToClientsParentLabelsApiResponse,
      PostApiPlatformAddDefaultPlatformToClientsParentLabelsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/AddDefaultPlatformToClientsParentLabels`, method: 'POST', params: { platformId: queryArg } }),
    }),
    getApiPlatformPlatforms: build.query<GetApiPlatformPlatformsApiResponse, GetApiPlatformPlatformsApiArg>({
      query: () => ({ url: `/api/Platform/Platforms` }),
    }),
    getApiPlatformSearchPlatforms: build.query<GetApiPlatformSearchPlatformsApiResponse, GetApiPlatformSearchPlatformsApiArg>({
      query: (queryArg) => ({
        url: `/api/Platform/SearchPlatforms`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiPlatformGetPlatformByPlatformId: build.query<GetApiPlatformGetPlatformByPlatformIdApiResponse, GetApiPlatformGetPlatformByPlatformIdApiArg>({
      query: (queryArg) => ({ url: `/api/Platform/GetPlatform/${queryArg.platformId}`, params: { withSummary: queryArg.withSummary } }),
    }),
    postApiPlatformCreatePlatform: build.mutation<PostApiPlatformCreatePlatformApiResponse, PostApiPlatformCreatePlatformApiArg>({
      query: (queryArg) => ({ url: `/api/Platform/CreatePlatform`, method: 'POST', body: queryArg }),
    }),
    putApiPlatformUpdatePlatformByPlatformId: build.mutation<
      PutApiPlatformUpdatePlatformByPlatformIdApiResponse,
      PutApiPlatformUpdatePlatformByPlatformIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/UpdatePlatform/${queryArg.platformId}`, method: 'PUT', body: queryArg.platformVm }),
    }),
    getApiPlatformSearchPlatformDeliveryGroups: build.query<
      GetApiPlatformSearchPlatformDeliveryGroupsApiResponse,
      GetApiPlatformSearchPlatformDeliveryGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/SearchPlatformDeliveryGroups`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiPlatformDeliveryGroupsForPlatformByPlatformId: build.query<
      GetApiPlatformDeliveryGroupsForPlatformByPlatformIdApiResponse,
      GetApiPlatformDeliveryGroupsForPlatformByPlatformIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/DeliveryGroupsForPlatform/${queryArg}` }),
    }),
    getApiPlatformGetPlatformDeliveryGroupByGroupId: build.query<
      GetApiPlatformGetPlatformDeliveryGroupByGroupIdApiResponse,
      GetApiPlatformGetPlatformDeliveryGroupByGroupIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/GetPlatformDeliveryGroup/${queryArg}` }),
    }),
    postApiPlatformCreatePlatformDeliveryGroup: build.mutation<
      PostApiPlatformCreatePlatformDeliveryGroupApiResponse,
      PostApiPlatformCreatePlatformDeliveryGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/CreatePlatformDeliveryGroup`,
        method: 'POST',
        body: queryArg.platformDeliveryGroupVm,
        params: { platformId: queryArg.platformId },
      }),
    }),
    putApiPlatformUpdatePlatformDeliveryGroupByGroupId: build.mutation<
      PutApiPlatformUpdatePlatformDeliveryGroupByGroupIdApiResponse,
      PutApiPlatformUpdatePlatformDeliveryGroupByGroupIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/UpdatePlatformDeliveryGroup/${queryArg.groupId}`, method: 'PUT', body: queryArg.platformDeliveryGroupVm }),
    }),
    deleteApiPlatformDeletePlatformDeliveryGroupByGroupId: build.mutation<
      DeleteApiPlatformDeletePlatformDeliveryGroupByGroupIdApiResponse,
      DeleteApiPlatformDeletePlatformDeliveryGroupByGroupIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/DeletePlatformDeliveryGroup/${queryArg}`, method: 'DELETE' }),
    }),
    getApiPlatformSearchPlatformDeliveryDetails: build.query<
      GetApiPlatformSearchPlatformDeliveryDetailsApiResponse,
      GetApiPlatformSearchPlatformDeliveryDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/SearchPlatformDeliveryDetails`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiPlatformGetPlatformDeliveryDetailsByDetailsId: build.query<
      GetApiPlatformGetPlatformDeliveryDetailsByDetailsIdApiResponse,
      GetApiPlatformGetPlatformDeliveryDetailsByDetailsIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/GetPlatformDeliveryDetails/${queryArg}` }),
    }),
    postApiPlatformCreatePlatformDeliveryDetails: build.mutation<
      PostApiPlatformCreatePlatformDeliveryDetailsApiResponse,
      PostApiPlatformCreatePlatformDeliveryDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/CreatePlatformDeliveryDetails`,
        method: 'POST',
        body: queryArg.platformDeliveryDetailsVm,
        params: { groupId: queryArg.groupId },
      }),
    }),
    putApiPlatformUpdatePlatformDeliveryDetailsByDetailsId: build.mutation<
      PutApiPlatformUpdatePlatformDeliveryDetailsByDetailsIdApiResponse,
      PutApiPlatformUpdatePlatformDeliveryDetailsByDetailsIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/UpdatePlatformDeliveryDetails/${queryArg.detailsId}`,
        method: 'PUT',
        body: queryArg.platformDeliveryDetailsVm,
      }),
    }),
    deleteApiPlatformDeletePlatformDeliveryDetailsByDetailsId: build.mutation<
      DeleteApiPlatformDeletePlatformDeliveryDetailsByDetailsIdApiResponse,
      DeleteApiPlatformDeletePlatformDeliveryDetailsByDetailsIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/DeletePlatformDeliveryDetails/${queryArg}`, method: 'DELETE' }),
    }),
    putApiPlatformUpdateDefaultTenantPlatforms: build.mutation<
      PutApiPlatformUpdateDefaultTenantPlatformsApiResponse,
      PutApiPlatformUpdateDefaultTenantPlatformsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Platform/UpdateDefaultTenantPlatforms`, method: 'PUT', body: queryArg }),
    }),
    postApiPlatformCreatePlatformTenantDeliveryDetails: build.mutation<
      PostApiPlatformCreatePlatformTenantDeliveryDetailsApiResponse,
      PostApiPlatformCreatePlatformTenantDeliveryDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/CreatePlatformTenantDeliveryDetails`,
        method: 'POST',
        body: queryArg.platformTenantDeliveryDetailsVm,
        params: { groupId: queryArg.groupId },
      }),
    }),
    deleteApiPlatformDeletePlatformTenantDeliveryDetailsByDetailsId: build.mutation<
      DeleteApiPlatformDeletePlatformTenantDeliveryDetailsByDetailsIdApiResponse,
      DeleteApiPlatformDeletePlatformTenantDeliveryDetailsByDetailsIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Platform/DeletePlatformTenantDeliveryDetails/${queryArg.detailsId}`,
        method: 'DELETE',
        params: { deliveryDetailsId: queryArg.deliveryDetailsId },
      }),
    }),
    postApiProductDDexUrlTrigger: build.mutation<PostApiProductDDexUrlTriggerApiResponse, PostApiProductDDexUrlTriggerApiArg>({
      query: () => ({ url: `/api/Product/DDexUrlTrigger`, method: 'POST' }),
    }),
    getApiProductGetAssetList: build.query<GetApiProductGetAssetListApiResponse, GetApiProductGetAssetListApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetAssetList`, params: { formatId: queryArg } }),
    }),
    getApiProductInitCache: build.query<GetApiProductInitCacheApiResponse, GetApiProductInitCacheApiArg>({
      query: () => ({ url: `/api/Product/InitCache` }),
    }),
    getApiProductGetConfigData: build.query<GetApiProductGetConfigDataApiResponse, GetApiProductGetConfigDataApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetConfigData`, params: { formatId: queryArg } }),
    }),
    postApiProductGenerateMetaDataId: build.mutation<PostApiProductGenerateMetaDataIdApiResponse, PostApiProductGenerateMetaDataIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GenerateMetaDataId`, method: 'POST', body: queryArg }),
    }),
    getApiProductGetPhysicalFormatListMapping: build.query<
      GetApiProductGetPhysicalFormatListMappingApiResponse,
      GetApiProductGetPhysicalFormatListMappingApiArg
    >({
      query: () => ({ url: `/api/Product/GetPhysicalFormatListMapping` }),
    }),
    getApiProductGetArtistSuggestions: build.query<GetApiProductGetArtistSuggestionsApiResponse, GetApiProductGetArtistSuggestionsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetArtistSuggestions`, params: { labelId: queryArg } }),
    }),
    getApiProductRelatedFormats: build.query<GetApiProductRelatedFormatsApiResponse, GetApiProductRelatedFormatsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/RelatedFormats`, params: { formatId: queryArg } }),
    }),
    getApiProductSingleFormat: build.query<GetApiProductSingleFormatApiResponse, GetApiProductSingleFormatApiArg>({
      query: (queryArg) => ({ url: `/api/Product/SingleFormat`, params: { formatId: queryArg } }),
    }),
    getApiProductGetPlatformIdentifiersForFormatById: build.query<
      GetApiProductGetPlatformIdentifiersForFormatByIdApiResponse,
      GetApiProductGetPlatformIdentifiersForFormatByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Product/GetPlatformIdentifiersForFormat/${queryArg}` }),
    }),
    getApiProductFormatById: build.query<GetApiProductFormatByIdApiResponse, GetApiProductFormatByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Format/${queryArg}` }),
    }),
    postApiProductCreateFormat: build.mutation<PostApiProductCreateFormatApiResponse, PostApiProductCreateFormatApiArg>({
      query: (queryArg) => ({ url: `/api/Product/CreateFormat`, method: 'POST', body: queryArg }),
    }),
    postApiProductUpdateFormat: build.mutation<PostApiProductUpdateFormatApiResponse, PostApiProductUpdateFormatApiArg>({
      query: (queryArg) => ({ url: `/api/Product/UpdateFormat`, method: 'POST', body: queryArg }),
    }),
    postApiProductCopyTracksFromRelatedFormat: build.mutation<
      PostApiProductCopyTracksFromRelatedFormatApiResponse,
      PostApiProductCopyTracksFromRelatedFormatApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Product/CopyTracksFromRelatedFormat`,
        method: 'POST',
        params: { currentFormatId: queryArg.currentFormatId, relatedFormatId: queryArg.relatedFormatId },
      }),
    }),
    getApiProductIsValidCatOrUpc: build.query<GetApiProductIsValidCatOrUpcApiResponse, GetApiProductIsValidCatOrUpcApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsValidCatOrUpc`, params: { formatId: queryArg.formatId, val: queryArg.val, type: queryArg['type'] } }),
    }),
    getApiProductGetClientInfo: build.query<GetApiProductGetClientInfoApiResponse, GetApiProductGetClientInfoApiArg>({
      query: () => ({ url: `/api/Product/GetClientInfo` }),
    }),
    postApiProductNewLabel: build.mutation<PostApiProductNewLabelApiResponse, PostApiProductNewLabelApiArg>({
      query: (queryArg) => ({ url: `/api/Product/NewLabel`, method: 'POST', body: queryArg }),
    }),
    getApiProductGetLabels: build.query<GetApiProductGetLabelsApiResponse, GetApiProductGetLabelsApiArg>({
      query: () => ({ url: `/api/Product/GetLabels` }),
    }),
    getApiProductGetArtWorkSpecs: build.query<GetApiProductGetArtWorkSpecsApiResponse, GetApiProductGetArtWorkSpecsApiArg>({
      query: () => ({ url: `/api/Product/GetArtWorkSpecs` }),
    }),
    postApiProductCreateImmersive: build.mutation<PostApiProductCreateImmersiveApiResponse, PostApiProductCreateImmersiveApiArg>({
      query: (queryArg) => ({ url: `/api/Product/CreateImmersive`, method: 'POST', body: queryArg }),
    }),
    getApiProductReleaseById: build.query<GetApiProductReleaseByIdApiResponse, GetApiProductReleaseByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Release/${queryArg}` }),
    }),
    getApiProductGetReleaseConfig: build.query<GetApiProductGetReleaseConfigApiResponse, GetApiProductGetReleaseConfigApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetReleaseConfig`, params: { formatId: queryArg } }),
    }),
    getApiProductFormatPriceTierMappingList: build.query<GetApiProductFormatPriceTierMappingListApiResponse, GetApiProductFormatPriceTierMappingListApiArg>({
      query: (queryArg) => ({ url: `/api/Product/FormatPriceTierMappingList`, params: { formatId: queryArg } }),
    }),
    postApiProductRelease: build.mutation<PostApiProductReleaseApiResponse, PostApiProductReleaseApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Release`, method: 'POST', body: queryArg }),
    }),
    getApiProductPlatformDspAndTerritoryExclusives: build.query<
      GetApiProductPlatformDspAndTerritoryExclusivesApiResponse,
      GetApiProductPlatformDspAndTerritoryExclusivesApiArg
    >({
      query: (queryArg) => ({ url: `/api/Product/PlatformDspAndTerritoryExclusives`, params: { formatId: queryArg } }),
    }),
    getApiProductGetTimeZoneInfo: build.query<GetApiProductGetTimeZoneInfoApiResponse, GetApiProductGetTimeZoneInfoApiArg>({
      query: () => ({ url: `/api/Product/GetTimeZoneInfo` }),
    }),
    getApiProductDealsById: build.query<GetApiProductDealsByIdApiResponse, GetApiProductDealsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Deals/${queryArg}` }),
    }),
    postApiProductDeals: build.mutation<PostApiProductDealsApiResponse, PostApiProductDealsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Deals`, method: 'POST', body: queryArg }),
    }),
    postApiProductCreatePublisher: build.mutation<PostApiProductCreatePublisherApiResponse, PostApiProductCreatePublisherApiArg>({
      query: (queryArg) => ({ url: `/api/Product/CreatePublisher`, method: 'POST', body: queryArg }),
    }),
    getApiProductConfirmPublisherSelectedByName: build.query<
      GetApiProductConfirmPublisherSelectedByNameApiResponse,
      GetApiProductConfirmPublisherSelectedByNameApiArg
    >({
      query: (queryArg) => ({ url: `/api/Product/ConfirmPublisherSelectedByName`, params: { name: queryArg } }),
    }),
    getApiProductTracks: build.query<GetApiProductTracksApiResponse, GetApiProductTracksApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Tracks`, params: { formatId: queryArg } }),
    }),
    getApiProductTrackList: build.query<GetApiProductTrackListApiResponse, GetApiProductTrackListApiArg>({
      query: (queryArg) => ({ url: `/api/Product/TrackList`, params: { formatId: queryArg.formatId, assetId: queryArg.assetId } }),
    }),
    getApiProductTrack: build.query<GetApiProductTrackApiResponse, GetApiProductTrackApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Track`, params: { formatId: queryArg.formatId, assetId: queryArg.assetId } }),
    }),
    postApiProductTrack: build.mutation<PostApiProductTrackApiResponse, PostApiProductTrackApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Track`, method: 'POST', body: queryArg }),
    }),
    getApiProductGetTrackListDetailed: build.query<GetApiProductGetTrackListDetailedApiResponse, GetApiProductGetTrackListDetailedApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetTrackListDetailed`, params: { formatId: queryArg } }),
    }),
    getApiProductGetTrackCredits: build.query<GetApiProductGetTrackCreditsApiResponse, GetApiProductGetTrackCreditsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetTrackCredits`, params: { formatId: queryArg.formatId, assetId: queryArg.assetId } }),
    }),
    getApiProductGetAppleEngineer: build.query<GetApiProductGetAppleEngineerApiResponse, GetApiProductGetAppleEngineerApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetAppleEngineer`, params: { formatId: queryArg.formatId, assetId: queryArg.assetId } }),
    }),
    getApiProductGetAssetInfoIdAsset: build.query<GetApiProductGetAssetInfoIdAssetApiResponse, GetApiProductGetAssetInfoIdAssetApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetAssetInfoIdAsset`, params: { assetInfoId: queryArg } }),
    }),
    getApiProductGetIsrcAsset: build.query<GetApiProductGetIsrcAssetApiResponse, GetApiProductGetIsrcAssetApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetIsrcAsset`, params: { isrc: queryArg } }),
    }),
    postApiProductUpdateTrackOrder: build.mutation<PostApiProductUpdateTrackOrderApiResponse, PostApiProductUpdateTrackOrderApiArg>({
      query: (queryArg) => ({ url: `/api/Product/UpdateTrackOrder`, method: 'POST', body: queryArg }),
    }),
    postApiProductValidateSelectedTrack: build.mutation<PostApiProductValidateSelectedTrackApiResponse, PostApiProductValidateSelectedTrackApiArg>({
      query: (queryArg) => ({ url: `/api/Product/ValidateSelectedTrack`, method: 'POST', body: queryArg }),
    }),
    postApiProductAddTrackToFormat: build.mutation<PostApiProductAddTrackToFormatApiResponse, PostApiProductAddTrackToFormatApiArg>({
      query: (queryArg) => ({ url: `/api/Product/AddTrackToFormat`, method: 'POST', body: queryArg }),
    }),
    postApiProductDeleteFormatTrack: build.mutation<PostApiProductDeleteFormatTrackApiResponse, PostApiProductDeleteFormatTrackApiArg>({
      query: (queryArg) => ({ url: `/api/Product/DeleteFormatTrack`, method: 'POST', body: queryArg }),
    }),
    getApiProductIsValidIsrc: build.query<GetApiProductIsValidIsrcApiResponse, GetApiProductIsValidIsrcApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsValidIsrc`, params: { assetId: queryArg.assetId, isrc: queryArg.isrc } }),
    }),
    getApiProductIsValidExistingIsrc: build.query<GetApiProductIsValidExistingIsrcApiResponse, GetApiProductIsValidExistingIsrcApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsValidExistingIsrc`, params: { isrc: queryArg } }),
    }),
    getApiProductIsValidExistingCat: build.query<GetApiProductIsValidExistingCatApiResponse, GetApiProductIsValidExistingCatApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsValidExistingCat`, params: { cat: queryArg } }),
    }),
    getApiProductGetContributorInstruments: build.query<GetApiProductGetContributorInstrumentsApiResponse, GetApiProductGetContributorInstrumentsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetContributorInstruments`, params: { id: queryArg } }),
    }),
    getApiProductSuggestedContributorRoles: build.query<GetApiProductSuggestedContributorRolesApiResponse, GetApiProductSuggestedContributorRolesApiArg>({
      query: (queryArg) => ({ url: `/api/Product/SuggestedContributorRoles`, params: { formatId: queryArg } }),
    }),
    getApiProductGetArtistContributors: build.query<GetApiProductGetArtistContributorsApiResponse, GetApiProductGetArtistContributorsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetArtistContributors`, params: { contributorId: queryArg } }),
    }),
    getApiProductGetWriterPublishers: build.query<GetApiProductGetWriterPublishersApiResponse, GetApiProductGetWriterPublishersApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetWriterPublishers`, params: { contributorId: queryArg } }),
    }),
    getApiProductGetMeadList: build.query<GetApiProductGetMeadListApiResponse, GetApiProductGetMeadListApiArg>({
      query: () => ({ url: `/api/Product/GetMeadList` }),
    }),
    getApiProductGetAssetDetails: build.query<GetApiProductGetAssetDetailsApiResponse, GetApiProductGetAssetDetailsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetAssetDetails`, params: { assetId: queryArg } }),
    }),
    getApiProductExtrasById: build.query<GetApiProductExtrasByIdApiResponse, GetApiProductExtrasByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Extras/${queryArg}` }),
    }),
    postApiProductExtras: build.mutation<PostApiProductExtrasApiResponse, PostApiProductExtrasApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Extras`, method: 'POST', body: queryArg }),
    }),
    getApiProductExtrasConfigById: build.query<GetApiProductExtrasConfigByIdApiResponse, GetApiProductExtrasConfigByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/ExtrasConfig/${queryArg}` }),
    }),
    getApiProductReviewById: build.query<GetApiProductReviewByIdApiResponse, GetApiProductReviewByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Review/${queryArg}` }),
    }),
    getApiProductGetApprovalStatesById: build.query<GetApiProductGetApprovalStatesByIdApiResponse, GetApiProductGetApprovalStatesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/GetApprovalStates/${queryArg}` }),
    }),
    getApiProductIsFormatValidById: build.query<GetApiProductIsFormatValidByIdApiResponse, GetApiProductIsFormatValidByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsFormatValid/${queryArg}` }),
    }),
    getApiProductIsFormatCmoValidById: build.query<GetApiProductIsFormatCmoValidByIdApiResponse, GetApiProductIsFormatCmoValidByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsFormatCmoValid/${queryArg}` }),
    }),
    getApiProductIsValidAllById: build.query<GetApiProductIsValidAllByIdApiResponse, GetApiProductIsValidAllByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsValidAll/${queryArg}` }),
    }),
    postApiProductApprove: build.mutation<PostApiProductApproveApiResponse, PostApiProductApproveApiArg>({
      query: (queryArg) => ({ url: `/api/Product/Approve`, method: 'POST', body: queryArg }),
    }),
    postApiProductRequestClientApproval: build.mutation<PostApiProductRequestClientApprovalApiResponse, PostApiProductRequestClientApprovalApiArg>({
      query: (queryArg) => ({ url: `/api/Product/RequestClientApproval`, method: 'POST', params: { formatId: queryArg } }),
    }),
    postApiProductValidatePlatforms: build.mutation<PostApiProductValidatePlatformsApiResponse, PostApiProductValidatePlatformsApiArg>({
      query: (queryArg) => ({ url: `/api/Product/ValidatePlatforms`, method: 'POST', params: { id: queryArg } }),
    }),
    postApiProductValidateCmos: build.mutation<PostApiProductValidateCmosApiResponse, PostApiProductValidateCmosApiArg>({
      query: (queryArg) => ({ url: `/api/Product/ValidateCmos`, method: 'POST', params: { id: queryArg } }),
    }),
    postApiProductValidateAndQueueJobsForFormat: build.mutation<
      PostApiProductValidateAndQueueJobsForFormatApiResponse,
      PostApiProductValidateAndQueueJobsForFormatApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Product/ValidateAndQueueJobsForFormat`,
        method: 'POST',
        params: { id: queryArg.id, deliveryTypeId: queryArg.deliveryTypeId },
      }),
    }),
    getApiProductIsApprovedOrQueuedById: build.query<GetApiProductIsApprovedOrQueuedByIdApiResponse, GetApiProductIsApprovedOrQueuedByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Product/IsApprovedOrQueued/${queryArg}` }),
    }),
    getApiProfileGetLastReportedDate: build.query<GetApiProfileGetLastReportedDateApiResponse, GetApiProfileGetLastReportedDateApiArg>({
      query: () => ({ url: `/api/Profile/GetLastReportedDate` }),
    }),
    getApiProfileClientDefaultPlatformDealPoliciesByClientId: build.query<
      GetApiProfileClientDefaultPlatformDealPoliciesByClientIdApiResponse,
      GetApiProfileClientDefaultPlatformDealPoliciesByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ClientDefaultPlatformDealPolicies/${queryArg}` }),
    }),
    getApiProfileClientDefaultPlatformsByClientId: build.query<
      GetApiProfileClientDefaultPlatformsByClientIdApiResponse,
      GetApiProfileClientDefaultPlatformsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ClientDefaultPlatforms/${queryArg}` }),
    }),
    getApiProfileClientDefaultPlatformsAvailableByClientId: build.query<
      GetApiProfileClientDefaultPlatformsAvailableByClientIdApiResponse,
      GetApiProfileClientDefaultPlatformsAvailableByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ClientDefaultPlatformsAvailable/${queryArg}` }),
    }),
    getApiProfileClientDefaultTerritoriesByClientId: build.query<
      GetApiProfileClientDefaultTerritoriesByClientIdApiResponse,
      GetApiProfileClientDefaultTerritoriesByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ClientDefaultTerritories/${queryArg}` }),
    }),
    getApiProfileClientDefaultPricesByClientId: build.query<
      GetApiProfileClientDefaultPricesByClientIdApiResponse,
      GetApiProfileClientDefaultPricesByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ClientDefaultPrices/${queryArg}` }),
    }),
    postApiProfileCreateClient: build.mutation<PostApiProfileCreateClientApiResponse, PostApiProfileCreateClientApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/CreateClient`, method: 'POST', params: { tenantId: queryArg.tenantId, clientName: queryArg.clientName } }),
    }),
    postApiProfileCreateClientAgreement: build.mutation<PostApiProfileCreateClientAgreementApiResponse, PostApiProfileCreateClientAgreementApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/CreateClientAgreement`, method: 'POST', body: queryArg }),
    }),
    postApiProfileCreatePaymentAccountByClientId: build.mutation<
      PostApiProfileCreatePaymentAccountByClientIdApiResponse,
      PostApiProfileCreatePaymentAccountByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/CreatePaymentAccount/${queryArg.clientId}`, method: 'POST', body: queryArg.paymentAccountVm }),
    }),
    getApiProfileGetClientBasicInfoByClientId: build.query<
      GetApiProfileGetClientBasicInfoByClientIdApiResponse,
      GetApiProfileGetClientBasicInfoByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientBasicInfo/${queryArg}` }),
    }),
    getApiProfileGetClientTenantTeamMembersByClientId: build.query<
      GetApiProfileGetClientTenantTeamMembersByClientIdApiResponse,
      GetApiProfileGetClientTenantTeamMembersByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientTenantTeamMembers/${queryArg}` }),
    }),
    getApiProfileGetTenantTeamMembersForClientContactsByTenantId: build.query<
      GetApiProfileGetTenantTeamMembersForClientContactsByTenantIdApiResponse,
      GetApiProfileGetTenantTeamMembersForClientContactsByTenantIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetTenantTeamMembersForClientContacts/${queryArg}` }),
    }),
    getApiProfileGetClientDefaultsByClientId: build.query<GetApiProfileGetClientDefaultsByClientIdApiResponse, GetApiProfileGetClientDefaultsByClientIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetClientDefaults/${queryArg}` }),
    }),
    getApiProfileGetDefaultsPlatforms: build.query<GetApiProfileGetDefaultsPlatformsApiResponse, GetApiProfileGetDefaultsPlatformsApiArg>({
      query: () => ({ url: `/api/Profile/GetDefaultsPlatforms` }),
    }),
    getApiProfileGetClientSaasProductsByClientId: build.query<
      GetApiProfileGetClientSaasProductsByClientIdApiResponse,
      GetApiProfileGetClientSaasProductsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientSaasProducts/${queryArg}` }),
    }),
    getApiProfileGetClientDomainCustomisationByClientId: build.query<
      GetApiProfileGetClientDomainCustomisationByClientIdApiResponse,
      GetApiProfileGetClientDomainCustomisationByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientDomainCustomisation/${queryArg}` }),
    }),
    getApiProfileGetClientAutoAddNewPlatformsByClientId: build.query<
      GetApiProfileGetClientAutoAddNewPlatformsByClientIdApiResponse,
      GetApiProfileGetClientAutoAddNewPlatformsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientAutoAddNewPlatforms/${queryArg}` }),
    }),
    getApiProfileGetClientResourcesByClientId: build.query<
      GetApiProfileGetClientResourcesByClientIdApiResponse,
      GetApiProfileGetClientResourcesByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientResources/${queryArg}` }),
    }),
    getApiProfileGetClientAgreementsByClientId: build.query<
      GetApiProfileGetClientAgreementsByClientIdApiResponse,
      GetApiProfileGetClientAgreementsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetClientAgreements/${queryArg}` }),
    }),
    getApiProfileGetPaymentAccountsByClientId: build.query<
      GetApiProfileGetPaymentAccountsByClientIdApiResponse,
      GetApiProfileGetPaymentAccountsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetPaymentAccounts/${queryArg}` }),
    }),
    getApiProfileRelatedProfilesByClientId: build.query<GetApiProfileRelatedProfilesByClientIdApiResponse, GetApiProfileRelatedProfilesByClientIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/RelatedProfiles/${queryArg}` }),
    }),
    putApiProfileUpdateClientByClientId: build.mutation<PutApiProfileUpdateClientByClientIdApiResponse, PutApiProfileUpdateClientByClientIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClient/${queryArg.clientId}`, method: 'PUT', body: queryArg.clientVm }),
    }),
    putApiProfileUpdateClientBasicInfoByClientId: build.mutation<
      PutApiProfileUpdateClientBasicInfoByClientIdApiResponse,
      PutApiProfileUpdateClientBasicInfoByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientBasicInfo/${queryArg.clientId}`, method: 'PUT', body: queryArg.clientBasicInfoVm }),
    }),
    putApiProfileUpdateClientTenantTeamMembersByClientId: build.mutation<
      PutApiProfileUpdateClientTenantTeamMembersByClientIdApiResponse,
      PutApiProfileUpdateClientTenantTeamMembersByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientTenantTeamMembers/${queryArg.clientId}`, method: 'PUT', body: queryArg.body }),
    }),
    putApiProfileUpdateClientDomainCustomisationByClientId: build.mutation<
      PutApiProfileUpdateClientDomainCustomisationByClientIdApiResponse,
      PutApiProfileUpdateClientDomainCustomisationByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientDomainCustomisation/${queryArg.clientId}`, method: 'PUT', body: queryArg.domainCustomisationVm }),
    }),
    putApiProfileUpdateClientAgreementByClientId: build.mutation<
      PutApiProfileUpdateClientAgreementByClientIdApiResponse,
      PutApiProfileUpdateClientAgreementByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientAgreement/${queryArg.clientId}`, method: 'PUT', body: queryArg.clientAgreementVm }),
    }),
    putApiProfileDiscontinueClientAgreementByClientId: build.mutation<
      PutApiProfileDiscontinueClientAgreementByClientIdApiResponse,
      PutApiProfileDiscontinueClientAgreementByClientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Profile/DiscontinueClientAgreement/${queryArg.clientId}`,
        method: 'PUT',
        params: { agreementId: queryArg.agreementId },
      }),
    }),
    deleteApiProfileDeleteClientAgreementByClientId: build.mutation<
      DeleteApiProfileDeleteClientAgreementByClientIdApiResponse,
      DeleteApiProfileDeleteClientAgreementByClientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Profile/DeleteClientAgreement/${queryArg.clientId}`,
        method: 'DELETE',
        params: { agreementId: queryArg.agreementId },
      }),
    }),
    putApiProfileUpdateClientSaasProductsByClientId: build.mutation<
      PutApiProfileUpdateClientSaasProductsByClientIdApiResponse,
      PutApiProfileUpdateClientSaasProductsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientSaasProducts/${queryArg.clientId}`, method: 'PUT', body: queryArg.body }),
    }),
    putApiProfileUpdateClientDefaultsByClientId: build.mutation<
      PutApiProfileUpdateClientDefaultsByClientIdApiResponse,
      PutApiProfileUpdateClientDefaultsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientDefaults/${queryArg.clientId}`, method: 'PUT', body: queryArg.clientDefaultsVm }),
    }),
    putApiProfileUpdateClientResourcesByClientId: build.mutation<
      PutApiProfileUpdateClientResourcesByClientIdApiResponse,
      PutApiProfileUpdateClientResourcesByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientResources/${queryArg.clientId}`, method: 'PUT', body: queryArg.body }),
    }),
    deleteApiProfileDeleteClientByClientId: build.mutation<DeleteApiProfileDeleteClientByClientIdApiResponse, DeleteApiProfileDeleteClientByClientIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/DeleteClient/${queryArg}`, method: 'DELETE' }),
    }),
    deleteApiProfileRemoveTeamMemberByClientId: build.mutation<
      DeleteApiProfileRemoveTeamMemberByClientIdApiResponse,
      DeleteApiProfileRemoveTeamMemberByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/RemoveTeamMember/${queryArg.clientId}`, method: 'DELETE', params: { teamMemberId: queryArg.teamMemberId } }),
    }),
    putApiProfileUpdateClientAutoAddNewPlatformsByClientId: build.mutation<
      PutApiProfileUpdateClientAutoAddNewPlatformsByClientIdApiResponse,
      PutApiProfileUpdateClientAutoAddNewPlatformsByClientIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateClientAutoAddNewPlatforms/${queryArg.clientId}`, method: 'PUT', body: queryArg.body }),
    }),
    getApiProfileSearchClients: build.query<GetApiProfileSearchClientsApiResponse, GetApiProfileSearchClientsApiArg>({
      query: (queryArg) => ({
        url: `/api/Profile/SearchClients`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiProfileGetClientUsersByClientId: build.query<GetApiProfileGetClientUsersByClientIdApiResponse, GetApiProfileGetClientUsersByClientIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetClientUsers/${queryArg}` }),
    }),
    getApiProfileGetFtpAccountsByClientId: build.query<GetApiProfileGetFtpAccountsByClientIdApiResponse, GetApiProfileGetFtpAccountsByClientIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetFTPAccounts/${queryArg}` }),
    }),
    postApiProfileSaveFtpAccount: build.mutation<PostApiProfileSaveFtpAccountApiResponse, PostApiProfileSaveFtpAccountApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/SaveFTPAccount`, method: 'POST', body: queryArg }),
    }),
    deleteApiProfileDeleteFtpAccount: build.mutation<DeleteApiProfileDeleteFtpAccountApiResponse, DeleteApiProfileDeleteFtpAccountApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/DeleteFTPAccount`, method: 'DELETE', body: queryArg }),
    }),
    getApiProfileParentLabelDefaultsPlatformDealPoliciesByParentLabelId: build.query<
      GetApiProfileParentLabelDefaultsPlatformDealPoliciesByParentLabelIdApiResponse,
      GetApiProfileParentLabelDefaultsPlatformDealPoliciesByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ParentLabelDefaultsPlatformDealPolicies/${queryArg}` }),
    }),
    getApiProfileParentLabelDefaultsPlatformsByParentLabelId: build.query<
      GetApiProfileParentLabelDefaultsPlatformsByParentLabelIdApiResponse,
      GetApiProfileParentLabelDefaultsPlatformsByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ParentLabelDefaultsPlatforms/${queryArg}` }),
    }),
    getApiProfileParentLabelDefaultPlatformsAvailableByParentLabelId: build.query<
      GetApiProfileParentLabelDefaultPlatformsAvailableByParentLabelIdApiResponse,
      GetApiProfileParentLabelDefaultPlatformsAvailableByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ParentLabelDefaultPlatformsAvailable/${queryArg}` }),
    }),
    getApiProfileParentLabelDefaultsTerritoriesByParentLabelId: build.query<
      GetApiProfileParentLabelDefaultsTerritoriesByParentLabelIdApiResponse,
      GetApiProfileParentLabelDefaultsTerritoriesByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ParentLabelDefaultsTerritories/${queryArg}` }),
    }),
    getApiProfileParentLabelDefaultsPricesByParentLabelId: build.query<
      GetApiProfileParentLabelDefaultsPricesByParentLabelIdApiResponse,
      GetApiProfileParentLabelDefaultsPricesByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/ParentLabelDefaultsPrices/${queryArg}` }),
    }),
    getApiProfileSearchParentLabels: build.query<GetApiProfileSearchParentLabelsApiResponse, GetApiProfileSearchParentLabelsApiArg>({
      query: (queryArg) => ({
        url: `/api/Profile/SearchParentLabels`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    postApiProfileCreateParentLabel: build.mutation<PostApiProfileCreateParentLabelApiResponse, PostApiProfileCreateParentLabelApiArg>({
      query: (queryArg) => ({
        url: `/api/Profile/CreateParentLabel`,
        method: 'POST',
        params: { clientId: queryArg.clientId, parentLabelName: queryArg.parentLabelName },
      }),
    }),
    getApiProfileGetParentLabelBasicInfoByParentLabelId: build.query<
      GetApiProfileGetParentLabelBasicInfoByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelBasicInfoByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelBasicInfo/${queryArg}` }),
    }),
    getApiProfileGetParentLabelDefaultsByParentLabelId: build.query<
      GetApiProfileGetParentLabelDefaultsByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelDefaultsByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelDefaults/${queryArg}` }),
    }),
    getApiProfileGetParentLabelResourcesByParentLabelId: build.query<
      GetApiProfileGetParentLabelResourcesByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelResourcesByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelResources/${queryArg}` }),
    }),
    getApiProfileGetParentLabelAutoAddNewPlatformsByParentLabelId: build.query<
      GetApiProfileGetParentLabelAutoAddNewPlatformsByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelAutoAddNewPlatformsByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelAutoAddNewPlatforms/${queryArg}` }),
    }),
    getApiProfileGetParentLabelRelatedProfilesByParentLabelId: build.query<
      GetApiProfileGetParentLabelRelatedProfilesByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelRelatedProfilesByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelRelatedProfiles/${queryArg}` }),
    }),
    getApiProfileGetParentLabelProductsByParentLabelId: build.query<
      GetApiProfileGetParentLabelProductsByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelProductsByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelProducts/${queryArg}` }),
    }),
    putApiProfileUpdateParentLabelByParentLabelId: build.mutation<
      PutApiProfileUpdateParentLabelByParentLabelIdApiResponse,
      PutApiProfileUpdateParentLabelByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateParentLabel/${queryArg.parentLabelId}`, method: 'PUT', body: queryArg.parentLabelVm }),
    }),
    putApiProfileUpdateParentLabelBasicInfoByParentLabelId: build.mutation<
      PutApiProfileUpdateParentLabelBasicInfoByParentLabelIdApiResponse,
      PutApiProfileUpdateParentLabelBasicInfoByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateParentLabelBasicInfo/${queryArg.parentLabelId}`, method: 'PUT', body: queryArg.parentLabelBasicInfoVm }),
    }),
    putApiProfileUpdateParentLabelDefaultsByParentLabelId: build.mutation<
      PutApiProfileUpdateParentLabelDefaultsByParentLabelIdApiResponse,
      PutApiProfileUpdateParentLabelDefaultsByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateParentLabelDefaults/${queryArg.parentLabelId}`, method: 'PUT', body: queryArg.labelDefaultsVm }),
    }),
    putApiProfileUpdateParentLabelResourcesByParentLabelId: build.mutation<
      PutApiProfileUpdateParentLabelResourcesByParentLabelIdApiResponse,
      PutApiProfileUpdateParentLabelResourcesByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateParentLabelResources/${queryArg.parentLabelId}`, method: 'PUT', body: queryArg.body }),
    }),
    putApiProfileUpdateParentLabelAutoAddNewPlatformsByParentLabelId: build.mutation<
      PutApiProfileUpdateParentLabelAutoAddNewPlatformsByParentLabelIdApiResponse,
      PutApiProfileUpdateParentLabelAutoAddNewPlatformsByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateParentLabelAutoAddNewPlatforms/${queryArg.parentLabelId}`, method: 'PUT', body: queryArg.body }),
    }),
    deleteApiProfileDeleteParentLabelByParentLabelId: build.mutation<
      DeleteApiProfileDeleteParentLabelByParentLabelIdApiResponse,
      DeleteApiProfileDeleteParentLabelByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/DeleteParentLabel/${queryArg}`, method: 'DELETE' }),
    }),
    getApiProfileGetParentLabelUsersByParentLabelId: build.query<
      GetApiProfileGetParentLabelUsersByParentLabelIdApiResponse,
      GetApiProfileGetParentLabelUsersByParentLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetParentLabelUsers/${queryArg}` }),
    }),
    getApiProfileLabelDefaultsPlatformDealPoliciesByLabelId: build.query<
      GetApiProfileLabelDefaultsPlatformDealPoliciesByLabelIdApiResponse,
      GetApiProfileLabelDefaultsPlatformDealPoliciesByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/LabelDefaultsPlatformDealPolicies/${queryArg}` }),
    }),
    getApiProfileLabelDefaultsPlatformsByLabelId: build.query<
      GetApiProfileLabelDefaultsPlatformsByLabelIdApiResponse,
      GetApiProfileLabelDefaultsPlatformsByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/LabelDefaultsPlatforms/${queryArg}` }),
    }),
    getApiProfileLabelDefaultPlatformsAvailableByLabelId: build.query<
      GetApiProfileLabelDefaultPlatformsAvailableByLabelIdApiResponse,
      GetApiProfileLabelDefaultPlatformsAvailableByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/LabelDefaultPlatformsAvailable/${queryArg}` }),
    }),
    getApiProfileLabelDefaultsTerritoriesByLabelId: build.query<
      GetApiProfileLabelDefaultsTerritoriesByLabelIdApiResponse,
      GetApiProfileLabelDefaultsTerritoriesByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/LabelDefaultsTerritories/${queryArg}` }),
    }),
    getApiProfileLabelDefaultsPricesByLabelId: build.query<
      GetApiProfileLabelDefaultsPricesByLabelIdApiResponse,
      GetApiProfileLabelDefaultsPricesByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/LabelDefaultsPrices/${queryArg}` }),
    }),
    getApiProfileSearchProfileLabels: build.query<GetApiProfileSearchProfileLabelsApiResponse, GetApiProfileSearchProfileLabelsApiArg>({
      query: (queryArg) => ({
        url: `/api/Profile/SearchProfileLabels`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    postApiProfileCreateLabel: build.mutation<PostApiProfileCreateLabelApiResponse, PostApiProfileCreateLabelApiArg>({
      query: (queryArg) => ({
        url: `/api/Profile/CreateLabel`,
        method: 'POST',
        params: { parentLabelId: queryArg.parentLabelId, labelName: queryArg.labelName },
      }),
    }),
    getApiProfileGetLabelBasicInfoByLabelId: build.query<GetApiProfileGetLabelBasicInfoByLabelIdApiResponse, GetApiProfileGetLabelBasicInfoByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetLabelBasicInfo/${queryArg}` }),
    }),
    getApiProfileGetLabelDefaultsByLabelId: build.query<GetApiProfileGetLabelDefaultsByLabelIdApiResponse, GetApiProfileGetLabelDefaultsByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetLabelDefaults/${queryArg}` }),
    }),
    getApiProfileGetLabelResourcesByLabelId: build.query<GetApiProfileGetLabelResourcesByLabelIdApiResponse, GetApiProfileGetLabelResourcesByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetLabelResources/${queryArg}` }),
    }),
    getApiProfileGetLabelRelatedProfilesByLabelId: build.query<
      GetApiProfileGetLabelRelatedProfilesByLabelIdApiResponse,
      GetApiProfileGetLabelRelatedProfilesByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetLabelRelatedProfiles/${queryArg}` }),
    }),
    getApiProfileGetLabelProductsByLabelId: build.query<GetApiProfileGetLabelProductsByLabelIdApiResponse, GetApiProfileGetLabelProductsByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetLabelProducts/${queryArg}` }),
    }),
    putApiProfileUpdateLabelByLabelId: build.mutation<PutApiProfileUpdateLabelByLabelIdApiResponse, PutApiProfileUpdateLabelByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/UpdateLabel/${queryArg.labelId}`, method: 'PUT', body: queryArg.labelVm }),
    }),
    putApiProfileUpdateLabelBasicInfoByLabelId: build.mutation<
      PutApiProfileUpdateLabelBasicInfoByLabelIdApiResponse,
      PutApiProfileUpdateLabelBasicInfoByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateLabelBasicInfo/${queryArg.labelId}`, method: 'PUT', body: queryArg.labelBasicInfoVm }),
    }),
    putApiProfileUpdateLabelDefaultsByLabelId: build.mutation<
      PutApiProfileUpdateLabelDefaultsByLabelIdApiResponse,
      PutApiProfileUpdateLabelDefaultsByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateLabelDefaults/${queryArg.labelId}`, method: 'PUT', body: queryArg.labelDefaultsVm }),
    }),
    putApiProfileUpdateLabelResourcesByLabelId: build.mutation<
      PutApiProfileUpdateLabelResourcesByLabelIdApiResponse,
      PutApiProfileUpdateLabelResourcesByLabelIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateLabelResources/${queryArg.labelId}`, method: 'PUT', body: queryArg.body }),
    }),
    deleteApiProfileDeleteLabelByLabelId: build.mutation<DeleteApiProfileDeleteLabelByLabelIdApiResponse, DeleteApiProfileDeleteLabelByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/DeleteLabel/${queryArg}`, method: 'DELETE' }),
    }),
    getApiProfileGetLabelUsersByLabelId: build.query<GetApiProfileGetLabelUsersByLabelIdApiResponse, GetApiProfileGetLabelUsersByLabelIdApiArg>({
      query: (queryArg) => ({ url: `/api/Profile/GetLabelUsers/${queryArg}` }),
    }),
    getApiProfileSearchContributors: build.query<GetApiProfileSearchContributorsApiResponse, GetApiProfileSearchContributorsApiArg>({
      query: (queryArg) => ({
        url: `/api/Profile/SearchContributors`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    postApiProfileCreateContributor: build.mutation<PostApiProfileCreateContributorApiResponse, PostApiProfileCreateContributorApiArg>({
      query: () => ({ url: `/api/Profile/CreateContributor`, method: 'POST' }),
    }),
    getApiProfileGetContributorBasicInfoByContributorId: build.query<
      GetApiProfileGetContributorBasicInfoByContributorIdApiResponse,
      GetApiProfileGetContributorBasicInfoByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorBasicInfo/${queryArg.contributorId}`, params: { languageId: queryArg.languageId } }),
    }),
    getApiProfileGetContributorImageByContributorId: build.query<
      GetApiProfileGetContributorImageByContributorIdApiResponse,
      GetApiProfileGetContributorImageByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorImage/${queryArg}` }),
    }),
    getApiProfileGetContributorTagsKeywordsByContributorId: build.query<
      GetApiProfileGetContributorTagsKeywordsByContributorIdApiResponse,
      GetApiProfileGetContributorTagsKeywordsByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorTagsKeywords/${queryArg}` }),
    }),
    getApiProfileGetContributorTags: build.query<GetApiProfileGetContributorTagsApiResponse, GetApiProfileGetContributorTagsApiArg>({
      query: () => ({ url: `/api/Profile/GetContributorTags` }),
    }),
    getApiProfileGetContributorResourcesByContributorId: build.query<
      GetApiProfileGetContributorResourcesByContributorIdApiResponse,
      GetApiProfileGetContributorResourcesByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorResources/${queryArg}` }),
    }),
    putApiProfileUpdateContributorByContributorId: build.mutation<
      PutApiProfileUpdateContributorByContributorIdApiResponse,
      PutApiProfileUpdateContributorByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateContributor/${queryArg.contributorId}`, method: 'PUT', body: queryArg.contributorVm }),
    }),
    putApiProfileUpdateContributorBasicInfoByContributorId: build.mutation<
      PutApiProfileUpdateContributorBasicInfoByContributorIdApiResponse,
      PutApiProfileUpdateContributorBasicInfoByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateContributorBasicInfo/${queryArg.contributorId}`, method: 'PUT', body: queryArg.acCreateContributorVm }),
    }),
    putApiProfileUpdateContributorImageByContributorId: build.mutation<
      PutApiProfileUpdateContributorImageByContributorIdApiResponse,
      PutApiProfileUpdateContributorImageByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateContributorImage/${queryArg.contributorId}`, method: 'PUT', body: queryArg.contributorImageVm }),
    }),
    putApiProfileUpdateContributorTagKeywordsByContributorId: build.mutation<
      PutApiProfileUpdateContributorTagKeywordsByContributorIdApiResponse,
      PutApiProfileUpdateContributorTagKeywordsByContributorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Profile/UpdateContributorTagKeywords/${queryArg.contributorId}`,
        method: 'PUT',
        body: queryArg.contributorTagsKeywordsVm,
      }),
    }),
    putApiProfileUpdateContributorResourcesByContributorId: build.mutation<
      PutApiProfileUpdateContributorResourcesByContributorIdApiResponse,
      PutApiProfileUpdateContributorResourcesByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/UpdateContributorResources/${queryArg.contributorId}`, method: 'PUT', body: queryArg.body }),
    }),
    deleteApiProfileDeleteContributorByContributorId: build.mutation<
      DeleteApiProfileDeleteContributorByContributorIdApiResponse,
      DeleteApiProfileDeleteContributorByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/DeleteContributor/${queryArg}`, method: 'DELETE' }),
    }),
    getApiProfileGetContributorProductsByContributorId: build.query<
      GetApiProfileGetContributorProductsByContributorIdApiResponse,
      GetApiProfileGetContributorProductsByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorProducts/${queryArg}` }),
    }),
    getApiProfileGetConnectedProfilesByContributorId: build.query<
      GetApiProfileGetConnectedProfilesByContributorIdApiResponse,
      GetApiProfileGetConnectedProfilesByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetConnectedProfiles/${queryArg}` }),
    }),
    getApiProfileGetContributorRelatedFormatsByContributorId: build.query<
      GetApiProfileGetContributorRelatedFormatsByContributorIdApiResponse,
      GetApiProfileGetContributorRelatedFormatsByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorRelatedFormats/${queryArg}` }),
    }),
    getApiProfileGetContributorRelatedAssetsByContributorId: build.query<
      GetApiProfileGetContributorRelatedAssetsByContributorIdApiResponse,
      GetApiProfileGetContributorRelatedAssetsByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorRelatedAssets/${queryArg}` }),
    }),
    getApiProfileGetContributorRelatedWorksByContributorId: build.query<
      GetApiProfileGetContributorRelatedWorksByContributorIdApiResponse,
      GetApiProfileGetContributorRelatedWorksByContributorIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Profile/GetContributorRelatedWorks/${queryArg}` }),
    }),
    getApiProxyDeliverymanByPath: build.query<GetApiProxyDeliverymanByPathApiResponse, GetApiProxyDeliverymanByPathApiArg>({
      query: (queryArg) => ({ url: `/api/Proxy/deliveryman/${queryArg}` }),
    }),
    postApiProxyDeliverymanByPath: build.mutation<PostApiProxyDeliverymanByPathApiResponse, PostApiProxyDeliverymanByPathApiArg>({
      query: (queryArg) => ({ url: `/api/Proxy/deliveryman/${queryArg}`, method: 'POST' }),
    }),
    getApiProxyDispatcherByPath: build.query<GetApiProxyDispatcherByPathApiResponse, GetApiProxyDispatcherByPathApiArg>({
      query: (queryArg) => ({ url: `/api/Proxy/dispatcher/${queryArg}` }),
    }),
    postApiProxyDispatcherByPath: build.mutation<PostApiProxyDispatcherByPathApiResponse, PostApiProxyDispatcherByPathApiArg>({
      query: (queryArg) => ({ url: `/api/Proxy/dispatcher/${queryArg}`, method: 'POST' }),
    }),
    getApiProxySongkickByPath: build.query<GetApiProxySongkickByPathApiResponse, GetApiProxySongkickByPathApiArg>({
      query: (queryArg) => ({ url: `/api/Proxy/songkick/${queryArg}` }),
    }),
    getApiReleaseScheduleGetReleaseSchedule: build.query<GetApiReleaseScheduleGetReleaseScheduleApiResponse, GetApiReleaseScheduleGetReleaseScheduleApiArg>({
      query: () => ({ url: `/api/ReleaseSchedule/GetReleaseSchedule` }),
    }),
    getApiReleaseScheduleSearchReleaseSchedule: build.query<
      GetApiReleaseScheduleSearchReleaseScheduleApiResponse,
      GetApiReleaseScheduleSearchReleaseScheduleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ReleaseSchedule/SearchReleaseSchedule`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSearchSearchFormats: build.query<GetApiSearchSearchFormatsApiResponse, GetApiSearchSearchFormatsApiArg>({
      query: (queryArg) => ({
        url: `/api/Search/SearchFormats`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSearchSearchContributors: build.query<GetApiSearchSearchContributorsApiResponse, GetApiSearchSearchContributorsApiArg>({
      query: (queryArg) => ({
        url: `/api/Search/SearchContributors`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSearchSearchContributorContributions: build.query<
      GetApiSearchSearchContributorContributionsApiResponse,
      GetApiSearchSearchContributorContributionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Search/SearchContributorContributions`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSearchSearchTracks: build.query<GetApiSearchSearchTracksApiResponse, GetApiSearchSearchTracksApiArg>({
      query: (queryArg) => ({
        url: `/api/Search/SearchTracks`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSearchSearchPublishers: build.query<GetApiSearchSearchPublishersApiResponse, GetApiSearchSearchPublishersApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchPublishers`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSearchSearchTenants: build.query<GetApiSearchSearchTenantsApiResponse, GetApiSearchSearchTenantsApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchTenants`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSearchSearchClients: build.query<GetApiSearchSearchClientsApiResponse, GetApiSearchSearchClientsApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchClients`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSearchSearchParentLabels: build.query<GetApiSearchSearchParentLabelsApiResponse, GetApiSearchSearchParentLabelsApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchParentLabels`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSearchSearchLabels: build.query<GetApiSearchSearchLabelsApiResponse, GetApiSearchSearchLabelsApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchLabels`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSearchSearchUsers: build.query<GetApiSearchSearchUsersApiResponse, GetApiSearchSearchUsersApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchUsers`, params: { term: queryArg.term, filterUserId: queryArg.filterUserId, count: queryArg.count } }),
    }),
    getApiSearchSearchContributorId: build.query<GetApiSearchSearchContributorIdApiResponse, GetApiSearchSearchContributorIdApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchContributorId`, params: { id: queryArg } }),
    }),
    getApiSearchSearchDdexPartyIds: build.query<GetApiSearchSearchDdexPartyIdsApiResponse, GetApiSearchSearchDdexPartyIdsApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchDdexPartyIds`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSearchSearchAssetTypeToFileFormats: build.query<GetApiSearchSearchAssetTypeToFileFormatsApiResponse, GetApiSearchSearchAssetTypeToFileFormatsApiArg>({
      query: (queryArg) => ({ url: `/api/Search/SearchAssetTypeToFileFormats`, params: { term: queryArg.term, count: queryArg.count } }),
    }),
    getApiSupplierSearchSuppliers: build.query<GetApiSupplierSearchSuppliersApiResponse, GetApiSupplierSearchSuppliersApiArg>({
      query: (queryArg) => ({
        url: `/api/Supplier/SearchSuppliers`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiSupplierGetSuggestedPartTypeDestinationByFormat: build.query<
      GetApiSupplierGetSuggestedPartTypeDestinationByFormatApiResponse,
      GetApiSupplierGetSuggestedPartTypeDestinationByFormatApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Supplier/GetSuggestedPartTypeDestinationByFormat`,
        params: { formatId: queryArg.formatId, platformId: queryArg.platformId },
      }),
    }),
    getApiSupplierGetSupplierKnownPartTypesBySupplierId: build.query<
      GetApiSupplierGetSupplierKnownPartTypesBySupplierIdApiResponse,
      GetApiSupplierGetSupplierKnownPartTypesBySupplierIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Supplier/GetSupplierKnownPartTypes/${queryArg}` }),
    }),
    getApiSupplierGetSupplierBySupplierId: build.query<GetApiSupplierGetSupplierBySupplierIdApiResponse, GetApiSupplierGetSupplierBySupplierIdApiArg>({
      query: (queryArg) => ({ url: `/api/Supplier/GetSupplier/${queryArg}` }),
    }),
    postApiSupplierCreateSupplier: build.mutation<PostApiSupplierCreateSupplierApiResponse, PostApiSupplierCreateSupplierApiArg>({
      query: (queryArg) => ({ url: `/api/Supplier/CreateSupplier`, method: 'POST', body: queryArg }),
    }),
    putApiSupplierUpdateSupplierBySupplierId: build.mutation<
      PutApiSupplierUpdateSupplierBySupplierIdApiResponse,
      PutApiSupplierUpdateSupplierBySupplierIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Supplier/UpdateSupplier/${queryArg.supplierId}`, method: 'PUT', body: queryArg.supplierVm }),
    }),
    deleteApiSupplierDeleteSupplierBySupplierId: build.mutation<
      DeleteApiSupplierDeleteSupplierBySupplierIdApiResponse,
      DeleteApiSupplierDeleteSupplierBySupplierIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Supplier/DeleteSupplier/${queryArg}`, method: 'DELETE' }),
    }),
    getApiSupplierGetSupplierTenantContactEmailsForOrder: build.query<
      GetApiSupplierGetSupplierTenantContactEmailsForOrderApiResponse,
      GetApiSupplierGetSupplierTenantContactEmailsForOrderApiArg
    >({
      query: (queryArg) => ({ url: `/api/Supplier/GetSupplierTenantContactEmailsForOrder`, params: { supplierId: queryArg } }),
    }),
    getApiSupplierDefaultSupplierForTenant: build.query<GetApiSupplierDefaultSupplierForTenantApiResponse, GetApiSupplierDefaultSupplierForTenantApiArg>({
      query: () => ({ url: `/api/Supplier/DefaultSupplierForTenant` }),
    }),
    postApiTagCreateFormatStatusTag: build.mutation<PostApiTagCreateFormatStatusTagApiResponse, PostApiTagCreateFormatStatusTagApiArg>({
      query: (queryArg) => ({ url: `/api/Tag/CreateFormatStatusTag`, method: 'POST', body: queryArg }),
    }),
    putApiTagEditFormatStatusTag: build.mutation<PutApiTagEditFormatStatusTagApiResponse, PutApiTagEditFormatStatusTagApiArg>({
      query: (queryArg) => ({ url: `/api/Tag/EditFormatStatusTag`, method: 'PUT', body: queryArg.tagVm, params: { tagId: queryArg.tagId } }),
    }),
    deleteApiTagDeleteFormatStatusTag: build.mutation<DeleteApiTagDeleteFormatStatusTagApiResponse, DeleteApiTagDeleteFormatStatusTagApiArg>({
      query: (queryArg) => ({ url: `/api/Tag/DeleteFormatStatusTag`, method: 'DELETE', params: { tagId: queryArg } }),
    }),
    postApiTagAddFormatStatusTag: build.mutation<PostApiTagAddFormatStatusTagApiResponse, PostApiTagAddFormatStatusTagApiArg>({
      query: (queryArg) => ({ url: `/api/Tag/AddFormatStatusTag`, method: 'POST', params: { formatId: queryArg.formatId, tagId: queryArg.tagId } }),
    }),
    deleteApiTagRemoveFormatStatusTag: build.mutation<DeleteApiTagRemoveFormatStatusTagApiResponse, DeleteApiTagRemoveFormatStatusTagApiArg>({
      query: (queryArg) => ({ url: `/api/Tag/RemoveFormatStatusTag`, method: 'DELETE', params: { formatId: queryArg.formatId, tagId: queryArg.tagId } }),
    }),
    postApiTagSetFormatStatusTags: build.mutation<PostApiTagSetFormatStatusTagsApiResponse, PostApiTagSetFormatStatusTagsApiArg>({
      query: (queryArg) => ({ url: `/api/Tag/SetFormatStatusTags`, method: 'POST', body: queryArg.body, params: { formatId: queryArg.formatId } }),
    }),
    getApiTestNope: build.query<GetApiTestNopeApiResponse, GetApiTestNopeApiArg>({
      query: () => ({ url: `/api/Test/Nope` }),
    }),
    postApiTestPost: build.mutation<PostApiTestPostApiResponse, PostApiTestPostApiArg>({
      query: (queryArg) => ({ url: `/api/Test/post`, method: 'POST', body: queryArg }),
    }),
    postApiTestPost2: build.mutation<PostApiTestPost2ApiResponse, PostApiTestPost2ApiArg>({
      query: () => ({ url: `/api/Test/post2`, method: 'POST' }),
    }),
    getApiTestPerms: build.query<GetApiTestPermsApiResponse, GetApiTestPermsApiArg>({
      query: () => ({ url: `/api/Test/perms` }),
    }),
    getApiTestWeatherForecast: build.query<GetApiTestWeatherForecastApiResponse, GetApiTestWeatherForecastApiArg>({
      query: () => ({ url: `/api/Test/WeatherForecast` }),
    }),
    getApiTicketGetProductInfoForTicket: build.query<GetApiTicketGetProductInfoForTicketApiResponse, GetApiTicketGetProductInfoForTicketApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/GetProductInfoForTicket`, params: { ticketId: queryArg } }),
    }),
    getApiTicketGetTickets: build.query<GetApiTicketGetTicketsApiResponse, GetApiTicketGetTicketsApiArg>({
      query: (queryArg) => ({
        url: `/api/Ticket/GetTickets`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiTicketGetTicketCategories: build.query<GetApiTicketGetTicketCategoriesApiResponse, GetApiTicketGetTicketCategoriesApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/GetTicketCategories`, params: { partnerId: queryArg.partnerId, productType: queryArg.productType } }),
    }),
    getApiTicketGetRelatedCreatedBy: build.query<GetApiTicketGetRelatedCreatedByApiResponse, GetApiTicketGetRelatedCreatedByApiArg>({
      query: () => ({ url: `/api/Ticket/GetRelatedCreatedBy` }),
    }),
    getApiTicketGetTicketsForProduct: build.query<GetApiTicketGetTicketsForProductApiResponse, GetApiTicketGetTicketsForProductApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/GetTicketsForProduct`, params: { productId: queryArg.productId, productType: queryArg.productType } }),
    }),
    postApiTicketCreateTicket: build.mutation<PostApiTicketCreateTicketApiResponse, PostApiTicketCreateTicketApiArg>({
      query: (queryArg) => ({
        url: `/api/Ticket/CreateTicket`,
        method: 'POST',
        body: queryArg.ticketVm,
        params: { productId: queryArg.productId, productType: queryArg.productType },
      }),
    }),
    putApiTicketUpdateTicketByTicketId: build.mutation<PutApiTicketUpdateTicketByTicketIdApiResponse, PutApiTicketUpdateTicketByTicketIdApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/UpdateTicket/${queryArg.ticketId}`, method: 'PUT', body: queryArg.ticketVm }),
    }),
    getApiTicketGetCommentsByTicketId: build.query<GetApiTicketGetCommentsByTicketIdApiResponse, GetApiTicketGetCommentsByTicketIdApiArg>({
      query: (queryArg) => ({
        url: `/api/Ticket/GetComments/${queryArg.ticketId}`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    postApiTicketAddCommentByTicketId: build.mutation<PostApiTicketAddCommentByTicketIdApiResponse, PostApiTicketAddCommentByTicketIdApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/AddComment/${queryArg.ticketId}`, method: 'POST', body: queryArg.commentVm }),
    }),
    postApiTicketProcessEmailReply: build.mutation<PostApiTicketProcessEmailReplyApiResponse, PostApiTicketProcessEmailReplyApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/ProcessEmailReply`, method: 'POST', body: queryArg }),
    }),
    getApiTicketGetCategoriesOpenClosePerms: build.query<GetApiTicketGetCategoriesOpenClosePermsApiResponse, GetApiTicketGetCategoriesOpenClosePermsApiArg>({
      query: () => ({ url: `/api/Ticket/GetCategoriesOpenClosePerms` }),
    }),
    putApiTicketOptInOutOfTicketByTicketId: build.mutation<PutApiTicketOptInOutOfTicketByTicketIdApiResponse, PutApiTicketOptInOutOfTicketByTicketIdApiArg>({
      query: (queryArg) => ({ url: `/api/Ticket/OptInOutOfTicket/${queryArg.ticketId}`, method: 'PUT', params: { receiveEmails: queryArg.receiveEmails } }),
    }),
    postApiUploadGetUploadPermission: build.mutation<PostApiUploadGetUploadPermissionApiResponse, PostApiUploadGetUploadPermissionApiArg>({
      query: (queryArg) => ({ url: `/api/Upload/GetUploadPermission`, method: 'POST', body: queryArg }),
    }),
    getApiUploadCheckFileExists: build.query<GetApiUploadCheckFileExistsApiResponse, GetApiUploadCheckFileExistsApiArg>({
      query: (queryArg) => ({ url: `/api/Upload/CheckFileExists`, params: { md5: queryArg.md5, quickMd5: queryArg.quickMd5 } }),
    }),
    getApiUploadCheckUploadSession: build.query<GetApiUploadCheckUploadSessionApiResponse, GetApiUploadCheckUploadSessionApiArg>({
      query: (queryArg) => ({ url: `/api/Upload/CheckUploadSession`, params: { token: queryArg } }),
    }),
    getApiUploadGetReportBlobs: build.query<GetApiUploadGetReportBlobsApiResponse, GetApiUploadGetReportBlobsApiArg>({
      query: (queryArg) => ({
        url: `/api/Upload/GetReportBlobs`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
        },
      }),
    }),
    getApiUploadDownloadReport: build.query<GetApiUploadDownloadReportApiResponse, GetApiUploadDownloadReportApiArg>({
      query: (queryArg) => ({ url: `/api/Upload/DownloadReport`, params: { guids: queryArg } }),
    }),
    putApiUserManagementChangePassword: build.mutation<PutApiUserManagementChangePasswordApiResponse, PutApiUserManagementChangePasswordApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/ChangePassword`, method: 'PUT', body: queryArg }),
    }),
    postApiUserManagementRequestPasswordReset: build.mutation<
      PostApiUserManagementRequestPasswordResetApiResponse,
      PostApiUserManagementRequestPasswordResetApiArg
    >({
      query: (queryArg) => ({ url: `/api/UserManagement/RequestPasswordReset`, method: 'POST', body: queryArg }),
    }),
    postApiUserManagementResetPassword: build.mutation<PostApiUserManagementResetPasswordApiResponse, PostApiUserManagementResetPasswordApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/ResetPassword`, method: 'POST', body: queryArg }),
    }),
    postApiUserManagementSendAuthCode: build.mutation<PostApiUserManagementSendAuthCodeApiResponse, PostApiUserManagementSendAuthCodeApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/SendAuthCode`, method: 'POST', body: queryArg }),
    }),
    postApiUserManagementVerifyAuthCode: build.mutation<PostApiUserManagementVerifyAuthCodeApiResponse, PostApiUserManagementVerifyAuthCodeApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/VerifyAuthCode`, method: 'POST', body: queryArg }),
    }),
    postApiUserManagementIsValidEmail: build.mutation<PostApiUserManagementIsValidEmailApiResponse, PostApiUserManagementIsValidEmailApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/IsValidEmail`, method: 'POST', body: queryArg }),
    }),
    putApiUserManagementAdminChangeUserPassword: build.mutation<
      PutApiUserManagementAdminChangeUserPasswordApiResponse,
      PutApiUserManagementAdminChangeUserPasswordApiArg
    >({
      query: (queryArg) => ({ url: `/api/UserManagement/AdminChangeUserPassword`, method: 'PUT', body: queryArg }),
    }),
    postApiUserManagementAddUser: build.mutation<PostApiUserManagementAddUserApiResponse, PostApiUserManagementAddUserApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/AddUser`, method: 'POST', body: queryArg }),
    }),
    postApiUserManagementAddUserToProduct: build.mutation<PostApiUserManagementAddUserToProductApiResponse, PostApiUserManagementAddUserToProductApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/AddUserToProduct`, method: 'POST', body: queryArg }),
    }),
    getApiUserManagementGetUserList: build.query<GetApiUserManagementGetUserListApiResponse, GetApiUserManagementGetUserListApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/GetUserList`, params: { userId: queryArg } }),
    }),
    getApiUserManagementGetAllUserPerms: build.query<GetApiUserManagementGetAllUserPermsApiResponse, GetApiUserManagementGetAllUserPermsApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/GetAllUserPerms`, params: { userId: queryArg } }),
    }),
    getApiUserManagementGetPendingUserList: build.query<GetApiUserManagementGetPendingUserListApiResponse, GetApiUserManagementGetPendingUserListApiArg>({
      query: () => ({ url: `/api/UserManagement/GetPendingUserList` }),
    }),
    getApiUserManagementGetPermSettingsOnBlock: build.query<
      GetApiUserManagementGetPermSettingsOnBlockApiResponse,
      GetApiUserManagementGetPermSettingsOnBlockApiArg
    >({
      query: (queryArg) => ({
        url: `/api/UserManagement/GetPermSettingsOnBlock`,
        params: {
          accessLevelCode: queryArg.accessLevelCode,
          accessLevelServiceId: queryArg.accessLevelServiceId,
          userId: queryArg.userId,
          contributorId: queryArg.contributorId,
        },
      }),
    }),
    postApiUserManagementSavePerms: build.mutation<PostApiUserManagementSavePermsApiResponse, PostApiUserManagementSavePermsApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/SavePerms`, method: 'POST', body: queryArg }),
    }),
    getApiUserManagementGetUserRoles: build.query<GetApiUserManagementGetUserRolesApiResponse, GetApiUserManagementGetUserRolesApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/GetUserRoles`, params: { userId: queryArg } }),
    }),
    postApiUserManagementSetUserRoles: build.mutation<PostApiUserManagementSetUserRolesApiResponse, PostApiUserManagementSetUserRolesApiArg>({
      query: (queryArg) => ({ url: `/api/UserManagement/SetUserRoles`, method: 'POST', body: queryArg.body, params: { userId: queryArg.userId } }),
    }),
    getApiUserManagementGetPermLevels: build.query<GetApiUserManagementGetPermLevelsApiResponse, GetApiUserManagementGetPermLevelsApiArg>({
      query: () => ({ url: `/api/UserManagement/GetPermLevels` }),
    }),
    getApiUserManagementGetUserRoleDescriptions: build.query<
      GetApiUserManagementGetUserRoleDescriptionsApiResponse,
      GetApiUserManagementGetUserRoleDescriptionsApiArg
    >({
      query: () => ({ url: `/api/UserManagement/GetUserRoleDescriptions` }),
    }),
    getApiUserManagementGetServicePermissionDescriptions: build.query<
      GetApiUserManagementGetServicePermissionDescriptionsApiResponse,
      GetApiUserManagementGetServicePermissionDescriptionsApiArg
    >({
      query: () => ({ url: `/api/UserManagement/GetServicePermissionDescriptions` }),
    }),
    getApiUserManagementGetPermissionDependencies: build.query<
      GetApiUserManagementGetPermissionDependenciesApiResponse,
      GetApiUserManagementGetPermissionDependenciesApiArg
    >({
      query: () => ({ url: `/api/UserManagement/GetPermissionDependencies` }),
    }),
    getApiUtilityGetResource: build.query<GetApiUtilityGetResourceApiResponse, GetApiUtilityGetResourceApiArg>({
      query: (queryArg) => ({ url: `/api/Utility/GetResource`, params: { id: queryArg } }),
    }),
    getApiUtilityGetDomainSettings: build.query<GetApiUtilityGetDomainSettingsApiResponse, GetApiUtilityGetDomainSettingsApiArg>({
      query: () => ({ url: `/api/Utility/GetDomainSettings` }),
    }),
    getApiUtilityGetStats: build.query<GetApiUtilityGetStatsApiResponse, GetApiUtilityGetStatsApiArg>({
      query: () => ({ url: `/api/Utility/GetStats` }),
    }),
    getApiUtilityGetPlatformUrlLinkTemplates: build.query<GetApiUtilityGetPlatformUrlLinkTemplatesApiResponse, GetApiUtilityGetPlatformUrlLinkTemplatesApiArg>({
      query: () => ({ url: `/api/Utility/GetPlatformUrlLinkTemplates` }),
    }),
    getApiUtilityGetExchangeRates: build.query<GetApiUtilityGetExchangeRatesApiResponse, GetApiUtilityGetExchangeRatesApiArg>({
      query: (queryArg) => ({ url: `/api/Utility/GetExchangeRates`, params: { maxAge: queryArg.maxAge, currencies: queryArg.currencies } }),
    }),
    getApiUtilityCheckOnTaskSession: build.query<GetApiUtilityCheckOnTaskSessionApiResponse, GetApiUtilityCheckOnTaskSessionApiArg>({
      query: (queryArg) => ({ url: `/api/Utility/CheckOnTaskSession`, params: { id: queryArg } }),
    }),
    getApiUtilityGetAuditorStatus: build.query<GetApiUtilityGetAuditorStatusApiResponse, GetApiUtilityGetAuditorStatusApiArg>({
      query: () => ({ url: `/api/Utility/GetAuditorStatus` }),
    }),
    getApiUtilityGetAutomationTasks: build.query<GetApiUtilityGetAutomationTasksApiResponse, GetApiUtilityGetAutomationTasksApiArg>({
      query: () => ({ url: `/api/Utility/GetAutomationTasks` }),
    }),
    postApiUtilityRefreshAuditorNow: build.mutation<PostApiUtilityRefreshAuditorNowApiResponse, PostApiUtilityRefreshAuditorNowApiArg>({
      query: () => ({ url: `/api/Utility/RefreshAuditorNow`, method: 'POST' }),
    }),
    getApiUtilityGetEnumsForScaffold: build.query<GetApiUtilityGetEnumsForScaffoldApiResponse, GetApiUtilityGetEnumsForScaffoldApiArg>({
      query: () => ({ url: `/api/Utility/GetEnumsForScaffold` }),
    }),
    getApiUtilityEcho: build.query<GetApiUtilityEchoApiResponse, GetApiUtilityEchoApiArg>({
      query: () => ({ url: `/api/Utility/Echo` }),
    }),
    getApiUtilityGetKnowledgeArticles: build.query<GetApiUtilityGetKnowledgeArticlesApiResponse, GetApiUtilityGetKnowledgeArticlesApiArg>({
      query: () => ({ url: `/api/Utility/GetKnowledgeArticles` }),
    }),
    getApiUtilityGetKnowledgeArticle: build.query<GetApiUtilityGetKnowledgeArticleApiResponse, GetApiUtilityGetKnowledgeArticleApiArg>({
      query: (queryArg) => ({ url: `/api/Utility/GetKnowledgeArticle`, params: { articleID: queryArg } }),
    }),
    getApiUtilityGetMemCacheInfo: build.query<GetApiUtilityGetMemCacheInfoApiResponse, GetApiUtilityGetMemCacheInfoApiArg>({
      query: (queryArg) => ({ url: `/api/Utility/GetMemCacheInfo`, params: { limit: queryArg } }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as anthologyApi };
export type GetApiAccountUserKnownAddressesApiResponse = /** status 200 Success */ UspGetUserKnownAddressesResult[];
export type GetApiAccountUserKnownAddressesApiArg = number;
export type GetApiAccountPersonalDetailsApiResponse = /** status 200 Success */ PersonalDetailsVm;
export type GetApiAccountPersonalDetailsApiArg = number;
export type PutApiAccountPersonalDetailsApiResponse = unknown;
export type PutApiAccountPersonalDetailsApiArg = PersonalDetailsVm;
export type PutApiAccountActiveStatusApiResponse = unknown;
export type PutApiAccountActiveStatusApiArg = boolean;
export type GetApiAccountGetPersonalUiSettingsApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiAccountGetPersonalUiSettingsApiArg = void;
export type PutApiAccountSetPersonalUiSettingApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PutApiAccountSetPersonalUiSettingApiArg = KeyValuePairStringString;
export type PostApiAccountLoginAsUserApiResponse = /** status 200 Success */ LoginResponseViewModel;
export type PostApiAccountLoginAsUserApiArg = LoginViewModel;
export type PostApiAccountLoginApiResponse = /** status 200 Success */ LoginResponseViewModel;
export type PostApiAccountLoginApiArg = LoginViewModel;
export type PostApiAccountRequestRefreshTokenApiResponse = /** status 200 Success */ LoginResponseViewModel;
export type PostApiAccountRequestRefreshTokenApiArg = string;
export type GetApiAccountGetUserPermsApiResponse = /** status 200 Success */ UserViewModel;
export type GetApiAccountGetUserPermsApiArg = number;
export type GetApiAccountGetPermsOnEntityApiResponse = /** status 200 Success */ {
  [key: string]: boolean;
};
export type GetApiAccountGetPermsOnEntityApiArg = {
  entityType?: PermissionIdType;
  entityId?: number;
};
export type GetApiAdminGetSyncHealthApiResponse = /** status 200 Success */ CacheSyncState[];
export type GetApiAdminGetSyncHealthApiArg = void;
export type GetApiAdminGetFireWallSettingsApiResponse = /** status 200 Success */ FireWallViewModel;
export type GetApiAdminGetFireWallSettingsApiArg = void;
export type PostApiAdminAddUpdateFireWallItemApiResponse = /** status 200 Success */ FireWallUpdatesViewModel;
export type PostApiAdminAddUpdateFireWallItemApiArg = FireWallViewModel;
export type PostApiAdminDeleteFireWallItemApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiAdminDeleteFireWallItemApiArg = FireWallViewModel;
export type PostApiAdminPushFireWallConfigToAzureApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiAdminPushFireWallConfigToAzureApiArg = void;
export type GetApiAdminWhoIsOnlineApiResponse = /** status 200 Success */ AspNetUser[];
export type GetApiAdminWhoIsOnlineApiArg = void;
export type GetApiAdminGet2FaStatusApiResponse = /** status 200 Success */ ShortTerm2FaTokenViewModel;
export type GetApiAdminGet2FaStatusApiArg = void;
export type PostApiAdminRequestShortTerm2FaCodeApiResponse = /** status 200 Success */ ShortTerm2FaTokenViewModel;
export type PostApiAdminRequestShortTerm2FaCodeApiArg = void;
export type PostApiAdminValidateShortTerm2FaCodeApiResponse = /** status 200 Success */ ShortTerm2FaTokenViewModel;
export type PostApiAdminValidateShortTerm2FaCodeApiArg = KeyValuePairStringString;
export type GetApiAdminListSecretsApiResponse = /** status 200 Success */ CloudSecretString[];
export type GetApiAdminListSecretsApiArg = number;
export type GetApiAdminShowSecretApiResponse = /** status 200 Success */ AcknowledgeAction;
export type GetApiAdminShowSecretApiArg = {
  key?: string;
  tenantid?: number;
};
export type PostApiAdminSaveSecretApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiAdminSaveSecretApiArg = {
  tenantid?: number;
  cloudSecretString: CloudSecretString;
};
export type GetApiAnalyticsInsightsTimelineApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiAnalyticsInsightsTimelineApiArg = {
  dataset?: string;
  dimension?: string;
  subDimension?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
  subLimit?: number;
  clientIds?: string;
  parentLabelIds?: string;
  labelIds?: string;
  artistIds?: string;
  formatIds?: string;
  assetIds?: string;
  royaltyTypes?: string;
  territories?: string;
  platformIds?: string;
  sortBy?: string;
  dateDimension?: string;
  forAllClients?: boolean;
};
export type GetApiAnalyticsInsightsLeaderboardsApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiAnalyticsInsightsLeaderboardsApiArg = {
  dataset?: string;
  dimension?: string;
  subDimension?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
  subLimit?: number;
  clientIds?: string;
  parentLabelIds?: string;
  labelIds?: string;
  artistIds?: string;
  formatIds?: string;
  assetIds?: string;
  royaltyTypes?: string;
  territories?: string;
  platformIds?: string;
  sortBy?: string;
  dateDimension?: string;
  forAllClients?: boolean;
};
export type GetApiAnalyticsClientReportingArticlesSummaryByClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfArticlesSummaryByClientLabel;
export type GetApiAnalyticsClientReportingArticlesSummaryByClientLabelApiArg = string;
export type GetApiAnalyticsClientReportingCmoSummaryRevenueApiResponse = /** status 200 Success */ CompactJsonTableZCodegenTypeTvfCmoSummaryRevenue;
export type GetApiAnalyticsClientReportingCmoSummaryRevenueApiArg = string;
export type GetApiAnalyticsClientReportingCmoSummaryRevenueByClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfCmoSummaryRevenueByClientLabel;
export type GetApiAnalyticsClientReportingCmoSummaryRevenueByClientLabelApiArg = string;
export type GetApiAnalyticsClientReportingDigitalSummaryApiResponse = /** status 200 Success */ CompactJsonTableZCodegenTypeTvfDigitalSummary;
export type GetApiAnalyticsClientReportingDigitalSummaryApiArg = string;
export type GetApiAnalyticsClientReportingDigitalSummaryClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfDigitalSummaryClientLabel;
export type GetApiAnalyticsClientReportingDigitalSummaryClientLabelApiArg = string;
export type GetApiAnalyticsClientReportingDirectSalesSummaryApiResponse = /** status 200 Success */ CompactJsonTableZCodegenTypeTvfDirectSalesSummary;
export type GetApiAnalyticsClientReportingDirectSalesSummaryApiArg = string;
export type GetApiAnalyticsClientReportingDirectSalesSummaryClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfDirectSalesSummaryClientLabel;
export type GetApiAnalyticsClientReportingDirectSalesSummaryClientLabelApiArg = string;
export type GetApiAnalyticsClientReportingOtherSalesSummaryApiResponse = /** status 200 Success */ CompactJsonTableZCodegenTypeTvfOtherSalesSummary;
export type GetApiAnalyticsClientReportingOtherSalesSummaryApiArg = string;
export type GetApiAnalyticsClientReportingOtherSalesSummaryClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfOtherSalesSummaryClientLabel;
export type GetApiAnalyticsClientReportingOtherSalesSummaryClientLabelApiArg = string;
export type GetApiAnalyticsClientReportingPhysicalArticlesSummaryApiResponse = /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalArticlesSummary;
export type GetApiAnalyticsClientReportingPhysicalArticlesSummaryApiArg = string;
export type GetApiAnalyticsClientReportingPhysicalNotificationFormatsUnLinkedAllTimeApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalNotificationFormatsUnLinkedAllTime;
export type GetApiAnalyticsClientReportingPhysicalNotificationFormatsUnLinkedAllTimeApiArg = void;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignClientReportingApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignClientReporting;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignClientReportingApiArg = string;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignSummary;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryApiArg = string;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabel;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelApiArg = string;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelFormatApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabelFormat;
export type GetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelFormatApiArg = string;
export type GetApiAnalyticsClientReportingPhysicalUniversalInvoiceSummaryApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfPhysicalUniversalInvoiceSummary;
export type GetApiAnalyticsClientReportingPhysicalUniversalInvoiceSummaryApiArg = string;
export type GetApiAnalyticsClientReportingRechargesSummaryApiResponse = /** status 200 Success */ CompactJsonTableZCodegenTypeTvfRechargesSummary;
export type GetApiAnalyticsClientReportingRechargesSummaryApiArg = string;
export type GetApiAnalyticsClientReportingRechargesSummaryClientLabelApiResponse =
  /** status 200 Success */ CompactJsonTableZCodegenTypeTvfRechargesSummaryClientLabel;
export type GetApiAnalyticsClientReportingRechargesSummaryClientLabelApiArg = string;
export type GetApiAssetRefreshAssetConflictStatusApiResponse = unknown;
export type GetApiAssetRefreshAssetConflictStatusApiArg = number[];
export type GetApiCatalogueGetFormatIngestionNotesApiResponse = /** status 200 Success */ ProductIngestionIssue[];
export type GetApiCatalogueGetFormatIngestionNotesApiArg = number;
export type GetApiCatalogueExportCatalogueApiResponse = unknown;
export type GetApiCatalogueExportCatalogueApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiCatalogueGetFormatDetailApiResponse = /** status 200 Success */ GetFormatsWithStatusResult;
export type GetApiCatalogueGetFormatDetailApiArg = number;
export type GetApiCatalogueGetRecentlyViewedFormatsApiResponse = /** status 200 Success */ FormatSearchModel[];
export type GetApiCatalogueGetRecentlyViewedFormatsApiArg = number;
export type GetApiCatalogueGetFormatContributorsApiResponse = /** status 200 Success */ UspGetAllFormatContributorsResult[];
export type GetApiCatalogueGetFormatContributorsApiArg = number;
export type GetApiClientReportingListDeletedReportsApiResponse = /** status 200 Success */ CompactJsonTableMetaImportJobs;
export type GetApiClientReportingListDeletedReportsApiArg = string;
export type PostApiClientReportingDeleteFinanceReportApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiClientReportingDeleteFinanceReportApiArg = number[];
export type PostApiClientReportingRecoverDeletedReportApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiClientReportingRecoverDeletedReportApiArg = number[];
export type PostApiClientReportingSaveUpdatedClientMappingApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiClientReportingSaveUpdatedClientMappingApiArg = ReportingFactManualMetaMatchData[];
export type PostApiClientReportingSaveFinanceReportMetaApiResponse = /** status 200 Success */ ReportMetadataSubmission[];
export type PostApiClientReportingSaveFinanceReportMetaApiArg = ReportMetadataSubmission[];
export type PostApiClientReportingSetFinanceReportReadyStateApiResponse = /** status 200 Success */ ReportMetadataReadyStatus[];
export type PostApiClientReportingSetFinanceReportReadyStateApiArg = ReportMetadataReadyStatus[];
export type GetApiClientReportingSearchReportMatchDataApiResponse = /** status 200 Success */ ReportMatchSearchResult[];
export type GetApiClientReportingSearchReportMatchDataApiArg = {
  term?: string;
  count?: number;
};
export type GetApiClientReportingGetReportIngestionStatusApiResponse = /** status 200 Success */ CompactJsonTableReportingQueryReportIngestionStatus;
export type GetApiClientReportingGetReportIngestionStatusApiArg = void;
export type GetApiClientReportingGetPlatformsAcceptingReportUploadsApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiClientReportingGetPlatformsAcceptingReportUploadsApiArg = void;
export type PostApiClientReportingLaunchImportJobApiResponse = /** status 200 Success */ AcknowledgeActionInt64;
export type PostApiClientReportingLaunchImportJobApiArg = ReportUploadSubmission;
export type GetApiClientReportingDigitalPendingReportsApiResponse = /** status 200 Success */ CompactJsonTableReportingQueryWaitingReportStaged;
export type GetApiClientReportingDigitalPendingReportsApiArg = string;
export type GetApiClientReportingReportingSimulateApiResponse = /** status 200 Success */ CompactJsonTableReportingQueryWaitingReportStaged;
export type GetApiClientReportingReportingSimulateApiArg = string;
export type GetApiClientReportingDigitalDataByFileApiResponse = unknown;
export type GetApiClientReportingDigitalDataByFileApiArg = {
  metaId?: number;
  src?: string;
};
export type GetApiClientReportingDigitalUnmatchedDataApiResponse = /** status 200 Success */ CompactJsonTableAnalysisRoyaltiesQueryUnmatchedStagedData;
export type GetApiClientReportingDigitalUnmatchedDataApiArg = void;
export type GetApiClientReportingDigitalBiggestChangesApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiClientReportingDigitalBiggestChangesApiArg = string;
export type GetApiClientReportingDigitalTotalsSheetApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiClientReportingDigitalTotalsSheetApiArg = string;
export type GetApiClientReportingDigitalPlatformProblemSummaryApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiClientReportingDigitalPlatformProblemSummaryApiArg = string;
export type GetApiClientReportingDigitalSalesClientSummaryApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiClientReportingDigitalSalesClientSummaryApiArg = string;
export type GetApiClientReportingDigitalDataByClientApiResponse = unknown;
export type GetApiClientReportingDigitalDataByClientApiArg = {
  clientId?: number;
  monthToReport?: string;
};
export type GetApiClientReportingCmoDataByClientApiResponse = unknown;
export type GetApiClientReportingCmoDataByClientApiArg = {
  clientId?: number;
  monthToReport?: string;
};
export type GetApiClientReportingCmoClientSummaryApiResponse = /** status 200 Success */ CompactJsonTableAnalysisCmoQueryClientReportSummary;
export type GetApiClientReportingCmoClientSummaryApiArg = void;
export type GetApiClientReportingCmoUnmatchedDataApiResponse = /** status 200 Success */ CompactJsonTableAnalysisCmoQueryUnmatchedStagedData;
export type GetApiClientReportingCmoUnmatchedDataApiArg = void;
export type PostApiClientReportingExecuteReportingJobApiResponse = /** status 200 Success */ AcknowledgeActionInt64;
export type PostApiClientReportingExecuteReportingJobApiArg = string;
export type GetApiClientReportingGetReportingStatusApiResponse = /** status 200 Success */ ReportingStatus;
export type GetApiClientReportingGetReportingStatusApiArg = void;
export type GetApiClientReportingPhysicalSummaryApiResponse = /** status 200 Success */ CompactJsonTableAnalysisPhysicalQueryPhysicalSalesSummary;
export type GetApiClientReportingPhysicalSummaryApiArg = string;
export type GetApiClientReportingSuggestedClientPaymentApiResponse = /** status 200 Success */ CompactJsonTableObject;
export type GetApiClientReportingSuggestedClientPaymentApiArg = string;
export type GetApiClientReportingInvoiceSummaryChartApiResponse = /** status 200 Success */ CompactJsonTableReportedQueryLineItemsGraph;
export type GetApiClientReportingInvoiceSummaryChartApiArg = {
  monthToReport?: string;
  clientId?: number;
};
export type GetApiClientReportingClientAgreementSummaryApiResponse = /** status 200 Success */ ReportingQueryAgreementRateSummary;
export type GetApiClientReportingClientAgreementSummaryApiArg = number;
export type GetApiClientReportingDownloadSummaryZipApiResponse = unknown;
export type GetApiClientReportingDownloadSummaryZipApiArg = string;
export type GetApiContributorGetSpotifyArtistIdApiResponse = /** status 200 Success */ AcArtistSearchVm[];
export type GetApiContributorGetSpotifyArtistIdApiArg = string;
export type GetApiContributorFetchAppleArtistByNameApiResponse = /** status 200 Success */ AcArtistSearchVm[];
export type GetApiContributorFetchAppleArtistByNameApiArg = string;
export type GetApiContributorGetExistingAppleArtistsByNameApiResponse = /** status 200 Success */ Artist[];
export type GetApiContributorGetExistingAppleArtistsByNameApiArg = string;
export type PostApiContributorCreateAppleArtistApiResponse = /** status 200 Success */ AcCreateAppleIdVm;
export type PostApiContributorCreateAppleArtistApiArg = AcCreateAppleIdVm;
export type PostApiContributorCreateContributorApiResponse = /** status 200 Success */ number;
export type PostApiContributorCreateContributorApiArg = AcCreateContributorVm;
export type GetApiContributorValidateResourceApiResponse = /** status 200 Success */ AcValidateTrackVm;
export type GetApiContributorValidateResourceApiArg = {
  id?: number;
  text?: string;
};
export type GetApiContributorCanEditAppleArtistInformationApiResponse = /** status 200 Success */ AcBoolResult;
export type GetApiContributorCanEditAppleArtistInformationApiArg = void;
export type GetApiContributorGetContributorGuidsApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiContributorGetContributorGuidsApiArg = number[];
export type PostApiDeliveryQueueJobForPlatformApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiDeliveryQueueJobForPlatformApiArg = XmlBuildVm;
export type PostApiDeliveryStartDeliveryImmediatelyApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiDeliveryStartDeliveryImmediatelyApiArg = XmlBuildVm;
export type PostApiDeliveryIngestXmlFileApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiDeliveryIngestXmlFileApiArg = void;
export type GetApiDeliveryGetFormatDeliveryHistoryApiResponse = /** status 200 Success */ UspGetFormatPlatformListResult[];
export type GetApiDeliveryGetFormatDeliveryHistoryApiArg = number;
export type GetApiDeliveryGetDeliveryJobInfoApiResponse = /** status 200 Success */ GetDeliveryJobDetail[];
export type GetApiDeliveryGetDeliveryJobInfoApiArg = void;
export type PostApiDeliveryGetXmlZipApiResponse = unknown;
export type PostApiDeliveryGetXmlZipApiArg = XmlBuildVm;
export type PostApiDeliveryGetXmlFileApiResponse = unknown;
export type PostApiDeliveryGetXmlFileApiArg = XmlBuildVm;
export type PostApiDeliveryGetXmlJsonApiResponse = /** status 200 Success */ XmlBuildReturnVm;
export type PostApiDeliveryGetXmlJsonApiArg = XmlBuildVm;
export type GetApiDeliverySearchFormatDeliveryInfoApiResponse = /** status 200 Success */ GetDeliveryJobsFormatResult[];
export type GetApiDeliverySearchFormatDeliveryInfoApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiDeliveryGetFormatDeliverySummaryApiResponse = /** status 200 Success */ UspGetDeliveryJobFormatSummaryResult[];
export type GetApiDeliveryGetFormatDeliverySummaryApiArg = void;
export type GetApiDeliveryAddressSearchDeliveryAddressesApiResponse = /** status 200 Success */ DeliveryAddressVm[];
export type GetApiDeliveryAddressSearchDeliveryAddressesApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiDeliveryAddressGetDeliveryAddressByDeliveryAddressIdApiResponse = /** status 200 Success */ DeliveryAddressVm;
export type GetApiDeliveryAddressGetDeliveryAddressByDeliveryAddressIdApiArg = number;
export type PostApiDeliveryAddressCreateDeliveryAddressApiResponse = /** status 200 Success */ number;
export type PostApiDeliveryAddressCreateDeliveryAddressApiArg = DeliveryAddressVm;
export type PutApiDeliveryAddressUpdateDeliveryAddressByDeliveryAddressIdApiResponse = /** status 200 Success */ number;
export type PutApiDeliveryAddressUpdateDeliveryAddressByDeliveryAddressIdApiArg = {
  deliveryAddressId: number;
  deliveryAddressVm: DeliveryAddressVm;
};
export type DeleteApiDeliveryAddressDeleteDeliveryAddressByDeliveryAddressIdApiResponse = unknown;
export type DeleteApiDeliveryAddressDeleteDeliveryAddressByDeliveryAddressIdApiArg = number;
export type GetApiDirectSaleSearchDirectSalesApiResponse = /** status 200 Success */ GetDirectSalesByTenantIdResult[];
export type GetApiDirectSaleSearchDirectSalesApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiDirectSaleGetDirectSaleByDirectSaleIdApiResponse = /** status 200 Success */ DirectSaleVm;
export type GetApiDirectSaleGetDirectSaleByDirectSaleIdApiArg = number;
export type PostApiDirectSaleCreateDirectSaleApiResponse = /** status 200 Success */ number;
export type PostApiDirectSaleCreateDirectSaleApiArg = DirectSaleVm;
export type PutApiDirectSaleUpdateDirectSaleByDirectSaleIdApiResponse = /** status 200 Success */ number;
export type PutApiDirectSaleUpdateDirectSaleByDirectSaleIdApiArg = {
  directSaleId: number;
  directSaleVm: DirectSaleVm;
};
export type DeleteApiDirectSaleDeleteDirectSaleByDirectSaleIdApiResponse = unknown;
export type DeleteApiDirectSaleDeleteDirectSaleByDirectSaleIdApiArg = number;
export type GetApiDirectSaleExportDirectSalesApiResponse = unknown;
export type GetApiDirectSaleExportDirectSalesApiArg = {
  startDate: string;
  endDate: string;
  clientId?: number;
};
export type GetApiDirectSaleGetTenantDefaultCurrencyApiResponse = /** status 200 Success */ number;
export type GetApiDirectSaleGetTenantDefaultCurrencyApiArg = void;
export type GetApiNewsGetNewsApiResponse = /** status 200 Success */ NewsStory[];
export type GetApiNewsGetNewsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiNotificationGetNotificationsApiResponse = /** status 200 Success */ UserNotification[];
export type GetApiNotificationGetNotificationsApiArg = {
  afterTime?: string;
  userId?: number;
};
export type PostApiNotificationMarkNoteAsReadApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiNotificationMarkNoteAsReadApiArg = NoteAction;
export type PostApiNotificationDeleteNotesApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiNotificationDeleteNotesApiArg = NoteAction;
export type PostApiNotificationAnnounceApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiNotificationAnnounceApiArg = UserNotification;
export type GetApiOrderSearchOrdersApiResponse = /** status 200 Success */ GetOrdersByTentantIdResult[];
export type GetApiOrderSearchOrdersApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiOrderGetOrderByOrderIdApiResponse = /** status 200 Success */ OrderVm;
export type GetApiOrderGetOrderByOrderIdApiArg = number;
export type GetApiOrderGetOrderSummaryForFormatByFormatIdApiResponse = /** status 200 Success */ UspGetOrderSummaryForFormatIdResult[];
export type GetApiOrderGetOrderSummaryForFormatByFormatIdApiArg = number;
export type PostApiOrderCreateOrderApiResponse = /** status 200 Success */ number;
export type PostApiOrderCreateOrderApiArg = {
  sendEmail?: boolean;
  orderVm: OrderVm;
};
export type PutApiOrderUpdateOrderByOrderIdApiResponse = unknown;
export type PutApiOrderUpdateOrderByOrderIdApiArg = {
  orderId: number;
  sendEmail?: boolean;
  orderVm: OrderVm;
};
export type PutApiOrderOverwriteOrderByOrderIdApiResponse = unknown;
export type PutApiOrderOverwriteOrderByOrderIdApiArg = {
  orderId: number;
  sendEmail?: boolean;
  orderVm: OrderVm;
};
export type DeleteApiOrderDeleteOrderByOrderIdApiResponse = unknown;
export type DeleteApiOrderDeleteOrderByOrderIdApiArg = number;
export type PutApiOrderCancelOrderByOrderIdApiResponse = unknown;
export type PutApiOrderCancelOrderByOrderIdApiArg = {
  orderId: number;
  sendEmail?: boolean;
};
export type PostApiOrderSendOrderEmailApiResponse = unknown;
export type PostApiOrderSendOrderEmailApiArg = number;
export type GetApiOrderSearchRechargesApiResponse = /** status 200 Success */ GetRechargesByTenantIdResult[];
export type GetApiOrderSearchRechargesApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiOrderSearchOtherSalesApiResponse = /** status 200 Success */ GetRechargesByTenantIdResult[];
export type GetApiOrderSearchOtherSalesApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiOrderGetRechargeByRechargeIdApiResponse = /** status 200 Success */ RechargeVm;
export type GetApiOrderGetRechargeByRechargeIdApiArg = number;
export type PostApiOrderCreateRechargeApiResponse = /** status 200 Success */ number;
export type PostApiOrderCreateRechargeApiArg = RechargeVm;
export type PutApiOrderUpdateRechargeApiResponse = unknown;
export type PutApiOrderUpdateRechargeApiArg = RechargeVm;
export type DeleteApiOrderDeleteRechargeByRechargeIdApiResponse = unknown;
export type DeleteApiOrderDeleteRechargeByRechargeIdApiArg = number;
export type GetApiOrderExportOrdersApiResponse = unknown;
export type GetApiOrderExportOrdersApiArg = {
  startDate: string;
  endDate: string;
  clientId?: number;
};
export type GetApiOrderExportRechargesApiResponse = unknown;
export type GetApiOrderExportRechargesApiArg = {
  category: number;
  startDate: string;
  endDate: string;
  clientId?: number;
};
export type GetByPartTypeIdApiResponse = /** status 200 Success */ PartTypeVm;
export type GetByPartTypeIdApiArg = number;
export type PutByPartTypeIdApiResponse = /** status 200 Success */ number;
export type PutByPartTypeIdApiArg = {
  partTypeId: number;
  description?: string;
  partTypeCategoryLinkId: number;
  numberOfItems?: number;
  carriesAudio?: boolean;
};
export type DeleteByPartTypeIdApiResponse = unknown;
export type DeleteByPartTypeIdApiArg = number;
export type GetApiPhysicalStockGetPhysicalStockSummaryLatestApiResponse = /** status 200 Success */ CompactJsonTableTrendsPhysicalStockSummaryLatest;
export type GetApiPhysicalStockGetPhysicalStockSummaryLatestApiArg = void;
export type GetApiPhysicalStockGetPhysicalStockReceivedSummaryApiResponse = /** status 200 Success */ CompactJsonTableTrendsPhysicalStockReceivedSummary;
export type GetApiPhysicalStockGetPhysicalStockReceivedSummaryApiArg = void;
export type GetApiPlatformGetRatesForPlatformExclusivesApiResponse = /** status 200 Success */ UspGetRatesForPlatformExclusivesResult[];
export type GetApiPlatformGetRatesForPlatformExclusivesApiArg = number;
export type PostApiPlatformAddDefaultPlatformToClientsParentLabelsApiResponse = /** status 200 Success */ number;
export type PostApiPlatformAddDefaultPlatformToClientsParentLabelsApiArg = number;
export type GetApiPlatformPlatformsApiResponse = /** status 200 Success */ PlatformVm[];
export type GetApiPlatformPlatformsApiArg = void;
export type GetApiPlatformSearchPlatformsApiResponse = /** status 200 Success */ UspGetPlatformsBasicByUserAndTenantIdResult[];
export type GetApiPlatformSearchPlatformsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiPlatformGetPlatformByPlatformIdApiResponse = /** status 200 Success */ PlatformVm;
export type GetApiPlatformGetPlatformByPlatformIdApiArg = {
  platformId: number;
  withSummary?: boolean;
};
export type PostApiPlatformCreatePlatformApiResponse = /** status 200 Success */ number;
export type PostApiPlatformCreatePlatformApiArg = PlatformVm;
export type PutApiPlatformUpdatePlatformByPlatformIdApiResponse = unknown;
export type PutApiPlatformUpdatePlatformByPlatformIdApiArg = {
  platformId: number;
  platformVm: PlatformVm;
};
export type GetApiPlatformSearchPlatformDeliveryGroupsApiResponse = /** status 200 Success */ PlatformDeliveryGroupSearchVm[];
export type GetApiPlatformSearchPlatformDeliveryGroupsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiPlatformDeliveryGroupsForPlatformByPlatformIdApiResponse = /** status 200 Success */ PlatformDeliveryGroupSearchVm[];
export type GetApiPlatformDeliveryGroupsForPlatformByPlatformIdApiArg = number;
export type GetApiPlatformGetPlatformDeliveryGroupByGroupIdApiResponse = /** status 200 Success */ PlatformDeliveryGroupVm;
export type GetApiPlatformGetPlatformDeliveryGroupByGroupIdApiArg = number;
export type PostApiPlatformCreatePlatformDeliveryGroupApiResponse = /** status 200 Success */ number;
export type PostApiPlatformCreatePlatformDeliveryGroupApiArg = {
  platformId?: number;
  platformDeliveryGroupVm: PlatformDeliveryGroupVm;
};
export type PutApiPlatformUpdatePlatformDeliveryGroupByGroupIdApiResponse = unknown;
export type PutApiPlatformUpdatePlatformDeliveryGroupByGroupIdApiArg = {
  groupId: number;
  platformDeliveryGroupVm: PlatformDeliveryGroupVm;
};
export type DeleteApiPlatformDeletePlatformDeliveryGroupByGroupIdApiResponse = unknown;
export type DeleteApiPlatformDeletePlatformDeliveryGroupByGroupIdApiArg = number;
export type GetApiPlatformSearchPlatformDeliveryDetailsApiResponse = /** status 200 Success */ PlatformDeliveryDetailsSearchVm[];
export type GetApiPlatformSearchPlatformDeliveryDetailsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiPlatformGetPlatformDeliveryDetailsByDetailsIdApiResponse = /** status 200 Success */ PlatformDeliveryDetailsVm;
export type GetApiPlatformGetPlatformDeliveryDetailsByDetailsIdApiArg = number;
export type PostApiPlatformCreatePlatformDeliveryDetailsApiResponse = /** status 200 Success */ number;
export type PostApiPlatformCreatePlatformDeliveryDetailsApiArg = {
  groupId?: number;
  platformDeliveryDetailsVm: PlatformDeliveryDetailsVm;
};
export type PutApiPlatformUpdatePlatformDeliveryDetailsByDetailsIdApiResponse = unknown;
export type PutApiPlatformUpdatePlatformDeliveryDetailsByDetailsIdApiArg = {
  detailsId: number;
  platformDeliveryDetailsVm: PlatformDeliveryDetailsVm;
};
export type DeleteApiPlatformDeletePlatformDeliveryDetailsByDetailsIdApiResponse = unknown;
export type DeleteApiPlatformDeletePlatformDeliveryDetailsByDetailsIdApiArg = number;
export type PutApiPlatformUpdateDefaultTenantPlatformsApiResponse = unknown;
export type PutApiPlatformUpdateDefaultTenantPlatformsApiArg = number[];
export type PostApiPlatformCreatePlatformTenantDeliveryDetailsApiResponse = unknown;
export type PostApiPlatformCreatePlatformTenantDeliveryDetailsApiArg = {
  groupId?: number;
  platformTenantDeliveryDetailsVm: PlatformTenantDeliveryDetailsVm;
};
export type DeleteApiPlatformDeletePlatformTenantDeliveryDetailsByDetailsIdApiResponse = unknown;
export type DeleteApiPlatformDeletePlatformTenantDeliveryDetailsByDetailsIdApiArg = {
  deliveryDetailsId?: number;
  detailsId: string;
};
export type PostApiProductDDexUrlTriggerApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiProductDDexUrlTriggerApiArg = void;
export type GetApiProductGetAssetListApiResponse = /** status 200 Success */ AcAssetInfoVm[];
export type GetApiProductGetAssetListApiArg = number;
export type GetApiProductInitCacheApiResponse = unknown;
export type GetApiProductInitCacheApiArg = void;
export type GetApiProductGetConfigDataApiResponse = /** status 200 Success */ AcConfigVm;
export type GetApiProductGetConfigDataApiArg = number;
export type PostApiProductGenerateMetaDataIdApiResponse = unknown;
export type PostApiProductGenerateMetaDataIdApiArg = AcGenerateMetaDataVm;
export type GetApiProductGetPhysicalFormatListMappingApiResponse = /** status 200 Success */ AcPhysicalFormatMappingVm[];
export type GetApiProductGetPhysicalFormatListMappingApiArg = void;
export type GetApiProductGetArtistSuggestionsApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiProductGetArtistSuggestionsApiArg = number;
export type GetApiProductRelatedFormatsApiResponse = /** status 200 Success */ FormatSearchModel[];
export type GetApiProductRelatedFormatsApiArg = number;
export type GetApiProductSingleFormatApiResponse = /** status 200 Success */ FormatSearchModel;
export type GetApiProductSingleFormatApiArg = number;
export type GetApiProductGetPlatformIdentifiersForFormatByIdApiResponse = /** status 200 Success */ PlatformIdentifierVm[];
export type GetApiProductGetPlatformIdentifiersForFormatByIdApiArg = number;
export type GetApiProductFormatByIdApiResponse = /** status 200 Success */ AcBasicVm;
export type GetApiProductFormatByIdApiArg = number;
export type PostApiProductCreateFormatApiResponse = /** status 200 Success */ AcBasicVm;
export type PostApiProductCreateFormatApiArg = AcBasicVm;
export type PostApiProductUpdateFormatApiResponse = /** status 200 Success */ AcBasicVm;
export type PostApiProductUpdateFormatApiArg = AcBasicVm;
export type PostApiProductCopyTracksFromRelatedFormatApiResponse = unknown;
export type PostApiProductCopyTracksFromRelatedFormatApiArg = {
  currentFormatId?: number;
  relatedFormatId?: number;
};
export type GetApiProductIsValidCatOrUpcApiResponse = unknown;
export type GetApiProductIsValidCatOrUpcApiArg = {
  formatId?: number;
  val?: string;
  type?: string;
};
export type GetApiProductGetClientInfoApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiProductGetClientInfoApiArg = void;
export type PostApiProductNewLabelApiResponse = /** status 200 Success */ SelectListVm;
export type PostApiProductNewLabelApiArg = AcLabelVm;
export type GetApiProductGetLabelsApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiProductGetLabelsApiArg = void;
export type GetApiProductGetArtWorkSpecsApiResponse = /** status 200 Success */ AcArtistWorkSpec[];
export type GetApiProductGetArtWorkSpecsApiArg = void;
export type PostApiProductCreateImmersiveApiResponse = /** status 200 Success */ number;
export type PostApiProductCreateImmersiveApiArg = AcImmerVm;
export type GetApiProductReleaseByIdApiResponse = /** status 200 Success */ AcReleaseVm;
export type GetApiProductReleaseByIdApiArg = number;
export type GetApiProductGetReleaseConfigApiResponse = /** status 200 Success */ AcReleaseConfigVm;
export type GetApiProductGetReleaseConfigApiArg = number;
export type GetApiProductFormatPriceTierMappingListApiResponse = /** status 200 Success */ UspGetPriceTiersForFormatsResult[];
export type GetApiProductFormatPriceTierMappingListApiArg = number;
export type PostApiProductReleaseApiResponse = /** status 200 Success */ AcReleaseVm;
export type PostApiProductReleaseApiArg = AcReleaseVm;
export type GetApiProductPlatformDspAndTerritoryExclusivesApiResponse = /** status 200 Success */ PlatformDspAndTerritoryExcsVm;
export type GetApiProductPlatformDspAndTerritoryExclusivesApiArg = number;
export type GetApiProductGetTimeZoneInfoApiResponse = /** status 200 Success */ CityTimeZone[];
export type GetApiProductGetTimeZoneInfoApiArg = void;
export type GetApiProductDealsByIdApiResponse = /** status 200 Success */ AcDealsVm;
export type GetApiProductDealsByIdApiArg = number;
export type PostApiProductDealsApiResponse = /** status 200 Success */ AcDealsVm;
export type PostApiProductDealsApiArg = AcDealsVm;
export type PostApiProductCreatePublisherApiResponse = /** status 200 Success */ AcPublisherVm;
export type PostApiProductCreatePublisherApiArg = AcPublisherVm;
export type GetApiProductConfirmPublisherSelectedByNameApiResponse = /** status 200 Success */ AcPublisherVm[];
export type GetApiProductConfirmPublisherSelectedByNameApiArg = string;
export type GetApiProductTracksApiResponse = /** status 200 Success */ AcTrackOverviewVm;
export type GetApiProductTracksApiArg = number;
export type GetApiProductTrackListApiResponse = /** status 200 Success */ UspGetTrackDisplayDetailsForFormatResult[];
export type GetApiProductTrackListApiArg = {
  formatId?: number;
  assetId?: number;
};
export type GetApiProductTrackApiResponse = /** status 200 Success */ AcTrackFormVm;
export type GetApiProductTrackApiArg = {
  formatId?: number;
  assetId?: number;
};
export type PostApiProductTrackApiResponse = /** status 200 Success */ AcTrackFormVm;
export type PostApiProductTrackApiArg = AcTrackFormVm;
export type GetApiProductGetTrackListDetailedApiResponse = /** status 200 Success */ AcTrackFormVm[];
export type GetApiProductGetTrackListDetailedApiArg = number;
export type GetApiProductGetTrackCreditsApiResponse = /** status 200 Success */ AcTrackCreditVm[];
export type GetApiProductGetTrackCreditsApiArg = {
  formatId?: number;
  assetId?: number;
};
export type GetApiProductGetAppleEngineerApiResponse = /** status 200 Success */ AcTrackCreditVm[];
export type GetApiProductGetAppleEngineerApiArg = {
  formatId?: number;
  assetId?: number;
};
export type GetApiProductGetAssetInfoIdAssetApiResponse = /** status 200 Success */ AssetSearchModel;
export type GetApiProductGetAssetInfoIdAssetApiArg = number;
export type GetApiProductGetIsrcAssetApiResponse = /** status 200 Success */ AssetSearchModel;
export type GetApiProductGetIsrcAssetApiArg = string;
export type PostApiProductUpdateTrackOrderApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiProductUpdateTrackOrderApiArg = AcReOrderVm;
export type PostApiProductValidateSelectedTrackApiResponse = /** status 200 Success */ AcValidateTrackVm;
export type PostApiProductValidateSelectedTrackApiArg = AcAddTrackVm;
export type PostApiProductAddTrackToFormatApiResponse = /** status 200 Success */ AcAddTrackVm;
export type PostApiProductAddTrackToFormatApiArg = AcAddTrackVm;
export type PostApiProductDeleteFormatTrackApiResponse = /** status 200 Success */ AcValidateTrackVm;
export type PostApiProductDeleteFormatTrackApiArg = AcDeleteTrack;
export type GetApiProductIsValidIsrcApiResponse = unknown;
export type GetApiProductIsValidIsrcApiArg = {
  assetId?: number;
  isrc?: string;
};
export type GetApiProductIsValidExistingIsrcApiResponse = unknown;
export type GetApiProductIsValidExistingIsrcApiArg = string;
export type GetApiProductIsValidExistingCatApiResponse = unknown;
export type GetApiProductIsValidExistingCatApiArg = string;
export type GetApiProductGetContributorInstrumentsApiResponse = /** status 200 Success */ UspGetContributorKnownInstrumentsResult[];
export type GetApiProductGetContributorInstrumentsApiArg = number;
export type GetApiProductSuggestedContributorRolesApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiProductSuggestedContributorRolesApiArg = number;
export type GetApiProductGetArtistContributorsApiResponse = /** status 200 Success */ UspGetContributorKnownPerformersResult[];
export type GetApiProductGetArtistContributorsApiArg = number;
export type GetApiProductGetWriterPublishersApiResponse = /** status 200 Success */ UspGetContributorKnownPublishersResult[];
export type GetApiProductGetWriterPublishersApiArg = number;
export type GetApiProductGetMeadListApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiProductGetMeadListApiArg = void;
export type GetApiProductGetAssetDetailsApiResponse = /** status 200 Success */ AcAssetDetail;
export type GetApiProductGetAssetDetailsApiArg = number;
export type GetApiProductExtrasByIdApiResponse = /** status 200 Success */ AcExtrasVm;
export type GetApiProductExtrasByIdApiArg = number;
export type PostApiProductExtrasApiResponse = /** status 200 Success */ AcExtrasVm;
export type PostApiProductExtrasApiArg = AcExtrasVm;
export type GetApiProductExtrasConfigByIdApiResponse = /** status 200 Success */ AcExtrasConfigVm;
export type GetApiProductExtrasConfigByIdApiArg = number;
export type GetApiProductReviewByIdApiResponse = /** status 200 Success */ AcReviewVm;
export type GetApiProductReviewByIdApiArg = number;
export type GetApiProductGetApprovalStatesByIdApiResponse = /** status 200 Success */ AcValidationStateVm;
export type GetApiProductGetApprovalStatesByIdApiArg = number;
export type GetApiProductIsFormatValidByIdApiResponse = /** status 200 Success */ AcValidateVm;
export type GetApiProductIsFormatValidByIdApiArg = number;
export type GetApiProductIsFormatCmoValidByIdApiResponse = /** status 200 Success */ AcValidateVm;
export type GetApiProductIsFormatCmoValidByIdApiArg = number;
export type GetApiProductIsValidAllByIdApiResponse = /** status 200 Success */ AcValidateVm;
export type GetApiProductIsValidAllByIdApiArg = number;
export type PostApiProductApproveApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiProductApproveApiArg = AcApproveVm;
export type PostApiProductRequestClientApprovalApiResponse = unknown;
export type PostApiProductRequestClientApprovalApiArg = number;
export type PostApiProductValidatePlatformsApiResponse = /** status 200 Success */ AcValidateVm;
export type PostApiProductValidatePlatformsApiArg = number;
export type PostApiProductValidateCmosApiResponse = /** status 200 Success */ AcValidateVm;
export type PostApiProductValidateCmosApiArg = number;
export type PostApiProductValidateAndQueueJobsForFormatApiResponse = /** status 200 Success */ AcQueueDeliveryVm;
export type PostApiProductValidateAndQueueJobsForFormatApiArg = {
  id?: number;
  deliveryTypeId?: number;
};
export type GetApiProductIsApprovedOrQueuedByIdApiResponse = /** status 200 Success */ AcApprovedOrValidated;
export type GetApiProductIsApprovedOrQueuedByIdApiArg = number;
export type GetApiProfileGetLastReportedDateApiResponse = /** status 200 Success */ string;
export type GetApiProfileGetLastReportedDateApiArg = void;
export type GetApiProfileClientDefaultPlatformDealPoliciesByClientIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformDealPoliciesByProfileResult[];
export type GetApiProfileClientDefaultPlatformDealPoliciesByClientIdApiArg = number;
export type GetApiProfileClientDefaultPlatformsByClientIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformsByProfileResult[];
export type GetApiProfileClientDefaultPlatformsByClientIdApiArg = number;
export type GetApiProfileClientDefaultPlatformsAvailableByClientIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformsAvailableByProfileResult[];
export type GetApiProfileClientDefaultPlatformsAvailableByClientIdApiArg = number;
export type GetApiProfileClientDefaultTerritoriesByClientIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformDealPoliciesByProfileResult[];
export type GetApiProfileClientDefaultTerritoriesByClientIdApiArg = number;
export type GetApiProfileClientDefaultPricesByClientIdApiResponse = /** status 200 Success */ UspGetDefaultPricesByProfileResult[];
export type GetApiProfileClientDefaultPricesByClientIdApiArg = number;
export type PostApiProfileCreateClientApiResponse = /** status 200 Success */ number;
export type PostApiProfileCreateClientApiArg = {
  tenantId?: number;
  clientName?: string;
};
export type PostApiProfileCreateClientAgreementApiResponse = unknown;
export type PostApiProfileCreateClientAgreementApiArg = CreateClientAgreementVm;
export type PostApiProfileCreatePaymentAccountByClientIdApiResponse = /** status 200 Success */ number;
export type PostApiProfileCreatePaymentAccountByClientIdApiArg = {
  clientId: number;
  paymentAccountVm: PaymentAccountVm;
};
export type GetApiProfileGetClientBasicInfoByClientIdApiResponse = /** status 200 Success */ ClientBasicInfoVm;
export type GetApiProfileGetClientBasicInfoByClientIdApiArg = number;
export type GetApiProfileGetClientTenantTeamMembersByClientIdApiResponse = /** status 200 Success */ ClientTenantTeamMemberVm[];
export type GetApiProfileGetClientTenantTeamMembersByClientIdApiArg = number;
export type GetApiProfileGetTenantTeamMembersForClientContactsByTenantIdApiResponse = /** status 200 Success */ ClientTenantTeamMemberVm[];
export type GetApiProfileGetTenantTeamMembersForClientContactsByTenantIdApiArg = number;
export type GetApiProfileGetClientDefaultsByClientIdApiResponse = /** status 200 Success */ ClientDefaultsVm;
export type GetApiProfileGetClientDefaultsByClientIdApiArg = number;
export type GetApiProfileGetDefaultsPlatformsApiResponse = /** status 200 Success */ PlatformToFormatTypeVm[];
export type GetApiProfileGetDefaultsPlatformsApiArg = void;
export type GetApiProfileGetClientSaasProductsByClientIdApiResponse = /** status 200 Success */ number[];
export type GetApiProfileGetClientSaasProductsByClientIdApiArg = number;
export type GetApiProfileGetClientDomainCustomisationByClientIdApiResponse = /** status 200 Success */ DomainCustomisationVm;
export type GetApiProfileGetClientDomainCustomisationByClientIdApiArg = number;
export type GetApiProfileGetClientAutoAddNewPlatformsByClientIdApiResponse = /** status 200 Success */ number[];
export type GetApiProfileGetClientAutoAddNewPlatformsByClientIdApiArg = number;
export type GetApiProfileGetClientResourcesByClientIdApiResponse = /** status 200 Success */ ResourceVm[];
export type GetApiProfileGetClientResourcesByClientIdApiArg = number;
export type GetApiProfileGetClientAgreementsByClientIdApiResponse = /** status 200 Success */ ClientAgreementVm[];
export type GetApiProfileGetClientAgreementsByClientIdApiArg = number;
export type GetApiProfileGetPaymentAccountsByClientIdApiResponse = /** status 200 Success */ PaymentAccountVm[];
export type GetApiProfileGetPaymentAccountsByClientIdApiArg = number;
export type GetApiProfileRelatedProfilesByClientIdApiResponse = /** status 200 Success */ ParentLabelSearchVm[];
export type GetApiProfileRelatedProfilesByClientIdApiArg = number;
export type PutApiProfileUpdateClientByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientByClientIdApiArg = {
  clientId: number;
  clientVm: ClientVm;
};
export type PutApiProfileUpdateClientBasicInfoByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientBasicInfoByClientIdApiArg = {
  clientId: number;
  clientBasicInfoVm: ClientBasicInfoVm;
};
export type PutApiProfileUpdateClientTenantTeamMembersByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientTenantTeamMembersByClientIdApiArg = {
  clientId: number;
  body: ClientTenantTeamMemberVm[];
};
export type PutApiProfileUpdateClientDomainCustomisationByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientDomainCustomisationByClientIdApiArg = {
  clientId: number;
  domainCustomisationVm: DomainCustomisationVm;
};
export type PutApiProfileUpdateClientAgreementByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientAgreementByClientIdApiArg = {
  clientId: number;
  clientAgreementVm: ClientAgreementVm;
};
export type PutApiProfileDiscontinueClientAgreementByClientIdApiResponse = unknown;
export type PutApiProfileDiscontinueClientAgreementByClientIdApiArg = {
  clientId: number;
  agreementId?: number;
};
export type DeleteApiProfileDeleteClientAgreementByClientIdApiResponse = unknown;
export type DeleteApiProfileDeleteClientAgreementByClientIdApiArg = {
  clientId: number;
  agreementId?: number;
};
export type PutApiProfileUpdateClientSaasProductsByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientSaasProductsByClientIdApiArg = {
  clientId: number;
  body: number[];
};
export type PutApiProfileUpdateClientDefaultsByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientDefaultsByClientIdApiArg = {
  clientId: number;
  clientDefaultsVm: ClientDefaultsVm;
};
export type PutApiProfileUpdateClientResourcesByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientResourcesByClientIdApiArg = {
  clientId: number;
  body: ResourceVm[];
};
export type DeleteApiProfileDeleteClientByClientIdApiResponse = unknown;
export type DeleteApiProfileDeleteClientByClientIdApiArg = number;
export type DeleteApiProfileRemoveTeamMemberByClientIdApiResponse = unknown;
export type DeleteApiProfileRemoveTeamMemberByClientIdApiArg = {
  clientId: number;
  teamMemberId?: number;
};
export type PutApiProfileUpdateClientAutoAddNewPlatformsByClientIdApiResponse = unknown;
export type PutApiProfileUpdateClientAutoAddNewPlatformsByClientIdApiArg = {
  clientId: number;
  body: number[];
};
export type GetApiProfileSearchClientsApiResponse = /** status 200 Success */ ClientSearchVm[];
export type GetApiProfileSearchClientsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiProfileGetClientUsersByClientIdApiResponse = /** status 200 Success */ GetUserAccessListVisibleOnlyResult[];
export type GetApiProfileGetClientUsersByClientIdApiArg = number;
export type GetApiProfileGetFtpAccountsByClientIdApiResponse = /** status 200 Success */ ClientFtpAccount[];
export type GetApiProfileGetFtpAccountsByClientIdApiArg = number;
export type PostApiProfileSaveFtpAccountApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiProfileSaveFtpAccountApiArg = ClientFtpAccount;
export type DeleteApiProfileDeleteFtpAccountApiResponse = /** status 200 Success */ AcknowledgeAction;
export type DeleteApiProfileDeleteFtpAccountApiArg = ClientFtpAccount;
export type GetApiProfileParentLabelDefaultsPlatformDealPoliciesByParentLabelIdApiResponse =
  /** status 200 Success */ UspGetDefaultPlatformDealPoliciesByProfileResult[];
export type GetApiProfileParentLabelDefaultsPlatformDealPoliciesByParentLabelIdApiArg = number;
export type GetApiProfileParentLabelDefaultsPlatformsByParentLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformsByProfileResult[];
export type GetApiProfileParentLabelDefaultsPlatformsByParentLabelIdApiArg = number;
export type GetApiProfileParentLabelDefaultPlatformsAvailableByParentLabelIdApiResponse =
  /** status 200 Success */ UspGetDefaultPlatformsAvailableByProfileResult[];
export type GetApiProfileParentLabelDefaultPlatformsAvailableByParentLabelIdApiArg = number;
export type GetApiProfileParentLabelDefaultsTerritoriesByParentLabelIdApiResponse =
  /** status 200 Success */ UspGetDefaultPlatformDealPoliciesByProfileResult[];
export type GetApiProfileParentLabelDefaultsTerritoriesByParentLabelIdApiArg = number;
export type GetApiProfileParentLabelDefaultsPricesByParentLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPricesByProfileResult[];
export type GetApiProfileParentLabelDefaultsPricesByParentLabelIdApiArg = number;
export type GetApiProfileSearchParentLabelsApiResponse = /** status 200 Success */ ParentLabelSearchVm[];
export type GetApiProfileSearchParentLabelsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type PostApiProfileCreateParentLabelApiResponse = /** status 200 Success */ number;
export type PostApiProfileCreateParentLabelApiArg = {
  clientId?: number;
  parentLabelName?: string;
};
export type GetApiProfileGetParentLabelBasicInfoByParentLabelIdApiResponse = /** status 200 Success */ ParentLabelBasicInfoVm;
export type GetApiProfileGetParentLabelBasicInfoByParentLabelIdApiArg = number;
export type GetApiProfileGetParentLabelDefaultsByParentLabelIdApiResponse = /** status 200 Success */ DefaultsVm;
export type GetApiProfileGetParentLabelDefaultsByParentLabelIdApiArg = number;
export type GetApiProfileGetParentLabelResourcesByParentLabelIdApiResponse = /** status 200 Success */ ResourceVm[];
export type GetApiProfileGetParentLabelResourcesByParentLabelIdApiArg = number;
export type GetApiProfileGetParentLabelAutoAddNewPlatformsByParentLabelIdApiResponse = /** status 200 Success */ number[];
export type GetApiProfileGetParentLabelAutoAddNewPlatformsByParentLabelIdApiArg = number;
export type GetApiProfileGetParentLabelRelatedProfilesByParentLabelIdApiResponse = /** status 200 Success */ ParentLabelSearchVm[];
export type GetApiProfileGetParentLabelRelatedProfilesByParentLabelIdApiArg = number;
export type GetApiProfileGetParentLabelProductsByParentLabelIdApiResponse = /** status 200 Success */ FormatSearchModel[];
export type GetApiProfileGetParentLabelProductsByParentLabelIdApiArg = number;
export type PutApiProfileUpdateParentLabelByParentLabelIdApiResponse = unknown;
export type PutApiProfileUpdateParentLabelByParentLabelIdApiArg = {
  parentLabelId: number;
  parentLabelVm: ParentLabelVm;
};
export type PutApiProfileUpdateParentLabelBasicInfoByParentLabelIdApiResponse = unknown;
export type PutApiProfileUpdateParentLabelBasicInfoByParentLabelIdApiArg = {
  parentLabelId: number;
  parentLabelBasicInfoVm: ParentLabelBasicInfoVm;
};
export type PutApiProfileUpdateParentLabelDefaultsByParentLabelIdApiResponse = unknown;
export type PutApiProfileUpdateParentLabelDefaultsByParentLabelIdApiArg = {
  parentLabelId: number;
  labelDefaultsVm: LabelDefaultsVm;
};
export type PutApiProfileUpdateParentLabelResourcesByParentLabelIdApiResponse = unknown;
export type PutApiProfileUpdateParentLabelResourcesByParentLabelIdApiArg = {
  parentLabelId: number;
  body: ResourceVm[];
};
export type PutApiProfileUpdateParentLabelAutoAddNewPlatformsByParentLabelIdApiResponse = unknown;
export type PutApiProfileUpdateParentLabelAutoAddNewPlatformsByParentLabelIdApiArg = {
  parentLabelId: number;
  body: number[];
};
export type DeleteApiProfileDeleteParentLabelByParentLabelIdApiResponse = unknown;
export type DeleteApiProfileDeleteParentLabelByParentLabelIdApiArg = number;
export type GetApiProfileGetParentLabelUsersByParentLabelIdApiResponse = /** status 200 Success */ GetUserAccessListVisibleOnlyResult[];
export type GetApiProfileGetParentLabelUsersByParentLabelIdApiArg = number;
export type GetApiProfileLabelDefaultsPlatformDealPoliciesByLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformDealPoliciesByProfileResult[];
export type GetApiProfileLabelDefaultsPlatformDealPoliciesByLabelIdApiArg = number;
export type GetApiProfileLabelDefaultsPlatformsByLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformsByProfileResult[];
export type GetApiProfileLabelDefaultsPlatformsByLabelIdApiArg = number;
export type GetApiProfileLabelDefaultPlatformsAvailableByLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformsAvailableByProfileResult[];
export type GetApiProfileLabelDefaultPlatformsAvailableByLabelIdApiArg = number;
export type GetApiProfileLabelDefaultsTerritoriesByLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPlatformDealPoliciesByProfileResult[];
export type GetApiProfileLabelDefaultsTerritoriesByLabelIdApiArg = number;
export type GetApiProfileLabelDefaultsPricesByLabelIdApiResponse = /** status 200 Success */ UspGetDefaultPricesByProfileResult[];
export type GetApiProfileLabelDefaultsPricesByLabelIdApiArg = number;
export type GetApiProfileSearchProfileLabelsApiResponse = /** status 200 Success */ LabelSearchVm[];
export type GetApiProfileSearchProfileLabelsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type PostApiProfileCreateLabelApiResponse = /** status 200 Success */ number;
export type PostApiProfileCreateLabelApiArg = {
  parentLabelId?: number;
  labelName?: string;
};
export type GetApiProfileGetLabelBasicInfoByLabelIdApiResponse = /** status 200 Success */ LabelBasicInfoVm;
export type GetApiProfileGetLabelBasicInfoByLabelIdApiArg = number;
export type GetApiProfileGetLabelDefaultsByLabelIdApiResponse = /** status 200 Success */ LabelDefaultsVm;
export type GetApiProfileGetLabelDefaultsByLabelIdApiArg = number;
export type GetApiProfileGetLabelResourcesByLabelIdApiResponse = /** status 200 Success */ ResourceVm[];
export type GetApiProfileGetLabelResourcesByLabelIdApiArg = number;
export type GetApiProfileGetLabelRelatedProfilesByLabelIdApiResponse = /** status 200 Success */ LabelSearchVm[];
export type GetApiProfileGetLabelRelatedProfilesByLabelIdApiArg = number;
export type GetApiProfileGetLabelProductsByLabelIdApiResponse = /** status 200 Success */ FormatSearchModel[];
export type GetApiProfileGetLabelProductsByLabelIdApiArg = number;
export type PutApiProfileUpdateLabelByLabelIdApiResponse = unknown;
export type PutApiProfileUpdateLabelByLabelIdApiArg = {
  labelId: number;
  labelVm: LabelVm;
};
export type PutApiProfileUpdateLabelBasicInfoByLabelIdApiResponse = unknown;
export type PutApiProfileUpdateLabelBasicInfoByLabelIdApiArg = {
  labelId: number;
  labelBasicInfoVm: LabelBasicInfoVm;
};
export type PutApiProfileUpdateLabelDefaultsByLabelIdApiResponse = unknown;
export type PutApiProfileUpdateLabelDefaultsByLabelIdApiArg = {
  labelId: number;
  labelDefaultsVm: LabelDefaultsVm;
};
export type PutApiProfileUpdateLabelResourcesByLabelIdApiResponse = unknown;
export type PutApiProfileUpdateLabelResourcesByLabelIdApiArg = {
  labelId: number;
  body: ResourceVm[];
};
export type DeleteApiProfileDeleteLabelByLabelIdApiResponse = unknown;
export type DeleteApiProfileDeleteLabelByLabelIdApiArg = number;
export type GetApiProfileGetLabelUsersByLabelIdApiResponse = /** status 200 Success */ GetUserAccessListVisibleOnlyResult[];
export type GetApiProfileGetLabelUsersByLabelIdApiArg = number;
export type GetApiProfileSearchContributorsApiResponse = /** status 200 Success */ LabelSearchVm[];
export type GetApiProfileSearchContributorsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type PostApiProfileCreateContributorApiResponse = /** status 200 Success */ number;
export type PostApiProfileCreateContributorApiArg = void;
export type GetApiProfileGetContributorBasicInfoByContributorIdApiResponse = /** status 200 Success */ AcCreateContributorVm;
export type GetApiProfileGetContributorBasicInfoByContributorIdApiArg = {
  contributorId: number;
  languageId?: number;
};
export type GetApiProfileGetContributorImageByContributorIdApiResponse = /** status 200 Success */ ContributorImageVm;
export type GetApiProfileGetContributorImageByContributorIdApiArg = number;
export type GetApiProfileGetContributorTagsKeywordsByContributorIdApiResponse = /** status 200 Success */ ContributorTagsKeywordsVm;
export type GetApiProfileGetContributorTagsKeywordsByContributorIdApiArg = number;
export type GetApiProfileGetContributorTagsApiResponse = /** status 200 Success */ ContributorTagValueVm[];
export type GetApiProfileGetContributorTagsApiArg = void;
export type GetApiProfileGetContributorResourcesByContributorIdApiResponse = /** status 200 Success */ ResourceVm[];
export type GetApiProfileGetContributorResourcesByContributorIdApiArg = number;
export type PutApiProfileUpdateContributorByContributorIdApiResponse = unknown;
export type PutApiProfileUpdateContributorByContributorIdApiArg = {
  contributorId: number;
  contributorVm: ContributorVm;
};
export type PutApiProfileUpdateContributorBasicInfoByContributorIdApiResponse = unknown;
export type PutApiProfileUpdateContributorBasicInfoByContributorIdApiArg = {
  contributorId: number;
  acCreateContributorVm: AcCreateContributorVm;
};
export type PutApiProfileUpdateContributorImageByContributorIdApiResponse = unknown;
export type PutApiProfileUpdateContributorImageByContributorIdApiArg = {
  contributorId: number;
  contributorImageVm: ContributorImageVm;
};
export type PutApiProfileUpdateContributorTagKeywordsByContributorIdApiResponse = unknown;
export type PutApiProfileUpdateContributorTagKeywordsByContributorIdApiArg = {
  contributorId: number;
  contributorTagsKeywordsVm: ContributorTagsKeywordsVm;
};
export type PutApiProfileUpdateContributorResourcesByContributorIdApiResponse = unknown;
export type PutApiProfileUpdateContributorResourcesByContributorIdApiArg = {
  contributorId: number;
  body: ResourceVm[];
};
export type DeleteApiProfileDeleteContributorByContributorIdApiResponse = unknown;
export type DeleteApiProfileDeleteContributorByContributorIdApiArg = number;
export type GetApiProfileGetContributorProductsByContributorIdApiResponse = /** status 200 Success */ {
  [key: string]: FormatSearchModel[];
};
export type GetApiProfileGetContributorProductsByContributorIdApiArg = number;
export type GetApiProfileGetConnectedProfilesByContributorIdApiResponse = /** status 200 Success */ ContributorSearchVm[];
export type GetApiProfileGetConnectedProfilesByContributorIdApiArg = number;
export type GetApiProfileGetContributorRelatedFormatsByContributorIdApiResponse = /** status 200 Success */ ContributorRelatedFormatWithRoleVm[];
export type GetApiProfileGetContributorRelatedFormatsByContributorIdApiArg = number;
export type GetApiProfileGetContributorRelatedAssetsByContributorIdApiResponse = /** status 200 Success */ ContributorRelatedAssetWithRoleVm[];
export type GetApiProfileGetContributorRelatedAssetsByContributorIdApiArg = number;
export type GetApiProfileGetContributorRelatedWorksByContributorIdApiResponse = /** status 200 Success */ UspGetContributorRelatedWorksWithRolesResult[];
export type GetApiProfileGetContributorRelatedWorksByContributorIdApiArg = number;
export type GetApiProxyDeliverymanByPathApiResponse = unknown;
export type GetApiProxyDeliverymanByPathApiArg = string;
export type PostApiProxyDeliverymanByPathApiResponse = unknown;
export type PostApiProxyDeliverymanByPathApiArg = string;
export type GetApiProxyDispatcherByPathApiResponse = unknown;
export type GetApiProxyDispatcherByPathApiArg = string;
export type PostApiProxyDispatcherByPathApiResponse = unknown;
export type PostApiProxyDispatcherByPathApiArg = string;
export type GetApiProxySongkickByPathApiResponse = unknown;
export type GetApiProxySongkickByPathApiArg = string;
export type GetApiReleaseScheduleGetReleaseScheduleApiResponse = /** status 200 Success */ GetReleaseScheduleByTenantResult[];
export type GetApiReleaseScheduleGetReleaseScheduleApiArg = void;
export type GetApiReleaseScheduleSearchReleaseScheduleApiResponse = /** status 200 Success */ ReleaseScheduleSearchModel[];
export type GetApiReleaseScheduleSearchReleaseScheduleApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSearchSearchFormatsApiResponse = /** status 200 Success */ FormatSearchModel[];
export type GetApiSearchSearchFormatsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSearchSearchContributorsApiResponse = /** status 200 Success */ ContributorSearchModel[];
export type GetApiSearchSearchContributorsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSearchSearchContributorContributionsApiResponse = /** status 200 Success */ AcContributorContributionVm[];
export type GetApiSearchSearchContributorContributionsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSearchSearchTracksApiResponse = /** status 200 Success */ AssetSearchModel[];
export type GetApiSearchSearchTracksApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSearchSearchPublishersApiResponse = /** status 200 Success */ AcPublisherVm[];
export type GetApiSearchSearchPublishersApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSearchSearchTenantsApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiSearchSearchTenantsApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSearchSearchClientsApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiSearchSearchClientsApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSearchSearchParentLabelsApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiSearchSearchParentLabelsApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSearchSearchLabelsApiResponse = /** status 200 Success */ SearchLabelSearchVm[];
export type GetApiSearchSearchLabelsApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSearchSearchUsersApiResponse = /** status 200 Success */ UserSearchVm[];
export type GetApiSearchSearchUsersApiArg = {
  term?: string;
  filterUserId?: number;
  count?: number;
};
export type GetApiSearchSearchContributorIdApiResponse = /** status 200 Success */ AcContributorSearchVm[];
export type GetApiSearchSearchContributorIdApiArg = number;
export type GetApiSearchSearchDdexPartyIdsApiResponse = /** status 200 Success */ DdexPartyIdSearchVm[];
export type GetApiSearchSearchDdexPartyIdsApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSearchSearchAssetTypeToFileFormatsApiResponse = /** status 200 Success */ AssetTypeToFileFormatSearchVm[];
export type GetApiSearchSearchAssetTypeToFileFormatsApiArg = {
  term?: string;
  count?: number;
};
export type GetApiSupplierSearchSuppliersApiResponse = /** status 200 Success */ GetSuppliersByTenantIdResult[];
export type GetApiSupplierSearchSuppliersApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiSupplierGetSuggestedPartTypeDestinationByFormatApiResponse = /** status 200 Success */ SupplierPartTypeDestinationVm[];
export type GetApiSupplierGetSuggestedPartTypeDestinationByFormatApiArg = {
  formatId?: number;
  platformId?: number;
};
export type GetApiSupplierGetSupplierKnownPartTypesBySupplierIdApiResponse = /** status 200 Success */ UspGetSupplierKnownPartTypesResult[];
export type GetApiSupplierGetSupplierKnownPartTypesBySupplierIdApiArg = number;
export type GetApiSupplierGetSupplierBySupplierIdApiResponse = /** status 200 Success */ SupplierVm;
export type GetApiSupplierGetSupplierBySupplierIdApiArg = number;
export type PostApiSupplierCreateSupplierApiResponse = /** status 200 Success */ number;
export type PostApiSupplierCreateSupplierApiArg = SupplierVm;
export type PutApiSupplierUpdateSupplierBySupplierIdApiResponse = /** status 200 Success */ number;
export type PutApiSupplierUpdateSupplierBySupplierIdApiArg = {
  supplierId: number;
  supplierVm: SupplierVm;
};
export type DeleteApiSupplierDeleteSupplierBySupplierIdApiResponse = unknown;
export type DeleteApiSupplierDeleteSupplierBySupplierIdApiArg = number;
export type GetApiSupplierGetSupplierTenantContactEmailsForOrderApiResponse = /** status 200 Success */ string[];
export type GetApiSupplierGetSupplierTenantContactEmailsForOrderApiArg = number;
export type GetApiSupplierDefaultSupplierForTenantApiResponse = /** status 200 Success */ DefaultSupplierVm;
export type GetApiSupplierDefaultSupplierForTenantApiArg = void;
export type PostApiTagCreateFormatStatusTagApiResponse = /** status 200 Success */ number;
export type PostApiTagCreateFormatStatusTagApiArg = TagVm;
export type PutApiTagEditFormatStatusTagApiResponse = unknown;
export type PutApiTagEditFormatStatusTagApiArg = {
  tagId?: number;
  tagVm: TagVm;
};
export type DeleteApiTagDeleteFormatStatusTagApiResponse = unknown;
export type DeleteApiTagDeleteFormatStatusTagApiArg = number;
export type PostApiTagAddFormatStatusTagApiResponse = unknown;
export type PostApiTagAddFormatStatusTagApiArg = {
  formatId?: number;
  tagId?: number;
};
export type DeleteApiTagRemoveFormatStatusTagApiResponse = unknown;
export type DeleteApiTagRemoveFormatStatusTagApiArg = {
  formatId?: number;
  tagId?: number;
};
export type PostApiTagSetFormatStatusTagsApiResponse = unknown;
export type PostApiTagSetFormatStatusTagsApiArg = {
  formatId?: number;
  body: number[];
};
export type GetApiTestNopeApiResponse = unknown;
export type GetApiTestNopeApiArg = void;
export type PostApiTestPostApiResponse = /** status 200 Success */ CyclicTestClass;
export type PostApiTestPostApiArg = CyclicTestClass;
export type PostApiTestPost2ApiResponse = /** status 200 Success */ CyclicTestClass;
export type PostApiTestPost2ApiArg = void;
export type GetApiTestPermsApiResponse = unknown;
export type GetApiTestPermsApiArg = void;
export type GetApiTestWeatherForecastApiResponse = /** status 200 Success */ ClWeatherForecast[];
export type GetApiTestWeatherForecastApiArg = void;
export type GetApiTicketGetProductInfoForTicketApiResponse = /** status 200 Success */ ProductInfoVm;
export type GetApiTicketGetProductInfoForTicketApiArg = number;
export type GetApiTicketGetTicketsApiResponse = /** status 200 Success */ TicketVm[];
export type GetApiTicketGetTicketsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiTicketGetTicketCategoriesApiResponse = /** status 200 Success */ UspGetTicketCategoryByProductandPartnerResult[];
export type GetApiTicketGetTicketCategoriesApiArg = {
  partnerId: number;
  productType: TicketProductTypes;
};
export type GetApiTicketGetRelatedCreatedByApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiTicketGetRelatedCreatedByApiArg = void;
export type GetApiTicketGetTicketsForProductApiResponse = /** status 200 Success */ TicketVm[];
export type GetApiTicketGetTicketsForProductApiArg = {
  productId: number;
  productType: TicketProductTypes;
};
export type PostApiTicketCreateTicketApiResponse = /** status 200 Success */ number;
export type PostApiTicketCreateTicketApiArg = {
  productId: number;
  productType: TicketProductTypes;
  ticketVm: TicketVm;
};
export type PutApiTicketUpdateTicketByTicketIdApiResponse = /** status 200 Success */ number;
export type PutApiTicketUpdateTicketByTicketIdApiArg = {
  ticketId: number;
  ticketVm: TicketVm;
};
export type GetApiTicketGetCommentsByTicketIdApiResponse = /** status 200 Success */ CommentVm[];
export type GetApiTicketGetCommentsByTicketIdApiArg = {
  ticketId: number;
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type PostApiTicketAddCommentByTicketIdApiResponse = /** status 200 Success */ AcknowledgeAction;
export type PostApiTicketAddCommentByTicketIdApiArg = {
  ticketId: number;
  commentVm: CommentVm;
};
export type PostApiTicketProcessEmailReplyApiResponse = /** status 200 Success */ number;
export type PostApiTicketProcessEmailReplyApiArg = EmailCommentVm;
export type GetApiTicketGetCategoriesOpenClosePermsApiResponse = /** status 200 Success */ {
  [key: string]: number[];
};
export type GetApiTicketGetCategoriesOpenClosePermsApiArg = void;
export type PutApiTicketOptInOutOfTicketByTicketIdApiResponse = /** status 200 Success */ number;
export type PutApiTicketOptInOutOfTicketByTicketIdApiArg = {
  ticketId: number;
  receiveEmails?: boolean;
};
export type PostApiUploadGetUploadPermissionApiResponse = /** status 200 Success */ BlobUploadPermsVm;
export type PostApiUploadGetUploadPermissionApiArg = BlobUploadRequestVm;
export type GetApiUploadCheckFileExistsApiResponse = /** status 200 Success */ AssetInformationWithMetum[];
export type GetApiUploadCheckFileExistsApiArg = {
  md5?: string;
  quickMd5?: string;
};
export type GetApiUploadCheckUploadSessionApiResponse = /** status 200 Success */ UploadSessionResult;
export type GetApiUploadCheckUploadSessionApiArg = string;
export type GetApiUploadGetReportBlobsApiResponse = /** status 200 Success */ ReportDocumentBlob[];
export type GetApiUploadGetReportBlobsApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
};
export type GetApiUploadDownloadReportApiResponse = unknown;
export type GetApiUploadDownloadReportApiArg = string;
export type PutApiUserManagementChangePasswordApiResponse = unknown;
export type PutApiUserManagementChangePasswordApiArg = ChangePasswordVm;
export type PostApiUserManagementRequestPasswordResetApiResponse = unknown;
export type PostApiUserManagementRequestPasswordResetApiArg = ResetPasswordRequestVm;
export type PostApiUserManagementResetPasswordApiResponse = unknown;
export type PostApiUserManagementResetPasswordApiArg = ResetPasswordVm;
export type PostApiUserManagementSendAuthCodeApiResponse = /** status 200 Success */ ShortTerm2FaTokenViewModel;
export type PostApiUserManagementSendAuthCodeApiArg = SignUpPostVm;
export type PostApiUserManagementVerifyAuthCodeApiResponse = /** status 200 Success */ AcknowledgeActionBoolean;
export type PostApiUserManagementVerifyAuthCodeApiArg = SignUpPostVm;
export type PostApiUserManagementIsValidEmailApiResponse = /** status 200 Success */ AcknowledgeActionBoolean;
export type PostApiUserManagementIsValidEmailApiArg = SignUpPostVm;
export type PutApiUserManagementAdminChangeUserPasswordApiResponse = unknown;
export type PutApiUserManagementAdminChangeUserPasswordApiArg = ChangePasswordVm;
export type PostApiUserManagementAddUserApiResponse = /** status 200 Success */ AcknowledgeActionInt32;
export type PostApiUserManagementAddUserApiArg = SimpleUserModel;
export type PostApiUserManagementAddUserToProductApiResponse = /** status 200 Success */ AcknowledgeActionInt32;
export type PostApiUserManagementAddUserToProductApiArg = SignUpDetailsVm;
export type GetApiUserManagementGetUserListApiResponse = /** status 200 Success */ UserListModel[];
export type GetApiUserManagementGetUserListApiArg = number;
export type GetApiUserManagementGetAllUserPermsApiResponse = /** status 200 Success */ GetUserAccessListVisibleOnlyResult[];
export type GetApiUserManagementGetAllUserPermsApiArg = number;
export type GetApiUserManagementGetPendingUserListApiResponse = /** status 200 Success */ PendingUser[];
export type GetApiUserManagementGetPendingUserListApiArg = void;
export type GetApiUserManagementGetPermSettingsOnBlockApiResponse = /** status 200 Success */ UspUserPermissionsGetUserPossibleAndCurrentPermissionsResult[];
export type GetApiUserManagementGetPermSettingsOnBlockApiArg = {
  accessLevelCode?: number;
  accessLevelServiceId?: number;
  userId?: number;
  contributorId?: number;
};
export type PostApiUserManagementSavePermsApiResponse = /** status 200 Success */ string;
export type PostApiUserManagementSavePermsApiArg = UpdateUserPermissionViewModel;
export type GetApiUserManagementGetUserRolesApiResponse = /** status 200 Success */ number[];
export type GetApiUserManagementGetUserRolesApiArg = number;
export type PostApiUserManagementSetUserRolesApiResponse = /** status 200 Success */ AcknowledgeActionString;
export type PostApiUserManagementSetUserRolesApiArg = {
  userId?: number;
  body: number[];
};
export type GetApiUserManagementGetPermLevelsApiResponse = /** status 200 Success */ UspUserPermissionsGetAccessPermissionsForUserResult[];
export type GetApiUserManagementGetPermLevelsApiArg = void;
export type GetApiUserManagementGetUserRoleDescriptionsApiResponse = /** status 200 Success */ AspNetRole[];
export type GetApiUserManagementGetUserRoleDescriptionsApiArg = void;
export type GetApiUserManagementGetServicePermissionDescriptionsApiResponse = /** status 200 Success */ ServicePermissionLookup[];
export type GetApiUserManagementGetServicePermissionDescriptionsApiArg = void;
export type GetApiUserManagementGetPermissionDependenciesApiResponse = /** status 200 Success */ ServicePermissionDependency[];
export type GetApiUserManagementGetPermissionDependenciesApiArg = void;
export type GetApiUtilityGetResourceApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiUtilityGetResourceApiArg = string;
export type GetApiUtilityGetDomainSettingsApiResponse = /** status 200 Success */ DomainsByTenant;
export type GetApiUtilityGetDomainSettingsApiArg = void;
export type GetApiUtilityGetStatsApiResponse = /** status 200 Success */ {
  [key: string]: number;
};
export type GetApiUtilityGetStatsApiArg = void;
export type GetApiUtilityGetPlatformUrlLinkTemplatesApiResponse = /** status 200 Success */ PlatformUrllinkTemplate[];
export type GetApiUtilityGetPlatformUrlLinkTemplatesApiArg = void;
export type GetApiUtilityGetExchangeRatesApiResponse = /** status 200 Success */ GetExchangeRateLatestByCurrencyResult[];
export type GetApiUtilityGetExchangeRatesApiArg = {
  maxAge?: number;
  currencies?: string;
};
export type GetApiUtilityCheckOnTaskSessionApiResponse = /** status 200 Success */ AllSessionStatus;
export type GetApiUtilityCheckOnTaskSessionApiArg = number;
export type GetApiUtilityGetAuditorStatusApiResponse = /** status 200 Success */ AuditState[];
export type GetApiUtilityGetAuditorStatusApiArg = void;
export type GetApiUtilityGetAutomationTasksApiResponse = /** status 200 Success */ SelectListVm[];
export type GetApiUtilityGetAutomationTasksApiArg = void;
export type PostApiUtilityRefreshAuditorNowApiResponse = /** status 200 Success */ AcknowledgeActionInt64;
export type PostApiUtilityRefreshAuditorNowApiArg = void;
export type GetApiUtilityGetEnumsForScaffoldApiResponse = /** status 200 Success */ {
  [key: string]: EnumEntry[];
};
export type GetApiUtilityGetEnumsForScaffoldApiArg = void;
export type GetApiUtilityEchoApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetApiUtilityEchoApiArg = void;
export type GetApiUtilityGetKnowledgeArticlesApiResponse = /** status 200 Success */ AllArticle[];
export type GetApiUtilityGetKnowledgeArticlesApiArg = void;
export type GetApiUtilityGetKnowledgeArticleApiResponse = /** status 200 Success */ AllArticle;
export type GetApiUtilityGetKnowledgeArticleApiArg = number;
export type GetApiUtilityGetMemCacheInfoApiResponse = unknown;
export type GetApiUtilityGetMemCacheInfoApiArg = number;
export type UspGetUserKnownAddressesResult = {
  company?: string | null;
  addressId?: number | null;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  stateProvinceCounty?: string | null;
  zipOrPostcode?: string | null;
  countryTerritoryLinkId?: number | null;
};
export type AddressVm = {
  line1: string;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  stateProvinceCounty: string;
  zipOrPostcode: string;
  countryTerritoryLinkId: number;
};
export type PersonalDetailsVm = {
  userId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  currencyId?: number | null;
  address?: AddressVm;
  lastActivity?: string | null;
  isActive?: boolean;
  displayName?: string | null;
};
export type AcknowledgeAction = {
  message?: string | null;
  isError?: boolean;
  value?: string | null;
};
export type KeyValuePairStringString = {
  key?: string | null;
  value?: string | null;
};
export type UspUserPermissionsGetServicePermissionsForUserResult = {
  inferredClientId?: number | null;
  inferredParentLabelId?: number | null;
  inferredLabelId?: number | null;
  inferredContributorId?: number | null;
  inferredPublisherGroupId?: number | null;
  servicePermissionLinkId?: number;
  accessLevelCode?: number | null;
  permissionLevelRank?: number | null;
};
export type UserPermissionSummaryModal = {
  tenantId?: number;
  roles?: string[] | null;
  accessTiers?: string[] | null;
  maxServices?: string[] | null;
  accessLevelRank?: number;
  allPermissions?: UspUserPermissionsGetServicePermissionsForUserResult[] | null;
};
export type UserViewModel = {
  userId?: number;
  tenantId?: number;
  username?: string | null;
  email?: string | null;
  firstname?: string | null;
  lastName?: string | null;
  mobile?: string | null;
  mobilePreferred?: boolean;
  userPermissions?: UserPermissionSummaryModal;
};
export type LoginResponseViewModel = {
  token?: string | null;
  refreshToken?: string | null;
  expiresIn?: number;
  user?: UserViewModel;
};
export type LoginViewModel = {
  email: string;
  password?: string | null;
};
export type PermissionIdType = 0 | 1 | 2 | 3 | 4;
export type CacheSyncState = {
  cacheSyncStateId?: number;
  jobKey?: string | null;
  source?: string | null;
  destination?: string | null;
  lastDuration?: number;
  lastRefresh?: string | null;
  refreshCount?: number;
  lastDdlchange?: string | null;
  watchedTables?: string | null;
  sourceType?: string | null;
  errorCount?: number;
  lastRunSuccess?: boolean;
  syncMethod?: string | null;
  lastSyncReason?: string | null;
};
export type FirewallRule = {
  ruleId?: number;
  ipgroupLinkId?: number;
  portGroupLinkId?: number;
  destinationLinkId?: number;
  ruleName?: string | null;
  createdOn?: string;
  updatedOn?: string;
};
export type FirewallIpaddress = {
  ipaddressId?: number;
  ipgroupLinkId?: number;
  ipaddressName?: string | null;
  ipaddressDefinition?: string | null;
  createdOn?: string;
  updatedOn?: string;
};
export type FirewallIpgroup = {
  ipgroupId?: number;
  groupName?: string | null;
  createdOn?: string;
  updatedOn?: string;
};
export type FirewallDestination = {
  destinationId?: number;
  destinationName?: string | null;
  destinationDefinition?: string | null;
  createdOn?: string;
  updatedOn?: string;
};
export type FirewallPortGroup = {
  portGroupId?: number;
  groupName?: string | null;
  groupDefinition?: string | null;
  allowTcp?: boolean | null;
  allowUdp?: boolean | null;
  createdOn?: string;
  updatedOn?: string;
};
export type FireWallViewModel = {
  rules?: FirewallRule[] | null;
  ipAddresses?: FirewallIpaddress[] | null;
  ipGroups?: FirewallIpgroup[] | null;
  destinations?: FirewallDestination[] | null;
  portGroups?: FirewallPortGroup[] | null;
};
export type FireWallUpdatesViewModel = {
  rules?: number[][] | null;
  ipAddresses?: number[][] | null;
  ipGroups?: number[][] | null;
  destinations?: number[][] | null;
  portGroups?: number[][] | null;
};
export type AspNetUser = {
  id?: number;
  displayName?: string | null;
  userPermissionLastUpdatedOn?: string | null;
  createdOn?: string;
  invitedBy?: number;
  lastActivity?: string | null;
  isActive?: boolean;
  currencyLinkIdReadOnly?: number;
  firstNameReadOnly?: string | null;
  lastNameReadOnly?: string | null;
  emailReadOnly?: string | null;
  addressLinkId?: number | null;
  userDataHash?: number | null;
};
export type ShortTerm2FaTokenViewModel = {
  generatedOn?: string;
  verifiedOn?: string | null;
  verificationWindowExpiresOn?: string;
  tokenExpiresOn?: string | null;
  verificationAttemptsRemaining?: number;
  code?: string | null;
  userId?: number;
};
export type CloudSecretString = {
  key?: string | null;
  value?: string | null;
  type?: string | null;
};
export type CompactJsonTableObject = {
  rowModel?: any | null;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfArticlesSummaryByClientLabel = {
  warehouse?: string | null;
  reporting_Month?: string | null;
  clientId?: number | null;
  label_Name?: string | null;
  closing_Stock?: number | null;
  goods_In?: number | null;
  goods_Out?: number | null;
  refurb_Units?: number | null;
  refub_Unit_Fee?: number | null;
  excess_Stock?: number | null;
  excess_Stock_Charge?: number | null;
  scrap_Stock?: number | null;
  scrap_Stock_Fee?: number | null;
  line_Charge?: number | null;
  clientName?: string | null;
};
export type CompactJsonTableZCodegenTypeTvfArticlesSummaryByClientLabel = {
  rowModel?: ZCodegenTypeTvfArticlesSummaryByClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfCmoSummaryRevenue = {
  platform_id?: number | null;
  platformName?: string | null;
  dsp_currency?: string | null;
  dsp_tenant_exrate?: number | null;
  tenant_value?: number | null;
  dsp_value?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfCmoSummaryRevenue = {
  rowModel?: ZCodegenTypeTvfCmoSummaryRevenue;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfCmoSummaryRevenueByClientLabel = {
  clientName?: string | null;
  platform_id?: number | null;
  platformName?: string | null;
  dsp_currency?: string | null;
  client_id?: number | null;
  label?: string | null;
  label_id?: number | null;
  dsp_tenant_exrate?: number | null;
  tenant_value?: number | null;
  dsp_value?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfCmoSummaryRevenueByClientLabel = {
  rowModel?: ZCodegenTypeTvfCmoSummaryRevenueByClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfDigitalSummary = {
  platformId?: number | null;
  platformName?: string | null;
  homeCurrencyAmount?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfDigitalSummary = {
  rowModel?: ZCodegenTypeTvfDigitalSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfDigitalSummaryClientLabel = {
  clientName?: string | null;
  platformId?: number | null;
  platformName?: string | null;
  client_id?: number | null;
  label_id?: number | null;
  homeCurrencyAmount?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfDigitalSummaryClientLabel = {
  rowModel?: ZCodegenTypeTvfDigitalSummaryClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfDirectSalesSummary = {
  supplierName?: string | null;
  quantity?: number | null;
  value?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfDirectSalesSummary = {
  rowModel?: ZCodegenTypeTvfDirectSalesSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfDirectSalesSummaryClientLabel = {
  clientName?: string | null;
  labelName?: string | null;
  supplierName?: string | null;
  labelLinkId?: number | null;
  quantity?: number | null;
  value?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfDirectSalesSummaryClientLabel = {
  rowModel?: ZCodegenTypeTvfDirectSalesSummaryClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfOtherSalesSummary = {
  supplierName?: string | null;
  partType?: string | null;
  rechargeAmount?: number | null;
  commissionAmount?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfOtherSalesSummary = {
  rowModel?: ZCodegenTypeTvfOtherSalesSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfOtherSalesSummaryClientLabel = {
  clientName?: string | null;
  labelName?: string | null;
  supplierName?: string | null;
  partType?: string | null;
  labelLinkId?: number | null;
  rechargeClientLinkId?: number | null;
  rechargeAmount?: number | null;
  commissionAmount?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfOtherSalesSummaryClientLabel = {
  rowModel?: ZCodegenTypeTvfOtherSalesSummaryClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalArticlesSummary = {
  warehouse?: string | null;
  reporting_Month?: string | null;
  formatTypeGroup?: string | null;
  closing_Stock?: number | null;
  goods_In?: number | null;
  goods_Out?: number | null;
  refurb_Units?: number | null;
  refub_Unit_Fee?: number | null;
  excess_Stock?: number | null;
  excess_Stock_Charge?: number | null;
  scrap_Stock?: number | null;
  scrap_Stock_Fee?: number | null;
  line_Charge?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalArticlesSummary = {
  rowModel?: ZCodegenTypeTvfPhysicalArticlesSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalNotificationFormatsUnLinkedAllTime = {
  articleNumber?: string | null;
  platformName?: string | null;
  lastReportedMonth?: string | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalNotificationFormatsUnLinkedAllTime = {
  rowModel?: ZCodegenTypeTvfPhysicalNotificationFormatsUnLinkedAllTime;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalSalesReturnsConsignClientReporting = {
  clientName?: string | null;
  platformName?: string | null;
  reportingMonth?: string | null;
  clientId?: number | null;
  labelName?: string | null;
  catNo?: string | null;
  formatId?: number | null;
  formatType?: string | null;
  upc?: string | null;
  artist?: string | null;
  title?: string | null;
  customerName?: string | null;
  dealerPrice?: number | null;
  countryCode?: string | null;
  salesDistRatePercentage?: number | null;
  salesDistMinFee?: number | null;
  salesDistVinylMinFee?: number | null;
  returnsDistRatePercentage?: number | null;
  returnsDistMinFee?: number | null;
  promoDistRatePercentage?: number | null;
  promoDistMinFee?: number | null;
  amazonVinylPackagingFee?: number | null;
  salesUnits?: number | null;
  salesValueNet?: number | null;
  salesDistFee?: number | null;
  returnsUnits?: number | null;
  returnsValue?: number | null;
  returnsDistFee?: number | null;
  consignmentShipmentUnits?: number | null;
  consignmentShipDistFee?: number | null;
  consignmentSaleUnits?: number | null;
  consignmentSalesCreditDistFee?: number | null;
  consignmentReturnUnits?: number | null;
  consignmentReturnsDistFee?: number | null;
  promoUnits?: number | null;
  promoDistFee?: number | null;
  financialAdjustmentUnitsIn?: number | null;
  financialAdjustmentValueIn?: number | null;
  financialAdjustmentUnitsOut?: number | null;
  financialAdjustmentValueOut?: number | null;
  vinylPackagingFee?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignClientReporting = {
  rowModel?: ZCodegenTypeTvfPhysicalSalesReturnsConsignClientReporting;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalSalesReturnsConsignSummary = {
  platformName?: string | null;
  reportingMonth?: string | null;
  salesUnits?: number | null;
  salesValueNet?: number | null;
  salesDistFee?: number | null;
  originalSalesUnits?: number | null;
  originalSalesValueNet?: number | null;
  returnsUnits?: number | null;
  returnsValue?: number | null;
  returnsDistFee?: number | null;
  consignmentShipmentUnits?: number | null;
  consignmentShipDistFee?: number | null;
  consignmentSaleUnits?: number | null;
  consignmentSalesCreditDistFee?: number | null;
  consignmentReturnUnits?: number | null;
  consignmentReturnsDistFee?: number | null;
  promoUnits?: number | null;
  promoDistFee?: number | null;
  financialAdjustmentUnitsIn?: number | null;
  financialAdjustmentValueIn?: number | null;
  financialAdjustmentUnitsOut?: number | null;
  financialAdjustmentValueOut?: number | null;
  vinylPackagingFee?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignSummary = {
  rowModel?: ZCodegenTypeTvfPhysicalSalesReturnsConsignSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabel = {
  clientName?: string | null;
  platformName?: string | null;
  reportingMonth?: string | null;
  clientId?: number | null;
  labelName?: string | null;
  salesUnits?: number | null;
  salesValueNet?: number | null;
  salesDistFee?: number | null;
  originalSalesUnits?: number | null;
  originalSalesValueNet?: number | null;
  returnsUnits?: number | null;
  returnsValue?: number | null;
  returnsDistFee?: number | null;
  consignmentShipmentUnits?: number | null;
  consignmentShipDistFee?: number | null;
  consignmentSaleUnits?: number | null;
  consignmentSalesCreditDistFee?: number | null;
  consignmentReturnUnits?: number | null;
  consignmentReturnsDistFee?: number | null;
  promoUnits?: number | null;
  promoDistFee?: number | null;
  financialAdjustmentUnitsIn?: number | null;
  financialAdjustmentValueIn?: number | null;
  financialAdjustmentUnitsOut?: number | null;
  financialAdjustmentValueOut?: number | null;
  vinylPackagingFee?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabel = {
  rowModel?: ZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabelFormat = {
  clientName?: string | null;
  platformName?: string | null;
  reportingMonth?: string | null;
  clientId?: number | null;
  labelName?: string | null;
  catNo?: string | null;
  formatId?: number | null;
  formatType?: string | null;
  salesUnits?: number | null;
  salesValueNet?: number | null;
  salesDistFee?: number | null;
  originalSalesUnits?: number | null;
  originalSalesValueNet?: number | null;
  returnsUnits?: number | null;
  returnsValue?: number | null;
  returnsDistFee?: number | null;
  consignmentShipmentUnits?: number | null;
  consignmentShipDistFee?: number | null;
  consignmentSaleUnits?: number | null;
  consignmentSalesCreditDistFee?: number | null;
  consignmentReturnUnits?: number | null;
  consignmentReturnsDistFee?: number | null;
  promoUnits?: number | null;
  promoDistFee?: number | null;
  financialAdjustmentUnitsIn?: number | null;
  financialAdjustmentValueIn?: number | null;
  financialAdjustmentUnitsOut?: number | null;
  financialAdjustmentValueOut?: number | null;
  vinylPackagingFee?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabelFormat = {
  rowModel?: ZCodegenTypeTvfPhysicalSalesReturnsConsignSummaryByClientLabelFormat;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfPhysicalUniversalInvoiceSummary = {
  platformName?: string | null;
  reportingMonth?: string | null;
  uniInvCategory?: string | null;
  units?: number | null;
  value?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfPhysicalUniversalInvoiceSummary = {
  rowModel?: ZCodegenTypeTvfPhysicalUniversalInvoiceSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfRechargesSummary = {
  supplierName?: string | null;
  rechargeCategory?: string | null;
  partType?: string | null;
  rechargeAmount?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfRechargesSummary = {
  rowModel?: ZCodegenTypeTvfRechargesSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ZCodegenTypeTvfRechargesSummaryClientLabel = {
  clientName?: string | null;
  supplierName?: string | null;
  rechargeCategory?: string | null;
  partType?: string | null;
  rechargeClientLinkId?: number | null;
  rechargeAmount?: number | null;
};
export type CompactJsonTableZCodegenTypeTvfRechargesSummaryClientLabel = {
  rowModel?: ZCodegenTypeTvfRechargesSummaryClientLabel;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ProductIngestionIssue = {
  issueId?: number;
  formatLinkId?: number | null;
  formatAssetLinkId?: number | null;
  contributorLinkId?: number | null;
  message?: string | null;
  isCritical?: boolean;
  resolvedBy?: number | null;
  ignoredBy?: number | null;
  resolutionNotes?: string | null;
  createdOn?: string;
  createdBy?: number;
  updatedOn?: string;
  updatedBy?: number;
};
export type GetFormatsWithStatusResult = {
  formatId?: number;
  catNo?: string | null;
  upc?: string | null;
  variousArtists?: boolean | null;
  formatTypeId?: number | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  labelId?: number | null;
  labelName?: string | null;
  createdOn?: string | null;
  createdBy?: number | null;
  dateInactivated?: string | null;
  localeContributorNames?: string | null;
  contributorIds?: string | null;
  title?: string | null;
  subTitle?: string | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroup?: string | null;
  packshotAssetId?: number | null;
  packshotGUID?: string | null;
  isPlatformApproved?: boolean | null;
  isCMOApproved?: boolean | null;
  isPlatformValidated?: boolean | null;
  isCMOValidated?: boolean | null;
  deliveryStateLinkId?: number | null;
  genreId?: number | null;
  titleGroupId?: number | null;
  jsonCurrentDealerPrice?: string | null;
  formatNotAssetETag?: number | null;
  formatETag?: number | null;
  tenantId?: number | null;
  tag_list?: string | null;
  ticketId?: number | null;
  failedPlatforms?: number | null;
  queuedPlatforms?: number | null;
  finishedPlatforms?: number | null;
  activePlatforms?: number | null;
  platforms?: number | null;
  removedPlatforms?: number | null;
  latestDeliveryCompleteTime?: string | null;
  platformApprovedDate?: string | null;
  platformValidatedDate?: string | null;
  parentLabelId?: number | null;
  clientId?: number | null;
  tenantLinkId?: number | null;
  isReleasePast?: number;
  deliveryStep?: number | null;
  tags?: string | null;
  primaryStatusLinkId?: number | null;
  popularity?: number;
  labelManagerList?: string | null;
  notes?: string | null;
};
export type FormatSearchModel = {
  formatId?: number;
  catNo?: string | null;
  upc?: string | null;
  variousArtists?: boolean | null;
  formatTypeId?: number | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  labelId?: number | null;
  labelName?: string | null;
  createdOn?: string | null;
  createdBy?: number | null;
  dateInactivated?: string | null;
  localeContributorNames?: string | null;
  contributorIds?: number[] | null;
  title?: string | null;
  subTitle?: string | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroup?: string | null;
  packshotAssetId?: number | null;
  packshotGUID?: string | null;
  isPlatformApproved?: boolean | null;
  isCMOApproved?: boolean | null;
  isPlatformValidated?: boolean | null;
  isCMOValidated?: boolean | null;
  deliveryStateLinkId?: number | null;
  genreId?: number | null;
  titleGroupId?: number | null;
  jsonCurrentDealerPrice?: string | null;
  formatNotAssetETag?: number | null;
  formatETag?: number | null;
  tenantId?: number | null;
  tag_list?: string | null;
  ticketId?: number | null;
  failedPlatforms?: number | null;
  queuedPlatforms?: number | null;
  finishedPlatforms?: number | null;
  activePlatforms?: number | null;
  platforms?: number | null;
  removedPlatforms?: number | null;
  latestDeliveryCompleteTime?: string | null;
  platformApprovedDate?: string | null;
  platformValidatedDate?: string | null;
  parentLabelId?: number | null;
  clientId?: number | null;
  tenantLinkId?: number | null;
  isReleasePast?: number;
  deliveryStep?: number | null;
  tags?: number[] | null;
  primaryStatusLinkId?: number | null;
  popularity?: number;
  labelManagerList?: number[] | null;
  notes?: string | null;
};
export type UspGetAllFormatContributorsResult = {
  assetLinkId?: number | null;
  contributorLinkId?: number | null;
  name?: string | null;
  assetContributorId?: number | null;
  contributorMusicalWorkLinkId?: number | null;
  sequence?: number | null;
  artistRoles?: string | null;
  contributorRoles?: string | null;
  instrumentRoles?: string | null;
  workRole?: string | null;
};
export type MetaImportJobs = {
  meta_link_id?: number | null;
  tenent_id?: number | null;
  platform_id?: number | null;
  source?: string | null;
  report_type?: string | null;
  report_date?: string | null;
  country?: string | null;
  currency?: string | null;
  filename?: string | null;
  uploaded?: boolean | null;
  complete?: boolean | null;
  deleted?: boolean | null;
  is_royalty?: boolean | null;
  sales_reported?: boolean | null;
  manual_add?: boolean | null;
  ready_to_report?: boolean | null;
  notes?: string | null;
  meta_updated_on?: string | null;
  remote_path?: string | null;
  report_from_date?: string | null;
  report_to_date?: string | null;
  size?: number | null;
  row_count?: number | null;
  destination?: string | null;
  dispatcher_session_id?: number | null;
  script_start_time?: string | null;
  ingest_start_time?: string | null;
  ingest_end_time?: string | null;
  vendor_type_id?: string | null;
  vendor_report_id?: string | null;
  report_creation_time?: string | null;
  archive_path?: string | null;
  import_type?: string | null;
  month_to_report?: string | null;
  md5?: string | null;
  extra_json?: string | null;
  is_ignored?: boolean | null;
  date_paid_from_platform?: string | null;
  uploaded_by?: number | null;
  modified_by?: number | null;
  prorata_amount?: number | null;
};
export type CompactJsonTableMetaImportJobs = {
  rowModel?: MetaImportJobs;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ReportingFactManualMetaMatchData = {
  match_hash?: string | null;
  notes?: string | null;
  created_on?: string | null;
  created_by?: number | null;
  track_artist?: string | null;
  track_title?: string | null;
  release_artist?: string | null;
  release_title?: string | null;
  catalogue_id?: string | null;
  label?: string | null;
  vendor_id?: string | null;
  platform_id?: number | null;
  isrc?: string | null;
  upc?: number | null;
  tenant_id?: number | null;
  client_id?: number | null;
  parent_label_id?: number | null;
  label_id?: number | null;
  format_id?: number | null;
  asset_id?: number | null;
  format_asset_id?: number | null;
};
export type ReportMetadataSubmission = {
  metaLinkId: number;
  currency?: string | null;
  exchangeRate?: number | null;
  prorataAmount?: number | null;
  datePaidByPlatform?: string | null;
  reportStartDate?: string | null;
  reportEndDate?: string | null;
  monthToReport?: string | null;
};
export type ReportMetadataReadyStatus = {
  metaLinkId: number;
  isReadyToReport: boolean;
};
export type ReportMatchSearchResult = {
  resultType?: string | null;
  itemTitle?: string | null;
  itemArtist?: string | null;
  label?: string | null;
  labelId?: number | null;
  formatId?: number | null;
  assetId?: number | null;
  formatAssetId?: number | null;
  matchScore?: number;
};
export type ReportingQueryReportIngestionStatus = {
  platform_id?: number | null;
  platformName?: string | null;
  report_type?: string | null;
  total_reports?: number | null;
  manual?: number | null;
  row_count?: number | null;
  size_gb?: number | null;
  last_import?: string | null;
  last_report_date?: string | null;
  norm_period?: number | null;
  median_lag_days?: number | null;
  expected_next_report?: string | null;
  is_warn?: number | null;
};
export type CompactJsonTableReportingQueryReportIngestionStatus = {
  rowModel?: ReportingQueryReportIngestionStatus;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type SelectListVm = {
  id?: number;
  alternativeId?: number;
  boolVal?: boolean;
  text?: string | null;
  sequence?: number | null;
  type?: string | null;
  description?: string | null;
  permissionLevelRank?: number | null;
  isoCode?: string | null;
  rate?: number | null;
  selected?: boolean | null;
  grouping?: string | null;
  groupingId?: number;
  isDigital?: boolean | null;
  isAudio?: boolean | null;
  isPreferred?: boolean | null;
  imageUrl?: string | null;
  color?: string | null;
};
export type AcknowledgeActionInt64 = {
  message?: string | null;
  isError?: boolean;
  value?: number;
};
export type ReportUploadSubmission = {
  platformId?: number;
  blobPath?: string | null;
};
export type ReportingQueryWaitingReportStaged = {
  platformName?: string | null;
  report_type?: string | null;
  report_date?: string | null;
  country?: string | null;
  month_to_report?: string | null;
  filename?: string | null;
  destination?: string | null;
  manual_add?: boolean | null;
  sales_reported?: boolean | null;
  source?: string | null;
  ready_to_report?: boolean | null;
  report_from_date?: string | null;
  report_to_date?: string | null;
  exchange_rate?: number | null;
  size?: number | null;
  md5?: string | null;
  tenent_id?: number | null;
  script_start_time?: string | null;
  multi_currency?: boolean | null;
  multi_report_date?: boolean | null;
  prorata_amount?: number | null;
  uploaded_by?: number | null;
  modified_by?: number | null;
  date_paid_from_platform?: string | null;
  modified_username?: string | null;
  uploaded_username?: string | null;
  all_checks_passed?: boolean | null;
  meta_link_id?: number | null;
  dsp_currency?: string | null;
  report_cat?: string | null;
  est_month_to_report?: string | null;
  min_mtr?: string | null;
  max_mtr?: string | null;
  row_count?: number | null;
  units?: number | null;
  dsp_value?: number | null;
  tenant_value?: number | null;
  est_exchange_rate?: number | null;
  unmatched_rows?: number | null;
};
export type CompactJsonTableReportingQueryWaitingReportStaged = {
  rowModel?: ReportingQueryWaitingReportStaged;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type AnalysisRoyaltiesQueryUnmatchedStagedData = {
  match_hash?: string | null;
  platformName?: string | null;
  vendor_id?: string | null;
  platform_id?: number | null;
  isrc?: string | null;
  upc?: string | null;
  label?: string | null;
  track_artist?: string | null;
  track_title?: string | null;
  release_artist?: string | null;
  release_title?: string | null;
  catalogue_id?: string | null;
  rowcount?: number | null;
  value?: number | null;
  file_count?: number | null;
  filename?: string | null;
};
export type CompactJsonTableAnalysisRoyaltiesQueryUnmatchedStagedData = {
  rowModel?: AnalysisRoyaltiesQueryUnmatchedStagedData;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type AnalysisCmoQueryClientReportSummary = {
  client_id?: number | null;
  company?: string | null;
  rowcount?: number | null;
  dsp_value?: number | null;
  tenant_value?: number | null;
  report_from_date?: string | null;
  report_to_date?: string | null;
};
export type CompactJsonTableAnalysisCmoQueryClientReportSummary = {
  rowModel?: AnalysisCmoQueryClientReportSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type AnalysisCmoQueryUnmatchedStagedData = {
  match_hash?: string | null;
  platformName?: string | null;
  vendor_id?: string | null;
  platform_id?: number | null;
  isrc?: string | null;
  label?: string | null;
  track_artist?: string | null;
  track_title?: string | null;
  rowcount?: number | null;
  value?: number | null;
  file_count?: number | null;
  filename?: string | null;
};
export type CompactJsonTableAnalysisCmoQueryUnmatchedStagedData = {
  rowModel?: AnalysisCmoQueryUnmatchedStagedData;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ReportingStatus = {
  tenantId?: number;
  lastReportingMonth?: string;
};
export type AnalysisPhysicalQueryPhysicalSalesSummary = {
  platformName?: string | null;
  platform_id?: number | null;
  reportingMonth?: string | null;
  labelName?: string | null;
  salesUnits?: number | null;
  salesValueNet?: number | null;
  salesDistFee?: number | null;
  returnsUnits?: number | null;
  returnsValue?: number | null;
  returnsDistFee?: number | null;
  promoUnits?: number | null;
  promoDistFee?: number | null;
  consignmentShipmentUnits?: number | null;
  consignmentShipDistFee?: number | null;
  consignmentSaleUnits?: number | null;
  consignmentCreditDistFee?: number | null;
  financialAdjustmentUnitsIn?: number | null;
  financialAdjustmentValueIn?: number | null;
  financialAdjustmentUnitsOut?: number | null;
  financialAdjustmentValueOut?: number | null;
};
export type CompactJsonTableAnalysisPhysicalQueryPhysicalSalesSummary = {
  rowModel?: AnalysisPhysicalQueryPhysicalSalesSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ReportedQueryLineItemsGraph = {
  client_id?: number | null;
  month_to_report?: string | null;
  invoiceLineItemDescription?: string | null;
  is_payment_to_client?: boolean | null;
  tenant_value?: number | null;
  client_value?: number | null;
};
export type CompactJsonTableReportedQueryLineItemsGraph = {
  rowModel?: ReportedQueryLineItemsGraph;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type ReportingQueryAgreementRateSummary = {
  clientLinkId?: number | null;
  clientAgreementId?: number | null;
  rates?: any | null;
};
export type AcArtistSearchVm = {
  id?: string | null;
  name?: string | null;
  imageUrl?: string | null;
  href?: string | null;
};
export type Locale = {
  code?: string | null;
  isNative?: boolean;
  text?: string | null;
};
export type Localization = {
  locale?: Locale;
  name?: string | null;
};
export type Localizations = {
  localization?: Localization[] | null;
};
export type Role = {
  roleType?: string | null;
};
export type ServicesTitle = {
  name?: string | null;
  roles?: Role[] | null;
  type?: string | null;
  appleId?: number;
  contentProvider?: string | null;
};
export type RecentTitles = {
  titles?: ServicesTitle[] | null;
};
export type Artist = {
  name?: string | null;
  artistType?: string | null;
  appleId?: number;
  genre?: string | null;
  locale?: Locale;
  localizations?: Localizations;
  created?: string | null;
  isCurated?: boolean;
  isPrimary?: boolean;
  isFeaturing?: boolean;
  isWith?: boolean;
  storeURL?: string | null;
  recentTitles?: RecentTitles;
};
export type AcCreateAppleIdVm = {
  appleId?: string | null;
  name: string;
  forceToken?: string | null;
};
export type AcCreateResourceVm = {
  id: number;
  text?: string | null;
};
export type AcCreateContributorVm = {
  text?: string | null;
  isIndividual?: boolean;
  knownTerritoryId?: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  resources?: AcCreateResourceVm[] | null;
  generateSpotifyId?: boolean;
  contributorBiography?: string | null;
  stageNameLanguageId?: number | null;
  stageName?: string | null;
  meadList?: number[] | null;
  countryOfBirthLinkId?: number | null;
  dob?: string | null;
  genderLinkId?: number | null;
};
export type AcValidateTrackVm = {
  formatId?: number;
  assetId?: number;
  message?: string | null;
  success?: boolean;
};
export type AcBoolResult = {
  result?: boolean;
};
export type DeliveryTypes = 1 | 2 | 3 | 4 | 5;
export type XmlType = 1 | 2 | 3 | 4;
export type XmlBuildVm = {
  jobId?: number;
  formatId?: number;
  platformId?: number;
  jobType?: DeliveryTypes;
  xmlType?: XmlType;
  formatDeliveryJobId?: number;
};
export type UspGetFormatPlatformListResult = {
  platformId?: number | null;
  platformName?: string | null;
  platformDisplayGroup?: string | null;
  platformDisplayGroupSequence?: number | null;
  platformSelected?: boolean | null;
  platformSelectedNotDefault?: boolean | null;
  platformNotSelectedIsDefault?: boolean | null;
  deliveryJobId?: number | null;
  deliveryStateLinkId?: number | null;
  deliveryState?: string | null;
  deliveryTypeLinkId?: number | null;
  deliveryType?: string | null;
  scheduledStartTime?: string | null;
  deliveryStartedOn?: string | null;
  deliveryCompletedOn?: string | null;
  deliveryNotes?: string | null;
  progress?: number | null;
  lastProgress?: string | null;
  rowOrder?: number | null;
  formatChangedSinceLastDelivered?: boolean | null;
};
export type GetDeliveryJobDetail = {
  deliveryJobId?: number;
  formatLinkId?: number | null;
  platformLinkId?: number;
  title?: string | null;
  platformName?: string | null;
  deliveryState?: string | null;
  deliveryTrigger?: string | null;
  deliveryType?: string | null;
  deliveryNotes?: string | null;
  upc?: string | null;
  scheduledStartTime?: string;
  lastProgress?: string | null;
  deliveryStartedOn?: string | null;
  deliveryCompletedOn?: string | null;
  progress?: number | null;
  retryCount?: number | null;
};
export type XmlBuildReturnVm = {
  dataAsBytes?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  xmlAsString?: string | null;
  errorMessage?: string | null;
  success?: boolean;
  timestamp?: string;
};
export type GetDeliveryJobsFormatResult = {
  deliveryJobId?: number;
  isCatalogueRelease?: boolean;
  initiatedBy?: number | null;
  deliveryNotes?: string | null;
  scheduledStartTime?: string;
  deliveryStartedOn?: string | null;
  deliveryCompletedOn?: string | null;
  progress?: number | null;
  lastProgress?: string | null;
  batchNumber?: string | null;
  retryCount?: number | null;
  deliveryStateId?: number;
  deliveryState?: string | null;
  isQueud?: boolean;
  isActive?: boolean;
  isStopped?: boolean;
  deliveryTriggerId?: number;
  deliveryTrigger?: string | null;
  deliveryTypeId?: number;
  deliveryType?: string | null;
  formatId?: number;
  catNo?: string | null;
  upc?: string | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  labelId?: number | null;
  labelName?: string | null;
  createdOn?: string | null;
  createdBy?: number | null;
  localeContributorNames?: string | null;
  contributorIds?: string | null;
  title?: string | null;
  subTitle?: string | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroup?: string | null;
  packshotGUID?: string | null;
  platformId?: number;
  platformName?: string | null;
  platformDeliveryGroupId?: number;
  platformDeliveryGroupName?: string | null;
  displayGroup?: string | null;
  platformBusinessTypeList?: string | null;
};
export type UspGetDeliveryJobFormatSummaryResult = {
  platformId?: number | null;
  platformName?: string | null;
  displayGroup?: string | null;
  lastDeliveryCompletedOn?: string | null;
  delivering?: number | null;
  queued?: number | null;
  cancelled?: number | null;
  failed?: number | null;
  finished?: number | null;
};
export type DeliveryAddressVm = {
  deliveryAddressId?: number | null;
  text?: string | null;
  contact?: string | null;
  company?: string | null;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  stateProvinceCounty?: string | null;
  zipOrPostcode?: string | null;
  countryTerritoryLinkId?: number | null;
};
export type GetDirectSalesByTenantIdResult = {
  directSaleID?: number;
  labelLinkId?: number;
  formatLinkId?: number | null;
  identifier?: string | null;
  notes?: string | null;
  quantity?: number;
  netUnitValue?: number;
  currencyId?: number | null;
  currency?: string | null;
  isoAlpha3Currency?: string | null;
  currencySymbol?: string | null;
  symbolBeforeValue?: boolean | null;
  monthToReport?: string;
  isReported?: boolean;
  dateReported?: string | null;
  reportedOnSalesInvoiceNo?: string | null;
  supplierId?: number | null;
  supplierName?: string | null;
  taxTypeOptionId?: number | null;
  taxOptionType?: string | null;
  taxTypeLinkId?: number | null;
  taxType?: string | null;
  labelName?: string | null;
  createdByName?: string | null;
  formatId?: number | null;
  catNo?: string | null;
  upc?: string | null;
  variousArtists?: boolean | null;
  formatTypeId?: number | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  localeContributorNames?: string | null;
  contributorIds?: string | null;
  title?: string | null;
  subTitle?: string | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroup?: string | null;
  packshotGUID?: string | null;
  jsonCurrentDealerPrice?: string | null;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  fifIlngDirectSalesDetailID?: number | null;
  clientId?: number;
  clientName?: string | null;
  platformId?: number | null;
};
export type DirectSaleVm = {
  directSaleId?: number | null;
  tenantLinkId?: number | null;
  supplierLinkId: number;
  supplierName?: string | null;
  labelLinkId: number;
  labelName?: string | null;
  formatLinkId: number;
  formatName?: string | null;
  identifier?: string | null;
  notes?: string | null;
  quantity: number;
  netUnitValue: number;
  currencyLinkId: number;
  monthToReport: string;
  isReported?: boolean;
};
export type NewsStory = {
  id?: number;
  subject?: string | null;
  body?: string | null;
  packshotGUID?: string | null;
  formatId?: number;
  eventDate?: string;
  platformId?: number | null;
};
export type UserNotification = {
  userId?: number;
  inboxId?: number;
  isRead?: boolean;
  noteId?: number;
  topicLinkId?: number | null;
  timeAdded?: string | null;
  timePublished?: string | null;
  expiryTime?: string | null;
  body?: string | null;
  icon?: string | null;
  severity?: string | null;
  metaJson?: any | null;
  relatedEntityTypeId?: number | null;
  relatedEntityId?: number | null;
  expireMins?: number | null;
};
export type NoteAction = {
  inboxIds?: string | null;
  userId?: number | null;
};
export type GetOrdersByTentantIdResult = {
  orderID?: number;
  orderNumber?: string | null;
  tenantLinkId?: number;
  labelLinkId?: number | null;
  publisherLinkId?: number | null;
  formatLinkId?: number | null;
  partTypeLinkId?: number;
  supplierLinkId?: number;
  orderNotes?: string | null;
  createdBy?: number;
  createdByName?: string | null;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  fifIstrCatNo?: string | null;
  fifiPurchaseOrderNo?: string | null;
  supplierId?: number | null;
  supplierName?: string | null;
  platformId?: number | null;
  partTypeID?: number;
  partType?: string | null;
  carriesAudio?: boolean | null;
  formatId?: number | null;
  catNo?: string | null;
  upc?: string | null;
  variousArtists?: boolean | null;
  formatTypeId?: number | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  labelId?: number | null;
  labelName?: string | null;
  clientId?: number | null;
  clientName?: string | null;
  localeContributorNames?: string | null;
  contributorIds?: string | null;
  title?: string | null;
  subTitle?: string | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroup?: string | null;
  packshotAssetId?: number | null;
  packshotGUID?: string | null;
  totalRequired?: number | null;
  totalDelivered?: number | null;
  dateRequired?: string | null;
  dateAchieved?: string | null;
  noOfDeliverySplits?: number | null;
  totalRecharged?: number | null;
  aggregatedOrderDetailID?: string | null;
  aggregatedQuantityRequired?: string | null;
  aggregatedQuantityDelivered?: string | null;
  aggregatedOrderDetailNotes?: string | null;
  aggregatedDestination?: string | null;
  deliveryJSON?: string | null;
  currencyId?: number | null;
  currencySymbol?: string | null;
  symbolBeforeValue?: boolean | null;
};
export type RechargeVm = {
  rechargeId?: number | null;
  orderDetailLinkId?: number | null;
  rechargeClientLinkId: number;
  clientName?: string | null;
  rechargeCategoryLinkId: number;
  rechargeNotes: string;
  rechargeAmount: number;
  commissionAmount?: number | null;
  rechargeMonth: string;
  dateRecharged?: string | null;
  isRecharged?: boolean | null;
  supplierLinkId: number;
  supplierName?: string | null;
  supplierTaxTypeOptionLinkId?: number | null;
  partTypeLinkId?: number | null;
  relatedAssetLinkId?: number | null;
  assetName?: string | null;
  relatedContributorLinkId?: number | null;
  contributorName?: string | null;
  relatedFormatAssetLinkId?: number | null;
  relatedFormatLinkId?: number | null;
  formatTitle?: string | null;
  relatedFormatTitleGroup?: number | null;
  relatedLabelLinkId?: number | null;
  labelName?: string | null;
  relatedPublisherLinkId?: number | null;
  publisherName?: string | null;
};
export type OrderDetailsVm = {
  orderDetailId?: number | null;
  orderLinkId?: number | null;
  deliveryDateRequired: string;
  deliveryDateAcheived?: string | null;
  quantityRequired: number;
  quantityDelivered?: number | null;
  orderDetailNotes?: string | null;
  deliveryAddressLinkId?: number | null;
  deliveryAddressName?: string | null;
  deliveryAddress?: DeliveryAddressVm;
  rechargeDetails?: RechargeVm[] | null;
};
export type UspGetOrderSummaryForFormatIdResult = {
  partType?: string | null;
  supplierName?: string | null;
  quantityOrdered?: number | null;
  quantityDelivered?: number | null;
  lastOrderedDate?: string | null;
  lastDeliveryDateRequired?: string | null;
  lastDeliveryDateAchieved?: string | null;
  partTypeID?: number | null;
  tenantLinkId?: number | null;
  labelLinkId?: number | null;
  publisherLinkId?: number | null;
  formatLinkId?: number | null;
  catNo?: string | null;
  upc?: string | null;
  supplierId?: number | null;
  platformId?: number | null;
};
export type OrderVm = {
  orderId?: number | null;
  formatId?: number | null;
  formatTitle?: string | null;
  formatPackshotGuid?: string | null;
  formatReleaseDate?: string | null;
  formatLocaleContributorNames?: string | null;
  formatFormatType?: string | null;
  formatUpc?: string | null;
  formatCatNo?: string | null;
  orderCancelled?: boolean;
  dateOrderEmailed?: string | null;
  supplierId: number;
  supplierName?: string | null;
  publisherLinkId?: number | null;
  publisherName?: string | null;
  labelLinkId?: number | null;
  labelName?: string | null;
  partTypeId: number;
  orderNotes: string;
  orderDetails: OrderDetailsVm[];
  formatSummary?: UspGetOrderSummaryForFormatIdResult[] | null;
};
export type GetRechargesByTenantIdResult = {
  rechargeID?: number;
  clientId?: number | null;
  clientName?: string | null;
  rechargeCategoryLinkId?: number | null;
  rechargeCategory?: string | null;
  supplierId?: number | null;
  supplierName?: string | null;
  orderDetailLinkId?: number | null;
  rechargeNotes?: string | null;
  currencySymbol?: string | null;
  symbolBeforeValue?: boolean | null;
  rechargeAmount?: number | null;
  commissionAmount?: number | null;
  rechargeMonth?: string | null;
  isRecharged?: boolean | null;
  dateRecharged?: string | null;
  labelId?: number | null;
  labelName?: string | null;
  relatedPublisherLinkId?: number | null;
  publisherName?: string | null;
  relatedFormatLinkId?: number | null;
  relatedFormatAssetLinkId?: number | null;
  relatedAssetLinkId?: number | null;
  relatedContributorLinkId?: number | null;
  createdBy?: number;
  createdByName?: string | null;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  fifiRechargeId?: number | null;
  partTypeLinkId?: number | null;
  relatedFormatTitleGroup?: number | null;
  reportedOnSalesInvoiceNo?: string | null;
  partType?: string | null;
  formatId?: number | null;
  catNo?: string | null;
  upc?: string | null;
  formatTypeId?: number | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroup?: string | null;
  packshotAssetId?: number | null;
  packshotGuid?: string | null;
  titleGroupId?: number | null;
  artist?: string | null;
  title?: string | null;
  subTitle?: string | null;
  isrc?: string | null;
  formatIdList?: string | null;
  formatAssetIdList?: string | null;
  catNoList?: string | null;
  upcList?: string | null;
  assetId?: number | null;
  assetGuid?: string | null;
  assetTypeGroupTag?: string | null;
  contributorId?: number | null;
  aggregatedContributorIds?: string | null;
  aggregatedPONumbers?: string | null;
  aggregatedProjectPONumbers?: string | null;
};
export type PartTypeVm = {
  partTypeId?: number | null;
  description?: string | null;
  partTypeCategoryLinkId: number;
  numberOfItems?: number | null;
  carriesAudio?: boolean;
};
export type TrendsPhysicalStockSummaryLatest = {
  warehouse?: string | null;
  clientName?: string | null;
  label?: string | null;
  catno?: string | null;
  upc?: string | null;
  packshotGUID?: string | null;
  release_date?: string | null;
  new_release?: boolean | null;
  date_deleted?: string | null;
  artist?: string | null;
  title?: string | null;
  format_type?: string | null;
  last_date_in_stock?: string | null;
  current_stock?: number | null;
  back_orders?: number | null;
  sales_all_time?: number | null;
  sales_last_7_days?: number | null;
  sales_previous_7_days?: number | null;
  sales_last_30_days?: number | null;
  sales_previous_30_days?: number | null;
  sales_previous_30_days_minus1?: number | null;
  sales_last_90_days?: number | null;
  sales_last_180_days?: number | null;
  sales_last_365_days?: number | null;
  sales_year_to_date?: number | null;
  returns_all_time?: number | null;
  days_OOS?: number | null;
  predicted_stock_required_30_days?: number | null;
  stock_received_last_7_days?: number | null;
  stock_received_last_30_days?: number | null;
  excess_stock_units?: number | null;
  stock_issue?: string | null;
  issue_rank?: number | null;
  formatId?: number | null;
  clientId?: number | null;
  labelId?: number | null;
  platformCatNo?: string | null;
  last_date_for_data?: string | null;
  tenantId?: number | null;
  platform_id?: number | null;
  delivery_address_id?: number | null;
};
export type CompactJsonTableTrendsPhysicalStockSummaryLatest = {
  rowModel?: TrendsPhysicalStockSummaryLatest;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type TrendsPhysicalStockReceivedSummary = {
  warehouse?: string | null;
  catno?: string | null;
  upc?: string | null;
  stock_received?: number | null;
  date_received?: string | null;
  label_name?: string | null;
  client_name?: string | null;
  format_id?: number | null;
  platform_id?: number | null;
  labelId?: number | null;
  clientId?: number | null;
  tenantId?: number | null;
  abS_UUID?: string | null;
};
export type CompactJsonTableTrendsPhysicalStockReceivedSummary = {
  rowModel?: TrendsPhysicalStockReceivedSummary;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type UspGetRatesForPlatformExclusivesResult = {
  rateId?: number | null;
  rateName?: string | null;
  rateDescription?: string | null;
  rateCategoryLinkId?: number | null;
  rateValueTypeLinkId?: number | null;
  isActive?: boolean | null;
};
export type TimeSpan = {
  ticks?: number;
  days?: number;
  hours?: number;
  milliseconds?: number;
  microseconds?: number;
  nanoseconds?: number;
  minutes?: number;
  seconds?: number;
  totalDays?: number;
  totalHours?: number;
  totalMilliseconds?: number;
  totalMicroseconds?: number;
  totalNanoseconds?: number;
  totalMinutes?: number;
  totalSeconds?: number;
};
export type PlatformDeliveryBatchScheduleVm = {
  platformDeliveryBatchScheduleId?: number | null;
  monthNo: number;
  dayOfMonthNo: number;
  lastRunDate?: string | null;
  isActive: boolean;
  dayOfWeekNo?: number | null;
  time?: TimeSpan;
};
export type PlatformCommercialModelVm = {
  platformCommercialModelId?: number | null;
  commercialModelLinkId?: number;
  useTypeLinkId?: number;
  allowDownloads?: boolean;
  allowStreamingAdSupported?: boolean;
  allowStreamingSubscription?: boolean;
  allowRightsClaim?: boolean;
  allowPlatformAssetEarliestStartDate?: boolean | null;
  excludePriceElement?: boolean | null;
};
export type SystemPlatformPolicyVm = {
  systemPlatformPolicyId?: number | null;
  platformPolicyName: string;
  isActive?: boolean | null;
  isDefault?: boolean | null;
  policyGroups: number[];
};
export type UspGetPlatformsBasicByUserAndTenantIdResult = {
  allowedToEdit?: boolean | null;
  isTenantOwned?: boolean | null;
  hasDeliveryGroup?: boolean | null;
  hasTenantDeliveryDetails?: boolean | null;
  showToUser?: boolean | null;
  platformId?: number | null;
  platformName?: string | null;
  pathToLogo?: string | null;
  isActive?: boolean | null;
  hidden?: boolean | null;
  parentPlatformLinkId?: number | null;
  deliveryPriority?: number | null;
  defaultRoyaltyMonthToReportOffset?: number | null;
  defaultPaymentCurrencyLinkId?: number | null;
  royaltyIngestionFileTypes?: string | null;
  currency?: string | null;
  platformDeliveryGroupId?: number | null;
  platformDeliveryGroupName?: string | null;
  metadataName?: string | null;
  deliveryCompleteFileName?: string | null;
  sendImage?: boolean | null;
  allowBooklets?: boolean | null;
  disableUpdate?: boolean | null;
  disableTakedown?: boolean | null;
  showBonusTrack?: boolean | null;
  useSystemGenres?: boolean | null;
  useSystemPrices?: boolean | null;
  useSystemArtistRoles?: boolean | null;
  useSystemContributorRoles?: boolean | null;
  metadataTypeLinkId?: number | null;
  metadataTypeName?: string | null;
  metadataFileType?: string | null;
  useSystemInstrumentRoles?: boolean | null;
  allowTimedRelease?: boolean | null;
  allowTimedPreOrder?: boolean | null;
  sendLyrics?: boolean | null;
  maxDeliveryBatchSize?: number | null;
  allowWorldwideTag?: boolean | null;
  allowDDEXDetailedPreviewDates?: boolean | null;
  allowEarlyAssetReleaseDates?: boolean | null;
  isMerlinDeal?: boolean | null;
  allowInstantGrats?: boolean | null;
  tenantId?: number | null;
  tenantName?: string | null;
  ddexPartyLinkId?: number | null;
  tenantDDEXPartyId?: string | null;
  uniqueShortNameId?: string | null;
  ddexPartyId?: number | null;
  dpid?: string | null;
  company?: string | null;
  isDefaultTenantPlatform?: boolean | null;
  platformDeliveryAssetType?: string | null;
  platformFormatType_JSON?: string | null;
  platformDisplayGroups_JSON?: string | null;
  platformBusinessTypes_JSON?: string | null;
  systemPlatformPolicies_JSON?: string | null;
  territories_JSON?: string | null;
  audioAssetFormats_JSON?: string | null;
  videoAssetFormats_JSON?: string | null;
  sendSystemContributorIdsInDDEX?: boolean | null;
  deliverToTestFolder?: boolean | null;
};
export type PlatformVm = {
  platformId?: number | null;
  platformName: string;
  platformDisplayGroupLinkId: number;
  pathToLogo?: string | null;
  pathToIcon?: string | null;
  isActive: boolean;
  hidden: boolean;
  parentPlatformLinkId?: number | null;
  ddexPartyLinkId?: number | null;
  ddexPartyName?: string | null;
  deliveryPriority?: number | null;
  defaultRoyaltyMonthToReportOffset?: number | null;
  defaultPaymentCurrencyLinkId?: number | null;
  royaltyIngestionDispatcherTaskId?: number | null;
  royaltyIngestionFileTypes?: string | null;
  rightsClaimBlockToStartDate?: boolean | null;
  platformTerritories?: number[] | null;
  formatTypes: number[];
  formatTypeGroups?: number[] | null;
  platformBusinessTypes: number[];
  platformDeliveryBatchSchedules?: PlatformDeliveryBatchScheduleVm[] | null;
  platformCommercialModels?: PlatformCommercialModelVm[] | null;
  systemPlatformPolicies?: SystemPlatformPolicyVm[] | null;
  summary?: UspGetPlatformsBasicByUserAndTenantIdResult;
};
export type PlatformDeliveryGroupSearchVm = {
  platformLinkId?: number;
  platformDeliveryGroupLinkId?: number;
  platformDeliveryGroupName?: string | null;
};
export type PlatformDeliveryGroupFileDetailsVm = {
  fileDetailsId?: number | null;
  assetTypeToFileFormatLinkId?: number;
  bitRate?: number | null;
  codec?: string | null;
  useOriginalFormat?: boolean | null;
  fileNamePath?: string | null;
  width?: number | null;
  height?: number | null;
  colorSpaceLinkId?: number | null;
};
export type PlatformDeliveryGroupDeliveryDelayVm = {
  deliveryDelayId?: number | null;
  formatTypeLinkId: number;
  deliveryDelayMinutes?: number | null;
};
export type PlatformDeliveryGroupVm = {
  platformDeliveryGroupId?: number | null;
  platformDeliveryGroupName?: string | null;
  metadataName?: string | null;
  deliveryCompleteFileName?: string | null;
  sendImage?: boolean;
  allowBooklets?: boolean;
  disableUpdate?: boolean;
  disableTakedown?: boolean;
  showBonusTrack?: boolean;
  useSystemGenres?: boolean;
  useSystemPrices?: boolean;
  useSystemArtistRoles?: boolean;
  useSystemContributorRoles?: boolean;
  metadataTypeLinkId?: number | null;
  useSystemInstrumentRoles?: boolean;
  delCompleteInReleaseFolder?: boolean;
  allowTimedRelease?: boolean;
  allowTimedPreOrder?: boolean;
  sendLyrics?: boolean;
  maxDeliveryBatchSize?: number;
  allowWorldwideTag?: boolean | null;
  allowDdexdetailedPreviewDates?: boolean | null;
  allowEarlyAssetReleaseDates?: boolean | null;
  platformDeliveryAssetTypeLinkId?: number;
  sendSystemContributorIdsInDdex: boolean;
  deliverToTestFolder: boolean;
  useSystemWriterRoles?: boolean | null;
  audioFileDetails?: PlatformDeliveryGroupFileDetailsVm[] | null;
  imageFileDetails?: PlatformDeliveryGroupFileDetailsVm[] | null;
  textFileDetails?: PlatformDeliveryGroupFileDetailsVm[] | null;
  videoFileDetails?: PlatformDeliveryGroupFileDetailsVm[] | null;
  deliveryDelays?: PlatformDeliveryGroupDeliveryDelayVm[] | null;
  isShared?: boolean;
};
export type PlatformDeliveryDetailsSearchVm = {
  host?: string | null;
  username?: string | null;
  notes?: string | null;
};
export type DeliveryPrivateKeyVm = {
  privateKey?: string | null;
  name?: string | null;
  description?: string | null;
};
export type PlatformDeliveryContactVm = {
  firstname?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  genderLinkId?: number | null;
};
export type PlatformDeliveryDetailsVm = {
  platformDeliveryDetailsId?: number | null;
  host?: string | null;
  username?: string | null;
  password?: string | null;
  serverTypeLinkId?: number | null;
  activeConnection?: boolean | null;
  port?: number | null;
  deliveryPath?: string | null;
  sendWriterMetadata?: boolean;
  sendContributorMetadata?: boolean;
  originalClientDeliveryAccount?: number | null;
  notes?: string | null;
  sendPublisherMetadata?: boolean;
  ftproot?: string | null;
  releaseFolder?: string | null;
  blobAccessKey?: string | null;
  blobSecretKey?: string | null;
  keyVaultSecret?: string | null;
  blobBucketName?: string | null;
  deliveryEmail?: string | null;
  maxDeliveriesPerIp?: number | null;
  maxDeliveriesPerAccount?: number | null;
  rightsClaimModelStartDateOverride?: boolean | null;
  deliveryPrivateKey?: DeliveryPrivateKeyVm;
  platformDeliveryContacts?: PlatformDeliveryContactVm[] | null;
};
export type PlatformTenantDeliveryDetailsVm = {
  platformDeliveryDetailsId?: number | null;
  host?: string | null;
  username?: string | null;
  password?: string | null;
  serverTypeLinkId?: number | null;
  activeConnection?: boolean | null;
  port?: number | null;
  deliveryPath?: string | null;
  sendWriterMetadata?: boolean;
  sendContributorMetadata?: boolean;
  originalClientDeliveryAccount?: number | null;
  notes?: string | null;
  sendPublisherMetadata?: boolean;
  ftproot?: string | null;
  releaseFolder?: string | null;
  blobAccessKey?: string | null;
  blobSecretKey?: string | null;
  keyVaultSecret?: string | null;
  blobBucketName?: string | null;
  deliveryEmail?: string | null;
  maxDeliveriesPerIp?: number | null;
  maxDeliveriesPerAccount?: number | null;
  rightsClaimModelStartDateOverride?: boolean | null;
  deliveryPrivateKey?: DeliveryPrivateKeyVm;
  platformDeliveryContacts?: PlatformDeliveryContactVm[] | null;
  clientLinkId?: number | null;
  isMerlinDeal?: boolean | null;
  platformAccountRefOrNo?: string | null;
};
export type AcAssetInfoVm = {
  assetId?: number;
  assetInfoId?: number | null;
  name?: string | null;
  mimeType?: string | null;
  md5?: string | null;
  size?: number | null;
  duration?: number | null;
  guid?: string | null;
  extension?: string | null;
};
export type AcArtistWorkSpec = {
  formatTypeGroupId?: number;
  isDigital?: boolean;
  height?: number;
  minHeight?: number;
  width?: number;
  minWidth?: number;
  ratioHeight?: number;
  ratioWidth?: number;
  ratioValue?: string | null;
  colorSpace?: string | null;
};
export type AcConfigVm = {
  defaultLabelId?: number;
  metadataLanguageId?: number;
  assetList?: AcAssetInfoVm[] | null;
  isDigital?: boolean;
  isAudio?: boolean;
  formatTypeGroupId?: number;
  artistWorkSpecs?: AcArtistWorkSpec[] | null;
};
export type AcGenerateMetaDataVm = {
  type?: string | null;
  labelId?: number;
  formatTypeLinkId?: number;
};
export type AcPhysicalFormatMappingVm = {
  id?: number;
  text?: string | null;
  formatTypeId?: number;
  units?: number | null;
  height?: number | null;
  weight?: number | null;
  length?: number | null;
  depth?: number | null;
};
export type PlatformIdentifierVm = {
  platformId?: number;
  platformIdentifier?: string | null;
};
export type AcBasicPhysicalVm = {
  productDimensionId?: number;
  units?: number;
  height?: number;
  weight?: number;
  length?: number;
  depth?: number;
  countryOfOriginId?: number;
  exportOnly?: boolean;
  narrative?: string | null;
  marketingInfo?: string | null;
  dvdAgeRatingId?: number;
  dvdRegionCodeId?: number;
};
export type AcTitleVm = {
  text?: string | null;
  titleVersion?: string | null;
  languageId?: number;
};
export type AcArtistVm = {
  contributorId?: number;
  roleId?: number;
  text?: string | null;
};
export type AcBasicVm = {
  id?: number;
  labelId?: number;
  formatTypeId?: number;
  physical?: AcBasicPhysicalVm;
  cat?: string | null;
  standardCat?: string | null;
  upc?: string | null;
  metadataLanguageId?: number;
  genreList?: number[] | null;
  titleList?: AcTitleVm[] | null;
  variousArtists?: boolean;
  artistList?: AcArtistVm[] | null;
  imageAssetInfoId?: number;
  guid?: string | null;
  relatedFormatId?: number | null;
  notes?: string | null;
};
export type AcLabelVm = {
  clientId?: number;
  name?: string | null;
};
export type AcImmerTrackVm = {
  isrc?: string | null;
  assetInfoId?: number | null;
};
export type AcImmerVm = {
  formatId?: number;
  formatTypeId?: number;
  cat?: string | null;
  upc?: string | null;
  trackList?: AcImmerTrackVm[] | null;
};
export type AcIndividualPlatformDealVm = {
  dealId?: number | null;
  startDate?: string | null;
  preOrder?: string | null;
  platformId?: number;
};
export type AcTerritoryDealVm = {
  dealId?: number | null;
  startDate?: string | null;
  preOrder?: string | null;
  territoryId?: number;
  platformId?: number;
};
export type AcDigitalDealVm = {
  dealId?: number | null;
  download?: boolean;
  allowStreaming?: boolean;
  formatPriceId?: number;
  assetPriceId?: number;
  preOrderPreview?: string | null;
  clipPreviewDate?: string | null;
  trackListPreviewDate?: string | null;
  covertArtPreviewDate?: string | null;
  platformExclusives?: AcIndividualPlatformDealVm[] | null;
  territoryExclusives?: AcTerritoryDealVm[] | null;
};
export type AcPhysicalDealVm = {
  dealId?: number | null;
  embargoDate?: string | null;
  dealerPrice?: number | null;
  buyPrice?: number | null;
  hmvPrice?: number | null;
  indieExclusive?: boolean;
  exportOnly?: boolean;
  exclusiveFor?: string | null;
};
export type AcFormatRightVm = {
  rightsControllerId?: number;
  pLine?: string | null;
  pLineYear?: number;
  territoryList?: number[] | null;
  registerNeighbouringRights?: boolean;
  labelId?: number | null;
  labelName?: string | null;
  cLine?: string | null;
  cLineYear?: number;
};
export type AcReleaseVm = {
  formatId?: number;
  isCatalogueRelease?: boolean;
  startDate?: string | null;
  isUtc?: boolean;
  originalReleaseDate?: string | null;
  preOrder?: string | null;
  digitalDeal?: AcDigitalDealVm;
  physicalDeal?: AcPhysicalDealVm;
  territoryList?: number[] | null;
  platformList?: number[] | null;
  rights?: AcFormatRightVm;
};
export type AcReleaseConfigVm = {
  isSingleTrackRelease?: boolean;
  isNew?: boolean;
  isDigital?: boolean;
  isAudio?: boolean;
  platformList?: SelectListVm[] | null;
  defaultTerritoryList?: number[] | null;
  defaultPlatformsList?: number[] | null;
  priceTierList?: SelectListVm[] | null;
  priceTierType?: string | null;
};
export type UspGetPriceTiersForFormatsResult = {
  priceTierTypeId?: number;
  priceTierType?: string | null;
  isFormatPrice?: boolean;
  priceTierId?: number;
  priceTierName?: string | null;
  maxNoOfTracks?: number | null;
  maxNoOfInstantGrats?: number | null;
  priceCheckForValidation?: number | null;
  currency?: string | null;
  currencySymbol?: string | null;
  isoAlpha3Currency?: string | null;
  symbolBeforeValue?: boolean | null;
  estimatedPrice?: number | null;
  platformLinkId?: number | null;
  platformName?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroupName?: string | null;
  isDefaultPricePerFormatGroup?: boolean | null;
};
export type AcPlatformDealVm = {
  dealId?: number | null;
  startDate?: string | null;
  preOrder?: string | null;
  platformList?: number[] | null;
};
export type PlatformDspAndTerritoryExcsVm = {
  platformDspExc?: AcPlatformDealVm[] | null;
  territoryExc?: AcTerritoryDealVm[] | null;
};
export type CityTimeZone = {
  cityTimeZoneId?: number;
  city?: string | null;
  cityAscii?: string | null;
  lat?: number | null;
  lng?: number | null;
  pop?: number | null;
  country?: string | null;
  iso2?: string | null;
  iso3?: string | null;
  province?: string | null;
  timezone?: string | null;
  territoryLinkId?: number | null;
};
export type AcDealVm = {
  dealId?: number | null;
  dealTypeLinkId?: number;
  formatId?: number;
  download?: boolean | null;
  streaming?: boolean | null;
  physicalPriceId?: number | null;
  formatPriceId?: number | null;
  assetPriceId?: number | null;
  startDate?: string | null;
  originalReleaseDate?: string | null;
  isUtc?: boolean | null;
  endDate?: string | null;
  preOrder?: string | null;
  preOrderPreview?: string | null;
  clipPreviewDate?: string | null;
  trackListPreviewDate?: string | null;
  covertArtPreviewDate?: string | null;
  territoryList?: number[] | null;
  platformList?: number[] | null;
  embargoDate?: string | null;
  dealerPrice?: number | null;
  buyPrice?: number | null;
  hmvPrice?: number | null;
  indieExclusive?: boolean;
  exportOnly?: boolean;
  exclusiveFor?: string | null;
};
export type AcDealsVm = {
  formatId?: number;
  isDigital?: boolean;
  isCatalogueRelease?: boolean;
  dealList?: AcDealVm[] | null;
  rights?: AcFormatRightVm;
};
export type AcPublisherVm = {
  publisherId?: number;
  text?: string | null;
};
export type UspGetTrackDisplayDetailsForFormatResult = {
  formatLinkId?: number;
  formatAssetId?: number;
  volume?: number | null;
  side?: number | null;
  sequence?: number | null;
  isHidden?: boolean | null;
  isBonus?: boolean | null;
  isFocusAsset?: boolean | null;
  assetGuid?: string;
  assetId?: number;
  assetTypeId?: number;
  assetTypeName?: string | null;
  assetTypeGroupName?: string | null;
  metadataLanguageId?: number;
  metadataLanguage?: string | null;
  parentalWarningTypeId?: number;
  parentalWarningType?: string | null;
  blobGuid?: string | null;
  titleLanguageId?: number | null;
  titleLanguage?: string | null;
  assetTitle?: string | null;
  assetTitleVersion?: string | null;
  assetRecordingId?: number | null;
  isrc?: string | null;
  recordingTerritoryId?: number | null;
  recordingTerritory?: string | null;
  commissioningTerritoryId?: number | null;
  commissionTerritory?: string | null;
  isReRecord?: boolean | null;
  audioLanguageId?: number | null;
  audioLanguage?: string | null;
  isInstrumental?: boolean | null;
  isNonMusic?: boolean | null;
  isGapless?: boolean | null;
  genreId?: number | null;
  genre?: string | null;
  subGenre?: string | null;
  genreLine?: string | null;
  releaseDealId?: number | null;
  assetPriceId?: number | null;
  startDateTime?: string | null;
  allowDownload?: boolean | null;
  allowStreaming?: boolean | null;
  clipPreviewDate?: string | null;
  artistNames?: string | null;
  assetInformationId?: number | null;
  originalFileName?: string | null;
  mimeType?: string | null;
  assetFormat?: string | null;
  mD5?: string | null;
  sizeBytes?: number | null;
  duration?: number | null;
  isSystemConflict?: boolean | null;
};
export type AcAssetDealVm = {
  download?: boolean;
  allowStreaming?: boolean;
  assetPriceId?: number;
  startDate?: string | null;
  clipPreviewDate?: string | null;
};
export type AcAssetRightVm = {
  rightsControllerId?: number;
  pLine?: string | null;
  pLineYear?: number;
  territoryList?: number[] | null;
  registerNeighbouringRights?: boolean;
  labelId?: number | null;
  labelName?: string | null;
  assetId?: number | null;
  startDate?: string;
  endDate?: string | null;
  policyId?: number | null;
};
export type AcContributorVm = {
  contributorId?: number;
  text?: string | null;
  roleList?: number[] | null;
  instrumentList?: number[] | null;
};
export type AcWriterDealVm = {
  publisherId?: number;
  text?: string | null;
  territoryList?: number[] | null;
  startDate?: string;
  endDate?: string | null;
};
export type AcWriterVm = {
  workId?: number;
  contributorId?: number;
  text?: string | null;
  percentage?: number;
  roleList?: number[] | null;
  dealList?: AcWriterDealVm[] | null;
};
export type AcWorkVm = {
  assetId?: number;
  workId?: number;
  title?: string | null;
  iswc?: string | null;
  creationYear?: number;
  opusNumber?: string | null;
  writerList?: AcWriterVm[] | null;
  publisherList?: AcPublisherVm[] | null;
};
export type AcLyricsVm = {
  assetId?: number | null;
  languageId?: number | null;
  lyrics?: string | null;
};
export type AcPreviewVm = {
  dspId?: number;
  second?: number;
  previewDate?: string | null;
};
export type AcTrackFormVm = {
  formatLinkId?: number;
  formatAssetId?: number;
  volume?: number | null;
  side?: number | null;
  sequence?: number | null;
  isHidden?: boolean | null;
  isBonus?: boolean | null;
  isFocusAsset?: boolean | null;
  assetGuid?: string;
  assetId?: number | null;
  assetTypeId?: number;
  assetTypeName?: string | null;
  assetTypeGroupName?: string | null;
  metadataLanguageId?: number;
  metadataLanguage?: string | null;
  parentalWarningTypeId?: number;
  parentalWarningType?: string | null;
  blobGuid?: string | null;
  titleLanguageId?: number | null;
  titleLanguage?: string | null;
  assetTitle?: string | null;
  assetTitleVersion?: string | null;
  assetRecordingId?: number | null;
  isrc?: string | null;
  recordingTerritoryId?: number | null;
  recordingTerritory?: string | null;
  commissioningTerritoryId?: number | null;
  commissionTerritory?: string | null;
  isReRecord?: boolean | null;
  audioLanguageId?: number | null;
  audioLanguage?: string | null;
  isInstrumental?: boolean | null;
  isNonMusic?: boolean | null;
  isGapless?: boolean | null;
  genreId?: number | null;
  genre?: string | null;
  subGenre?: string | null;
  genreLine?: string | null;
  releaseDealId?: number | null;
  assetPriceId?: number | null;
  startDateTime?: string | null;
  allowDownload?: boolean | null;
  allowStreaming?: boolean | null;
  clipPreviewDate?: string | null;
  artistNames?: string | null;
  assetInformationId?: number | null;
  originalFileName?: string | null;
  mimeType?: string | null;
  assetFormat?: string | null;
  mD5?: string | null;
  sizeBytes?: number | null;
  duration?: number | null;
  isSystemConflict?: boolean;
  formatId?: number;
  titleList?: AcTitleVm[] | null;
  genreList?: number[] | null;
  deal?: AcAssetDealVm;
  rightList?: AcAssetRightVm[] | null;
  artistList?: AcArtistVm[] | null;
  contributorList?: AcContributorVm[] | null;
  workList?: AcWorkVm[] | null;
  lyricDetails?: AcLyricsVm;
  relatedIsrc?: string | null;
  previewList?: AcPreviewVm[] | null;
  meadList?: number[] | null;
  appleEngineerEmail?: string | null;
  hasPreview?: boolean;
};
export type AcTrackOverviewVm = {
  label?: string | null;
  basic?: AcBasicVm;
  release?: AcReleaseVm;
  isDigitalFormat?: boolean;
  isAudioFormat?: boolean;
  formatReleaseDate?: string;
  isMixedMedia?: boolean;
  isSingleTrackRelease?: boolean;
  trackList?: UspGetTrackDisplayDetailsForFormatResult[] | null;
  formTrackList?: AcTrackFormVm[] | null;
};
export type AcTrackCreditVm = {
  vol?: number;
  seq?: number;
  guid?: string | null;
  title?: string | null;
  artistList?: AcArtistVm[] | null;
  contributorList?: AcContributorVm[] | null;
  workList?: AcWorkVm[] | null;
};
export type AssetSearchModel = {
  assetId?: number;
  assetGuid?: string | null;
  assetTypeGroupTag?: string | null;
  languageId?: number | null;
  languageName?: string | null;
  assetTitle?: string | null;
  assetTitleVersion?: string | null;
  assetRecordingId?: number | null;
  isrc?: string | null;
  recordingTerritoryId?: number | null;
  recordingTerritoryName?: string | null;
  isNonMusic?: boolean | null;
  genreId?: number | null;
  mainGenre?: string | null;
  subGenre?: string | null;
  blobGuid?: string | null;
  artistNames?: string | null;
  artistIds?: number[] | null;
  formatIdList?: number[] | null;
  formatAssetIdList?: number[] | null;
  catNoList?: string[] | null;
  upclist?: string[] | null;
  packshotGuid?: string | null;
  assetCreatedByClientId?: number | null;
  ownedByClientId?: number[] | null;
  licensedByClientId?: string | null;
  assetEtag?: number | null;
  fifiTrackId?: number | null;
  isSystemConflict?: boolean;
  tenantId?: number | null;
  updatedOn?: string | null;
  isInstrumental?: boolean | null;
  labelList?: number[] | null;
  formatId?: number;
  cat?: string | null;
};
export type AcAssetReOrderVm = {
  volume?: number;
  sequence?: number;
  assetId?: number;
};
export type AcReOrderVm = {
  formatId?: number;
  assets?: AcAssetReOrderVm[] | null;
};
export type AcAddTrackVm = {
  formatId?: number;
  assetId?: number;
  message?: string | null;
  success?: boolean;
  vol?: number;
  track?: UspGetTrackDisplayDetailsForFormatResult;
};
export type AcDeleteTrack = {
  formatId?: number;
  assetId?: number;
};
export type UspGetContributorKnownInstrumentsResult = {
  instrumentRoleId?: number | null;
  instrumentRoleName?: string | null;
  numberOfTimesUsed?: number | null;
};
export type UspGetContributorKnownPerformersResult = {
  contributorLinkId?: number | null;
  assetCount?: number | null;
  localeContributorName?: string | null;
  resourceContributorRoleLinkIds?: string | null;
  resourceContributorRoleNames?: string | null;
  instrumentRoleLinkIds?: string | null;
  instrumentRoleNames?: string | null;
};
export type UspGetContributorKnownPublishersResult = {
  publisherId?: number | null;
  publisherName?: string | null;
  publisherConnectionCount?: number | null;
  lastConnectionDate?: string | null;
  isKnownConnection?: boolean | null;
};
export type FactAssetsSummaryListCache = {
  assetId?: number;
  assetGuid?: string | null;
  assetTypeGroupTag?: string | null;
  languageId?: number | null;
  languageName?: string | null;
  assetTitle?: string | null;
  assetTitleVersion?: string | null;
  assetRecordingId?: number | null;
  isrc?: string | null;
  recordingTerritoryId?: number | null;
  recordingTerritoryName?: string | null;
  isNonMusic?: boolean | null;
  genreId?: number | null;
  mainGenre?: string | null;
  subGenre?: string | null;
  blobGuid?: string | null;
  artistNames?: string | null;
  artistIds?: string | null;
  formatIdList?: string | null;
  formatAssetIdList?: string | null;
  catNoList?: string | null;
  upclist?: string | null;
  packshotGuid?: string | null;
  assetCreatedByClientId?: number | null;
  ownedByClientId?: string | null;
  licensedByClientId?: string | null;
  assetEtag?: number | null;
  fifiTrackId?: number | null;
  isSystemConflict?: boolean;
  tenantId?: number | null;
  updatedOn?: string | null;
  isInstrumental?: boolean | null;
  labelList?: string | null;
};
export type AssetInformationVideoDetail = {
  assetInformationLinkId?: number;
  duration?: number | null;
  totalBitRate?: number | null;
  videoStreams?: number | null;
  videoCodec?: string | null;
  videoCodecLong?: string | null;
  videoCodecProfile?: string | null;
  videoBitRate?: number | null;
  frameRate?: number | null;
  width?: number | null;
  height?: number | null;
  audioStreams?: number | null;
  audioChannels?: number | null;
  audioCodec?: string | null;
  audioCodecLong?: string | null;
  audioBitRate?: number | null;
  bpm?: number | null;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
};
export type AssetInformationAudioDetail = {
  assetInformationLinkId?: number;
  audioBitRate?: number | null;
  bitDepth?: number | null;
  sampleRateHz?: number | null;
  audioStreams?: number | null;
  audioChannels?: number | null;
  audioCodecLong?: string | null;
  audioCodec?: string | null;
  duration?: number | null;
  bpm?: number | null;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
};
export type AcAssetDetail = {
  formatLinkId?: number;
  formatAssetId?: number;
  volume?: number | null;
  side?: number | null;
  sequence?: number | null;
  isHidden?: boolean | null;
  isBonus?: boolean | null;
  isFocusAsset?: boolean | null;
  assetGuid?: string;
  assetId?: number | null;
  assetTypeId?: number;
  assetTypeName?: string | null;
  assetTypeGroupName?: string | null;
  metadataLanguageId?: number;
  metadataLanguage?: string | null;
  parentalWarningTypeId?: number;
  parentalWarningType?: string | null;
  blobGuid?: string | null;
  titleLanguageId?: number | null;
  titleLanguage?: string | null;
  assetTitle?: string | null;
  assetTitleVersion?: string | null;
  assetRecordingId?: number | null;
  isrc?: string | null;
  recordingTerritoryId?: number | null;
  recordingTerritory?: string | null;
  commissioningTerritoryId?: number | null;
  commissionTerritory?: string | null;
  isReRecord?: boolean | null;
  audioLanguageId?: number | null;
  audioLanguage?: string | null;
  isInstrumental?: boolean | null;
  isNonMusic?: boolean | null;
  isGapless?: boolean | null;
  genreId?: number | null;
  genre?: string | null;
  subGenre?: string | null;
  genreLine?: string | null;
  releaseDealId?: number | null;
  assetPriceId?: number | null;
  startDateTime?: string | null;
  allowDownload?: boolean | null;
  allowStreaming?: boolean | null;
  clipPreviewDate?: string | null;
  artistNames?: string | null;
  assetInformationId?: number | null;
  originalFileName?: string | null;
  mimeType?: string | null;
  assetFormat?: string | null;
  mD5?: string | null;
  sizeBytes?: number | null;
  duration?: number | null;
  isSystemConflict?: boolean;
  formatId?: number;
  titleList?: AcTitleVm[] | null;
  genreList?: number[] | null;
  deal?: AcAssetDealVm;
  rightList?: AcAssetRightVm[] | null;
  artistList?: AcArtistVm[] | null;
  contributorList?: AcContributorVm[] | null;
  workList?: AcWorkVm[] | null;
  lyricDetails?: AcLyricsVm;
  relatedIsrc?: string | null;
  previewList?: AcPreviewVm[] | null;
  meadList?: number[] | null;
  appleEngineerEmail?: string | null;
  hasPreview?: boolean;
  summary?: FactAssetsSummaryListCache;
  video?: AssetInformationVideoDetail;
  audio?: AssetInformationAudioDetail;
  relatedFormatIdList?: number[] | null;
};
export type AcPriceIntervalVm = {
  dealId?: number | null;
  platformId?: number;
  priceId?: number;
  territoryList?: number[] | null;
  startDate?: string;
  endDate?: string | null;
};
export type AcExtrasVm = {
  id?: number;
  appleMotionPhoneId?: number;
  appleMotionDesktopId?: number;
  priceIntervalList?: AcPriceIntervalVm[] | null;
};
export type AcExtrasConfigVm = {
  isDigital?: boolean;
  isAudio?: boolean;
  priceTierList?: SelectListVm[] | null;
  priceTierType?: string | null;
  defaultTerritoryList?: number[] | null;
  defaultStartDate?: string;
};
export type ApprovalState = 0 | 1 | 2;
export type AcReviewVm = {
  overview?: AcTrackOverviewVm;
  createdOn?: string;
  state?: ApprovalState;
  approvedDate?: string;
};
export type AcValidationStateVm = {
  approved?: boolean;
  cmoValidated?: boolean;
  platformValidated?: boolean;
};
export type AcValidationMessageVm = {
  group?: string | null;
  id?: string | null;
  explanation?: string | null;
  inputMessage?: string | null;
  critical?: boolean;
  nav?: string | null;
  assetGuid?: string | null;
};
export type AcValidateVm = {
  isValid?: boolean;
  errors?: AcValidationMessageVm[] | null;
};
export type AcApproveVm = {
  formatId?: number;
  request?: boolean;
};
export type AcQueueJobsErrorVm = {
  errorMessage?: string | null;
};
export type AcQueueJobsVm = {
  platformId?: number;
  platformName?: string | null;
  platformDisplayGroup?: string | null;
  allowDelivery?: boolean;
  alreadyExists?: boolean;
  formatDeliveryJobId?: number | null;
  errors?: AcQueueJobsErrorVm[] | null;
};
export type AcQueueDeliveryVm = {
  isValid?: boolean;
  messageList?: AcValidationMessageVm[] | null;
  spResultList?: AcQueueJobsVm[] | null;
};
export type AcApprovedOrValidated = {
  releaseDate?: string | null;
  approved?: boolean;
  delivered?: boolean;
  digitalSub?: boolean;
};
export type UspGetDefaultPlatformDealPoliciesByProfileResult = {
  systemPlatformPolicyId?: number | null;
  platformPolicyName?: string | null;
  platformLinkId?: number | null;
  platformId?: number | null;
  platformName?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroupName?: string | null;
  defaultLevel?: string | null;
};
export type UspGetDefaultPlatformsByProfileResult = {
  platformId?: number | null;
  platformName?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroupName?: string | null;
  defaultLevel?: string | null;
  isDigital?: boolean | null;
};
export type UspGetDefaultPlatformsAvailableByProfileResult = {
  platformId?: number | null;
  platformName?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroupName?: string | null;
  defaultLevel?: string | null;
};
export type UspGetDefaultPricesByProfileResult = {
  priceTierId?: number | null;
  priceTierName?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroupName?: string | null;
  maxNoOfTracks?: number | null;
  maxNoOfInstantGrats?: number | null;
  priceTierTypeId?: number | null;
  priceTierType?: string | null;
  defaultLevel?: string | null;
};
export type CreateClientAgreementVm = {
  clientId: number;
  copyPreviousRates?: boolean;
  startDate: string;
  endDate: string;
};
export type PaymentAccountVm = {
  accountId?: number | null;
  accountName: string;
  accountNumber: string;
  accountSortCode: string;
  accountIban?: string | null;
  accountBic?: string | null;
  bankTransactionTypeId?: number | null;
};
export type ProfileAddressVm = {
  addressId?: number | null;
  type?: string | null;
  line1: string;
  line2?: string | null;
  line3?: string | null;
  city: string;
  stateProvinceCounty?: string | null;
  zipOrPostcode: string;
  countryTerritoryLinkId?: number | null;
};
export type ClientBasicInfoVm = {
  clientName?: string | null;
  ddexPartyLinkId?: number | null;
  ddexPartyName?: string | null;
  payClientAutomatically?: boolean | null;
  addresses?: ProfileAddressVm[] | null;
};
export type ClientTenantTeamMemberVm = {
  userId?: number;
  roleId?: number;
  fullName?: string | null;
};
export type DefaultPlatformVm = {
  platformId?: number;
  formatTypeGroupId?: number;
};
export type ClientDefaultsVm = {
  defaultPlatforms?: DefaultPlatformVm[] | null;
  defaultTerritoryIds?: number[] | null;
  defaultAlbumPriceTier: number;
  defaultSinglePriceTier: number;
  defaultVideoPriceTier: number;
  policyGroupId?: number | null;
  noPlatformDealPolicies?: boolean | null;
  defaultLanguageId: number;
  defaultCurrencyId: number;
  upcPrefix?: string | null;
  isrcPrefix?: string | null;
  catNoPrefix?: string | null;
  autoGenerateUpc?: boolean | null;
  autoGenerateIsrc?: boolean | null;
  autoGenerateCatNo?: boolean | null;
};
export type PlatformToFormatTypeVm = {
  platformToFormatTypesId?: number;
  platformId?: number;
  platformName?: string | null;
  formatTypeId?: number;
  formatTypeName?: string | null;
  formatTypeGroupId?: number;
};
export type DomainCustomisationVm = {
  domainName: string;
  subDomainName?: string | null;
  customisationName?: string | null;
  logo?: string | null;
  favicon?: string | null;
  icon?: string | null;
  backgroundColor?: string | null;
  primaryColor?: string | null;
  accentColor?: string | null;
  textColor?: string | null;
  fontFamily?: string | null;
  fontScale?: number | null;
  footerContent?: string | null;
  customCss?: string | null;
  emailBackgroundColor?: string | null;
  emailPrimaryColor?: string | null;
  emailAccentColor?: string | null;
  emailTextColor?: string | null;
  logoLightBackground?: string | null;
};
export type ResourceVm = {
  resourceId?: number | null;
  resourceTypeId?: number;
  uri?: string | null;
};
export type ClientAgreementRateVm = {
  clientAgreementRateId?: number | null;
  value: number;
  rateLinkId: number;
  name?: string | null;
  platformLinkId?: number | null;
  valueType?: string | null;
  category?: string | null;
  basedOnNetSalesIncome?: boolean | null;
  basedOnNetSalesAndReturnsIncome?: boolean | null;
};
export type ClientAgreementVm = {
  clientAgreementId?: number | null;
  startDate: string;
  endDate: string;
  rollingDaysAfterEndDate: number;
  daysNotice: number;
  paymentCurrencyId?: number;
  registeredForTax?: boolean | null;
  taxNo?: string | null;
  salesTaxTypeOptionId?: number | null;
  purchasesTaxTypeOptionId?: number | null;
  vatRegistrationTerritory?: number | null;
  selfBillLastSignedDate?: string | null;
  allowSyncRight?: boolean;
  requiresNetSalesCostsInvoice?: boolean;
  mcpsRegisterApplications?: boolean;
  mcpsLicenceTypeId?: number | null;
  mcpsApiId?: string | null;
  useOwnAccount?: boolean | null;
  neighbouringRightsRegistration?: boolean | null;
  agreementConfirmed?: boolean;
  paymentAccountLinkId?: number | null;
  paymentAccount?: PaymentAccountVm;
  rates?: ClientAgreementRateVm[] | null;
  applyRatesFromCurrentAgreement?: boolean;
};
export type LabelSearchVm = {
  labelId?: number;
  clientName?: string | null;
  labelName?: string | null;
  parentLabelName?: string | null;
  labelManager?: string | null;
  artists?: string[] | null;
};
export type ParentLabelSearchVm = {
  labelId?: number;
  clientName?: string | null;
  labelName?: string | null;
  labelManager?: string | null;
  labels?: LabelSearchVm[] | null;
  artists?: string[] | null;
};
export type ClientVm = {
  basicInfo?: ClientBasicInfoVm;
  clientTenantTeamMembers?: ClientTenantTeamMemberVm[] | null;
  defaults?: ClientDefaultsVm;
  products?: number[] | null;
  domainCustomisation?: DomainCustomisationVm;
  autoAddNewPlatforms?: number[] | null;
  resources?: ResourceVm[] | null;
};
export type ClientSearchVm = {
  clientId?: number;
  clientName?: string | null;
  currencySymbol?: string | null;
  balanceAmount?: number;
  labelManagers?: string[] | null;
  parentLabels?: ParentLabelSearchVm[] | null;
};
export type GetUserAccessListVisibleOnlyResult = {
  userId?: number;
  displayName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  lastActivity?: string | null;
  isActive?: boolean;
  canEdit?: boolean | null;
  isDirectRelationship?: boolean | null;
  roleList?: string | null;
  tenantId?: number | null;
  blockClientId?: number | null;
  blockClientName?: string | null;
  accessLevelCode?: number | null;
  accessLevelName?: string | null;
  permissionLevelRank?: number | null;
  accessLevelDescription?: string | null;
  blockPrimaryKey?: number | null;
  blockName?: string | null;
  contributorId?: number | null;
  contributorName?: string | null;
  serviceAsList?: string | null;
};
export type ClientFtpAccount = {
  username: string;
  showIncoming?: boolean | null;
  showOutgoing?: boolean | null;
  isActive?: boolean | null;
  passwordHint?: string | null;
  updatedBy?: number;
  updatedOn?: string;
  clientFtpdetailId?: number;
  clientLinkid: number;
  setPassword?: string | null;
};
export type ParentLabelBasicInfoVm = {
  labelName?: string | null;
  contactPerson?: string | null;
  addresses?: ProfileAddressVm[] | null;
  clientId: number;
  clientName?: string | null;
};
export type DefaultsVm = {
  defaultPlatforms?: DefaultPlatformVm[] | null;
  defaultTerritoryIds?: number[] | null;
  defaultAlbumPriceTier: number;
  defaultSinglePriceTier: number;
  defaultVideoPriceTier: number;
  policyGroupId?: number | null;
  noPlatformDealPolicies?: boolean | null;
};
export type LabelDefaultsVm = {
  defaultPlatforms?: DefaultPlatformVm[] | null;
  defaultTerritoryIds?: number[] | null;
  defaultAlbumPriceTier: number;
  defaultSinglePriceTier: number;
  defaultVideoPriceTier: number;
  policyGroupId?: number | null;
  noPlatformDealPolicies?: boolean | null;
  defaultLanguageId?: number | null;
  defaultCurrencyId?: number | null;
  active?: boolean | null;
  isrcPrefix?: string | null;
  keywords?: string[] | null;
};
export type ParentLabelVm = {
  defaults?: LabelDefaultsVm;
  resources?: ResourceVm[] | null;
  autoAddNewPlatforms?: number[] | null;
  basicInfo?: ParentLabelBasicInfoVm;
};
export type LabelBasicInfoVm = {
  labelName?: string | null;
  contactPerson?: string | null;
  addresses?: ProfileAddressVm[] | null;
  parentLabelId: number;
  parentLabelName?: string | null;
};
export type LabelVm = {
  defaults?: LabelDefaultsVm;
  resources?: ResourceVm[] | null;
  autoAddNewPlatforms?: number[] | null;
  basicInfo?: LabelBasicInfoVm;
};
export type ContributorImageVm = {
  assetFileFormatLinkId?: number;
  guid?: string;
  pixelWidth?: number | null;
  pixelHeight?: number | null;
};
export type ContributorTagsKeywordsVm = {
  tags?: number[] | null;
  keywords?: string[] | null;
};
export type ContributorTagValueVm = {
  valueId?: number;
  displayValue?: string | null;
};
export type ContributorVm = {
  basicInfo?: AcCreateContributorVm;
  image?: ContributorImageVm;
  tagsKeywords?: ContributorTagsKeywordsVm;
  resources?: ResourceVm[] | null;
};
export type StageName = {
  text?: string | null;
  languageId?: number;
};
export type ContributorSearchVm = {
  labelId?: number;
  clientName?: string | null;
  labelName?: string | null;
  parentLabelName?: string | null;
  labelManager?: string | null;
  artists?: string[] | null;
  id?: number;
  isContributor?: boolean;
  stageNames?: StageName[] | null;
  name?: string | null;
  artistsTotalCount?: number;
  roles?: string | null;
  formatId?: number;
};
export type ContributorRelatedFormatWithRoleVm = {
  formatId?: number;
  catNo?: string | null;
  upc?: string | null;
  variousArtists?: boolean | null;
  formatTypeId?: number | null;
  formatType?: string | null;
  formatDisplayType?: string | null;
  isDigital?: boolean | null;
  labelId?: number | null;
  labelName?: string | null;
  createdOn?: string | null;
  createdBy?: number | null;
  dateInactivated?: string | null;
  localeContributorNames?: string | null;
  contributorIds?: string | null;
  title?: string | null;
  subTitle?: string | null;
  originalReleaseDate?: string | null;
  releaseDate?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroup?: string | null;
  packshotAssetId?: number | null;
  packshotGuid?: string | null;
  isPlatformApproved?: boolean | null;
  isCmoapproved?: boolean | null;
  isPlatformValidated?: boolean | null;
  isCmovalidated?: boolean | null;
  deliveryStateLinkId?: number | null;
  genreId?: number | null;
  titleGroupId?: number | null;
  jsoncurrentDealerPrice?: string | null;
  formatNotAssetEtag?: number | null;
  formatEtag?: number | null;
  tenantId?: number | null;
  popularity?: number | null;
  clientId?: number | null;
  notes?: string | null;
  role?: string | null;
};
export type ContributorRelatedAssetWithRoleVm = {
  assetId?: number;
  assetGuid?: string | null;
  assetTypeGroupTag?: string | null;
  languageId?: number | null;
  languageName?: string | null;
  assetTitle?: string | null;
  assetTitleVersion?: string | null;
  assetRecordingId?: number | null;
  isrc?: string | null;
  recordingTerritoryId?: number | null;
  recordingTerritoryName?: string | null;
  isNonMusic?: boolean | null;
  genreId?: number | null;
  mainGenre?: string | null;
  subGenre?: string | null;
  blobGuid?: string | null;
  artistNames?: string | null;
  artistIds?: string | null;
  formatIdList?: string | null;
  formatAssetIdList?: string | null;
  catNoList?: string | null;
  upclist?: string | null;
  packshotGuid?: string | null;
  assetCreatedByClientId?: number | null;
  ownedByClientId?: string | null;
  licensedByClientId?: string | null;
  assetEtag?: number | null;
  fifiTrackId?: number | null;
  isSystemConflict?: boolean;
  tenantId?: number | null;
  updatedOn?: string | null;
  isInstrumental?: boolean | null;
  labelList?: string | null;
  role?: string | null;
};
export type UspGetContributorRelatedWorksWithRolesResult = {
  contributorId?: number | null;
  workId?: number | null;
  role?: string | null;
  workTitle?: string | null;
  iswc?: string | null;
  workCreationYear?: number | null;
  musicalWorkType?: string | null;
  assetIdList?: string | null;
  publisherNameList?: string | null;
};
export type GetReleaseScheduleByTenantResult = {
  releaseDate?: string | null;
  format?: string | null;
  artist?: string | null;
  title?: string | null;
  genre?: string | null;
  catNo?: string | null;
  upc?: string | null;
  labelName?: string | null;
  dealerPriceJSON?: string | null;
  exclusivePlatformJSON?: string | null;
  exclusiveTerritoryJSON?: string | null;
  releaseType?: string | null;
  notes?: string | null;
  isDigital?: boolean | null;
  packshotGUID?: string | null;
  statusTag?: string | null;
  validation?: string | null;
  originalReleaseDate?: string | null;
  titleGroupId?: number | null;
  warehouseJSON?: string | null;
  callOff?: string | null;
  labelManagerJSON?: string | null;
  currentStock?: number | null;
  formatId?: number;
  statusTagId?: number | null;
  formatTypeId?: number;
  labelManagerList?: string | null;
  contributorIds?: string | null;
  labelId?: number | null;
};
export type ReleaseScheduleSearchModel = {
  releaseDate?: string | null;
  format?: string | null;
  artist?: string | null;
  title?: string | null;
  genre?: string | null;
  catNo?: string | null;
  upc?: string | null;
  labelName?: string | null;
  dealerPriceJSON?: string | null;
  exclusivePlatformJSON?: string | null;
  exclusiveTerritoryJSON?: string | null;
  releaseType?: string | null;
  notes?: string | null;
  isDigital?: boolean | null;
  packshotGUID?: string | null;
  statusTag?: string | null;
  validation?: string | null;
  originalReleaseDate?: string | null;
  titleGroupId?: number | null;
  warehouseJSON?: string | null;
  callOff?: string | null;
  labelManagerJSON?: string | null;
  currentStock?: number | null;
  formatId?: number;
  statusTagId?: number | null;
  formatTypeId?: number;
  labelManagerList?: number[] | null;
  contributorIds?: number[] | null;
  labelId?: number | null;
};
export type ContributorSearchModel = {
  contributorId?: number;
  isIndividual?: boolean | null;
  contributorEtag?: number | null;
  contributorDefaultName?: string | null;
  contributorNames?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  surname?: string | null;
  conutryOfBirth?: string | null;
  citizenship?: string | null;
  dob?: string | null;
  genderLinkId?: number | null;
  contributorFormats?: number[] | null;
  contributorLabels?: number[] | null;
  contributorAssets?: number[] | null;
  contributorWorks?: number[] | null;
  contributorCreatedByClientId?: number | null;
  isArtist?: boolean | null;
  isContributor?: boolean | null;
  isWriter?: boolean | null;
  guid?: string | null;
  nativeId?: string | null;
  tenantId?: number | null;
  isProducer?: boolean | null;
  contributorLabelNames?: string | null;
};
export type AcContributorRecentReleaseVm = {
  image?: string | null;
  title?: string | null;
  upc?: string | null;
  isrc?: string | null;
  role?: string | null;
};
export type AcContributorContributionVm = {
  contributorId?: number;
  name?: string | null;
  image?: string | null;
  recentReleases?: AcContributorRecentReleaseVm[] | null;
};
export type SearchLabelSearchVm = {
  clientId?: number;
  labelId?: number;
  parentLabelId?: number;
  labelName?: string | null;
  parentLabelName?: string | null;
};
export type UserSearchVm = {
  displayName?: string | null;
  accessLevelDescription?: string | null;
  blockName?: string | null;
  emailActivated?: boolean | null;
};
export type AcContributorSearchVm = {
  contributorId?: number;
  text?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  labelNames?: string | null;
  labelIds?: string | null;
  pplId?: string | null;
};
export type DdexPartyIdSearchVm = {
  ddexPartyId?: number;
  dpid?: string | null;
  company?: string | null;
  name?: string | null;
};
export type AssetTypeToFileFormatSearchVm = {
  assetTypeToFileFormatId?: number;
  name?: string | null;
};
export type GetSuppliersByTenantIdResult = {
  supplierId?: number;
  supplierName?: string | null;
  platformId?: number | null;
  defaultOrderText?: string | null;
  defaultLanguageLinkId?: number | null;
  languageName?: string | null;
  defaultCurrencyLinkId?: number | null;
  isoAlpha3Currency?: string | null;
  isActive?: boolean | null;
  createdBy?: number;
  displayName?: string | null;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  taxTypeOptionId?: number | null;
  taxOptionType?: string | null;
  taxOptionTypeDescription?: string | null;
  taxTypeLinkId?: number | null;
  taxType?: string | null;
  taxTypeDescription?: string | null;
  accountsRef?: string | null;
  supplierTenantId?: number | null;
  addressId?: number | null;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  stateProvinceCounty?: string | null;
  zipOrPostcode?: string | null;
  territoryName?: string | null;
  contactJSON?: string | null;
};
export type SupplierPartTypeDestinationVm = {
  partTypeID?: number | null;
  partTypeName?: string | null;
  supplierId?: number | null;
  supplierName?: string | null;
  supplierTaxTypeOptionLinkId?: number | null;
  contact?: string | null;
  company?: string | null;
  deliveryAddressId?: number | null;
  line1?: string | null;
  zipOrPostcode?: string | null;
  timesUsed?: number | null;
  clientId?: number | null;
  clientName?: string | null;
  carriesAudio?: boolean | null;
  deliveryAddressName?: string | null;
};
export type UspGetSupplierKnownPartTypesResult = {
  partTypeID?: number | null;
  partTypeName?: string | null;
  timesOrdered?: number | null;
};
export type SupplierTenantAddressVm = {
  supplierTenantAddressId?: number | null;
  supplierAddressTypeId?: number | null;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  stateProvinceCounty?: string | null;
  zipOrPostcode?: string | null;
  countryTerritoryId?: number | null;
};
export type SupplierTenantContactVm = {
  supplierTenantContactId?: number | null;
  firstName: string;
  lastName: string;
  emailAddress: string;
  genderId?: number | null;
  supplierTenantContactToTypes: number[];
  supplierTenantContactToTypesName?: string | null;
};
export type SupplierVm = {
  supplierId?: number | null;
  supplierName?: string | null;
  defaultOrderText?: string | null;
  defaultLanguageLinkId?: number | null;
  defaultCurrencyLinkId?: number | null;
  taxtypeLinkId?: number | null;
  platformLinkId?: number | null;
  supplierTenantId?: number | null;
  supplierTenantActive?: boolean;
  clientLinkId?: number | null;
  clientName?: string | null;
  accountsRef?: string | null;
  supplierTenantAddresses?: SupplierTenantAddressVm[] | null;
  supplierTenantContacts?: SupplierTenantContactVm[] | null;
};
export type DefaultSupplierVm = {
  supplierId?: number;
  supplierName?: string | null;
  taxtypeOptionId?: number;
  taxtypeOptionName?: string | null;
};
export type TagVm = {
  tagId?: number;
  text?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  isInternal?: boolean;
};
export type CyclicTestClass = {
  num?: number;
  name?: string | null;
  num2?: number;
  nest?: CyclicTestClass[] | null;
};
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type DateOnly = {
  year?: number;
  month?: number;
  day?: number;
  dayOfWeek?: DayOfWeek;
  dayOfYear?: number;
  dayNumber?: number;
};
export type ClWeatherForecast = {
  date?: DateOnly;
  temperatureC?: number;
  temperatureF?: number;
  summary?: string | null;
};
export type TicketProductTypes = 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11;
export type ProductInfoVm = {
  productId?: number;
  productType?: TicketProductTypes;
};
export type CommentVm = {
  commentId?: number;
  createdOn?: string;
  createdBy?: number;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  comment: string;
  attachmentLink?: string | null;
  isAgentViewOnly?: boolean;
  specificCommentResponse?: number | null;
  partnerWideAccess?: boolean;
};
export type CollaboratorVm = {
  userId?: number;
  receiveEmailNotifications?: boolean;
  receiveSystemNotifications?: boolean;
};
export type TicketVm = {
  ticketId?: number | null;
  relatedProduct?: number;
  relatedProductType?: number;
  isProductValidator?: boolean | null;
  agentGroupId: number;
  priorityLinkId: number;
  statusLinkId: number;
  categoryLinkId: number;
  partnerLinkId?: number | null;
  firstComment?: CommentVm;
  comments?: CommentVm[] | null;
  url?: string | null;
  email?: string | null;
  createdBy?: number;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  createdOn?: string;
  lastCommentAdded?: string | null;
  updatedBy?: number | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedOn?: string;
  collaborators?: CollaboratorVm[] | null;
  ratings?: number[] | null;
  tags?: number[] | null;
  metadata?: string | null;
};
export type UspGetTicketCategoryByProductandPartnerResult = {
  productId?: number;
  productName?: string | null;
  productDescription?: string | null;
  partnerId?: number;
  partnerName?: string | null;
  categoryId?: number;
  category?: string | null;
  sequence?: number | null;
};
export type EmailCommentVm = {
  subject?: string | null;
  from?: string | null;
  to?: string | null;
  text?: string | null;
  html?: string | null;
};
export type BlobUploadPermsVm = {
  bucket?: string | null;
  remotePath?: string | null;
  finalPostUrl?: string | null;
  putUrls?: {
    [key: string]: string | null;
  } | null;
};
export type BlobUploadRequestVm = {
  destinationCode?: string | null;
  filename?: string | null;
  contentType?: string | null;
  uploadSessionId?: string | null;
  size?: number;
  tags?: {
    [key: string]: string | null;
  } | null;
};
export type AssetInformationWithMetum = {
  sizeBytes?: number;
  crc?: string | null;
  md5?: string | null;
  fiveMbMd5?: string | null;
  assetFileFormatLinkId?: number | null;
  assetInformationId?: number;
  mimeType?: string | null;
  guid?: string;
  previewCreated?: boolean;
  previewFiles?: number | null;
  isValidFile?: boolean;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  assetFormat?: string | null;
  colorSpaceLinkId?: number | null;
  channelBitDepth?: number | null;
  height?: number | null;
  width?: number | null;
  colorChannels?: number | null;
  colorSpaceName?: string | null;
  audioChannels?: number | null;
  duration?: number | null;
  audioCodec?: string | null;
  audioBitRate?: number | null;
  bpm?: number | null;
  bitDepth?: number | null;
  sampleRateHz?: number | null;
  videoCodec?: string | null;
  totalBitRate?: number | null;
  videoStreams?: number | null;
  videoBitRate?: number | null;
  frameRate?: number | null;
  audioStreams?: number | null;
  uploadSuccess?: boolean | null;
  uploadFileName?: string | null;
  uploadCreated?: string | null;
  uploadLastUpdateTime?: string | null;
  finishedOn?: string | null;
  uploadState?: string | null;
  progress?: number | null;
  error?: string | null;
};
export type BlobUploadConfirmation = {
  blobUploadId?: number;
  uploadSessionToken?: string | null;
  assetInformationLinkId?: number | null;
  wasNewAssetCreated?: boolean;
  isUpdatePreviewJob?: boolean;
  originalFileName?: string | null;
  success?: boolean;
  willCreatePreview?: boolean;
  previewCreated?: boolean;
  state?: string | null;
  durationSeconds?: number | null;
  progress?: number | null;
  finishedOn?: string | null;
  error?: string | null;
  fififormatTrackId?: number | null;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
};
export type UploadSessionResult = {
  assets?: AssetInformationWithMetum[] | null;
  blobUploads?: BlobUploadConfirmation[] | null;
};
export type ReportDocumentBlob = {
  tenant_id?: number | null;
  client_id?: number | null;
  uid?: string | null;
  path?: string | null;
  report_date?: string | null;
  report_type?: string | null;
  currency?: string | null;
  territory?: string | null;
  notes?: string | null;
  size?: number | null;
  row_count?: number | null;
  creation_date?: string | null;
  is_private_tenant_copy?: boolean | null;
  is_legacy?: boolean | null;
  custom_name?: string | null;
};
export type ChangePasswordVm = {
  userId?: number | null;
  password: string;
  newPassword: string;
};
export type ResetPasswordRequestVm = {
  email: string;
};
export type ResetPasswordVm = {
  email: string;
  token: string;
  newPassword: string;
};
export type SignUpPostVm = {
  tenantId?: number;
  inviteUserId?: number;
  userId?: number;
  firstname?: string | null;
  lastname?: string | null;
  originalEmail?: string | null;
  email?: string | null;
  mobilePrefix?: string | null;
  mobile?: string | null;
  password?: string | null;
  confirmPassword?: string | null;
  currencyId?: number;
  territoryId?: number;
  languageId?: number;
  authCode?: string | null;
};
export type AcknowledgeActionBoolean = {
  message?: string | null;
  isError?: boolean;
  value?: boolean;
};
export type AcknowledgeActionInt32 = {
  message?: string | null;
  isError?: boolean;
  value?: number;
};
export type SimpleUserModel = {
  email?: string | null;
  firstname?: string | null;
  surname?: string | null;
  password?: string | null;
};
export type SignUpDetailsVm = {
  tenantId?: number;
  inviteUserId?: number;
  userId?: number;
  firstname?: string | null;
  lastname?: string | null;
  originalEmail?: string | null;
  email?: string | null;
  mobilePrefix?: string | null;
  mobile?: string | null;
  password?: string | null;
  confirmPassword?: string | null;
  currencyId?: number;
  territoryId?: number;
  languageId?: number;
  authCode?: string | null;
  tenant?: string | null;
  inviteUser?: string | null;
  currency?: string | null;
  territory?: string | null;
  territoryIso2?: string | null;
  language?: string | null;
  clients?: string[] | null;
  parentLabels?: string[] | null;
  labels?: string[] | null;
  labelArtist?: string[] | null;
};
export type UserListModel = {
  userId?: number;
  displayName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  lastActivity?: string | null;
  isActive?: boolean;
  canEdit?: boolean | null;
  isDirectRelationship?: boolean | null;
  roleList?: string | null;
  tenantId?: number | null;
  blockClientId?: number | null;
  blockClientName?: string | null;
  accessLevelCode?: number | null;
  accessLevelName?: string | null;
  permissionLevelRank?: number | null;
  accessLevelDescription?: string | null;
  blockPrimaryKey?: number | null;
  blockName?: string | null;
  contributorId?: number | null;
  contributorName?: string | null;
  serviceAsList?: string | null;
  levels?: GetUserAccessListVisibleOnlyResult[] | null;
};
export type PendingUser = {
  id?: number;
  friendlyName?: string | null;
  userName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  createdOn?: string;
  userPermissionLastUpdatedOn?: string | null;
  updatedOn?: string | null;
};
export type UspUserPermissionsGetUserPossibleAndCurrentPermissionsResult = {
  serviceId?: number | null;
  permissionId?: number | null;
  servicePermissionId?: number | null;
  isSetForUser?: boolean | null;
};
export type ServicePermissionAccess = {
  accessLevelCode?: number;
  accessLevelServiceId?: number;
  contributorId?: number | null;
  servicePermissionId?: number;
};
export type UpdateUserPermissionViewModel = {
  mode?: string | null;
  userId?: number;
  invite?: boolean;
  userPermissionSet?: ServicePermissionAccess[] | null;
};
export type AcknowledgeActionString = {
  message?: string | null;
  isError?: boolean;
  value?: string | null;
};
export type UspUserPermissionsGetAccessPermissionsForUserResult = {
  userPermissionAccessLevelId?: number | null;
  userPermissionAccessLevelDescription?: string | null;
  count?: number | null;
};
export type AspNetRole = {
  id?: number;
  name?: string | null;
  normalizedName?: string | null;
  concurrencyStamp?: string | null;
  description?: string | null;
};
export type ServicePermissionLookup = {
  serviceLinkId?: number;
  permissionTypeLinkId?: number;
  servicePermissionDescription?: string | null;
  servicePermissionsId?: number;
  codeName?: string | null;
};
export type ServicePermissionDependency = {
  servicePermissionDependencyId?: number;
  servicePermissionLinkId?: number;
  includedServicePermissionLinkId?: number;
  description?: string | null;
};
export type DomainsByTenant = {
  tenantId?: number;
  isActive?: boolean;
  tenantName?: string | null;
  enforce2Fa?: boolean;
  defaultRightsCommissioningTerritoryLinkId?: number;
  upcprefix?: string | null;
  isrcprefix?: string | null;
  autoGenerateUpc?: boolean | null;
  autoGenerateCatNo?: boolean | null;
  autoGenerateIsrc?: boolean | null;
  orderPrefix?: string | null;
  tenantCurrencyLinkId?: number | null;
  uniqueShortNameId?: string | null;
  taxtypeLinkId?: number;
  domainId?: number;
  domainName?: string | null;
  subDomainName?: string | null;
  isTenantDefault?: boolean;
  domainCustomisationId?: number;
  customisationName?: string | null;
  logo?: string | null;
  logoLightBackground?: string | null;
  favicon?: string | null;
  icon?: string | null;
  backgroundColor?: string | null;
  primaryColor?: string | null;
  accentColor?: string | null;
  textColor?: string | null;
  fontFamily?: string | null;
  fontScale?: number | null;
  footerContent?: string | null;
  customCss?: string | null;
  emailBackgroundColor?: string | null;
  emailPrimaryColor?: string | null;
  emailAccentColor?: string | null;
  emailTextColor?: string | null;
};
export type PlatformUrllinkTemplate = {
  platformUrllinkTemplateId?: number;
  platformUrllinkTemplate1?: string | null;
  platformLinkId?: number | null;
  platformUrllinkType?: number | null;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  territoryLinkId?: number | null;
};
export type GetExchangeRateLatestByCurrencyResult = {
  fromCurrencyId?: number | null;
  fromCurrency?: string | null;
  fromISOAlpha3Currency?: string | null;
  fromCurrencySymbol?: string | null;
  fromSymbolBeforeValue?: boolean | null;
  multiplierRate?: number;
  rateDate?: string;
  toCurrencyId?: number | null;
  toCurrency?: string | null;
  toISOAlpha3Currency?: string | null;
  toCurrencySymbol?: string | null;
  toSequence?: number | null;
  toSymbolBeforeValue?: boolean | null;
};
export type AllSessionStatus = {
  id?: number;
  taskName?: string | null;
  targetScript?: string | null;
  executeTarget?: string | null;
  args?: string | null;
  sessionId?: number | null;
  status?: string | null;
  createTime?: string | null;
  startTime?: string | null;
  lastUpdate?: string | null;
  appName?: string | null;
  exitCode?: number | null;
  duration?: number | null;
  progressPercent?: number | null;
  progressStep?: number | null;
  progressMaxsteps?: number | null;
  progressMessage?: string | null;
};
export type AuditState = {
  id?: string | null;
  tenantId?: number | null;
  lastRun?: string | null;
  importance?: number | null;
  description?: string | null;
  runtime?: number | null;
  success?: boolean | null;
  info?: string | null;
  message?: string | null;
  isWarning?: boolean | null;
};
export type EnumEntry = {
  id?: number;
  enumName?: string | null;
  name?: string | null;
  description?: string | null;
};
export type AllArticle = {
  categoryId?: number;
  category?: string | null;
  description?: string | null;
  articleId?: number;
  title?: string | null;
  articleContent?: string | null;
  categoryLinkId?: number | null;
  sequence?: number;
  languageLinkId?: number;
  isActive?: boolean;
  createdBy?: number;
  createdOn?: string;
  languageId?: number;
  languageName?: string | null;
  languageAbbreviation?: string | null;
  languageIso3?: string | null;
};
export const {
  useGetApiAccountUserKnownAddressesQuery,
  useGetApiAccountPersonalDetailsQuery,
  usePutApiAccountPersonalDetailsMutation,
  usePutApiAccountActiveStatusMutation,
  useGetApiAccountGetPersonalUiSettingsQuery,
  usePutApiAccountSetPersonalUiSettingMutation,
  usePostApiAccountLoginAsUserMutation,
  usePostApiAccountLoginMutation,
  usePostApiAccountRequestRefreshTokenMutation,
  useGetApiAccountGetUserPermsQuery,
  useGetApiAccountGetPermsOnEntityQuery,
  useGetApiAdminGetSyncHealthQuery,
  useGetApiAdminGetFireWallSettingsQuery,
  usePostApiAdminAddUpdateFireWallItemMutation,
  usePostApiAdminDeleteFireWallItemMutation,
  usePostApiAdminPushFireWallConfigToAzureMutation,
  useGetApiAdminWhoIsOnlineQuery,
  useGetApiAdminGet2FaStatusQuery,
  usePostApiAdminRequestShortTerm2FaCodeMutation,
  usePostApiAdminValidateShortTerm2FaCodeMutation,
  useGetApiAdminListSecretsQuery,
  useGetApiAdminShowSecretQuery,
  usePostApiAdminSaveSecretMutation,
  useGetApiAnalyticsInsightsTimelineQuery,
  useGetApiAnalyticsInsightsLeaderboardsQuery,
  useGetApiAnalyticsClientReportingArticlesSummaryByClientLabelQuery,
  useGetApiAnalyticsClientReportingCmoSummaryRevenueQuery,
  useGetApiAnalyticsClientReportingCmoSummaryRevenueByClientLabelQuery,
  useGetApiAnalyticsClientReportingDigitalSummaryQuery,
  useGetApiAnalyticsClientReportingDigitalSummaryClientLabelQuery,
  useGetApiAnalyticsClientReportingDirectSalesSummaryQuery,
  useGetApiAnalyticsClientReportingDirectSalesSummaryClientLabelQuery,
  useGetApiAnalyticsClientReportingOtherSalesSummaryQuery,
  useGetApiAnalyticsClientReportingOtherSalesSummaryClientLabelQuery,
  useGetApiAnalyticsClientReportingPhysicalArticlesSummaryQuery,
  useGetApiAnalyticsClientReportingPhysicalNotificationFormatsUnLinkedAllTimeQuery,
  useGetApiAnalyticsClientReportingPhysicalSalesReturnsConsignClientReportingQuery,
  useGetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryQuery,
  useGetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelQuery,
  useGetApiAnalyticsClientReportingPhysicalSalesReturnsConsignSummaryByClientLabelFormatQuery,
  useGetApiAnalyticsClientReportingPhysicalUniversalInvoiceSummaryQuery,
  useGetApiAnalyticsClientReportingRechargesSummaryQuery,
  useGetApiAnalyticsClientReportingRechargesSummaryClientLabelQuery,
  useGetApiAssetRefreshAssetConflictStatusQuery,
  useGetApiCatalogueGetFormatIngestionNotesQuery,
  useGetApiCatalogueExportCatalogueQuery,
  useGetApiCatalogueGetFormatDetailQuery,
  useGetApiCatalogueGetRecentlyViewedFormatsQuery,
  useGetApiCatalogueGetFormatContributorsQuery,
  useGetApiClientReportingListDeletedReportsQuery,
  usePostApiClientReportingDeleteFinanceReportMutation,
  usePostApiClientReportingRecoverDeletedReportMutation,
  usePostApiClientReportingSaveUpdatedClientMappingMutation,
  usePostApiClientReportingSaveFinanceReportMetaMutation,
  usePostApiClientReportingSetFinanceReportReadyStateMutation,
  useGetApiClientReportingSearchReportMatchDataQuery,
  useGetApiClientReportingGetReportIngestionStatusQuery,
  useGetApiClientReportingGetPlatformsAcceptingReportUploadsQuery,
  usePostApiClientReportingLaunchImportJobMutation,
  useGetApiClientReportingDigitalPendingReportsQuery,
  useGetApiClientReportingReportingSimulateQuery,
  useGetApiClientReportingDigitalDataByFileQuery,
  useGetApiClientReportingDigitalUnmatchedDataQuery,
  useGetApiClientReportingDigitalBiggestChangesQuery,
  useGetApiClientReportingDigitalTotalsSheetQuery,
  useGetApiClientReportingDigitalPlatformProblemSummaryQuery,
  useGetApiClientReportingDigitalSalesClientSummaryQuery,
  useGetApiClientReportingDigitalDataByClientQuery,
  useGetApiClientReportingCmoDataByClientQuery,
  useGetApiClientReportingCmoClientSummaryQuery,
  useGetApiClientReportingCmoUnmatchedDataQuery,
  usePostApiClientReportingExecuteReportingJobMutation,
  useGetApiClientReportingGetReportingStatusQuery,
  useGetApiClientReportingPhysicalSummaryQuery,
  useGetApiClientReportingSuggestedClientPaymentQuery,
  useGetApiClientReportingInvoiceSummaryChartQuery,
  useGetApiClientReportingClientAgreementSummaryQuery,
  useGetApiClientReportingDownloadSummaryZipQuery,
  useGetApiContributorGetSpotifyArtistIdQuery,
  useGetApiContributorFetchAppleArtistByNameQuery,
  useGetApiContributorGetExistingAppleArtistsByNameQuery,
  usePostApiContributorCreateAppleArtistMutation,
  usePostApiContributorCreateContributorMutation,
  useGetApiContributorValidateResourceQuery,
  useGetApiContributorCanEditAppleArtistInformationQuery,
  useGetApiContributorGetContributorGuidsQuery,
  usePostApiDeliveryQueueJobForPlatformMutation,
  usePostApiDeliveryStartDeliveryImmediatelyMutation,
  usePostApiDeliveryIngestXmlFileMutation,
  useGetApiDeliveryGetFormatDeliveryHistoryQuery,
  useGetApiDeliveryGetDeliveryJobInfoQuery,
  usePostApiDeliveryGetXmlZipMutation,
  usePostApiDeliveryGetXmlFileMutation,
  usePostApiDeliveryGetXmlJsonMutation,
  useGetApiDeliverySearchFormatDeliveryInfoQuery,
  useGetApiDeliveryGetFormatDeliverySummaryQuery,
  useGetApiDeliveryAddressSearchDeliveryAddressesQuery,
  useGetApiDeliveryAddressGetDeliveryAddressByDeliveryAddressIdQuery,
  usePostApiDeliveryAddressCreateDeliveryAddressMutation,
  usePutApiDeliveryAddressUpdateDeliveryAddressByDeliveryAddressIdMutation,
  useDeleteApiDeliveryAddressDeleteDeliveryAddressByDeliveryAddressIdMutation,
  useGetApiDirectSaleSearchDirectSalesQuery,
  useGetApiDirectSaleGetDirectSaleByDirectSaleIdQuery,
  usePostApiDirectSaleCreateDirectSaleMutation,
  usePutApiDirectSaleUpdateDirectSaleByDirectSaleIdMutation,
  useDeleteApiDirectSaleDeleteDirectSaleByDirectSaleIdMutation,
  useGetApiDirectSaleExportDirectSalesQuery,
  useGetApiDirectSaleGetTenantDefaultCurrencyQuery,
  useGetApiNewsGetNewsQuery,
  useGetApiNotificationGetNotificationsQuery,
  usePostApiNotificationMarkNoteAsReadMutation,
  usePostApiNotificationDeleteNotesMutation,
  usePostApiNotificationAnnounceMutation,
  useGetApiOrderSearchOrdersQuery,
  useGetApiOrderGetOrderByOrderIdQuery,
  useGetApiOrderGetOrderSummaryForFormatByFormatIdQuery,
  usePostApiOrderCreateOrderMutation,
  usePutApiOrderUpdateOrderByOrderIdMutation,
  usePutApiOrderOverwriteOrderByOrderIdMutation,
  useDeleteApiOrderDeleteOrderByOrderIdMutation,
  usePutApiOrderCancelOrderByOrderIdMutation,
  usePostApiOrderSendOrderEmailMutation,
  useGetApiOrderSearchRechargesQuery,
  useGetApiOrderSearchOtherSalesQuery,
  useGetApiOrderGetRechargeByRechargeIdQuery,
  usePostApiOrderCreateRechargeMutation,
  usePutApiOrderUpdateRechargeMutation,
  useDeleteApiOrderDeleteRechargeByRechargeIdMutation,
  useGetApiOrderExportOrdersQuery,
  useGetApiOrderExportRechargesQuery,
  useGetByPartTypeIdQuery,
  usePutByPartTypeIdMutation,
  useDeleteByPartTypeIdMutation,
  useGetApiPhysicalStockGetPhysicalStockSummaryLatestQuery,
  useGetApiPhysicalStockGetPhysicalStockReceivedSummaryQuery,
  useGetApiPlatformGetRatesForPlatformExclusivesQuery,
  usePostApiPlatformAddDefaultPlatformToClientsParentLabelsMutation,
  useGetApiPlatformPlatformsQuery,
  useGetApiPlatformSearchPlatformsQuery,
  useGetApiPlatformGetPlatformByPlatformIdQuery,
  usePostApiPlatformCreatePlatformMutation,
  usePutApiPlatformUpdatePlatformByPlatformIdMutation,
  useGetApiPlatformSearchPlatformDeliveryGroupsQuery,
  useGetApiPlatformDeliveryGroupsForPlatformByPlatformIdQuery,
  useGetApiPlatformGetPlatformDeliveryGroupByGroupIdQuery,
  usePostApiPlatformCreatePlatformDeliveryGroupMutation,
  usePutApiPlatformUpdatePlatformDeliveryGroupByGroupIdMutation,
  useDeleteApiPlatformDeletePlatformDeliveryGroupByGroupIdMutation,
  useGetApiPlatformSearchPlatformDeliveryDetailsQuery,
  useGetApiPlatformGetPlatformDeliveryDetailsByDetailsIdQuery,
  usePostApiPlatformCreatePlatformDeliveryDetailsMutation,
  usePutApiPlatformUpdatePlatformDeliveryDetailsByDetailsIdMutation,
  useDeleteApiPlatformDeletePlatformDeliveryDetailsByDetailsIdMutation,
  usePutApiPlatformUpdateDefaultTenantPlatformsMutation,
  usePostApiPlatformCreatePlatformTenantDeliveryDetailsMutation,
  useDeleteApiPlatformDeletePlatformTenantDeliveryDetailsByDetailsIdMutation,
  usePostApiProductDDexUrlTriggerMutation,
  useGetApiProductGetAssetListQuery,
  useGetApiProductInitCacheQuery,
  useGetApiProductGetConfigDataQuery,
  usePostApiProductGenerateMetaDataIdMutation,
  useGetApiProductGetPhysicalFormatListMappingQuery,
  useGetApiProductGetArtistSuggestionsQuery,
  useGetApiProductRelatedFormatsQuery,
  useGetApiProductSingleFormatQuery,
  useGetApiProductGetPlatformIdentifiersForFormatByIdQuery,
  useGetApiProductFormatByIdQuery,
  usePostApiProductCreateFormatMutation,
  usePostApiProductUpdateFormatMutation,
  usePostApiProductCopyTracksFromRelatedFormatMutation,
  useGetApiProductIsValidCatOrUpcQuery,
  useGetApiProductGetClientInfoQuery,
  usePostApiProductNewLabelMutation,
  useGetApiProductGetLabelsQuery,
  useGetApiProductGetArtWorkSpecsQuery,
  usePostApiProductCreateImmersiveMutation,
  useGetApiProductReleaseByIdQuery,
  useGetApiProductGetReleaseConfigQuery,
  useGetApiProductFormatPriceTierMappingListQuery,
  usePostApiProductReleaseMutation,
  useGetApiProductPlatformDspAndTerritoryExclusivesQuery,
  useGetApiProductGetTimeZoneInfoQuery,
  useGetApiProductDealsByIdQuery,
  usePostApiProductDealsMutation,
  usePostApiProductCreatePublisherMutation,
  useGetApiProductConfirmPublisherSelectedByNameQuery,
  useGetApiProductTracksQuery,
  useGetApiProductTrackListQuery,
  useGetApiProductTrackQuery,
  usePostApiProductTrackMutation,
  useGetApiProductGetTrackListDetailedQuery,
  useGetApiProductGetTrackCreditsQuery,
  useGetApiProductGetAppleEngineerQuery,
  useGetApiProductGetAssetInfoIdAssetQuery,
  useGetApiProductGetIsrcAssetQuery,
  usePostApiProductUpdateTrackOrderMutation,
  usePostApiProductValidateSelectedTrackMutation,
  usePostApiProductAddTrackToFormatMutation,
  usePostApiProductDeleteFormatTrackMutation,
  useGetApiProductIsValidIsrcQuery,
  useGetApiProductIsValidExistingIsrcQuery,
  useGetApiProductIsValidExistingCatQuery,
  useGetApiProductGetContributorInstrumentsQuery,
  useGetApiProductSuggestedContributorRolesQuery,
  useGetApiProductGetArtistContributorsQuery,
  useGetApiProductGetWriterPublishersQuery,
  useGetApiProductGetMeadListQuery,
  useGetApiProductGetAssetDetailsQuery,
  useGetApiProductExtrasByIdQuery,
  usePostApiProductExtrasMutation,
  useGetApiProductExtrasConfigByIdQuery,
  useGetApiProductReviewByIdQuery,
  useGetApiProductGetApprovalStatesByIdQuery,
  useGetApiProductIsFormatValidByIdQuery,
  useGetApiProductIsFormatCmoValidByIdQuery,
  useGetApiProductIsValidAllByIdQuery,
  usePostApiProductApproveMutation,
  usePostApiProductRequestClientApprovalMutation,
  usePostApiProductValidatePlatformsMutation,
  usePostApiProductValidateCmosMutation,
  usePostApiProductValidateAndQueueJobsForFormatMutation,
  useGetApiProductIsApprovedOrQueuedByIdQuery,
  useGetApiProfileGetLastReportedDateQuery,
  useGetApiProfileClientDefaultPlatformDealPoliciesByClientIdQuery,
  useGetApiProfileClientDefaultPlatformsByClientIdQuery,
  useGetApiProfileClientDefaultPlatformsAvailableByClientIdQuery,
  useGetApiProfileClientDefaultTerritoriesByClientIdQuery,
  useGetApiProfileClientDefaultPricesByClientIdQuery,
  usePostApiProfileCreateClientMutation,
  usePostApiProfileCreateClientAgreementMutation,
  usePostApiProfileCreatePaymentAccountByClientIdMutation,
  useGetApiProfileGetClientBasicInfoByClientIdQuery,
  useGetApiProfileGetClientTenantTeamMembersByClientIdQuery,
  useGetApiProfileGetTenantTeamMembersForClientContactsByTenantIdQuery,
  useGetApiProfileGetClientDefaultsByClientIdQuery,
  useGetApiProfileGetDefaultsPlatformsQuery,
  useGetApiProfileGetClientSaasProductsByClientIdQuery,
  useGetApiProfileGetClientDomainCustomisationByClientIdQuery,
  useGetApiProfileGetClientAutoAddNewPlatformsByClientIdQuery,
  useGetApiProfileGetClientResourcesByClientIdQuery,
  useGetApiProfileGetClientAgreementsByClientIdQuery,
  useGetApiProfileGetPaymentAccountsByClientIdQuery,
  useGetApiProfileRelatedProfilesByClientIdQuery,
  usePutApiProfileUpdateClientByClientIdMutation,
  usePutApiProfileUpdateClientBasicInfoByClientIdMutation,
  usePutApiProfileUpdateClientTenantTeamMembersByClientIdMutation,
  usePutApiProfileUpdateClientDomainCustomisationByClientIdMutation,
  usePutApiProfileUpdateClientAgreementByClientIdMutation,
  usePutApiProfileDiscontinueClientAgreementByClientIdMutation,
  useDeleteApiProfileDeleteClientAgreementByClientIdMutation,
  usePutApiProfileUpdateClientSaasProductsByClientIdMutation,
  usePutApiProfileUpdateClientDefaultsByClientIdMutation,
  usePutApiProfileUpdateClientResourcesByClientIdMutation,
  useDeleteApiProfileDeleteClientByClientIdMutation,
  useDeleteApiProfileRemoveTeamMemberByClientIdMutation,
  usePutApiProfileUpdateClientAutoAddNewPlatformsByClientIdMutation,
  useGetApiProfileSearchClientsQuery,
  useGetApiProfileGetClientUsersByClientIdQuery,
  useGetApiProfileGetFtpAccountsByClientIdQuery,
  usePostApiProfileSaveFtpAccountMutation,
  useDeleteApiProfileDeleteFtpAccountMutation,
  useGetApiProfileParentLabelDefaultsPlatformDealPoliciesByParentLabelIdQuery,
  useGetApiProfileParentLabelDefaultsPlatformsByParentLabelIdQuery,
  useGetApiProfileParentLabelDefaultPlatformsAvailableByParentLabelIdQuery,
  useGetApiProfileParentLabelDefaultsTerritoriesByParentLabelIdQuery,
  useGetApiProfileParentLabelDefaultsPricesByParentLabelIdQuery,
  useGetApiProfileSearchParentLabelsQuery,
  usePostApiProfileCreateParentLabelMutation,
  useGetApiProfileGetParentLabelBasicInfoByParentLabelIdQuery,
  useGetApiProfileGetParentLabelDefaultsByParentLabelIdQuery,
  useGetApiProfileGetParentLabelResourcesByParentLabelIdQuery,
  useGetApiProfileGetParentLabelAutoAddNewPlatformsByParentLabelIdQuery,
  useGetApiProfileGetParentLabelRelatedProfilesByParentLabelIdQuery,
  useGetApiProfileGetParentLabelProductsByParentLabelIdQuery,
  usePutApiProfileUpdateParentLabelByParentLabelIdMutation,
  usePutApiProfileUpdateParentLabelBasicInfoByParentLabelIdMutation,
  usePutApiProfileUpdateParentLabelDefaultsByParentLabelIdMutation,
  usePutApiProfileUpdateParentLabelResourcesByParentLabelIdMutation,
  usePutApiProfileUpdateParentLabelAutoAddNewPlatformsByParentLabelIdMutation,
  useDeleteApiProfileDeleteParentLabelByParentLabelIdMutation,
  useGetApiProfileGetParentLabelUsersByParentLabelIdQuery,
  useGetApiProfileLabelDefaultsPlatformDealPoliciesByLabelIdQuery,
  useGetApiProfileLabelDefaultsPlatformsByLabelIdQuery,
  useGetApiProfileLabelDefaultPlatformsAvailableByLabelIdQuery,
  useGetApiProfileLabelDefaultsTerritoriesByLabelIdQuery,
  useGetApiProfileLabelDefaultsPricesByLabelIdQuery,
  useGetApiProfileSearchProfileLabelsQuery,
  usePostApiProfileCreateLabelMutation,
  useGetApiProfileGetLabelBasicInfoByLabelIdQuery,
  useGetApiProfileGetLabelDefaultsByLabelIdQuery,
  useGetApiProfileGetLabelResourcesByLabelIdQuery,
  useGetApiProfileGetLabelRelatedProfilesByLabelIdQuery,
  useGetApiProfileGetLabelProductsByLabelIdQuery,
  usePutApiProfileUpdateLabelByLabelIdMutation,
  usePutApiProfileUpdateLabelBasicInfoByLabelIdMutation,
  usePutApiProfileUpdateLabelDefaultsByLabelIdMutation,
  usePutApiProfileUpdateLabelResourcesByLabelIdMutation,
  useDeleteApiProfileDeleteLabelByLabelIdMutation,
  useGetApiProfileGetLabelUsersByLabelIdQuery,
  useGetApiProfileSearchContributorsQuery,
  usePostApiProfileCreateContributorMutation,
  useGetApiProfileGetContributorBasicInfoByContributorIdQuery,
  useGetApiProfileGetContributorImageByContributorIdQuery,
  useGetApiProfileGetContributorTagsKeywordsByContributorIdQuery,
  useGetApiProfileGetContributorTagsQuery,
  useGetApiProfileGetContributorResourcesByContributorIdQuery,
  usePutApiProfileUpdateContributorByContributorIdMutation,
  usePutApiProfileUpdateContributorBasicInfoByContributorIdMutation,
  usePutApiProfileUpdateContributorImageByContributorIdMutation,
  usePutApiProfileUpdateContributorTagKeywordsByContributorIdMutation,
  usePutApiProfileUpdateContributorResourcesByContributorIdMutation,
  useDeleteApiProfileDeleteContributorByContributorIdMutation,
  useGetApiProfileGetContributorProductsByContributorIdQuery,
  useGetApiProfileGetConnectedProfilesByContributorIdQuery,
  useGetApiProfileGetContributorRelatedFormatsByContributorIdQuery,
  useGetApiProfileGetContributorRelatedAssetsByContributorIdQuery,
  useGetApiProfileGetContributorRelatedWorksByContributorIdQuery,
  useGetApiProxyDeliverymanByPathQuery,
  usePostApiProxyDeliverymanByPathMutation,
  useGetApiProxyDispatcherByPathQuery,
  usePostApiProxyDispatcherByPathMutation,
  useGetApiProxySongkickByPathQuery,
  useGetApiReleaseScheduleGetReleaseScheduleQuery,
  useGetApiReleaseScheduleSearchReleaseScheduleQuery,
  useGetApiSearchSearchFormatsQuery,
  useGetApiSearchSearchContributorsQuery,
  useGetApiSearchSearchContributorContributionsQuery,
  useGetApiSearchSearchTracksQuery,
  useGetApiSearchSearchPublishersQuery,
  useGetApiSearchSearchTenantsQuery,
  useGetApiSearchSearchClientsQuery,
  useGetApiSearchSearchParentLabelsQuery,
  useGetApiSearchSearchLabelsQuery,
  useGetApiSearchSearchUsersQuery,
  useGetApiSearchSearchContributorIdQuery,
  useGetApiSearchSearchDdexPartyIdsQuery,
  useGetApiSearchSearchAssetTypeToFileFormatsQuery,
  useGetApiSupplierSearchSuppliersQuery,
  useGetApiSupplierGetSuggestedPartTypeDestinationByFormatQuery,
  useGetApiSupplierGetSupplierKnownPartTypesBySupplierIdQuery,
  useGetApiSupplierGetSupplierBySupplierIdQuery,
  usePostApiSupplierCreateSupplierMutation,
  usePutApiSupplierUpdateSupplierBySupplierIdMutation,
  useDeleteApiSupplierDeleteSupplierBySupplierIdMutation,
  useGetApiSupplierGetSupplierTenantContactEmailsForOrderQuery,
  useGetApiSupplierDefaultSupplierForTenantQuery,
  usePostApiTagCreateFormatStatusTagMutation,
  usePutApiTagEditFormatStatusTagMutation,
  useDeleteApiTagDeleteFormatStatusTagMutation,
  usePostApiTagAddFormatStatusTagMutation,
  useDeleteApiTagRemoveFormatStatusTagMutation,
  usePostApiTagSetFormatStatusTagsMutation,
  useGetApiTestNopeQuery,
  usePostApiTestPostMutation,
  usePostApiTestPost2Mutation,
  useGetApiTestPermsQuery,
  useGetApiTestWeatherForecastQuery,
  useGetApiTicketGetProductInfoForTicketQuery,
  useGetApiTicketGetTicketsQuery,
  useGetApiTicketGetTicketCategoriesQuery,
  useGetApiTicketGetRelatedCreatedByQuery,
  useGetApiTicketGetTicketsForProductQuery,
  usePostApiTicketCreateTicketMutation,
  usePutApiTicketUpdateTicketByTicketIdMutation,
  useGetApiTicketGetCommentsByTicketIdQuery,
  usePostApiTicketAddCommentByTicketIdMutation,
  usePostApiTicketProcessEmailReplyMutation,
  useGetApiTicketGetCategoriesOpenClosePermsQuery,
  usePutApiTicketOptInOutOfTicketByTicketIdMutation,
  usePostApiUploadGetUploadPermissionMutation,
  useGetApiUploadCheckFileExistsQuery,
  useGetApiUploadCheckUploadSessionQuery,
  useGetApiUploadGetReportBlobsQuery,
  useGetApiUploadDownloadReportQuery,
  usePutApiUserManagementChangePasswordMutation,
  usePostApiUserManagementRequestPasswordResetMutation,
  usePostApiUserManagementResetPasswordMutation,
  usePostApiUserManagementSendAuthCodeMutation,
  usePostApiUserManagementVerifyAuthCodeMutation,
  usePostApiUserManagementIsValidEmailMutation,
  usePutApiUserManagementAdminChangeUserPasswordMutation,
  usePostApiUserManagementAddUserMutation,
  usePostApiUserManagementAddUserToProductMutation,
  useGetApiUserManagementGetUserListQuery,
  useGetApiUserManagementGetAllUserPermsQuery,
  useGetApiUserManagementGetPendingUserListQuery,
  useGetApiUserManagementGetPermSettingsOnBlockQuery,
  usePostApiUserManagementSavePermsMutation,
  useGetApiUserManagementGetUserRolesQuery,
  usePostApiUserManagementSetUserRolesMutation,
  useGetApiUserManagementGetPermLevelsQuery,
  useGetApiUserManagementGetUserRoleDescriptionsQuery,
  useGetApiUserManagementGetServicePermissionDescriptionsQuery,
  useGetApiUserManagementGetPermissionDependenciesQuery,
  useGetApiUtilityGetResourceQuery,
  useGetApiUtilityGetDomainSettingsQuery,
  useGetApiUtilityGetStatsQuery,
  useGetApiUtilityGetPlatformUrlLinkTemplatesQuery,
  useGetApiUtilityGetExchangeRatesQuery,
  useGetApiUtilityCheckOnTaskSessionQuery,
  useGetApiUtilityGetAuditorStatusQuery,
  useGetApiUtilityGetAutomationTasksQuery,
  usePostApiUtilityRefreshAuditorNowMutation,
  useGetApiUtilityGetEnumsForScaffoldQuery,
  useGetApiUtilityEchoQuery,
  useGetApiUtilityGetKnowledgeArticlesQuery,
  useGetApiUtilityGetKnowledgeArticleQuery,
  useGetApiUtilityGetMemCacheInfoQuery,
} = injectedRtkApi;
