import { SelectListVm } from '@api/anthologyApi';
import { ProductCardProps } from '@components/shared/product-card/ProductCard';
import { darken, lighten } from '@mui/material/styles';
import { parseToRgba, readableColor, rgba, toHex } from 'color2k';
import { format } from 'date-fns';
import _ from 'lodash';
import environment from '../environment';

export const humanBytes = (i: number, precision: number = 2, units = 'B') => {
  if (i == null || i === 0) {
    return '0 ' + units;
  }

  const multiple = Math.max(Math.min(Math.trunc(Math.log2(Math.abs(i)) / Math.log2(1000)), 8), 0);
  const value = multiple > 0 ? Math.round((i / Math.pow(1000, multiple)) * 10 ** precision) / 10 ** precision : i;
  return `${value} ${['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'][multiple]}${units}`;
};

export function isFloat(x: any) {
  return _.isNumber(x) && x - Math.round(x) !== 0;
}

export function formatNumber(x: number, dp = 0, fallback: string = '0') {
  if (!x || _.isNaN(x)) {
    return fallback;
  }

  return parseFloat(x as any)
    .toFixed(dp)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// TODO: Make this user's locale
export const formatNumberShort = (value: number, decimalPlaces?: number) =>
  typeof value === 'number'
    ? new Intl.NumberFormat('en-UK', { notation: 'compact', maximumFractionDigits: decimalPlaces || 1, minimumFractionDigits: decimalPlaces || 0 }).format(
        value
      )
    : value;

export function retryOperation<T>(operation: () => Promise<T>, retries = 5, delay = 1000, factor = 1.2): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch((reason) => {
        if (retries > 0) {
          return new Promise<T>((r) => setTimeout(r, delay))
            .then(retryOperation.bind(null, operation, retries - 1, delay * factor, factor))
            .then(resolve as any)
            .catch(reject);
        }
        return reject(reason);
      });
  });
}

export const localizeNumber = (num: number): string => {
  const browserLanguage = typeof window !== 'undefined' ? navigator.language : 'en-US';

  return new Intl.NumberFormat(browserLanguage).format(num);
};

export const stringToColour = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).slice(-2);
  }
  return colour;
};

export const recursiveMap = (obj: any, fn: (x: any) => any) => {
  const copy = { ...obj } as any;

  for (const k in copy) {
    const v = copy[k];
    if (_.isArray(v) || _.isPlainObject(v)) {
      copy[k] = recursiveMap(v, fn);
    } else {
      copy[k] = fn(v);
    }
  }

  return copy;
};

export const invertColor = (col: string) => {
  const p = parseToRgba(col);
  return toHex(rgba(255 - p[0], 255 - p[1], 255 - p[2], p[3]));
};

export function safe<T>(fn: () => T, onError: T) {
  try {
    return fn();
  } catch {
    return onError;
  }
}

export function createLightDarkContrast(main: string, tonalOffset: number | { light: number; dark: number } = 0.3) {
  const tonalOffsetLight = (tonalOffset as any).light || (tonalOffset as number);
  const tonalOffsetDark = (tonalOffset as any).dark || (tonalOffset as number);

  return {
    main: main,
    light: lighten(main, tonalOffsetLight),
    dark: darken(main, tonalOffsetDark),
    contrastText: readableColor(main),
  };
}

export const getPackshot = (packshotGUID: string) => `${environment.CDNRoot}/preview/image/sm_crop_webp/${packshotGUID?.toLowerCase()}.webp`;

// export function productCardProps(f: FactCatalogueSummaryAlias) {
// Temp for secondaryStatus properties until endpoint updated
export function productCardProps(f: any) {
  return {
    formatId: f.formatId,
    bgcolor: 'background.paper',
    packshotGUID: f.packshotGUID,
    caption: f.localeContributorNames ?? '',
    title: f.title ?? '',
    label: f.labelName ?? '',
    status: f.status,
    statusColor: f.statusColor,
    statusTooltip: f.statusTooltip,
    secondaryStatusTooltip: f.secondaryStatusTooltip ?? '',
    format: f.formatType ?? '',
    formatTypeId: f.formatTypeId,
    date: f.releaseDate ? format(new Date(f.releaseDate ?? ''), 'MMM d, yyyy') : '',
    upc: f.upc,
    catNo: f.catNo,
    description: f.description,
  } as ProductCardProps;
}

export const isValidGuid = (val: string) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(val);
};

export function flattenObject(data: any, path: string = '') {
  const result: { [key: string]: string } = {};
  for (var i in data) {
    if (data[i] !== null && typeof data[i] == 'object') {
      Object.assign(result, flattenObject(data[i], path + '.' + i));
    } else {
      result[(path + '.' + i).replace(/^\./, '')] = data[i];
    }
  }
  return result;
}

export function pseudoRandomChecksum(s: any) {
  var seed = cyrb128(s.toString());
  return mulberry32(seed[0])();
}

export function cyrb128(str: string) {
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  return [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];
}

export function mulberry32(seed: number) {
  return function () {
    var t = (seed += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export function spliceAnywhere<T>(source: T[], insert: T[], start: number = 0) {
  if (!insert) {
    return source;
  }

  const newitems = [...source];

  if (newitems.length <= start) {
    newitems.push(...(Array.from<undefined>({ length: start - newitems.length + 1 }) as any));
  }

  newitems.splice(start, insert.length, ...insert);
  return newitems;
}

export const mapServerErrorsToModelNames = (errorModel: any, formModel: any) => {
  let errors: { name: string; reason: string }[] = errorModel?.error?.data?.validationErrors ?? [];
  const errDict: { [key: string]: string[] } = errorModel?.error?.data?.exceptionMessage?.errors ?? {};
  Object.keys(errDict).forEach((name) => errDict[name].forEach((reason: string) => modelErrors.push({ name, reason })));
  errors = errors.map((x) => ({
    name: x.name.replace('$.', ''),
    reason: x.reason.includes('JSON') ? 'required' : x.reason,
  }));
  const normalToReal = Object.fromEntries(Object.keys(flattenObject(formModel)).map((x) => [x.toLowerCase(), x]));

  const formErrors: typeof errors = [];
  const modelErrors: typeof errors = [];
  errors.forEach((e) => {
    const normalised = normalToReal[e.name.replace('[', '.').replace(']', '').toLowerCase()];
    if (!!normalised) {
      modelErrors.push({ ...e, name: normalised });
    } else {
      formErrors.push(e);
    }
  });

  return { modelErrors, formErrors };
};

export function isPromise(p: any) {
  if (p !== null && typeof p === 'object' && typeof p.then === 'function' && typeof p.catch === 'function') {
    return true;
  }

  return false;
}

export const scrollIntoView = (value: string, input: boolean = true) => {
  let el = document.querySelector(input ? `[name="${value}"]` : `#${value}`);
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

export const filterCreateErrors = (result: any, model: any) => {
  const apierr = mapServerErrorsToModelNames(result, model);
  const allErrors = [...apierr.modelErrors, ...apierr.formErrors];
  var flags = {} as any;
  var distinctByKey =
    allErrors.filter(function (entry) {
      if (flags[entry.name]) {
        return false;
      }
      flags[entry.name] = true;
      return true;
    }) ?? [];
  return distinctByKey;
};

export const removeUtc = (dt: Date) => {
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
};

export const convertStringTimeToNumeric = (val: string) => {
  let total = 0;
  if (val) {
    val.split(':').forEach((o, i) => {
      if (i > 1) {
        return false;
      }
      const multiply = i === 0 ? 60 : 1;
      total += parseInt(o.replace(/^0+/, '0'), 10) * multiply;
    });
  }
  return total;
};

export const getGenreLists = (genreList: SelectListVm[]) => {
  const grps = _.groupBy(genreList, 'grouping');
  const mainGenreLookup = Object.keys(grps).map((x) => ({
    id: grps[x][0].id,
    text: x,
  }));
  const subGenreLookup = Object.fromEntries(Object.keys(grps).map((x) => [grps[x][0].id, grps[x].map((x) => ({ ...x, text: x.text ?? '' }))]));
  return { mainGenreLookup, subGenreLookup };
};

export const toTitleCase = (str: string) => {
  if (str == null) {
    return '';
  }
  const articles = ['a', 'an', 'the'];
  const conjunctions = ['for', 'and', 'nor', 'but', 'or', 'yet', 'so', 'is'];
  const prepositions = ['with', 'at', 'from', 'into', 'upon', 'of', 'to', 'in', 'for', 'on', 'by', 'like', 'over', 'plus', 'but', 'up', 'down', 'off', 'near'];

  const replaceCharsWithSpace = (str: string) => str.replace(/[^0-9a-z&/\\]/gi, ' ').replace(/(\s\s+)/gi, ' ');
  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.substring(1);
  const normalizeStr = (str: string) => str.toLowerCase().trim();
  const shouldCapitalize = (word: string, fullWordList: string[], posWithinStr: number) => {
    if (posWithinStr === 0 || posWithinStr === fullWordList.length - 1) {
      return true;
    }
    return !(articles.includes(word) || conjunctions.includes(word) || prepositions.includes(word));
  };

  str = replaceCharsWithSpace(str);
  str = normalizeStr(str);

  let words = str.split(' ');
  if (words.length <= 2) {
    words = words.map((w) => capitalizeFirstLetter(w));
  } else {
    for (let i = 0; i < words.length; i++) {
      words[i] = shouldCapitalize(words[i], words, i) ? capitalizeFirstLetter(words[i]) : words[i];
    }
  }
  return words.join(' ');
};

export const emptyObject = (obj: any): boolean => {
  return Object.keys(obj ?? {}).length === 0;
};

export async function httpRequest<T>(url: string, init: RequestInit | undefined = undefined): Promise<T> {
  const response = await fetch(url, init);
  return await response.json();
}

export const secondsToTime = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');

  return h + ':' + m + ':' + s;
};

/**
 * This does nothing, it is just a placeholder to tell TypeScript that you want a hook to depend on a value,
 *  so you dont get the hook dependancy warning when you want to include dependancies that are not otherwise explicitly used in the body
 */
export function hookDependsOn(someObject: any) {
  return someObject;
}
