import { ThemeOptions } from '@mui/material';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { anthologyApi } from '@src/api/anthologyApi';
import { Guid } from 'guid-typescript';
import _ from 'lodash';
import { store } from '../features/store';
import { themeSetTheme, updateDomainSettings } from '../features/themeSlice';
import { getAnthologyTheme, makeThemeFromTokens, tokensFromBackend } from '../utils/theme/themes';
import diagnosticService from './diagnosticsService';

class themeServiceClass {
  state = () => store.getState().theme;

  init() {
    const initState = this.state();

    if (!initState.domainConfig?.domainCustomisationId) {
      //switch to default if somthing is wrong
      store.dispatch(themeSetTheme({ customDomain: false }));
    }

    // fire off request to backend to fetch more themes
    this.loadThemesFromServer();
  }

  _getTheme(useDomain: boolean, isDark: boolean): ThemeOptions {
    const state = this.state();

    if (state.domainConfig?.domainCustomisationId === 1) {
      useDomain = false;
    }

    const tokens = useDomain ? tokensFromBackend(state.domainConfig, isDark) : getAnthologyTheme(isDark);
    const themeOpts = makeThemeFromTokens(tokens as any);

    try {
      document.querySelector("meta[name='theme-color']")!.setAttribute('content', (themeOpts.palette?.primary as any).main ?? '');
    } catch {}

    const newTheme: ThemeOptions = extendTheme(themeOpts);
    delete (newTheme as any).colorSchemes.dark;

    return newTheme;
  }

  getTheme(useDomain: boolean, isDark: boolean): ThemeOptions {
    try {
      return this._getTheme(useDomain, isDark);
    } catch (e: any) {
      diagnosticService.exception(e, 'Error creating theme object');
      return this._getTheme(false, isDark);
    }
  }

  loadThemesFromServer() {
    store
      .dispatch(anthologyApi.endpoints.getApiUtilityGetDomainSettings.initiate())
      .unwrap()
      .then((newState) => {
        const state = this.state();

        let oldToken = tokensFromBackend(state.domainConfig);
        let newToken = tokensFromBackend(newState);

        if (!state.theme.customDomain || !_.isEqual(oldToken, newToken)) {
          store.dispatch(updateDomainSettings(newState));
          store.dispatch(themeSetTheme({ customDomain: true, version: Guid.create().toString() }));
          diagnosticService.log('New theme settings were loaded from server');
        }
      });
  }

  makeThemeFromOneColor(colour: string) {
    const newDom = {
      ...this.state().domainConfig,
      domainCustomisationId: -1,
      primaryColor: colour,
      accentColor: undefined,
      backgroundColor: undefined,
      textColor: undefined,
    };
    store.dispatch(updateDomainSettings(newDom));
    store.dispatch(themeSetTheme({ customDomain: true, version: Guid.create().toString() }));
  }
}

const themeService = new themeServiceClass();
export default themeService;
