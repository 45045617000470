/* eslint-disable no-console */

import { combine } from '@datadog/browser-core';
import { datadogLogs, LogsEvent, StatusType } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import env from '../environment';
import { store } from '../features/store';
import { subscribeToSlice } from '../utils/redux';

export const LogLevels = {
  0: { type: 'DISABLED' as StatusType, fn: console.debug.bind(console) },
  1: { type: StatusType.debug, fn: console.debug.bind(console) },
  2: { type: StatusType.info, fn: console.info.bind(console) },
  3: { type: StatusType.warn, fn: console.warn.bind(console) },
  4: { type: StatusType.error, fn: console.error.bind(console) },
};

export class DiagnosticServiceClass {
  msgBuffer: LogsEvent[] = [];
  envTag = env.production ? 'prod' : 'dev';

  userState = () => store.getState().auth.user;

  init() {
    env.logging.printLevel = env.logging.printLevel ?? 0;
    env.logging.uploadLevel = env.logging.uploadLevel ?? 0;

    datadogLogs.init({
      clientToken: env.datadog.logToken,
      site: 'datadoghq.eu',
      service: env.datadog.appName,
      env: this.envTag,
      version: env.autoGitHash,
      forwardErrorsToLogs: env.logging.uploadLevel !== 0,
      beforeSend: (log) => this.datadogBeforeSend(log),
    });

    datadogLogs.logger.setHandler(['http']);
    datadogLogs.logger.setLevel('debug');

    if (env.datadog.enableRUM) {
      datadogRum.init({
        applicationId: env.datadog.rumAppId,
        clientToken: env.datadog.rumToken,
        site: 'datadoghq.eu',
        service: env.datadog.appName,
        env: this.envTag,

        version: env.autoGitHash,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        startSessionReplayRecordingManually: true,
      });

      subscribeToSlice(
        (s) => s.auth.user,
        (a, b) => a?.user?.userId === b?.user?.userId,
        true,
        (newUser, oldUser) => {
          // if the current user is Null nothing happens and the state before refresh is kept

          //there was an existing different userid - clear session
          // this will happen in a logoff event or a switch user event
          if (oldUser?.user?.userId) {
            this.warn('RUM session stopped');
            datadogRum.stopSessionReplayRecording();
            datadogRum.clearUser();
          }

          // there is not a userid - start session
          if (newUser?.user?.userId) {
            this.warn('RUM session starting ' + newUser?.user?.userId);
            datadogRum.startSessionReplayRecording();
            datadogRum.setUser({
              id: newUser?.user?.userId.toString(),
              name: newUser?.user?.username ?? newUser?.user?.email ?? undefined,
              email: newUser?.user?.email ?? undefined,
            });
          }
        }
      );
    }

    this.info('Starting logging session with key ' + datadogLogs.getInternalContext()?.session_id);
    this.info('Console verbosity set to ' + LogLevels[env.logging.printLevel as keyof typeof LogLevels].type);
    this.info('Upload verbosity set to ' + LogLevels[env.logging.uploadLevel as keyof typeof LogLevels].type);

    setInterval(() => {
      if (this.msgBuffer.length > 0) {
        const payload = this.msgBuffer.splice(0, this.msgBuffer.length);
        console.info('Sending ' + payload.length + ' logs');
        // store.dispatch(anthologyApi.endpoints.postApiFrontEndLoggingSubmitLog.initiate(payload));
      }
    }, 5000);
  }

  public debug = (...e: any[]) => this.prepare_log(1, e);
  public info = (...e: any[]) => this.prepare_log(2, e);
  public log = (...e: any[]) => this.prepare_log(2, e);
  public warn = (...e: any[]) => this.prepare_log(3, e);
  public error = (...e: any[]) => this.prepare_log(4, e);
  public internal_error = (...e: any[]) => this.prepare_log(4, e, undefined, 1);
  public exception = (err: Error, ...e: any[]) => this.prepare_log(4, e, err);

  private datadogBeforeSend(log: LogsEvent): boolean {
    const meta = log._meta ?? {};
    delete log._meta;

    // we dont need user info in internal copy
    const internal_log = combine(log, { env: this.envTag, version: env.autoGitHash });
    internal_log.context = meta;
    this.msgBuffer.push(internal_log);

    // add context for datadog

    const user = this.userState();

    log.usr = {
      id: user?.user?.userId,
      name: user?.user?.username,
      email: user?.user?.email,
    };

    Object.entries(meta ?? {}).forEach((kv) => {
      log[kv[0]] = kv[1];
    });

    return true;

    // return void signals datadog to send message
  }

  private prepare_log(level: keyof typeof LogLevels, msgParts: any[], err: Error | undefined = undefined, offset = 0) {
    if (!this.should_print(level) && !this.should_upload(level)) {
      return;
    }

    if (err == null) {
      err = msgParts.find((e) => e instanceof Error);
    }

    msgParts = msgParts.filter((e) => e != null && !(e instanceof Error));

    const canPrint = (x: any) => typeof x != 'object' && !Array.isArray(x);

    const nonPrintable = msgParts.filter((x) => !canPrint(x));
    let msg = msgParts
      .filter((x) => canPrint(x))
      .map((x) => x.toString())
      .join(' ');
    const context = nonPrintable.length <= 1 ? nonPrintable[0] ?? {} : { ...nonPrintable };

    if (err !== undefined) {
      msg += ` (${err?.name}: ${err?.message})`;
    }

    if (this.should_print(level)) {
      const params: any[] = [LogLevels[level].type.toUpperCase(), '|', ...msgParts, '|', err != null ? '\n' + err.stack : ''];
      LogLevels[level].fn(...params);
    }

    if (this.should_upload(level)) {
      datadogLogs.logger.log(msg, { _meta: context }, LogLevels[level]?.type, err);
    }
  }

  private should_upload(level: number) {
    return env.logging.uploadLevel > 0 && level >= env.logging.uploadLevel;
  }

  private should_print(level: number) {
    return env.logging.printLevel > 0 && level >= env.logging.printLevel;
  }
}

const diagnosticService = new DiagnosticServiceClass();
export default diagnosticService;
