import { Box, Skeleton } from '@mui/material';
import { useIntervalEffect } from '@react-hookz/web';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { GridChildComponentProps } from 'react-window';
import { InfiniteGridWrapper, InfiniteGridWrapperRef } from '@components/utility/InfiniteGridWrapper';
import { LogData } from '@models/dispatcher';
import { pseudoRandomChecksum, spliceAnywhere } from '../../../../utils/helpers';
import './logViewStyles.scss';

type InfiniteLogViewerProps = {
  live?: boolean;
  autoScroll?: boolean;
  items?: LogData[];
  itemLengthGetter?: () => Promise<number>;
  itemGetter?: (offset: number, pagesize: number, cache: boolean) => Promise<LogData[]>;
};

export function InfiniteLogViewer({ live, items, itemLengthGetter, itemGetter, autoScroll = true }: InfiniteLogViewerProps) {
  const pageSize = 100;
  const ref = React.useRef<InfiniteGridWrapperRef>();

  const [state, setState] = useState({ items: [] as LogData[], itemCount: 0 });

  useEffect(() => {
    if (items) {
      setState((s) => ({ ...s, items: items, itemCount: items.length }));
    }
  }, [items]);

  useEffect(() => {
    if (itemLengthGetter) {
      itemLengthGetter().then((n) => {
        setState((state) => ({ ...state, itemCount: Math.max(n, state.itemCount) }));
      });
    }
  }, [itemLengthGetter]);

  useIntervalEffect(() => {
    if (live && itemGetter) {
      loadNextPage(Math.max(state.itemCount, 0), state.itemCount + pageSize, false);
    }
  }, 2000);

  const loadNextPage = (startin: number, endin: number, useCache = true) => {
    const start = useCache ? Math.floor(startin / pageSize) * pageSize : startin;
    const end = useCache ? Math.ceil(endin / pageSize) * pageSize : endin;

    return itemGetter!(start, end - start + 1, useCache).then((lines: LogData[]) => {
      if (!lines) return;

      const newitems = spliceAnywhere(state.items, lines, start);
      const newlen = Math.max(state.itemCount, start + lines.length);
      setState((state) => ({ ...state, items: newitems, itemCount: Math.max(state.itemCount, start + lines.length) }));

      if (autoScroll && live && (ref.current?.scrollPercent ?? 0) > 0.9 && newlen !== state.itemCount) {
        setTimeout(() => {
          ref.current?.grid?.scrollToItem({ align: 'end', columnIndex: 1, rowIndex: newlen });
        }, 100);
      }
    });
  };

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', bgcolor: 'black' }}>
        <InfiniteGridWrapper
          wrapperRef={ref as any}
          items={state.items}
          itemCount={state.itemCount ?? pageSize}
          loadNextPage={itemGetter ? loadNextPage : undefined}
          itemHeight={20}
          itemWidth={1}
          columnCount={1}
          itemComponent={(r) => <LogLine r={r} />}
          loadingComponent={LoadingLine}
          chunkSize={pageSize}
        />
      </Box>
    </>
  );
}

function LoadingLine(child: GridChildComponentProps<any>) {
  return <Skeleton variant="rectangular" width={100 + pseudoRandomChecksum(child.rowIndex) * 600} height={18}></Skeleton>;
}

function LogLine({ r }: { r: LogData }) {
  function msgClass(r: LogData) {
    const mcls = r.pipetype === 'monitor' || r.loglevel === 'DEBUG' ? 'NOTSET' : null;
    return r.loglevel === 'ERROR' || r.loglevel === 'CRITICAL' || r.pipetype === 'stderr' ? 'ERROR' : mcls;
  }

  return (
    <pre className="logline console--text">
      <span>{r.session_id ? 'SID: ' + r.session_id : ''} </span>
      <span className="LOGTIME">{format(parseISO(r.log_time as any), 'd/M/yy, HH:mm:ss')} </span>
      <span className={`prespace ${r.loglevel}`}> {r.loglevel.padStart(7)}</span>
      <span className={`prespace ${msgClass(r) ?? ''}`}> {r.msg.slice(0, 1200)}</span>
    </pre>
  );
}
