import { ButtonWrapper } from '@anthology/shared/src/components';
import { Stack, TextField } from '@mui/material';
import { RootState } from '@src/features/store';
import { useSelector } from 'react-redux';

function secondsToTime(e: any) {
  const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');
  return m + ':' + s;
}

const TimeStampSelector = ({ value, ...rest }: any) => {
  let newVal = value;
  if (!((typeof value === 'string' || value instanceof String) && value.includes(':'))) {
    newVal = secondsToTime(value);
  }

  const currentTheme = useSelector((state: RootState) => state.theme.theme);
  const { isDark } = currentTheme;

  return (
    <Stack direction={'row'} gap={2}>
      <TextField
        {...rest}
        value={newVal}
        type={'time'}
        size="small"
        sx={{
          '&.MuiTextField-root': {
            'input::-webkit-calendar-picker-indicator': {
              filter: isDark ? 'invert(100%)' : '',
            },
          },
        }}
      />
      {newVal !== '00:00' && (
        <ButtonWrapper variant="text" onClick={() => rest.onChange(0)}>
          Reset
        </ButtonWrapper>
      )}
    </Stack>
  );
};

export default TimeStampSelector;
