import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AcAssetInfoVm } from '@api/anthologyApi';
export type CreateKey = 'assetList';

interface CreateState {
  format: { [key: number]: { [key in CreateKey]: any } };
}

const init = {
  format: {},
} as CreateState;

const slice = createSlice({
  name: 'create',
  initialState: init,
  reducers: {
    setCreateData: (state, { payload }: PayloadAction<{ formatId: number; route: CreateKey; data: any }>) => {
      const format = state.format[payload.formatId] ?? {
        assetList: [],
      };
      format[payload.route] = payload.data;
      state.format[payload.formatId] = format;
    },
    addAsset: (state, { payload }: PayloadAction<{ formatId: number; data: AcAssetInfoVm }>) => {
      const assetList: AcAssetInfoVm[] = state.format[payload.formatId].assetList || [];
      if (assetList.some((asset: AcAssetInfoVm) => asset.assetInfoId === payload.data.assetInfoId)) return;
      state.format[payload.formatId].assetList = [...assetList, payload.data];
    },
  },
});

export const { setCreateData, addAsset } = slice.actions;
export default slice.reducer;
