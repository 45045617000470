import { ButtonWrapper } from '@anthology/shared/src/components';
import {
  AllSessionStatus,
  ReportUploadSubmission,
  useGetApiClientReportingGetPlatformsAcceptingReportUploadsQuery,
  useGetApiUtilityCheckOnTaskSessionQuery,
  usePostApiClientReportingLaunchImportJobMutation,
} from '@api/anthologyApi';
import FileUploader from '@components/shared/file-uploader/FileUploader';
import FormHook, { FormHookDropDown } from '@components/shared/form-components/FormHook';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useIntervalEffect } from '@react-hookz/web';
import { UploadDestinations } from '@services/blobService';
import { TaskLiveLogViewer } from '@src/pages/cloudAdmin/dispatcher/dispatcherComps';
import notificationService from '@src/services/notificationService';
import { differenceInSeconds, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

type ApproxTimeProgressProps = {
  approxTimeSec: number;
  progress?: number;
  active?: boolean;
  softMax?: number;
  softMin?: number;
} & LinearProgressProps;

export const ApproxTimeProgress = ({
  approxTimeSec,
  softMax = 100,
  softMin = 0,
  progress = 0,
  active = true,
  ...rest
}: ApproxTimeProgressProps): JSX.Element => {
  const refreshInterval = 200;
  const [v, setV] = useState(0);
  const [ticks, setTicks] = useState(0);

  useIntervalEffect(() => {
    if (active) setTicks((x) => x + 1);
  }, refreshInterval);

  useEffect(() => {
    setV(progress);
  }, [progress]);

  useEffect(() => {
    setV((x) => {
      let nv = x + (refreshInterval / 1000) * (100 / approxTimeSec);
      nv = Math.min(nv, softMax, 100);
      nv = Math.max(nv, softMin, 0);
      return nv;
    });
  }, [ticks, softMax, softMin, approxTimeSec]);

  return <LinearProgress variant={active ? 'determinate' : 'indeterminate'} value={v} {...rest}></LinearProgress>;
};

export function MiniSessionViewer({
  sessionid,
  defaultShowLog,
  onPoll,
  onFinish,
  approxDurationExpected,
  maxStepsExpected,
  ultraMini,
}: {
  sessionid: number;
  defaultShowLog?: boolean;
  onPoll?: (state: AllSessionStatus) => void;
  onFinish?: (success: boolean, finalState: AllSessionStatus) => void;
  approxDurationExpected?: number;
  maxStepsExpected?: number;
  ultraMini?: boolean;
}) {
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState<boolean | undefined>(undefined);
  const [showLog, setShowLog] = useState(!!defaultShowLog);
  const { data: sessionStatus, currentData: freshStatus } = useGetApiUtilityCheckOnTaskSessionQuery(sessionid, { skip: isDone, pollingInterval: 1000 });

  useEffect(() => {
    if (!!freshStatus) {
      onPoll?.(freshStatus);
      if (freshStatus.exitCode != null) {
        const err = freshStatus.exitCode !== 0;
        setIsDone(true);
        setIsError(err);
        onFinish?.(!err, freshStatus);
      }
    }
  }, [freshStatus, onFinish, onPoll]);

  const currentStep = sessionStatus?.progressStep ?? 0;
  const finalStep = sessionStatus?.progressMaxsteps ?? maxStepsExpected ?? 5;
  const isLogViewerLive = sessionStatus?.status === 'running' || differenceInSeconds(new Date(), parseISO(sessionStatus?.lastUpdate ?? '1900-01-01')) < 10;
  const has_prog = ![0, 100].includes(sessionStatus?.progressPercent ?? 0);

  if (ultraMini) {
    return (
      <>
        <Typography variant="bodyLarge" color={!isDone ? undefined : isError ? 'error.main' : 'success.main'}>
          {!!sessionStatus?.progressMaxsteps ? `Step ${sessionStatus?.progressStep}/${finalStep}: ` : ''}
          {isError !== undefined && isError ? 'Failed: ' : ''}
          {isError !== undefined && !isError ? 'Finished: ' : ''}
          {sessionStatus?.progressMessage ?? 'Starting...'}
          {has_prog ? ` : ${sessionStatus!.progressPercent}%` : ''}
        </Typography>

        <ApproxTimeProgress
          approxTimeSec={approxDurationExpected ?? 40}
          active={!!sessionStatus?.progressMessage}
          softMin={isDone ? 100 : ((currentStep - 1) / finalStep) * 100}
          softMax={(currentStep / finalStep) * 100}
          color={'secondary'}
          sx={{ height: '8px', borderRadius: '10px', marginBottom: 2, marginTop: 2 }}
        />
      </>
    );
  }

  return (
    <>
      <Typography variant="sub2" color={!isDone ? undefined : isError ? 'error.main' : 'success.main'}>
        {!!sessionStatus?.progressMaxsteps ? `Step ${sessionStatus?.progressStep}/${finalStep}: ` : ''}
        {isError !== undefined && isError ? 'Failed: ' : ''}
        {isError !== undefined && !isError ? 'Finished: ' : ''}
        {sessionStatus?.progressMessage ?? 'Starting...'}
        {has_prog ? ` : ${sessionStatus!.progressPercent}%` : ''}
      </Typography>

      <ApproxTimeProgress
        approxTimeSec={approxDurationExpected ?? 40}
        active={!!sessionStatus?.progressMessage}
        softMin={isDone ? 100 : ((currentStep - 1) / finalStep) * 100}
        softMax={(currentStep / finalStep) * 100}
        color={'secondary'}
        sx={{ height: '20px', borderRadius: '10px', marginBottom: 2, marginTop: 2 }}
      />

      <ButtonWrapper onClick={() => setShowLog((x) => !x)}> {showLog ? 'Hide details' : 'Show details'} </ButtonWrapper>

      <Box height={'70vh'} visibility={isError || showLog ? 'visible' : 'hidden'}>
        <TaskLiveLogViewer sessionid={sessionid} live={isLogViewerLive}></TaskLiveLogViewer>
      </Box>
    </>
  );
}

const validationSchema = Yup.object().shape({
  platformId: Yup.number().required(),
  blobPath: Yup.string().required(),
});

export const UploadReportWithProgress = (): JSX.Element => {
  const hookForm = useForm({
    mode: 'all',
    defaultValues: {} as ReportUploadSubmission,
    resolver: yupResolver(validationSchema),
  });

  const { data: allPlats } = useGetApiClientReportingGetPlatformsAcceptingReportUploadsQuery();
  const [liveSessionId, setLiveSessionId] = useState(-1);
  const [sendJob] = usePostApiClientReportingLaunchImportJobMutation();

  const sortedPlats = useMemo(() => {
    if (!allPlats) return [];
    return _.sortBy(allPlats, ['grouping', 'text']).map((x) => ({ ...x, text: `${x.text} (${x.type})` }));
  }, [allPlats]);

  const selectedPlat = sortedPlats.find((x) => x.id === hookForm.watch('platformId'));

  function clickSubmit() {
    sendJob(hookForm.getValues())
      .unwrap()
      .then((x) => {
        setLiveSessionId(x.value!);
        hookForm.resetField('blobPath');
        notificationService.postNotification({
          noteProps: {
            message: `Report ingestion in progress. `,
            meta: { sessionid: x.value! },
          },
          flashForSec: 20,
          expiresInSec: 120,
        });
      });
  }

  return (
    <FormProvider {...hookForm}>
      <Typography variant="h3">Upload Report</Typography>

      <Grid2 container spacing={2} py={1} mt={2} maxWidth={'900px'} marginLeft={'auto'} marginRight={'auto'}>
        <Grid2 xs={12}>
          <FormHookDropDown valueIsID label={'Report type'} name={'platformId'} options={sortedPlats} groupBy={(x) => x.grouping} fullWidth />
        </Grid2>

        <Grid2 md={12}>
          <FormHook name="blobPath">
            {(hook) => (
              <FileUploader
                showDropzone
                isDoc
                accept={selectedPlat?.type ?? 'select a platform above'}
                value={hook.value}
                onChange={(s) => hook.onChange(`${s.remoteBucket}/${s.remotePath}`)}
                destinationBucket={UploadDestinations.reports}
              />
            )}
          </FormHook>
        </Grid2>

        <Grid2 xs={12}>
          <ButtonWrapper variant="contained" onClick={clickSubmit} disabled={!hookForm.formState.isValid}>
            Upload
          </ButtonWrapper>
        </Grid2>
        <Grid2 xs={12}>{liveSessionId < 0 ? undefined : <MiniSessionViewer sessionid={liveSessionId} key={liveSessionId} />}</Grid2>
      </Grid2>
    </FormProvider>
  );
};
