import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import _ from 'lodash';

const env = import.meta.env;

export const isDev = env.DEV;
export const isProd = !isDev;

// DEV CONFIG, ALL OPTIONS MUST BE SPECIFIED, SEE BELOW FOR PRODUCTION
// some options may come from .env file ( import.meta.env )
const environment = {
  production: false,
  clientRoot: typeof window !== 'undefined' ? `${window.location.origin}` : '',
  apiRoot: env.VITE_API_ROOT || 'https://dev.anthology.to',
  enableReduxDevTools: true,
  CDNRoot: 'http://cdn.anthology.to',

  MuiLicenceKey: '463269195681cf145f3857bafcb3aaeaTz03ODQ5NyxFPTE3MzEyNjMxNjcwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
  defaultErrorImage: 'http://cdn.anthology.to/static/missing.png',

  logging: {
    printLevel: 1, // 0=OFF, 1=DEBUG, 2=INFO, 3=WARN, 4=ERROR
    uploadLevel: 0,
  },

  datadog: {
    rumAppId: 'f54e23db-cc50-4c0b-91ec-fc8be053fc59',
    rumToken: 'pub40f8610796f3a9a9080a49b0651652d0',
    logToken: 'pub25f0ea416a1ea119a9488c80d71dd82d',
    appName: 'anthology-react',
    enableRUM: false,
  },

  //these values are automatically populated during production build
  autoGitHash: '334',
  autoVersion: '0.1.0',
  autoSourceHash: 'o9f5gkaOT3KEzgF7cQLKIvdMu2g=',
  autoBuildDate: '2024-01-31T13:24:00.113Z',

  // Branding
  PRODUCT_NAME: 'Anthology',

  // External
  slinkyUrl: 'slinky.ink/',

  runtime: runTimeEnvironment(),
};

if (isProd) {
  // -------------------  PRODUCTION overrides ----------------------
  // Only values that are different are needed
  _.merge(environment, {
    production: true,
    apiRoot: '',

    enableReduxDevTools: false,

    logging: {
      printLevel: 3, // 0=OFF, 1=DEBUG, 2=INFO, 3=WARN, 4=ERROR
      uploadLevel: 1,
    },

    datadog: {
      enableRUM: true,
    },
  } as typeof environment);
}

function runTimeEnvironment() {
  const platform = Capacitor.getPlatform();
  return {
    platform,
    isWeb: platform === 'web',
    isAndroid: platform === 'android',
    isIos: platform === 'ios',
    isMobileApp: ['ios', 'android'].includes(platform),
    isElectron: platform === 'electron',
    deviceInfo: Device.getInfo(),
  };
}

export default environment;
