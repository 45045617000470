import { useEffect, useState } from "react";
import { unknownImage } from "../../constants/urls";
import { ImageWrapperProps } from "./ImageWrapperProps";

const ImageWrapper = ({
  src,
  fallbackSrc,
  width,
  height,
  fullWidth,
  alt,
  autoHeight,
  fit,
  className,
  style,
}: ImageWrapperProps) => {
  const [imageSrc, setImageSrc] = useState(src);

  const useFallBackImg = () => {
    setImageSrc(fallbackSrc || unknownImage);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return (
    <img
      src={imageSrc}
      onError={useFallBackImg}
      alt={alt || ""}
      width={fullWidth ? "100%" : width}
      height={autoHeight ? "auto" : height || width}
      style={{ ...style, objectFit: fit || style?.objectFit }}
      className={className}
    />
  );
};

export default ImageWrapper;
