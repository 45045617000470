import { Stop, PlayArrow, DoneOutline, Warning, Error, DisabledByDefault } from '@mui/icons-material';
import { Chip, Stack, Badge } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback } from 'react';
import { DispatcherTaskInfo } from '@models/dispatcher';
import { anthologyApi } from '@src/api/anthologyApi';
import { InfiniteLogViewer } from './infiniteLogViewer/InfiniteLogViewer';

export const RenderStatus = React.memo((params: GridRenderCellParams<DispatcherTaskInfo>) => {
  let val = params.value ?? 'unknown';

  if (!!(params.row.exit_code && params.row.exit_code !== 0) && val === 'stopped') {
    val = 'failed';
  }

  if (!params.row.schedule_active) {
    val = 'disabled';
  }

  const props =
    (
      {
        stopped: { color: 'info', icon: <Stop /> },
        running: { color: 'success', icon: <PlayArrow />, variant: 'filled' },
        failed: { color: 'error', icon: <Warning />, variant: 'filled' },
        disabled: { icon: <DisabledByDefault />, variant: 'filled' },
      } as any
    )[val] ?? {};

  return <Chip size="small" variant="outlined" label={val} {...props}></Chip>;
});

export const RenderWarn = React.memo((params: GridRenderCellParams<DispatcherTaskInfo>) => {
  return (
    <Stack direction="row" spacing={1}>
      {(params.row.warnings ?? 0) + (params.row.errors ?? 0) === 0 && (
        <div title="Last Run Success">
          <DoneOutline color="success" />
        </div>
      )}
      {(params.row.warnings ?? 0) > 0 && (
        <Badge badgeContent={params.row.warnings} color="info">
          <Warning color="warning" />
        </Badge>
      )}
      {(params.row.errors ?? 0) > 0 && (
        <Badge badgeContent={params.row.errors} color="info">
          <Error color="error" />
        </Badge>
      )}
    </Stack>
  );
});

export function TaskLiveLogViewer({ sessionid, live }: { sessionid: number; live: boolean }) {
  const [triggerLog] = anthologyApi.useLazyDispatcherGetJobLogQuery();
  const [triggerLen] = anthologyApi.useLazyDispatcherGetJobLogLengthQuery();

  const getter = useCallback(
    (offset: number, pagesize: number, cache: boolean) => triggerLog({ sessionid, offset, pagesize }, cache).then((x) => x.data!),
    [sessionid, triggerLog]
  );
  const lenGetter = useCallback(() => triggerLen({ sessionid }).then((x) => x.data!.rows), [sessionid, triggerLen]);

  return <InfiniteLogViewer live={live} key={sessionid} itemGetter={getter} itemLengthGetter={lenGetter} />;
}
