export const imageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp', 'heif', 'heic'];
export const audioFileTypes = ['mp3', 'wav', 'aac', 'flac', 'ogg', 'm4a', 'wma', 'alac', 'opus', 'amr', 'aif', 'aiff'];
export const videoFileTypes = [
  'mp4',
  'mov',
  'wmv',
  'flv',
  'avi',
  'avchd',
  'webm',
  'mkv',
  'm4v',
  '3gp',
  'mpg',
  'mpeg',
  'm2ts',
  'mts',
  'ts',
  'ogv',
  'mxf',
  'asf',
];
export const spreadsheetFileTypes = ['xls', 'xlsx', 'ods', 'csv', 'tsv', 'slk', 'gnumeric', 'numbers'];
export const compressedFileTypes = ['zip', 'rar', '7z', 'tar', 'gz', 'bz2', 'xz'];
export const fileTypes = {
  image: imageFileTypes,
  audio: audioFileTypes,
  video: videoFileTypes,
  spreadsheet: spreadsheetFileTypes,
  compressed: compressedFileTypes,
};
export const playerFileTypes = ['webm', 'jpeg', 'jpg', 'png', 'gif', 'svg', 'webp', 'bmp', 'mp3', 'wav', 'ogg', 'aac', 'flac'];
export const allFileTypes = [...imageFileTypes, ...audioFileTypes, ...videoFileTypes, ...spreadsheetFileTypes, ...compressedFileTypes];
