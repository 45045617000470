import {
  BsBuilding,
  BsBuildingFill,
  BsCreditCard2Back,
  BsCreditCard2BackFill,
  BsDisc,
  BsDiscFill,
  BsMusicNote,
  BsPerson,
  BsPersonFill,
  BsPlayBtn,
  BsPlayBtnFill,
  BsSoundwave,
} from 'react-icons/bs';
import { FcGlobe } from 'react-icons/fc';
import { MdOutlinePlayLesson, MdPlayLesson } from 'react-icons/md';

export const CommonIcons = {
  ProductDigital: {
    Regular: BsSoundwave,
    Fill: BsSoundwave,
  },
  ProductPhysical: {
    Regular: BsDisc,
    Fill: BsDiscFill,
  },
  ProductVideo: {
    Regular: BsPlayBtn,
    Fill: BsPlayBtnFill,
  },
  Track: {
    Regular: BsMusicNote,
    Fill: BsMusicNote,
  },
  Artist: {
    Regular: BsPerson,
    Fill: BsPersonFill,
  },
  Finance: {
    Regular: BsCreditCard2Back,
    Fill: BsCreditCard2BackFill,
  },
  Slinky: {
    Regular: MdOutlinePlayLesson,
    Fill: MdPlayLesson,
  },
  Label: {
    Regular: BsBuilding,
    Fill: BsBuildingFill,
  },
  WorldWide: FcGlobe,
};
