import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SelectListVm } from '@src/api/anthologyApi';

export type KnownTypes =
  | 'accesslevel'
  | 'agecertification'
  | 'artistrole'
  | 'assettype'
  | 'contributorrole'
  | 'currency'
  | 'formatstatustags'
  | 'formattype'
  | 'formattypegroup'
  | 'genre'
  | 'saasproduct'
  | 'currency'
  | 'tenant'
  | 'gender'
  | 'instrument'
  | 'language'
  | 'musicalworktype'
  | 'parentaltype'
  | 'parttype'
  | 'parttypecategory'
  | 'permission'
  | 'physicalformatdimension'
  | 'physicalformatmapping'
  | 'platform'
  | 'platformbusinesstype'
  | 'platformdisplaygroup'
  | 'policytype'
  | 'price'
  | 'publisher'
  | 'publisherrole'
  | 'rechargecategory'
  | 'regioncode'
  | 'resourcetype'
  | 'rightscontrollertype'
  | 'service'
  | 'ticketsstatus'
  | 'supplieraddresstype'
  | 'suppliercontacttype'
  | 'taxtype'
  | 'taxtypeoption'
  | 'territory'
  | 'writerRole'
  | 'rate'
  | 'clientsettings'
  | 'ticketscategory'
  | 'ticketspartner'
  | 'ticketspriority'
  | 'ticketsproduct'
  | 'ticketsrating'
  | 'ticketsstatus'
  | 'ticketstag'
  | 'mcpslicencetype'
  | 'banktransactiontype'
  | 'platformdeliveryassettype'
  | 'metadatatype'
  | 'colorspace'
  | 'platformdeliveryservertype'
  | 'platformcommercialmodel'
  | 'commercialmodel'
  | 'usetype'
  | 'teammemberrole';

export type ConfigState = {
  enum: { [key in KnownTypes]: SelectListVm[] };
};

const init = {
  enum: {},
} as ConfigState;

const slice = createSlice({
  name: 'config',
  initialState: init,
  reducers: {
    configSetItem: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: KnownTypes;
        items: SelectListVm[];
      }>
    ) => {
      if (payload.items) {
        state.enum = state.enum ?? {};
        state.enum[payload.key] = payload.items;
      }
    },
  },
});

export const { configSetItem } = slice.actions;

export default slice.reducer;
