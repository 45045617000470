import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';
import RightClickMenuWrapper from '../right-click-menu-wrapper/RightClickMenuWrapper';
import './LabelIcon.scss';
import { LabelIconProps } from './LabelIconProps';

const LabelIcon = ({ Icon, text, selected, itemClick, expandable, isExpanded, isSmall, getNotificationCount, fullPath }: LabelIconProps) => {
  const [notificationCount, setNotificationCount] = useState(0);
  useEffect(() => {
    if (getNotificationCount) {
      getNotificationCount().then((res) => setNotificationCount(res));
    }
  }, [getNotificationCount]);
  return (
    <RightClickMenuWrapper path={fullPath}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        className={`LabelIcon ${selected ? ' LabelIcon--Selected' : ''} ${expandable ? 'expandable' : ''}`}
        onMouseDown={itemClick}
      >
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          {Icon && (
            <Box display={'flex'} className="LabelIcon__Icon">
              <Icon size="1rem" />
            </Box>
          )}
          <Box className={`LabelIcon__Text ${isSmall ? 'LabelIcon__Text__Small' : ''}`}>
            <Typography variant={isSmall ? 'bodyMedium' : 'bodyLarge'} className="oneLineTruncate">
              {text}
            </Typography>
            {expandable && (isExpanded ? <BsCaretDownFill size="0.5rem" /> : <BsCaretRightFill size="0.5rem" />)}
          </Box>
        </Stack>
        {!!notificationCount && <Typography color={'text.secondary'}>{notificationCount > 50 ? '50+' : notificationCount}</Typography>}
      </Stack>
    </RightClickMenuWrapper>
  );
};

export default LabelIcon;
