import { avatarBlankDark, avatarBlankLight } from '@anthology/shared/src/constants/urls';
import { RootState } from '@src/features/store';
import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ArtistImg = ({ imgUrl, contributorId, style }: { imgUrl?: string; contributorId?: number; style?: CSSProperties }) => {
  const nav = useNavigate();
  const onClick = () => {
    nav(`/profiles/contributors/contributor-detail/${contributorId}`);
  };
  const { theme } = useSelector((state: RootState) => state.theme);
  const isDarkMode = theme.isDark;
  const fallbackImg = isDarkMode ? avatarBlankDark : avatarBlankLight;
  return (
    <img
      src={imgUrl || fallbackImg}
      className={contributorId ? 'clickable' : ''}
      alt=""
      style={{ borderRadius: '50%', objectFit: 'cover', aspectRatio: '1/1', ...style }}
      onClick={contributorId ? onClick : undefined}
    />
  );
};

export default ArtistImg;
