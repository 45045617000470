import { useAudioService } from '@anthology/shared/src/services';
import { Close, Pause, PlayArrow, Repeat, Shuffle, SkipNext, SkipPrevious, VolumeMute, VolumeUp } from '@mui/icons-material';
import { Box, ClickAwayListener, IconButton, Popover, Slide, Slider } from '@mui/material';

import { useSafeAreaInsets } from '@src/hooks/useSafeArea';

import { formatDuration } from '@anthology/shared/src/utils/helpers';
import { useUiSlice } from '@src/services/layoutService';
import React, { useRef, useState } from 'react';
import './AudioPlayer.scss';

export const audioPlayerHeight = '80px';

export default function AudioPlayer() {
  const { state } = useAudioService(false);

  return state.isMiniPlayer ? (
    <></>
  ) : (
    <Slide direction="up" in={state.playerVisible} mountOnEnter unmountOnExit>
      <AudioPlayerMain />
    </Slide>
  );
}

const Progress = () => {
  const { controls, state } = useAudioService(true);

  return (
    <Slider
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => formatDuration(value, true)}
      min={0}
      max={state.duration}
      value={state.currentTime}
      color={'secondary'}
      onChange={(e, v) => controls.seek(v as number)}
    />
  );
};

const AudioPlayerMain = React.forwardRef((props, ref) => {
  const { controls, state } = useAudioService(false);
  const [showVolume, setShowVolume] = useState(false);
  const volumnButtonRef = useRef<any>();
  const ui = useUiSlice();
  const playing = controls.now_playing();

  const CloseButton = () => (
    <IconButton onClick={() => controls.close()}>
      <Close></Close>
    </IconButton>
  );

  const safeArea = useSafeAreaInsets();
  const bottomPosition = safeArea.bottom + ui.bottomBarHeight;

  const isMuted = state.volume === 0;

  const toggleMute = () => {
    if (isMuted) {
      controls.setVolume(0.5);
    } else {
      controls.setVolume(0);
    }
  };

  return (
    <Box
      className={'container visible'}
      ref={ref as any}
      sx={{
        left: ui.sideBarMarginWidth,
        bottom: bottomPosition,
        width: `calc(100vw - ${ui.sideBarMarginWidth}px)`,
        height: audioPlayerHeight,
      }}
    >
      <div className="player">
        <div className="progress">
          <div className="current_time">{state.readableCurrentTime}</div>
          <div className="remain_time">{state.readableDuration}</div>
          <Progress></Progress>
        </div>

        <div className="column left">
          <div className="info_container">
            <div className="packshot">
              <img src={playing.imageUrl} alt="" />
            </div>
            <div className="info oneLineTruncate">
              <span className="title">{playing?.title}</span>
              <br />
              <span className="artist">{playing?.artist}</span>
            </div>
          </div>
        </div>

        <div className="column center">
          <div className="buttons">
            <div className="icon hide_small">
              <IconButton onClick={() => controls.toggle_repeat()} className={state.repeat ? 'active' : ''}>
                <Repeat></Repeat>
              </IconButton>
            </div>
            <div className="icon hide_small">
              <IconButton onClick={() => controls.previous()}>
                <SkipPrevious></SkipPrevious>
              </IconButton>
            </div>
            <div className="icon play">
              {state.playing ? (
                <IconButton onClick={() => controls.pause()}>
                  <Pause></Pause>
                </IconButton>
              ) : (
                <IconButton onClick={() => controls.play()}>
                  <PlayArrow></PlayArrow>
                </IconButton>
              )}
            </div>
            <div className="icon">
              <IconButton onClick={() => controls.next()}>
                <SkipNext></SkipNext>
              </IconButton>
            </div>
            <div className="icon hide_small">
              <IconButton onClick={() => controls.toggle_shuffle()} className={state.shuffle ? 'active' : ''}>
                <Shuffle></Shuffle>
              </IconButton>
            </div>
            <div className="icon show_small">
              <CloseButton />
            </div>
          </div>
        </div>

        <div className="column right hide_small">
          <div className="buttons">
            <div className="icon hide_small">
              <ClickAwayListener onClickAway={() => setShowVolume(false)}>
                <Box>
                  <IconButton onClick={toggleMute} onMouseEnter={() => setShowVolume(true)} ref={volumnButtonRef}>
                    {isMuted ? <VolumeMute /> : <VolumeUp />}
                  </IconButton>
                  <Popover
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      onMouseLeave: () => setShowVolume(false),
                      sx: { height: 120, overflow: 'hidden', py: 2 },
                    }}
                    open={showVolume}
                    anchorEl={volumnButtonRef.current}
                    hideBackdrop
                    sx={{ position: 'unset' }}
                  >
                    <Slider
                      orientation="vertical"
                      sx={{ color: 'text.secondary', '.MuiSlider-thumb': { borderRadius: 2, height: 9 } }}
                      value={state.volume}
                      min={0}
                      max={1}
                      onChange={(e, v) => controls.setVolume(v as number)}
                      step={0.05}
                    />
                  </Popover>
                </Box>
              </ClickAwayListener>
            </div>
            {/* <div className="icon">
        <IconButton  onClick={togglePlaylist}>queue_music</IconButton >

        <div className="playlist" *ngIf="showPlaylist">
          <ul>
            <li *ngFor="let item of audioPlayerService.state.playlist; let i = index" onClick={"audioPlayerService.goto(i);" class.current_track="audioPlayerService.state.current_track===i">
              {{i+1}}. {{item.title}}
            </li>
          </ul>
        </div>
      </div> */}
            <div className="icon">
              <CloseButton />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
});
