import { Box, useTheme } from "@mui/material";
import { CSSProperties, useCallback, useMemo } from "react";
import showdown from "showdown";
import { CDNRoot } from "../../constants/urls";

const theme2Css = (t: CSSProperties, mt: number = 60) =>
  `font-size: ${t.fontSize}; font-weight: ${t.fontWeight}; line-height: ${t.lineHeight}; letter-spacing: ${t.letterSpacing}; margin-top: ${mt}px `;

export function useShowDown(headingTopMargin: number = 60) {
  const theme = useTheme();

  const converter = useMemo(() => {
    const classMap: any = {
      h1: theme2Css(theme.typography.h1, headingTopMargin),
      h2: theme2Css(theme.typography.h2, headingTopMargin),
      h3: theme2Css(theme.typography.h3, headingTopMargin),
      h4: theme2Css(theme.typography.h4, headingTopMargin),
      h5: theme2Css(theme.typography.h5, headingTopMargin),
    };

    const bindings = Object.keys(classMap).map((key) => ({
      type: "output",
      regex: new RegExp(`<${key}(.*)>`, "g"),
      replace: `<${key} style="${classMap[key]}" $1>`,
    }));

    var converter = new showdown.Converter({
      tables: true,
      parseImgDimensions: true,
      noHeaderId: false,
      simplifiedAutoLink: true,
      openLinksInNewWindow: true,
      headerLevelStart: 2,
      extensions: [...bindings],
    });

    return converter;
  }, [theme, headingTopMargin]);

  const renderMd = useCallback(
    (source: string, extractContents: boolean = true) => {
      const body = converter.makeHtml(source.replaceAll("{cdn_root}", CDNRoot));
      const regex = /<h3.*id\s*=\s*("[^"]*"|'[^']*')[^>]*>([^<]+)/gm;
      const contentsArray = [];

      if (extractContents) {
        let m;

        while ((m = regex.exec(body)) !== null) {
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }

          const [id, title] = m.slice(1);
          contentsArray[m.index] = {
            id: id.replaceAll('"', ""),
            title: title,
          };
        }
      }

      return { body: body, contents: contentsArray };
    },
    [converter]
  );
  return renderMd;
}

const MarkDownView = ({ source }: { source: string }) => {
  const renderMd = useShowDown();
  const md = renderMd(source);
  return (
    <Box sx={{ "> span > p": { margin: 0 } }}>
      <span dangerouslySetInnerHTML={{ __html: md.body }} />
    </Box>
  );
};

export default MarkDownView;
