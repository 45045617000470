import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import ElectronHeader from '@src/components/electron/electron-header/ElectronHeader';
import ElectronMenu from '@src/components/electron/electron-menu/ElectronMenu';
import MobileMenu from '@src/components/mobile/mobile-menu/MobileMenu';

import { useSafeAreaInsets } from '@src/hooks/useSafeArea';

import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { useAudioService } from '@anthology/shared/src/services';
import MobileTopMenu from '@src/components/mobile/mobile-top-menu/MobileTopMenu';
import { audioPlayerHeight } from '@src/components/shared/audio-player/AudioPlayer';
import environment from '@src/environment';
import { setSideMenuState } from '@src/features/uiSlice';
import { PageLayouts, useUiSlice } from '@src/services/layoutService';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConnectionMessage from '../connection-message/ConnectionMessage';
import Header from '../header/Header';
import SideNavigation from '../nav/SideNavigation';
import './Dashboard.scss';

type DashboardProps = {
  children?: React.ReactNode;
};

const Dashboard = ({ children }: DashboardProps) => {
  const ui = useUiSlice();

  const fullScreen = ui.layout === PageLayouts.fullscreen;
  const pagePad = ui.dashboardPadding;

  const safeAreaInsets = useSafeAreaInsets();
  const mobileDashboardPaddingBottom = `${ui.bottomBarHeight + (fullScreen ? 0 : safeAreaInsets.bottom)}px`;

  const dashboardRef = useRef<HTMLElement>();
  ui.actions.dashboardElement = dashboardRef;

  const location = useLocation();
  const isMobileLayout = useCssBreakpoint('sm');
  const isMobileApp = environment.runtime.isMobileApp;
  const dispatch = useDispatch();
  useEffect(() => {
    if (isMobileLayout) {
      dispatch(setSideMenuState({ isOpen: false }));
    }
  }, [dispatch, isMobileLayout, location.pathname]);

  const { state: audioState } = useAudioService(false);

  if (!environment.runtime.isElectron) {
    return (
      <>
        <Stack className={`DashboardContainer `}>
          <SideNavigation />
          <Header />
          <MobileTopMenu />
          <Box
            id={'dashboard'}
            className={`Dashboard ${isMobileApp ? 'DashboardMobileApp' : ''}`}
            height={'100vh'}
            bgcolor={'background.default'}
            padding={pagePad + 'px'}
            paddingLeft={pagePad + ui.sideBarMarginWidth + 'px'}
            paddingTop={(ui.topBarHeight || pagePad) + 'px'}
            color={'text.contrast'}
            flex={1}
            ref={dashboardRef}
            pb={mobileDashboardPaddingBottom}
            pt={`${ui.dashboardTopPadding + safeAreaInsets.top}px`}
          >
            <Box sx={{ pb: audioState.playerVisible ? '120px' : 0 }}>{children}</Box>
          </Box>
          <Box paddingLeft={`${ui.sideBarMarginWidth}px`} paddingBottom={audioState.playerVisible ? audioPlayerHeight : 0}>
            <ConnectionMessage />
          </Box>
          <MobileMenu />
        </Stack>
      </>
    );
  }

  return (
    <Stack className={`DashboardContainer DashboardContainerElectron`}>
      <ElectronHeader />
      {!ui.isMobileLayout && <ElectronMenu />}
      <Box
        id={'dashboard'}
        className={`Dashboard DashboardElectron`}
        height={ui.isMobileLayout && !fullScreen ? '100vh' : undefined}
        bgcolor={'background.default'}
        padding={pagePad + 'px'}
        paddingLeft={!fullScreen ? 3 : pagePad + ui.sideBarMarginWidth + 'px'}
        paddingTop={!fullScreen ? 3 : pagePad + ui.topBarHeight + 'px'}
        color={'text.contrast'}
        flex={1}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default Dashboard;
