import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SlinkyVm } from '@api/slinkyApi';
import { AcSlinkyTrackingVm } from '@models/apiTemp/acSlinkyTrackingVm';
//import { LinksAndSocials } from '../pages/slinky/links/LinksPage';

export interface SlinkyWrapperVm {
  // lists: { [key: string]: any };
  tracking: AcSlinkyTrackingVm;
  // links: LinksAndSocials;
}

export interface SlinkyState {
  slinky: { [key: string | number]: SlinkyWrapperVm }; // it's likely number, but I didn't want to touch tracking page
}

const init = {
  slinky: {},
} as SlinkyState;

const slice = createSlice({
  name: 'slinky',
  initialState: init,
  reducers: {
    setTracking: (state, { payload }: PayloadAction<{ slinkyId: string; data: AcSlinkyTrackingVm }>) => {
      const slinky = state.slinky[payload.slinkyId] ?? { basic: {} };
      state.slinky[payload.slinkyId] = slinky;
      state.slinky[payload.slinkyId].tracking = payload.data;
    },
    setLinks: (state, { payload }: PayloadAction<{ slinkyId: number; data: SlinkyVm }>) => {
      const slinky = state.slinky[payload.slinkyId] ?? { basic: {} };
      state.slinky[payload.slinkyId] = slinky;
      // state.slinky[payload.slinkyId].links = payload.data;
    },
  },
});

export const { setTracking, setLinks } = slice.actions;
export default slice.reducer;
