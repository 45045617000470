import { EventEmitter } from "events";

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const formatDuration = (seconds: number, short = false, min_level = 5) => {
  const levels = [
    [Math.floor(seconds / 31536000), "year"],
    [Math.floor((seconds % 31536000) / 86400), "day"],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), "hr"],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), "min"],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) % 60), "sec"],
  ];

  let returntext = "";
  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0 && returntext === "" && i < min_level) {
      continue;
    }
    if (short) {
      returntext +=
        levels[i][0].toString().padStart(2, "0") + (i === 4 ? "" : ":");
    } else {
      returntext +=
        " " +
        levels[i][0] +
        " " +
        (levels[i][0] === 1 ? levels[i][1] : levels[i][1] + "s");
    }
  }
  return returntext.trim();
};

class SimpleEmitter<T> {
  public e!: EventEmitter;

  constructor() {
    this.e = new EventEmitter();
  }

  emit(arg: T): boolean {
    return this.e.emit("event", arg);
  }

  /**
   * Adds a change listener. Returns a funtion to unsubscribe
   *
   * @returns A function to unsubscribe.
   */
  subscribe(listener: (arg: T) => void): () => void {
    this.e.addListener("event", listener);
    return () => this.e.removeListener("event", listener);
  }

  unsubscribe(listener: (arg: T) => void): void {
    this.e.removeListener("event", listener);
  }
}

const isClient = () => typeof window !== "undefined";

export { SimpleEmitter, formatDuration, isClient, openInNewTab };
