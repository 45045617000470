import { capitaliseInitial } from '@anthology/shared/src/utils/string';
import { Collapse, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';

import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LabelIcon from '../../shared/label-icon/LabelIcon';

import useLocationMeta from '@src/hooks/useLocationMeta';
import { RouteMeta } from '@src/routeMeta';

export interface NavItemProps {
  item: Partial<RouteMeta>;
  hideIcon?: boolean;
  isCollapsed?: boolean;
  iconSize?: number;
  onClick?: () => void;
}

const NavItem = ({ item, onClick, hideIcon = false, isCollapsed = false, iconSize = 28 }: NavItemProps) => {
  const { meta, topLevel } = useLocationMeta();
  const navigate = useNavigate();

  const { children, path, icon, label, getNotificationCount } = item;
  const navChildren = (children && children.filter((child) => child.navListItem)) || [];
  const selected = path === topLevel;
  const [isExpanded, setIsExpanded] = useState(selected);

  // Dropdown when collapsed

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToPath = (path: string) => {
    handleClose();
    navigate(path);
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!isCollapsed && navChildren.length > 0) {
        setIsExpanded(!isExpanded);
      } else if (navChildren.length > 0) {
        handleOpen(event);
      } else if (path !== undefined) {
        navigate(path);
      } else if (onClick) {
        onClick();
      }
    },
    [isCollapsed, isExpanded, navChildren.length, navigate, onClick, path]
  );

  const Icon = () => !!icon && (icon as any)({ size: iconSize });

  // Reset when navigation

  const location = useLocation();

  useEffect(() => {
    if (!path || location.pathname.indexOf(path) === -1) {
      setIsExpanded(false);
    }
  }, [location.pathname, path]);

  return (
    <>
      {isCollapsed ? (
        <Stack alignItems={'center'}>
          <Tooltip title={capitaliseInitial(label || '')} placement="right">
            <IconButton key={path} onClick={handleClick} color={selected ? 'primary' : 'inherit'} sx={{ p: 1.5 }}>
              <Icon></Icon>
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <LabelIcon
          key={path}
          itemClick={handleClick}
          Icon={icon}
          text={label!}
          selected={selected}
          expandable={navChildren.length > 0}
          isExpanded={isExpanded}
          getNotificationCount={getNotificationCount}
        ></LabelIcon>
      )}

      {isExpanded && navChildren.length > 0 && !isCollapsed && (
        <Collapse in={isExpanded}>
          <Stack gap={0.5} pl={1.5}>
            {navChildren.map((child) => {
              const fullPath = `${item.path}/${child.path}`;
              return (
                <LabelIcon
                  key={child.path}
                  fullPath={fullPath}
                  text={child.label}
                  selected={!!meta?.fullPath && meta.fullPath.indexOf(fullPath) > -1}
                  isSmall
                ></LabelIcon>
              );
            })}
          </Stack>
        </Collapse>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {navChildren.map((child) => {
          const fullPath = `${item.path}/${child.path}`;
          return (
            <MenuItem key={child.path} onClick={() => goToPath(fullPath)}>
              {capitaliseInitial(child.label)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default NavItem;
