import { useIntervalEffect } from '@react-hookz/web';
import { useAuth } from '@services/authService';
import { LocationWithMeta } from '@src/hooks/useLocationMeta';
import React, { useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';

type RouteGuardProps = {
  children?: React.ReactNode;
  routeMeta: LocationWithMeta;
};

const RouteGuard = ({ routeMeta, children }: RouteGuardProps) => {
  const { location, meta } = routeMeta;
  const { actions } = useAuth();

  const allow = useMemo(() => actions.canAccessRoute(meta), [meta, actions]);
  const [expired, setExpired] = useState(actions.checkTokenExpired());

  useIntervalEffect(() => {
    if (!expired && actions.checkTokenExpired()) {
      setExpired(true);
    }
  }, 1000);

  if ((allow && !expired) || meta?.authRules?.includes('public')) {
    return <>{children}</>;
  }

  return <Navigate to="/login" state={location.pathname}></Navigate>;
};

export default RouteGuard;
