import { Button, ButtonProps, styled } from "@mui/material";

export type ButtonWrapperProps = ButtonProps;

const getContainerHeight = (size: "large" | "medium" | "small") => {
  switch (size) {
    case "large":
      return "56px";
    case "medium":
      return "43px";
    case "small":
      return "35px";
  }
};

const getPadding = (size: "large" | "medium" | "small") => {
  switch (size) {
    case "large":
      return "20px 24px";
    case "medium":
      return "13px 24px";
    case "small":
      return "9px 24px";
  }
};

const StyledButton = styled(Button)(({ theme, size = "medium" }) => ({
  height: getContainerHeight(size),
  padding: getPadding(size),
  borderRadius: "30px",
  fontSize: theme.typography.button.fontSize,
}));

export function ButtonWrapper(props: ButtonWrapperProps) {
  return <StyledButton {...props} />;
}
