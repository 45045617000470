import { createTypography, defaultThemeTokens, themeCssBreakpoints } from '@anthology/shared/src/constants/theme';
import {
  CorePalette,
  DynamicScheme,
  MaterialDynamicColors,
  TonalPalette,
  argbFromHex,
  hexFromArgb,
  themeFromSourceColor,
} from '@material/material-color-utilities';
import { ThemeOptions } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';
import { colorChannel } from '@mui/system';
import { DomainsByTenant } from '@src/api/anthologyApi';
import { getLuminance, readableColorIsBlack } from 'color2k';
import _ from 'lodash';
import { createLightDarkContrast } from '../helpers';

const lightTokens = {
  ...defaultThemeTokens,
  isDark: false,

  textPrimary: '#2E2E2E',
  textSecondary: '#727272',
  textDisabled: '#848592',

  backgroundDefault: '#F9F9FB',
  backgroundPaper: '#FFFFFF',
  backgroundDialog: '#FEFEFE',
};

const autoThemeTokens = {
  ...defaultThemeTokens,
  textPrimary: undefined,
  textSecondary: undefined,
  textDisabled: undefined,
  backgroundDefault: undefined,
  backgroundPaper: undefined,
  backgroundDialog: undefined,
};

export type themeTokens = typeof defaultThemeTokens;

export const getAnthologyTheme = (isDark: boolean = true) => (isDark ? defaultThemeTokens : { ...lightTokens, isDark: false });

export function tokensFromBackend(be: DomainsByTenant, isDark: boolean = true): themeTokens {
  const t = _.cloneDeep(autoThemeTokens) as any as themeTokens;
  t.isDark = isDark;
  be = be ?? {};

  if (isDark) {
    t.backgroundDefault = be.backgroundColor ?? t.backgroundDefault;
    t.textPrimary = be.textColor ?? t.textPrimary;
  }

  t.palette.primary = be.primaryColor ?? t.palette.primary;
  t.palette.secondary = (be.accentColor ?? undefined) as any;

  t.font = be.fontFamily ?? t.font;
  t.fontScale = be.fontScale ?? t.fontScale;

  return t;
}

export function makeThemeFromTokens(tokens: themeTokens): Partial<ThemeOptions> {
  const typography = createTypography(tokens.font, tokens.fontScale);
  const muiPalette: PaletteOptions = muiPaletteFromMaterial3(tokens);

  // Technically these themes should wrap palette in  colorSchemes: {   light: {
  // to work with css vars but if omitted the values are put in 'light' colorScheme
  // keeping this way to allow compat with non css var theme system
  const t: Partial<ThemeOptions> = {
    typography: typography,
    palette: muiPalette,
    breakpoints: { values: themeCssBreakpoints },

    components: {
      MuiChip: {
        variants: [
          {
            props: { variant: 'card' },
            style: {
              width: 'fit-content',
              height: '20px',
              padding: '8px',
              borderRadius: '2px',
              ':hover': {
                cursor: 'pointer',
              },
              '& .MuiChip-label': {
                padding: 0,
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: '110%',
              },
            },
          },
        ],
      },

      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: (muiPalette.error as any).main,
            '&$error': {
              color: (muiPalette.error as any).main,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: typography.body1?.fontSize,
            fontWeight: typography.body1?.fontWeight,
            maxWidth: '500px',
          },
        },
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
    },
  };

  t.palette!.background = {
    ...toChannel(t.palette!.background),
    ...t.palette!.background,
  };
  (t.palette!.common as any) = {
    background: t.palette?.background.default,
    onBackground: t.palette?.text?.primary,
    ...t.palette?.common,
  };

  return t;
}

function muiPaletteFromMaterial3(tokens: themeTokens, blendColors = true): PaletteOptions {
  const mt = materialDynamicScheme(
    tokens.palette.primary,
    tokens.isDark,
    Object.entries(tokens.palette)
      .filter((x) => (x[1] ?? '').length > 0)
      .map(([k, v]) => ({ name: k, value: v })),
    blendColors
  );

  const nearestTone = (tones: TonalPalette, target: string) => {
    //figure out the nearest tone value from 0-100 to the provided source color
    const res = 2;
    const comparer = (x: string, y: string) => Math.abs(getLuminance(x) - getLuminance(y));
    const match = _.minBy(_.range(0, 100, res), (t) => comparer(hexFromArgb(tones.tone(t)), target));
    return match;
  };

  const optsFromCustom = (tones: TonalPalette, midpoint: number | undefined = undefined) => {
    //based on
    //https://github.com/material-foundation/material-color-utilities/blob/ce99247b6bae7cdb5aa859b99b605cc6b2e96e7a/typescript/utils/theme_utils.ts#L120

    let darkMid = 75;
    let lightMid = 40;

    if (midpoint) {
      // only override the tone provided if its too dark/light for each theme
      // otherwise stay true to original
      darkMid = midpoint >= 50 ? midpoint : 50;
      lightMid = midpoint < 50 ? midpoint : 45;
    }

    const spread = 10;

    const mp = tokens.isDark ? darkMid : lightMid;
    const main = hexFromArgb(tones.tone(mp));

    return {
      light: hexFromArgb(tones.tone(Math.min(mp + spread, 100))),
      main: main,
      dark: hexFromArgb(tones.tone(Math.max(mp - spread, 0))),
      contrastText: readableColorIsBlack(main) ? hexFromArgb(tones.tone(10)) : hexFromArgb(tones.tone(100)),
    };
  };

  const cp = Object.fromEntries(
    Object.entries(tokens.palette).map(([k, v]) => {
      if (!blendColors && (tokens.palette as any)[k]) {
        //use fixed method with no modification to colors
        return [k, createLightDarkContrast((tokens.palette as any)[k])];
      } else {
        let tones = null;
        if (['primary', 'secondary', 'tertiary'].includes(k)) {
          // if the palette is one of the built in palettes use the theme
          tones = (mt.theme.palettes as any)[k];
        } else {
          //generate varients from custom cols
          tones = CorePalette.of(mt.customPalettes[k].value).a1;
        }
        const orig = (tokens.palette as any)[k];
        const nm = orig ? nearestTone(tones, orig) : undefined;
        return [k, optsFromCustom(tones, nm)];
      }
    })
  );

  const disabledAlphaHex = '40';

  return {
    mode: tokens.isDark ? 'dark' : 'light',
    background: {
      default: tokens.backgroundDefault ?? hexFromArgb(MaterialDynamicColors.surface.getArgb(mt.dynamic)),
      paper: tokens.backgroundPaper ?? hexFromArgb(MaterialDynamicColors.surfaceContainer.getArgb(mt.dynamic)),
      dialog: tokens.backgroundDialog ?? hexFromArgb(MaterialDynamicColors.surfaceContainerHigh.getArgb(mt.dynamic)),
    } as any,
    text: {
      primary: tokens.textPrimary ?? hexFromArgb(MaterialDynamicColors.onSurface.getArgb(mt.dynamic)),
      secondary: tokens.textSecondary ?? hexFromArgb(MaterialDynamicColors.onSurfaceVariant.getArgb(mt.dynamic)),
      disabled: tokens.textDisabled ?? hexFromArgb(MaterialDynamicColors.onSurface.getArgb(mt.dynamic)) + disabledAlphaHex,
    },
    ...cp,
  };
}

function materialDynamicScheme(primaryColor: string, isDark: boolean, extraCols: { name: string; value: string }[] = [], blend = true, contrastLevel = 0.5) {
  extraCols = extraCols ?? [];

  const cols = (extraCols ?? []).map((c) => ({
    name: c.name,
    value: argbFromHex(c.value),
    blend: blend,
  }));

  const theme = themeFromSourceColor(argbFromHex(primaryColor), cols);

  const customPalettes = Object.fromEntries(theme.customColors.map((c) => [c.color.name, c]));

  //override theme second/tertiary if proivided, if not add them to custompallets
  ['primary', 'secondary', 'tertiary'].forEach((p) => {
    if (p !== 'primary' && customPalettes[p]) {
      (theme.palettes as any)[p] = TonalPalette.fromInt(customPalettes[p].value);
    }
  });

  const dynamic = new DynamicScheme({
    sourceColorArgb: theme.source,
    primaryPalette: theme.palettes.primary,
    secondaryPalette: theme.palettes.secondary,
    tertiaryPalette: theme.palettes.tertiary,
    neutralPalette: theme.palettes.neutral,
    neutralVariantPalette: theme.palettes.neutralVariant,
    isDark: isDark,
    contrastLevel: contrastLevel,
    variant: 3,
  });

  return {
    theme,
    customPalettes,
    dynamic,
  };
}

function toChannel(obj: any) {
  return _.fromPairs(Object.entries(obj).map(([k, v]) => [k + 'Channel', colorChannel(v as string)]));
}

export const colorArray = ['#ED117D', '#4FC0E8', '#FFCF55', '#F96F50', '#6565A5', '#A2D465', '#EC5566', '#48CFAD'];

export const getColorItem = (index: number, settings?: { darken?: boolean; reverse?: boolean }): string => {
  const colors = settings?.reverse ? colorArray.reverse() : colorArray;
  const l = colors.length;
  return `${index > l ? colors[(index % l) + index] : colors[index]}${settings?.darken ? '55' : ''}`;
};
