const CDNRoot = "http://cdn.anthology.to";
const staticPath = `${CDNRoot}/static/`;
const defaultErrorImage =  `${CDNRoot}/static/missing.png`;
const generalPath = `${CDNRoot}/static/general/`;
const logosPath = `${CDNRoot}/static/logos/`;
const avatarBlankDark = `${generalPath}avatar-blank-dark-mode.png`;
const avatarBlankLight = `${generalPath}avatar-blank.png`;
const flagPath = `${staticPath}flags/`;
const unknownImage = `${flagPath}circle/xx.svg`;
const slinkyIconUrl = `${logosPath}slinky-icon.png`;
const slinkyBaseUrl = "https://slinky.ink/";

export {
  CDNRoot,
  avatarBlankDark,
  avatarBlankLight,
  defaultErrorImage,
  flagPath,
  generalPath,
  logosPath,
  slinkyBaseUrl,
  slinkyIconUrl,
  staticPath,
  unknownImage,
};
