import { Guid } from "guid-typescript";

const replaceAt = (
  string: string,
  index: number,
  replacement: string
): string => {
  return string.substring(0, index) + replacement + string.substring(index + 1);
};

const isSpecialCharacter = (string: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const match = string.match(format);
  return !!match && match.length > 0;
};

const capitaliseInitial = (string: string): string => {
  return string.charAt(0)?.toUpperCase() + string.slice(1);
};

const removeSpecialCharacters = (string: string): string => {
  return string.replace(/[^A-Z0-9]/gi, "");
};

const removeDuplicateWordsFromString = (string: string): string => {
  if (!string) return "";
  const array = string.split(" ");
  const unique = Array.from(new Set(array.reverse())).reverse();
  return unique.join(" ");
};

const truncateString = (string: string, maxLength: number): string => {
  const visableString = string.substring(0, maxLength);
  if (string.length > visableString.length) {
    return visableString + "...";
  } else {
    return visableString;
  }
};

const uid = () => Guid.create().toString();

const generateInitials = (string: string) => {
  if (!string) return "";
  const nameArray = string.split(" ");
  const firstInital = nameArray[0][0]?.toUpperCase();
  const isSecondInitialUppercase =
    nameArray[0][1] && nameArray[0][1]?.toUpperCase() === nameArray[0][1];
  let secondInitial;
  if (isSecondInitialUppercase) {
    secondInitial = nameArray[0][1];
  } else if (nameArray[1]) {
    secondInitial = nameArray[1][0]?.toUpperCase();
  } else if (nameArray[0][1]) {
    secondInitial = nameArray[0][1];
  }
  return `${firstInital}${secondInitial}`;
};

const textFilterMatch = (string: string, term: string) => {
  return !term || string.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1;
};

const camelToTitle = (string: string) => {
  const result = string.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export {
  camelToTitle,
  capitaliseInitial,
  generateInitials,
  isSpecialCharacter,
  removeDuplicateWordsFromString,
  removeSpecialCharacters,
  replaceAt,
  textFilterMatch,
  truncateString,
  uid,
};
