import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import { useEffect, useMemo, useState } from 'react';

export const useSafeAreaInsets = () => {
  const [safeAreaInsets, setSafeAreaInsets] = useState<SafeAreaInsets>({
    insets: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  });
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then((res: any) => {
      if (res.insets) {
        // res = {
        //   insets: {
        //     top: 47,
        //     bottom: 38,
        //     left: 5,
        //     right: 5,
        //   },
        // };
        setSafeAreaInsets(res);
      }
    });
  }, []);

  return useMemo(() => {
    const insets = safeAreaInsets?.insets;
    return {
      ...insets,
      top: Math.max(insets.top, 0),
      bottom: Math.max(insets.bottom - 5, 0),
    };
  }, [safeAreaInsets?.insets]);
};
