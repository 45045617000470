import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../features/store';
import { themeSetTheme } from '../../../features/themeSlice';

import { Box, IconButton, Stack } from '@mui/material';
import { BsMoon, BsSun } from 'react-icons/bs';
import './ThemeSwitcher.scss';

export default function ThemeSwitcherComponent({ isCollapsed }: { isCollapsed?: boolean }) {
  const dispatcher = useDispatch();
  const theme = useSelector((state: RootState) => state.theme.theme);

  const handleSwitch = (isDark?: boolean) => {
    dispatcher(themeSetTheme({ isDark: isDark || !theme.isDark }));
  };

  return isCollapsed ? (
    <Stack alignItems={'center'} mt={2}>
      <IconButton onClick={() => handleSwitch(!theme.isDark)}>{theme.isDark ? <BsSun /> : <BsMoon />}</IconButton>
    </Stack>
  ) : (
    <Box className="ThemeSwitcher">
      <BsSun onClick={() => handleSwitch(false)} />
      <Switch id="theme" checked={theme.isDark} onChange={() => handleSwitch()} size={'small'} />
      <BsMoon onClick={() => handleSwitch(true)} />
    </Box>
  );
}
