import { useForceUpdate } from '@anthology/shared/src/hooks';
import { SimpleEmitter } from '@anthology/shared/src/utils/helpers';
import { anthologyApi, AssetInformationWithMetum, BlobUploadPermsVm } from '@api/anthologyApi';
import { useEffectOnce } from '@src/hooks/useEffectOnce';
import { differenceInSeconds, parseISO } from 'date-fns';
import { Guid } from 'guid-typescript';
import _, { isNumber } from 'lodash';
import { useState } from 'react';
import * as SparkMD5 from 'spark-md5';
import { store } from '../features/store';
import { retryOperation } from '../utils/helpers';
import { default as diagnosticService, default as log } from './diagnosticsService';

export interface IUploadFileValidationState {
  message: string;
  isValid: boolean;
}

export interface IUploadedFile {
  uploadSessionToken: Guid;
  localMd5?: string;
  alreadyExists: boolean;
  progress: number;
  assetInfo?: AssetInformationWithMetum;
  sessionName: string;
  destinationId: UploadDestinations;
  verifyMD5: boolean;
  pollForProcessing: boolean;
  state: UploadState;
  file: File;
  abortControl: AbortController;
  removeOnComplete: boolean;
  reactKey: string;
  remotePath: string;
  remoteBucket: string;
  mediaType: keyof typeof MediaTypes;
  processingStarted: Date;
  failReason: string;
  isValid?: (param: any) => Promise<IUploadFileValidationState>;
}

export enum UploadDestinations {
  assets = 'assets',
  reports = 'reports',
  slinky = 'slinky',
  slinkyDestination = 'slinky_destination',
  ticketAttachment = 'ticket_attachment',
  // Not real yet
  profiles = 'profiles',
}

export enum UploadState {
  added = 'Checking',
  uploading = 'Uploading',
  processing = 'Processing',
  preview = 'Making Preview',
  complete = 'Complete',
  failed = 'Failed',
}

const MediaTypes = {
  audio: 'wav,flac,mp3,wma,ogg,aac,aiff'.split(','),
  video: 'mov,mp4,mkv,wmv,avi,webm,m4v'.split(','),
  document: 'pdf'.split(','),
  image: 'jpg,jpeg,png,bmp,gif,tiff,webp'.split(','),
};

const defaultOptions: Partial<IUploadedFile> = {
  alreadyExists: false,
  progress: 0,
  verifyMD5: false,
  pollForProcessing: false,
  state: UploadState.added,
  removeOnComplete: false,
};

const PROCESSING_TIMEOUT = 120;

class BlobStorageServiceClass {
  public uploadComplete = new SimpleEmitter<IUploadedFile>();
  public progress = new SimpleEmitter<boolean>();
  files: IUploadedFile[] = [];

  constructor() {
    this.startPolling();
  }

  startPolling() {
    setInterval(() => {
      const waitingFiles = this.files.filter((x) => (x.state === UploadState.processing || x.state === UploadState.preview) && x.pollForProcessing);
      if (waitingFiles.length === 0) return;

      const keys = _.uniq(waitingFiles.map((x) => x.uploadSessionToken)).join(',');

      waitingFiles.forEach((uploadedFile) => {
        const procTime = differenceInSeconds(new Date(), uploadedFile.processingStarted ?? new Date());
        if (procTime > PROCESSING_TIMEOUT) {
          this.setState(uploadedFile, { state: UploadState.failed, failReason: `Processing timed out after ${PROCESSING_TIMEOUT}s` });
        }
      });

      store.dispatch(anthologyApi.endpoints.getApiUploadCheckUploadSession.initiate(keys, { forceRefetch: true })).then(({ data }) => {
        const foundAssets = data!.assets!;
        const foundBlobs = data!.blobUploads!;

        waitingFiles.forEach((uploadedFile) => {
          const asset = foundAssets.find((x) => x.uploadFileName === uploadedFile.file.name);
          const blob = foundBlobs.find((x) => x.originalFileName === uploadedFile.file.name);

          if (!blob) return;

          // if there is an error OR it finished with success = false OR it finished after stating it will make preview but did not
          if (blob.error || (!!blob.finishedOn && (!blob.success || (blob.willCreatePreview && !blob.previewCreated)))) {
            const err = (blob.error ?? '')
              .split('\n')
              .map((x) => x.trim())
              .filter((x) => x.length > 0)
              .at(-1);
            this.setState(uploadedFile, { state: UploadState.failed, failReason: `Upload failed: ${err}` });
            return;
          }

          if (asset) {
            this.setState(uploadedFile, {
              assetInfo: asset,
              processingStarted: parseISO(asset.uploadLastUpdateTime!),
              progress: asset.progress!,
              state: !!asset.finishedOn ? UploadState.complete : UploadState.preview,
            });

            if (asset.uploadSuccess) {
              this.uploadComplete.emit(uploadedFile);
            }
          } else {
            this.setState(uploadedFile, {
              processingStarted: parseISO(blob.updatedOn!),
              progress: blob.progress!,
            });
          }
        });
      });
    }, 2000);
  }

  uploadToBlobStorage(fileObject: File, token: Guid, options: Partial<IUploadedFile>) {
    const file: IUploadedFile = {
      ...defaultOptions,
      ...options,
      reactKey: Guid.create().toString(),
      file: fileObject,
      uploadSessionToken: token,
      abortControl: new AbortController(),
    } as any;

    if (!file.isValid) {
      file.isValid = (p: any) => Promise.resolve({ isValid: true } as IUploadFileValidationState);
    }

    Object.entries(MediaTypes).forEach(([k, ops]) => {
      if (ops.includes(file.file.name.split('.').at(-1)!.toLowerCase())) {
        file.mediaType = k as any;
      }
    });

    this.files.push(file);
    this.setState(file, { state: UploadState.added });

    //file.verifyMD5 = false;

    file
      .isValid(file)
      .then((o) => {
        if (o.isValid) {
          if (file.verifyMD5) {
            computeChecksumMd5FirstNBytes(file.file, 5000000).then((md5) => {
              file.localMd5 = md5;
              log.info('File quickMd5 is ' + md5);
              store.dispatch(anthologyApi.endpoints.getApiUploadCheckFileExists.initiate({ quickMd5: md5 })).then((resp) => {
                const info = resp.data!;
                if (info.length > 0) {
                  this.setState(file, { state: UploadState.complete, assetInfo: info[0], alreadyExists: true, progress: 100 });
                  this.uploadComplete.emit(file);
                } else {
                  this.uploadToGCP(file);
                }
              });
            });
          } else {
            this.uploadToGCP(file);
          }
        } else {
          this.setState(file, { state: UploadState.failed, failReason: o.message });
        }
      })
      .catch((err) => this.setState(file, { state: UploadState.failed, failReason: `Upload failed: ${err}` }));
  }

  private uploadToGCP(file: IUploadedFile) {
    this.setState(file, { state: UploadState.uploading });

    try {
      const gcs = new GoogleBlobUploader(
        file.destinationId,
        file.file,
        { session_id: file.uploadSessionToken.toString() },
        file.abortControl.signal,

        (e) => {
          file.progress = Math.round((e.loadedBytes / file.file.size) * 1000) / 10;
          this.progress.emit(true);
        },
        (p) => {
          this.setState(file, { remotePath: p.remotePath!, remoteBucket: p.bucket! });
        }
      );
      gcs
        .startUpload()
        .then(
          (response) => {
            if (response.status === 200) {
              if (file.pollForProcessing) {
                this.setState(file, { state: UploadState.processing });
              } else {
                this.setState(file, { state: UploadState.complete });
                this.uploadComplete.emit(file);
              }
            } else {
              this.setState(file, { state: UploadState.failed, failReason: `Upload failed: ${response.status}: ${response.text}` });
            }
          },
          (err) => {
            this.setState(file, { state: UploadState.failed, failReason: `Upload failed: ${err}` });
            log.warn('Error uploading to gcp', err);
          }
        )
        .finally(() => {
          log.info('Finished upload to gcp');
        });
    } catch (error) {
      this.setState(file, { state: UploadState.failed, failReason: `Upload failed: ${error}` });
    }
  }

  private setState(file: IUploadedFile, state: Partial<IUploadedFile> = {}) {
    _.merge(file, state);

    if (state.state === UploadState.complete) {
      if (file.removeOnComplete) {
        this.deleteFile(file);
      }
    }
    if (state.state === UploadState.processing) {
      file.processingStarted = new Date();
    }
    this.progress.emit(true);
  }

  deleteFile(file: number | IUploadedFile) {
    let index;
    if (isNumber(file)) {
      index = file;
    } else {
      index = this.files.findIndex((x) => x.file.name === file.file.name);
    }

    if (this.files[index] == null) {
      return;
    }

    this.files[index].abortControl.abort();
    this.files.splice(index, 1);
    this.progress.emit(true);
  }

  public getProgress(session: string) {
    const sessfiles = this.files.filter((x) => x.sessionName === session);
    let p = sessfiles.length > 0 ? sessfiles.map((elem) => elem.progress).reduce((a, b) => a + b, 0) / sessfiles.length : 0;
    p = Math.round(p * 10) / 10;
    return p;
  }
}

type GoogleBlobPart = {
  id: number;
  started: boolean;
  etag?: string;
  bytesSent: number;
  url?: string;
  abort?: () => void;
};

type BlobUploadMeta = {
  filename?: string;
  content_type?: string;
  session_id?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GoogleBlobUploader {
  private destination_id: string;
  private filename: string;
  private chunkSize!: number;
  private file!: File;
  private parts: GoogleBlobPart[];
  private progressCallback: ((e: any) => void) | undefined;
  private gotPermissionCallback: ((e: BlobUploadPermsVm) => void) | undefined;
  private aborted = false;
  private meta: BlobUploadMeta = {};

  private throttleProgressCallback = _.throttle(this.onProgress, 300);

  //For this to work CORS must allow x-goog-hash, etag on PUT,POST

  constructor(
    desitnation_id: string,
    file: File,
    meta: BlobUploadMeta = {},
    abortSignal?: AbortSignal,
    onProgess?: (e: any) => void,
    onPermissionFetch?: (e: BlobUploadPermsVm) => void
  ) {
    this.destination_id = desitnation_id;
    this.file = file;

    this.filename = meta.filename ?? file.name;
    this.parts = [];
    this.progressCallback = onProgess;
    this.gotPermissionCallback = onPermissionFetch;
    this.meta = meta;
    this.aborted = false;

    if (!!abortSignal) {
      abortSignal.onabort = (ev) => {
        this.abort();
      };
    }
  }

  public async startUpload(): Promise<any> {
    const perms = await this.getPermission();
    if (this.gotPermissionCallback) {
      this.gotPermissionCallback(perms);
    }

    this.initParts(perms.putUrls as any);

    try {
      await this.uploadAllParts();
      return this.finaliseBlob(perms.finalPostUrl!);
    } finally {
      this.abort();
    }
  }

  public abort() {
    this.aborted = true;
    this.parts.forEach((p) => {
      try {
        p.abort?.();
      } catch {}
    });
  }

  private async uploadAllParts(maxsimultaneousJobs = 4) {
    return new Promise((resolve, reject) => {
      const onFinish = () => {
        const next = this.parts.find((x) => !x.started);
        if (next) {
          retryOperation(() => this.putChunk(next), 10, 1000, 1.2).then(
            (p) => onFinish(),
            (e) => reject(e)
          );
        }
        const remaining = this.parts.find((x) => !x.etag);
        if (!remaining) {
          this.onProgress();
          resolve(true);
        }
      };

      for (let i = 0; i < maxsimultaneousJobs; i++) onFinish();
    });
  }

  private async putChunk(part: GoogleBlobPart): Promise<GoogleBlobPart> {
    if (this.aborted) {
      return Promise.reject({ status: -1, statusText: 'Aborted before start' });
    }

    part.started = true;
    const range = [(part.id - 1) * this.chunkSize, Math.min(part.id * this.chunkSize, this.file.size)];
    const slice = this.file.slice(range[0], range[1]);

    const localChunkMd5Check = computeChecksumMd5(slice, false).then((md5) => 'md5=' + window.btoa(md5));

    return new Promise((resolve, _reject) => {
      const reject = (code: number, msg: string) => {
        diagnosticService.info(`Chunk ${part.id}/${this.parts.length} failed for ${this.file.name}: ${code}: ${msg}`);
        _reject({ status: code, statusText: msg });
      };
      const xhr = new XMLHttpRequest();
      part.abort = () => xhr.abort();

      xhr.open('PUT', part.url!);

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          reject(xhr.status, xhr.statusText);
          return;
        }

        const serverHash = xhr
          .getResponseHeader('x-goog-hash')
          ?.split(',')
          .map((x) => x.trim())
          .find((x) => x.startsWith('md5'));

        localChunkMd5Check.then((localHash) => {
          if (!serverHash || localHash === serverHash) {
            part.etag = xhr.getResponseHeader('etag')!;
            part.bytesSent = slice.size;
            diagnosticService.info(`Chunk ${part.id}/${this.parts.length} done for ${this.file.name}, size: ${slice.size}, googhash: ${serverHash}`);
            resolve(part);
          } else {
            reject(-2, `MD5 mismatch: ${localHash} -> ${serverHash} `);
          }
        });
      };

      xhr.onerror = () => reject(xhr.status, xhr.statusText);
      xhr.ontimeout = () => reject(-3, 'Timeout');
      xhr.onabort = () => reject(-1, 'Aborted in transit');

      xhr.upload.onprogress = (e) => {
        part.bytesSent = e.loaded;
        this.throttleProgressCallback();
      };

      xhr.send(slice);
    });
  }

  private onProgress() {
    this.progressCallback?.({ loadedBytes: _.sum(this.parts.map((x) => x.bytesSent)) });
  }

  private initParts(chunkUrls: { [key: string]: string }) {
    this.chunkSize = Math.ceil(this.file.size / Object.entries(chunkUrls).length);
    this.parts = _.sortBy(
      Object.entries(chunkUrls).map(([n, url]) => ({ id: parseInt(n), bytesSent: 0, started: false, url: url })),
      'id'
    );
  }

  private async finaliseBlob(finalPostUrl: string) {
    const partsXml = _.orderBy(this.parts, (x) => x.id).map((x) => `<Part><PartNumber>${x.id}</PartNumber><ETag>${x.etag}</ETag></Part>`);
    const payload = `<CompleteMultipartUpload>${partsXml.join('')}</CompleteMultipartUpload>`;
    const finalResp = await fetch(finalPostUrl, {
      method: 'POST',
      body: payload,
    });

    return { status: finalResp.status, text: finalResp.text };
  }

  private async getPermission(): Promise<BlobUploadPermsVm> {
    const perm = store
      .dispatch(
        anthologyApi.endpoints.postApiUploadGetUploadPermission.initiate({
          destinationCode: this.destination_id,
          filename: this.filename,
          size: this.file.size,
          uploadSessionId: this.meta.session_id,
          contentType: this.meta.content_type ?? this.file.type,
        })
      )
      .unwrap();

    return perm;
  }
}

const blobService = new BlobStorageServiceClass();
export default blobService;

/**
 * Interact with the blob upload service, the hook causes a rerender on file upload progress.
 *
 * @param destinationBucket Choose from UploadBuckets for destination
 * @param sessionName A freindly name for the group of files you are working with e.g. "CreateUploads" this is to allow multiple components to display their own set of files
 * @param options Any default values for IUploadedFile, most useful: verifyMD5, pollForProcessing
 *
 *
 * @returns files: a list of files in this session giving access to full details
 * @returns totalProgress: The average progress of all files in your session
 * @returns uploadFile a funtion to accept new uploads
 * @returns onComplete an event emitter that emits a file on upload completion
 */
export const useBlobStorageService = (destinationBucket: UploadDestinations, sessionName: string, options: Partial<IUploadedFile> = {}) => {
  const [sessionToken] = useState(() => Guid.create());
  const [onComplete] = useState(() => new SimpleEmitter<IUploadedFile>());
  const forceUpdate = useForceUpdate();

  useEffectOnce(() => {
    const ps = blobService.progress.subscribe(() => forceUpdate());
    const cs = blobService.uploadComplete.subscribe((x) => {
      if (x.sessionName === sessionName) {
        onComplete.emit(x);
        forceUpdate();
      }
    });
    return () => {
      ps();
      cs();
    };
  });

  return {
    files: blobService.files.filter((x) => x.sessionName === sessionName),
    totalProgress: blobService.getProgress(sessionName),
    uploadFile: (file: File) => blobService.uploadToBlobStorage(file, sessionToken, { ...options, destinationId: destinationBucket, sessionName }),
    deleteFile: (file: IUploadedFile | number) => blobService.deleteFile(file),
    onComplete,
  };
};

async function computeChecksumMd5FirstNBytes(file: Blob, firstNBytes: number = 5000000, hexdigest: boolean = true): Promise<string> {
  const spark = new SparkMD5.ArrayBuffer();
  spark.append(await file.slice(0, Math.min(firstNBytes, file.size)).arrayBuffer());
  return spark.end(!hexdigest);
}

async function computeChecksumMd5(file: Blob, hexdigest: boolean = true): Promise<string> {
  const chunkSize = 4194304; // Read in chunks of 4MB
  const spark = new SparkMD5.ArrayBuffer();

  const chunks = Math.ceil(file.size / chunkSize);

  for (let c = 0; c < chunks; c++) {
    const range = [c * chunkSize, Math.min((c + 1) * chunkSize, file.size)];
    spark.append(await file.slice(range[0], range[1]).arrayBuffer());
  }

  return spark.end(!hexdigest);
}
