import { Badge, Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import { CircleIconButton } from '@anthology/shared/src/components';
import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { capitaliseInitial } from '@anthology/shared/src/utils/string';
import { useEventListener } from '@react-hookz/web';
import AccountAvatar from '@src/components/shared/account-avatar/AccountAvatar';
import environment from '@src/environment';
import { avatarRoutes } from '@src/routeMeta';
import { useAuth } from '@src/services/authService';
import { UiElementSizes, useUiSlice } from '@src/services/layoutService';
import { useNotificationService } from '@src/services/notificationService';
import { BsBell, BsBellFill, BsBoxArrowUpRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import MainSearch, { SearchGroupId } from '../MainSearch';
import ThemeSwitcherComponent from '../theme-switcher/ThemeSwitcher';
import style from './Header.module.scss';
import NotificationItem from './NotificationItem';
import UserSummary from './UserSummary';

const notificationButtonSx = {
  borderColor: 'rgba(var(--mui-palette-text-primaryChannel) / 0.25)!important',
  ':hover': {
    borderColor: 'var(--mui-palette-text-secondary)!important',
  },
  backgroundColor: 'var(--mui-palette-background-main)',
  p: 2,
  fontSize: 18,
  background: 'rgba(var(--mui-palette-background-defaultChannel) / 0.3)',
  backdropFilter: 'blur(15px)',
};
const avatarSize = 52;
const avatarProps = {
  width: avatarSize,
  height: avatarSize,
  border: 'solid 1px var(--mui-palette-background-default)!important',
  cursor: 'pointer',
  ':hover': { opacity: '0.75' },
};

const Header = () => {
  const isMobileLayout = useCssBreakpoint('sm');
  const auth = useAuth();
  const ui = useUiSlice();
  const nav = useNavigate();

  // Notifications
  const note = useNotificationService();
  const [noteAnchorEl, setNoteAnchorEl] = useState<null | HTMLElement>(null);
  const noteOpen = Boolean(noteAnchorEl);
  const handleNoteClick = (event: React.MouseEvent<HTMLElement>) => {
    setNoteAnchorEl(event.currentTarget);
    note.hideFlashes();
  };
  const handleNoteClose = () => {
    setNoteAnchorEl(null);
  };
  const viewAllNotifications = () => {
    nav('/notifications/');
    handleNoteClose();
  };

  // Avatar

  const [avatarAnchorEl, setAvatarAnchorEl] = useState<null | HTMLElement>(null);
  const avatarOpen = Boolean(avatarAnchorEl);
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarAnchorEl(event.currentTarget);
  };
  const handleAvatarClose = () => {
    setAvatarAnchorEl(null);
  };

  const openNavItem = (path: string) => {
    nav(path);
    handleAvatarClose();
  };

  const logout = () => {
    setAvatarAnchorEl(null);
    if (auth.auth.isSignedIn) {
      auth.actions.logout();
    }
    nav('/login');
  };

  // Portal

  const containerLeft = useRef(null);
  const containerCentre = useRef(null);
  if (!isMobileLayout) {
    ui.actions.headerPortalLeft = containerLeft;
    ui.actions.headerPortalCentre = containerCentre;
  }

  // Scroll
  const [showBackdrop, setShowBackdrop] = useState(false);
  useEventListener(ui.actions.dashboardElement?.current, 'scroll', (e: any) => {
    const position = e.target.scrollTop;
    setShowBackdrop(position > 100);
  });

  const portalsDisplay = ui.isSearchOpen ? 'none' : 'flex';

  return !isMobileLayout ? (
    <>
      <Box
        className={style.header}
        minHeight={ui.topBarHeight}
        width={`calc(100vw - ${ui.sideBarWidth}px)`}
        paddingY={ui.uiConstants.topBarPaddingY}
        paddingX={ui.uiConstants.topBarPaddingX}
        display={ui.isTopBarOpen && !environment.runtime.isElectron ? 'flex' : 'none'}
      >
        {/* the portals */}
        <Box ref={containerLeft} className={style.portal} display={portalsDisplay} />
        <Box ref={containerCentre} className={style.portal} display={portalsDisplay} />
        {/* spacer */}
        <Box minWidth={40} sx={{ pointerEvents: 'none' }}></Box>
        <Stack height={ui.topBarHeight} justifyContent={'center'}>
          <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} sx={{ pointerEvents: 'auto' }}>
            <MainSearch allowedTypes={[SearchGroupId.Artists, SearchGroupId.Labels, SearchGroupId.Releases, SearchGroupId.Tracks]} />
            <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} gap={2} width={UiElementSizes.topBarNonSearchContentWidth}>
              <CircleIconButton showBorder sx={notificationButtonSx} onClick={handleNoteClick}>
                <Badge
                  badgeContent={note.unreadCount}
                  color="error"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  max={50}
                  sx={{ '.MuiBadge-badge': { top: 7, left: 7 } }}
                >
                  {note.unreadCount ? <BsBellFill /> : <BsBell />}
                </Badge>
              </CircleIconButton>
              <Menu
                id="notification-items"
                aria-labelledby="notification-items"
                anchorEl={noteAnchorEl}
                open={noteOpen}
                onClose={handleNoteClose}
                sx={{ mt: 2 }}
                disableScrollLock
                disableAutoFocus
                disableEnforceFocus
              >
                <Stack width={500} px={2}>
                  <Stack pt={1} pb={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h4">Notifications</Typography>
                    <CircleIconButton onClick={viewAllNotifications}>
                      <BsBoxArrowUpRight size={17} />
                    </CircleIconButton>
                  </Stack>
                  {note.notifications.length === 0 && (
                    <Typography variant="labelLarge" color={'text.secondary'} whiteSpace={'nowrap'} p={1}>
                      Nothing to see here!
                    </Typography>
                  )}
                  {note.notifications.map((notification, index) => {
                    return (
                      <Stack key={notification.id}>
                        <MenuItem>
                          <NotificationItem notification={notification} />
                        </MenuItem>
                        {index < note.notifications.length - 1 && <Divider />}
                      </Stack>
                    );
                  })}
                </Stack>
              </Menu>
              <Stack ref={avatarAnchorEl as any} onClick={handleAvatarClick}>
                <AccountAvatar sx={avatarProps} colourless />
              </Stack>
              <Menu id="account-items" aria-labelledby="account-items" anchorEl={avatarAnchorEl} open={avatarOpen} onClose={handleAvatarClose} sx={{ mt: 2 }}>
                <UserSummary />
                <Divider sx={{ mb: 1 }} />
                {avatarRoutes.map((route) => (
                  <MenuItem key={route.path} onClick={() => openNavItem(route.path)}>
                    {route.icon && (
                      <ListItemIcon>
                        <route.icon />
                      </ListItemIcon>
                    )}
                    <ListItemText>{capitaliseInitial(route.label)}</ListItemText>
                  </MenuItem>
                ))}
                <Divider />
                <Stack>
                  <ThemeSwitcherComponent />
                </Stack>
                <Divider sx={{ my: 1 }} />
                <MenuItem onClick={logout}>Log out</MenuItem>
              </Menu>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {showBackdrop && <Box className={style.headerBackdrop} height={ui.topBarHeight} />}
    </>
  ) : (
    <></>
  );
};

export default Header;
