import { Alert } from '@mui/material';
import { useEventListener } from '@react-hookz/web';
import React, { useEffect, useState } from 'react';

// https://dev.to/sagartrimukhe/no-internet-connection-wrapper-for-react-apps-5dl8

const ConnectionMessage = () => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
    // event listeners to update the state
  }, []);

  useEventListener(window, 'online', () => {
    setOnline(true);
  });

  useEventListener(window, 'offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return <></>;
  } else {
    return (
      <Alert severity="warning" variant="filled" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
        You are not connected to the internet
      </Alert>
    );
  }
};

export default ConnectionMessage;
