import { PackshotSize, getPackshotUrl } from '@anthology/shared/src/utils/fileLocations';
import { Box, BoxProps, Stack } from '@mui/material';
import { useConfigItem } from '@src/services/configService';
import { useMemo } from 'react';
import environment from '../../../environment';
import { getFormatTypeUi } from '../catalogue-item/catalogueItemHelpers';

export type PackshotImageProps = {
  guid: string | undefined | null;
  size: PackshotSize;
  errorImage?: string;
  alt?: string;
  formatTypeId?: number;
} & BoxProps;

export default function PackshotImage({ guid, size, errorImage, alt, formatTypeId, ...props }: PackshotImageProps) {
  const config = useConfigItem('formattype');
  const { formattype } = config;
  const formatType = formattype?.find((type) => type.id === formatTypeId);
  const { placeholderIcon } = getFormatTypeUi(formatType);
  const showIcon = !guid && !!placeholderIcon;
  const imgProps = useMemo(() => {
    return {
      src: !showIcon ? getPackshotUrl(guid || '', size, errorImage) : '',
      alt: alt ?? guid,
      onError: !showIcon
        ? ({ currentTarget }: any) => {
            currentTarget.onerror = null;
            currentTarget.src = errorImage ?? environment.defaultErrorImage;
          }
        : undefined,
      loading: 'lazy' as const,
    };
  }, [showIcon, guid, size, errorImage, alt]);

  return showIcon ? (
    <Stack
      justifyContent="center"
      alignItems="center"
      width={props.width}
      height={props.height}
      padding={6}
      sx={{ bgcolor: 'var(--mui-palette-background-dialog)' }}
    >
      {placeholderIcon({ size: '100%' })}
    </Stack>
  ) : (
    <Box component={'img'} {...imgProps} {...props} />
  );
}
