import { ButtonWrapper } from '@anthology/shared/src/components';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, LinearProgress, Stack, StackProps, Typography } from '@mui/material';
import { IUploadFileValidationState, IUploadedFile, UploadDestinations, useBlobStorageService } from '@services/blobService';
import { SelectListVm } from '@src/api/anthologyApi';
import { useEffectOnce } from '@src/hooks/useEffectOnce';
import { Guid } from 'guid-typescript';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { BsImageFill } from 'react-icons/bs';
import style from './ImageUploader.module.scss';
import environment from '@src/environment';

type ImageUploaderProps = {
  uploaderOptions: Partial<IUploadedFile>; // Options
  view?: 'icon' | 'button';
  valueAsId?: boolean;
  imageOptions?: SelectListVm[];
  value?: any;
  title?: string;
  helpText?: string;
  accept?: string;
  onChange: (value: any) => void;
  onUploadComplete?: (value: IUploadedFile) => void;
  disableUpload?: boolean;
  iconSize?: number;
  validate?: (param: any) => Promise<IUploadFileValidationState>;
  buttonText?: string;
  maxSizeMb?: number;
} & StackProps;

const ImageUploader = React.forwardRef(
  (
    {
      uploaderOptions,
      view = 'icon',
      valueAsId = false,
      imageOptions = [],
      value,
      title,
      helpText,
      accept,
      onChange,
      onUploadComplete,
      disableUpload,
      iconSize = 50,
      validate,
      buttonText = 'Upload Image',
      maxSizeMb,
      ...rest
    }: ImageUploaderProps,
    ref
  ) => {
    const [file, setFile] = useState<string | undefined | null>(undefined);
    const [err, setErr] = useState<string | null>(null);
    const [dragActive, setDragActive] = useState(false);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const sessid = useMemo(() => Guid.create().toString(), []);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { onComplete, uploadFile, deleteFile } = useBlobStorageService(
      uploaderOptions.destinationId ?? UploadDestinations.slinky,
      uploaderOptions.sessionName ?? sessid,
      uploaderOptions
    );

    useImperativeHandle(ref, () => ({
      inputRef: inputFileRef,
      removeFile: () => {
        onChange?.('');
        if (inputFileRef.current && inputFileRef.current.value) {
          inputFileRef.current.value = '';
        }
      },
    }));

    useEffect(() => {
      if (uploaderOptions.sessionName === 'create-image' && imageOptions.length === 0) {
        setFile(undefined);
      } else if (valueAsId && value && imageOptions.length > 0) {
        const image = imageOptions.find((o) => o.id === value);
        if (image) {
          setFile(image.imageUrl ?? '');
        }
      } else if (value) {
        setFile(value);
      } else {
        setFile(undefined);
      }
    }, [imageOptions, uploaderOptions.sessionName, value, valueAsId]);

    const handleDrag = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    //show progress incase file is large
    const handleDrop = (e: any) => {
      setFile(undefined);
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      const file = e.target?.files || e.dataTransfer?.files;
      if (!file) return;
      const maxSize = maxSizeMb && maxSizeMb * 1024 * 1024;
      if (maxSize && file[0].size > maxSize) {
        return setErr(`File size must be less than ${maxSizeMb} MB`);
      }
      if (validate == null) {
        validate = (p: any) => Promise.resolve({ isValid: true } as IUploadFileValidationState);
      }
      validate(file[0])
        .then((o) => {
          //const url = window.URL.createObjectURL(file[0]);
          if (o.isValid) {
            setErr('');
            uploadFile(file[0]);
            setShowLoader(true);
          } else {
            setErr(o.message);
            setFile((o) => undefined);
            onChange?.(null);
          }
        })
        .catch()
        .finally(() => {});
    };

    useEffectOnce(() => {
      return onComplete.subscribe((x: any) => {
        setFile(`${environment.CDNRoot}/${x.remotePath}`);
        deleteFile(x);

        onChange?.(valueAsId ? x.assetInfo?.assetInformationId : `${environment.CDNRoot}/${x.remotePath}`);
        onUploadComplete?.(x);
        setShowLoader(false);
      });
    });

    const showFileDialog = () => {
      if (showLoader) {
        alert('file is already being processed');
      } else {
        inputFileRef.current && inputFileRef.current.click();
      }
    };

    const resetOnClick = (event: any) => {
      event.target.value = null;
    };

    return (
      <Stack {...rest}>
        <input
          style={{ display: 'none' }}
          accept={accept}
          ref={inputFileRef}
          type="file"
          multiple={false}
          onChange={handleDrop}
          onClick={resetOnClick}
          disabled={disableUpload ?? false}
        />
        <Box
          className={`${style.container} ${dragActive && style.dragActive}`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          onClick={() => showFileDialog()}
          sx={{ backgroundImage: `url(${file})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}
        >
          {file === undefined && (
            <>
              {view === 'icon' && <BsImageFill fontSize={iconSize} />}
              {view === 'button' && (
                <Stack alignItems={'center'} justifyContent={'center'} gap={2} padding={2}>
                  <UploadIcon fontSize="large" />
                  <ButtonWrapper variant="contained" size="small">
                    {buttonText}
                  </ButtonWrapper>
                </Stack>
              )}
            </>
          )}
        </Box>
        {err && (
          <Box>
            <Typography color={'error'} variant={'caption'}>
              {err}
            </Typography>
          </Box>
        )}
        {showLoader && <LinearProgress />}
      </Stack>
    );
  }
);

export default ImageUploader;
