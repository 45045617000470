import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { DomainsByTenant } from '@src/api/anthologyApi';
import { useSelector } from 'react-redux';
import { RootState } from './store';

type ThemeState = {
  theme: { customDomain: boolean; isDark: boolean; version: string };
  domainConfig: DomainsByTenant;
};

const init: ThemeState = {
  theme: { customDomain: false, version: '', isDark: true },
  domainConfig: {},
};

const slice = createSlice({
  name: 'theme',
  initialState: init,
  reducers: {
    themeSetTheme: (state, { payload }: PayloadAction<{ customDomain?: boolean; isDark?: boolean; version?: string }>) => {
      if (!_.isUndefined(payload.customDomain)) {
        state.theme.customDomain = payload.customDomain;
      }
      if (!_.isUndefined(payload.isDark)) {
        state.theme.isDark = payload.isDark;
      }
      if (!_.isUndefined(payload.version)) {
        state.theme.version = payload.version;
      }
    },
    updateDomainSettings: (state, { payload }: PayloadAction<DomainsByTenant>) => {
      state.domainConfig = payload;
    },
  },
});

export const { themeSetTheme, updateDomainSettings } = slice.actions;
export const useThemeSlice = () => useSelector((state: RootState) => state.theme);

export default slice.reducer;
