import { ImageWrapper } from '@anthology/shared/src/components';
import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { getArtistImgUrl } from '@anthology/shared/src/utils/fileLocations';
import { generateInitials } from '@anthology/shared/src/utils/string';
import {
  useGetApiSearchSearchContributorsQuery,
  useGetApiSearchSearchFormatsQuery,
  useGetApiSearchSearchLabelsQuery,
  useGetApiSearchSearchTracksQuery,
} from '@api/anthologyApi';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Avatar, Box, ClickAwayListener, DialogContentText, Paper, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useGetApiSlinkyCatalogueSearchQuery } from '@src/api/slinkyApi';
import environment from '@src/environment';
import { setIsSearchOpen } from '@src/features/uiSlice';
import { UiElementSizes, useUiSlice } from '@src/services/layoutService';
import _ from 'lodash';
import React, { KeyboardEventHandler, ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsBuilding, BsBuildingFill, BsDisc, BsFileEarmarkMusic, BsPeople, BsPersonFill } from 'react-icons/bs';
import { MdAlbum, MdAudiotrack, MdLibraryMusic, MdMicNone } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Key } from 'ts-keycode-enum';
import { DialogWrapper } from '../shared/dialog/DialogWrapper';
import AutoCompleteInput from '../shared/form-components/AutoCompleteInput';
import PackshotImage from '../shared/packshot-image/PackshotImage';
import SearchInput from '../shared/search-input/SearchInput';
import style from './MainSearch.module.scss';

type Result = {
  id: any;
  group: string;
  imageGuid?: string;
  imageUrl?: string;
  hideImage?: boolean;
  text: string;
  subtitle?: string;
  exactMatches?: string[];
  info1?: string;
  info2?: string;
  link?: () => void;
  isLoading?: boolean;
};

export enum SearchGroupId {
  Releases = 'Releases',
  Tracks = 'Tracks',
  Artists = 'Artists',
  Labels = 'Labels',
  Slinkys = 'Slinkys',
}

const GroupInfo = {
  Releases: {
    id: SearchGroupId.Releases,
    groupIcon: <MdLibraryMusic />,
    order: 1,
    hideImage: false,
  },
  Tracks: {
    id: SearchGroupId.Tracks,
    groupIcon: <MdAudiotrack />,
    order: 2,
    hideImage: false,
  },
  Artists: {
    id: SearchGroupId.Artists,
    groupIcon: <MdMicNone />,
    order: 3,
    hideImage: false,
  },
  Labels: {
    id: SearchGroupId.Labels,
    groupIcon: <MdAlbum />,
    order: 4,
    hideImage: true,
  },
  Slinkys: {
    id: SearchGroupId.Slinkys,
    order: 6,
    hideImage: false,
  },
};

const links = [
  {
    id: SearchGroupId.Artists,
    title: 'Artists',
    icon: BsPeople,
    link: '/profiles/contributors/',
  },
  {
    id: SearchGroupId.Labels,
    title: 'Labels',
    icon: BsBuilding,
    link: '/profiles/labels/',
  },
  {
    id: SearchGroupId.Releases,
    title: 'Formats',
    icon: BsDisc,
    link: '/catalogue/',
  },
  {
    id: SearchGroupId.Releases,
    title: 'Tracks',
    icon: BsFileEarmarkMusic,
    link: '/catalogue/',
  },
];

let inputRef: any;

const getInnerInput = (): HTMLInputElement => inputRef;

export type MainSearchOnSelect = (type: SearchGroupId | null, id: number, name: string, data?: any) => void;

export default function MainSearch({
  onSelect,
  allowedTypes,
  placeholder,
  autoCompleteLabel,
  isTabs,
}: {
  onSelect?: MainSearchOnSelect;
  allowedTypes?: SearchGroupId[];
  placeholder?: string;
  autoCompleteLabel?: string;
  isTabs?: boolean;
}) {
  const dispatch = useDispatch();
  const [idEntered, setIdEntered] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState('');
  const nav = useNavigate();
  const [previousResults, setPreviousResults] = useState<any[]>();
  const limit = onSelect ? 4 : 15;

  const {
    data: format,
    isFetching: isLoadingFormats,
    refetch: refetchFormat,
  } = useGetApiSearchSearchFormatsQuery(
    { term: query, limit: limit },
    { skip: query === '' || (allowedTypes && !allowedTypes.includes(SearchGroupId.Releases)) }
  );
  const {
    data: track,
    isFetching: isLoadingTracks,
    refetch: refetchTracks,
  } = useGetApiSearchSearchTracksQuery({ term: query, limit: limit }, { skip: query === '' || (allowedTypes && !allowedTypes.includes(SearchGroupId.Tracks)) });
  const {
    data: artist,
    isFetching: isLoadingArtists,
    refetch: refetchArtists,
  } = useGetApiSearchSearchContributorsQuery(
    { term: query, limit: limit },
    { skip: query === '' || (allowedTypes && !allowedTypes.includes(SearchGroupId.Artists)) }
  );
  const {
    data: label,
    isFetching: isLoadingLabels,
    refetch: refetchLabels,
  } = useGetApiSearchSearchLabelsQuery({ term: query, count: limit }, { skip: query === '' || (allowedTypes && !allowedTypes.includes(SearchGroupId.Labels)) });
  const {
    data: slinky,
    isFetching: isLoadingSlinkys,
    refetch: refetchSlinkys,
  } = useGetApiSlinkyCatalogueSearchQuery(
    { term: query, limit: limit },
    { skip: query === '' || (allowedTypes && !allowedTypes.includes(SearchGroupId.Slinkys)) }
  );

  const isLoading = useMemo(
    () => isLoadingFormats || isLoadingTracks || isLoadingArtists || isLoadingLabels || isLoadingSlinkys,
    [isLoadingArtists, isLoadingFormats, isLoadingLabels, isLoadingTracks, isLoadingSlinkys]
  );

  const isMobileLayout = useCssBreakpoint('sm');
  const isElectron = environment.runtime.isElectron;

  const closeSearch = useCallback(() => {
    dispatch(setIsSearchOpen(false));
    const innerInput = getInnerInput();
    innerInput?.blur();
    setQuery('');
  }, [dispatch]);

  const selectItem: MainSearchOnSelect = useCallback(
    (type, id, label, data) => {
      onSelect?.(type, id, label, data);
      closeSearch();
    },
    [closeSearch, onSelect]
  );

  const getFullAssetTitle = (x: any) => x.assetTitle + (x.assetTitleVersion ? ` (${x.assetTitleVersion})` : '');

  const results = useMemo(() => {
    return _.uniqBy(
      [
        ...(artist ?? []).map((x: any) => ({
          id: 'artist' + x.contributorId,
          group: GroupInfo.Artists.id,
          groupOrder: GroupInfo.Artists.order,
          hideImage: GroupInfo.Artists.hideImage,
          imageGuid: x.guid,
          text: x.contributorDefaultName,
          subtitle: x.contributorLabelNames,
          link: () =>
            onSelect
              ? selectItem(SearchGroupId.Artists, x.contributorId, x.contributorDefaultName, x)
              : nav('/profiles/contributors/contributor-detail/' + x.contributorId),
        })),
        ...(label ?? []).map((x: any) => ({
          id: 'label' + x.labelId,
          group: GroupInfo.Labels.id,
          groupOrder: GroupInfo.Labels.order,
          hideImage: GroupInfo.Labels.hideImage,
          text: x.labelName,
          subtitle: x.parentLabelName,
          link: () => (onSelect ? selectItem(SearchGroupId.Labels, x.labelId, x.labelName, x) : nav('/profiles/labels/label-detail/' + x.labelId)),
        })),
        ...(format ?? []).map((x: any) => ({
          id: 'format' + x.formatId,
          group: GroupInfo.Releases.id,
          groupOrder: GroupInfo.Releases.order,
          hideImage: GroupInfo.Releases.hideImage,
          imageGuid: x.packshotGUID,
          text: x.title,
          subtitle: x.localeContributorNames,
          exactMatches: [(x.upc ?? '').toLowerCase(), (x.catNo ?? '').toLowerCase()],
          info1: x.formatType,
          info2: `${x.upc} | ${x.catNo}`,
          link: () =>
            onSelect
              ? selectItem(SearchGroupId.Releases, x.formatId, `${x.title} - ${x.localeContributorNames}`, x)
              : nav('/catalogue/formats/format-detail/' + x.formatId),
        })),
        ...(track ?? []).map((x: any) => ({
          id: 'track' + x.assetId,
          group: GroupInfo.Tracks.id,
          groupOrder: GroupInfo.Tracks.order,
          hideImage: GroupInfo.Tracks.hideImage,
          imageGuid: x.packshotGuid,
          text: getFullAssetTitle(x),
          exactMatches: [(x.isrc ?? '').toLowerCase()],
          subtitle: x.artistNames,
          info1: `ISRC: ${x.isrc}`,
          // TODO: Add Album name or 'X Formats' if > 1, add catalogueNum of album after '|'
          // info2:
          link: () =>
            onSelect
              ? selectItem(SearchGroupId.Tracks, x.assetId, `${getFullAssetTitle(x)} – ${x.artistNames}`, x)
              : nav('catalogue/assets/asset-detail/' + x.assetId),
        })),
        ...(slinky ?? []).map((x: any) => ({
          id: 'slinky' + x.slinkyId,
          group: GroupInfo.Slinkys.id,
          groupOrder: GroupInfo.Slinkys.order,
          hideImage: GroupInfo.Slinkys.hideImage,
          imageUrl: x.coverArtImageUrl,
          text: x.titleText,
          exactMatches: [(x.slinkyId ?? '').toString()],
          subtitle: x.subTitleText,
          info1: x.isLive ? 'Live' : 'Draft',
          // TODO: Add Album name or 'X Formats' if > 1, add catalogueNum of album after '|'
          // info2:
          link: () => (onSelect ? selectItem(SearchGroupId.Slinkys, x.slinkyId, x.titleText, x) : nav('/catalogue/slinkys/slinky-details/' + x.slinkyId)),
        })),
      ],
      (x) => x.id
    ) as Result[];
  }, [artist, label, format, track, slinky, onSelect, selectItem, nav]);

  const skeletonResults = Object.values(GroupInfo)
    .filter((group) => !allowedTypes || allowedTypes.includes(group.id))
    .reduce((acc: any[], group: any) => {
      return [
        ...acc,
        ..._.range(5).map((index) => ({
          id: group.id + index,
          group: group.id,
          isLoading: true,
          hideImage: group.hideImage,
        })),
      ];
    }, []);

  useEffect(() => {
    //wait upto 5s for entered id to show up and then jump to that page, otherwise clear
    if (idEntered) {
      const match = results.filter((x) => (x.exactMatches ?? []).includes(idEntered));
      if (match.length > 0) {
        setIdEntered(undefined);
        match[0].link?.();
      }
      const to = setTimeout(() => setIdEntered(undefined), 5000);
      return () => clearTimeout(to);
    }
  }, [results, idEntered]);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.keyCode === Key.Escape) {
      closeSearch();
    }
  };

  useEffect(() => {
    isElectron && dispatch(setIsSearchOpen(query.length > 0));
  }, [dispatch, isElectron, query]);

  const onClickSearchInput = () => {
    dispatch(setIsSearchOpen(true));
    const innerInput = getInnerInput();
    innerInput?.focus();
  };

  const location = useLocation();

  useEffect(() => {
    closeSearch();
    if (results && results.length > 0) {
      setPreviousResults(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeSearch, location.pathname]);

  const resultGroups = _.groupBy(
    (isLoading ? skeletonResults : results).sort((a, b) => a.groupOrder - b.groupOrder),
    (x) => x?.group
  );

  const getData = () => {
    artist && refetchArtists();
    format && refetchFormat();
    label && refetchLabels();
    track && refetchTracks();
    slinky && refetchSlinkys();
  };

  // const searchInputDefaultSize = useMemo(() => (location.pathname === '/' ? 600 : 55), [location.pathname]);
  const searchInputDefaultSize = 55;

  const ui = useUiSlice();

  const inputContainerRef = useRef();

  const availableSpace = `calc(100vw - ${ui.sideBarMarginWidth + ui.dashboardPadding * 1.5 + UiElementSizes.topBarNonSearchContentWidth}px)`;
  const isBelowXl = useCssBreakpoint('xl');
  const isSmallDeviceMode = isTabs || isBelowXl;

  const resultUiGroups = Object.entries(resultGroups).map(([group, members]) => {
    const membersUi = members.map((item) => <RenderSearchResult item={item} isSmallDeviceMode={isSmallDeviceMode} />);
    return [group, membersUi];
  });

  const showResultsContainer = !onSelect && ui.isSearchOpen && (query.length > 0 || !!previousResults);

  // Autocomplete version

  const onChangeAutoComplete = () => onSelect && onSelect(null, 0, '');

  const Results = () => (
    <Stack gap={5}>
      {!query && !onSelect && <Typography variant="sub4">Previous search</Typography>}
      <Box sx={{ transition: 'opacity 0.1s ease-in', opacity: !!query ? 1 : 0.5, ':hover': { opacity: 1 } }}>
        <MultiColumnSearchContainer resultUiGroups={resultUiGroups} isSmallDeviceMode={isSmallDeviceMode} allowedTypes={allowedTypes} isSelect={onSelect} />
      </Box>
    </Stack>
  );

  return isMobileLayout && !onSelect ? (
    <Stack gap={2} height={onSelect ? 'auto' : '75vh'}>
      <SearchInput onChange={(e) => setQuery(e.currentTarget.value)} onInput={getData} autoFocus />
      {query && <Results />}
      {!query && (
        <Grid2 container flex={1} alignItems={'center'}>
          {links.map((link) => (
            <Grid2 xs={6} height={200} display={'flex'} key={link.id}>
              <Stack className="clickable" flex={1} alignItems={'center'} justifyContent={'center'} gap={2} onClick={() => nav(link.link)}>
                {link.icon({ size: 35 })}
                <Typography variant="sub2">{link.title}</Typography>
              </Stack>
            </Grid2>
          ))}
        </Grid2>
      )}
    </Stack>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          zIndex: 2,
          position: 'relative',
        }}
        ref={inputContainerRef}
      >
        {onSelect ? (
          <ClickAwayListener onClickAway={closeSearch}>
            <Stack width={'100%'}>
              <AutoCompleteInput
                value={query || placeholder}
                onChange={onChangeAutoComplete}
                onInputChange={(e, v) => setQuery(v)}
                PopperComponent={() => <></>}
                label={autoCompleteLabel || 'Search'}
                fullWidth
              />
              {query.length > 0 && query !== placeholder && <Results />}
            </Stack>
          </ClickAwayListener>
        ) : (
          <SearchInput
            size={isElectron ? 'small' : 'medium'}
            sx={{
              backdropFilter: 'blur(15px)',
              backgroundColor: 'rgba(var(--mui-palette-background-defaultChannel) / 0.3)',
              borderRadius: '30px',
              zIndex: 100,
            }}
            autoCollapseRange={isElectron ? undefined : [searchInputDefaultSize + 'px', availableSpace]}
            defaultWidth="100vw"
            onKeyDown={onKeyDown}
            placeholder="Search artists, labels, releases or tracks..."
            onInput={getData}
            onChange={(e) => setQuery(e.currentTarget.value)}
            onClick={onClickSearchInput}
            value={query}
            InputProps={{
              ref: (input) => (inputRef = input),
              sx: { pl: 0 },
              onFocus: () => !isElectron && dispatch(setIsSearchOpen(true)),
              onClick: onClickSearchInput,
            }}
            isOpen={ui.isSearchOpen}
            onClear={showResultsContainer ? closeSearch : undefined}
          />
        )}
        {showResultsContainer && (
          <Paper
            className={`${style.resultsContainer} ${!!previousResults && !query && style.fadeIn}`}
            sx={{
              top: onSelect ? 10 : 70,
              right: onSelect ? undefined : 0,
              width: onSelect ? undefined : '100%',
              height: onSelect ? 800 : `calc(100vh - ${ui.topBarHeight + ui.dashboardPadding}px)`,
              left: onSelect ? 0 : undefined,
            }}
          >
            <Results />
          </Paper>
        )}
      </Box>
      {!onSelect && (
        <Box
          className={style.backdrop}
          display={ui.isSearchOpen ? 'block' : 'none'}
          height={isElectron ? `calc(100vh - ${ui.topBarElectronHeight}px)` : '100vh'}
          onClick={closeSearch}
        ></Box>
      )}
    </>
  );
}

export const MainSearchModal = ({ children }: { children: (setOpen: (open: boolean) => void, isOpen: boolean) => ReactElement }) => {
  const [showSearch, setShowSearch] = useState(false);
  const fullWidthDialog = useCssBreakpoint('md');
  const isMobileLayout = useCssBreakpoint('sm');
  const location = useLocation();
  useEffect(() => {
    setShowSearch(false);
  }, [location.pathname]);
  return (
    <>
      {children((x) => setShowSearch(x), showSearch)}
      <DialogWrapper
        title={'Search'}
        open={showSearch}
        onClose={() => setShowSearch(false)}
        maxWidth={'xl'}
        sx={{ pb: isMobileLayout ? 0 : '50vh', '.MuiPaper-root': { width: '100%', margin: fullWidthDialog ? 0 : undefined } }}
        disableRestoreFocus
      >
        <DialogContentText>Search by label, artist, product title, ISRC, UPC, Cat#, and more...</DialogContentText>
        <Box mt={5}>
          <MainSearch allowedTypes={[SearchGroupId.Artists, SearchGroupId.Labels, SearchGroupId.Releases, SearchGroupId.Tracks]} />
        </Box>
      </DialogWrapper>
    </>
  );
};

export const MultiColumnSearchContainer = ({ children, resultUiGroups, isSmallDeviceMode, isSelect, allowedTypes, onClickSearchArea, ...other }: any) => {
  const defaultGroup = resultUiGroups && resultUiGroups[0] && resultUiGroups[0][0];
  const [selectedGroup, setSelectedGroup] = useState(defaultGroup);

  let uiGroups: any[][];
  const mainChild = resultUiGroups ? null : (React.Children.toArray(children).find((x) => x) as any);

  if (resultUiGroups) {
    uiGroups = resultUiGroups;
  } else {
    const items = children && (React.Children.toArray(mainChild.props.children) as ReactJSXElement[]);
    const grps = _.groupBy(items, (x) => x?.props?.children?.props.item.group);

    uiGroups = _.orderBy(
      Object.entries(grps).filter((x) => x[0] !== 'undefined'),
      (x) => (GroupInfo as any)[x[0]].order
    );
  }

  const newBody = (
    <>
      {isSmallDeviceMode && uiGroups.some((x) => x[0] === selectedGroup) && (
        <Tabs value={selectedGroup} onChange={(e, value) => setSelectedGroup(value)} sx={{ mb: 3 }} key={uiGroups.length}>
          {uiGroups.map(([group, members]) => (
            <Tab label={group} value={group} key={group} />
          ))}
        </Tabs>
      )}
      <Stack direction={'row'} width={'100%'} zIndex={2} gap={3}>
        {uiGroups
          .filter((x) => !isSmallDeviceMode || x[0] === selectedGroup)
          .map(([group, members]) => {
            const groupInfo = _.get(GroupInfo, group) as (typeof GroupInfo)['Artists'];
            const children = members.map((member: any) => ({
              ...member,
              key: member.props.item.id,
            }));
            return (
              groupInfo && (
                <Box flex={isSmallDeviceMode ? 1 : allowedTypes ? 1 / allowedTypes.length : 0.25} key={group}>
                  {!isSmallDeviceMode && (
                    <Stack direction={'row'} spacing={1} alignItems={'center'} textTransform={'uppercase'} pl={isSelect ? 0 : 2} mb={'14px'}>
                      {groupInfo.groupIcon}
                      <Typography variant="labelMedium" color="text.secondary" fontWeight={700}>
                        {group}
                      </Typography>
                    </Stack>
                  )}
                  <Stack gap={1}>{children}</Stack>
                </Box>
              )
            );
          })}
      </Stack>
    </>
  );

  return (
    <Stack onClick={onClickSearchArea}>
      {resultUiGroups ? (
        newBody
      ) : (
        <Paper {...other} sx={{ backgroundColor: 'background.paper', width: '100%', height: '100%', p: 2 }}>
          {React.cloneElement(mainChild, { children: newBody, style: { minHeight: '600px' } })}
        </Paper>
      )}
    </Stack>
  );
};

const RenderSearchResult = memo(
  ({ item, isSmallDeviceMode }: { item: Result; isSmallDeviceMode?: boolean }) => {
    const { isLoading } = item;
    const imgWidth = '80px';
    const isArtist = item.group === SearchGroupId.Artists;
    const artistImage = isArtist && item.imageGuid && getArtistImgUrl(item.imageGuid);
    return (
      <div data-group={item.group} onClick={() => item.link?.()} key={item.id}>
        <Stack direction={'row'} alignItems={'center'} spacing={'12px'} padding={1} className="clickable" maxWidth={500}>
          {item.hideImage || isArtist ? (
            <Avatar
              sx={{ fontSize: 30, width: imgWidth, height: imgWidth, bgcolor: 'text.secondary', opacity: !!artistImage ? 1 : 0.25 }}
              src={artistImage || ''}
            >
              {item.text ? generateInitials(item.text) : item.group === SearchGroupId.Labels ? <BsBuildingFill /> : <BsPersonFill />}
            </Avatar>
          ) : item.imageUrl !== undefined ? (
            <ImageWrapper src={item.imageUrl} height={70} />
          ) : (
            <PackshotImage guid={item.imageGuid!} size="sm" width={imgWidth} />
          )}
          <Stack spacing={0.75} width={isSmallDeviceMode ? 'auto' : '180px'}>
            <Stack>
              <Typography variant="h5" m={0} className={'oneLineTruncate'}>
                {isLoading ? <Skeleton width={140} /> : item.text}
              </Typography>
              <Typography variant="sub5" m={0} className={'oneLineTruncate'}>
                {isLoading ? <Skeleton width={112} /> : item.subtitle}
              </Typography>
            </Stack>
            <Stack spacing={'3px'}>
              {item.info1 || (isLoading && [SearchGroupId.Releases, SearchGroupId.Tracks].includes(item.group as any)) ? (
                <Typography variant="captionSmall" m={0} sx={{ textTransform: 'uppercase' }} className={'oneLineTruncate'}>
                  {isLoading ? <Skeleton width={100} /> : item.info1}
                </Typography>
              ) : (
                <></>
              )}
              {item.info2 || (isLoading && item.group === SearchGroupId.Releases.toString()) ? (
                <Typography variant="captionSmall" m={0} className={'oneLineTruncate'}>
                  {isLoading ? <Skeleton width={105} /> : item.info2}
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  },
  (x, y) => x.item.id === y.item.id
);
