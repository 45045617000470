import { SelectListVm } from '@anthology/shared/src/api/slinkyApi';
import { CommonIcons } from '@src/constants/commonIcons';
import { BsCassette, BsDisc, BsSoundwave, BsVinyl } from 'react-icons/bs';
import { GiSoundWaves } from 'react-icons/gi';
import { MdOutlineLibraryMusic } from 'react-icons/md';
import { PiCassetteTapeDuotone, PiDiscDuotone, PiVideo, PiVinylRecordDuotone } from 'react-icons/pi';
import { TbSquare } from 'react-icons/tb';
import { DigitalPhysical } from './catalogueConsts';

export const getFormatTypeUi = (formatType?: SelectListVm) => {
  if (!formatType) return {};
  const formatTypeName = formatType && formatType.text;
  const isAlbum = formatTypeName && formatTypeName.indexOf('Album') > -1;
  const isAudio = formatType && formatType.isAudio;
  const isDigital = formatType && formatType.isDigital;
  const isVinyl = formatTypeName && formatTypeName?.indexOf('"') > -1;
  const isCassette = formatTypeName && formatTypeName?.indexOf('Cassette') > -1;

  const formatGroupLabel = formatType ? (!isAudio ? 'Video' : isAlbum ? 'Album' : 'Single') : '';

  const formatTypeGroupLabel = isDigital ? DigitalPhysical.Digital.label : DigitalPhysical.Physical.label;
  const formatIcon = isDigital ? BsSoundwave : isVinyl ? BsVinyl : isCassette ? BsCassette : BsDisc;
  const formatGroupIcon = formatType ? (!isAudio ? CommonIcons.ProductVideo.Regular : isAlbum ? MdOutlineLibraryMusic : TbSquare) : null;
  const placeholderIcon = isAudio ? (isDigital ? GiSoundWaves : isVinyl ? PiVinylRecordDuotone : isCassette ? PiCassetteTapeDuotone : PiDiscDuotone) : PiVideo;

  return {
    formatTypeLabel: formatTypeName,
    formatGroupLabel: formatGroupLabel,
    formatTypeGroupLabel: formatTypeGroupLabel,
    formatIcon: formatIcon,
    formatGroupIcon: formatGroupIcon,
    placeholderIcon: placeholderIcon,
  };
};
