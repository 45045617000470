import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';

import { Breakpoint } from '@mui/system';
import { PageLayouts } from '@src/services/layoutService';

type UiState = {
  layout: PageLayouts;

  isTopBarOpen: boolean;
  isSideBarOpen: boolean;
  isSideBarCollapsed: boolean;
  isSideBarFloating: boolean;
  isBottomBarOpen: boolean;
  currentSize: Breakpoint;
  isSearchOpen: boolean;
  isKeyboardOpen: boolean;
};

const init: UiState = {
  layout: PageLayouts.fullscreen,

  isTopBarOpen: false,
  isSideBarOpen: false,
  isSideBarCollapsed: false,
  isSideBarFloating: false,

  isBottomBarOpen: false,

  currentSize: 'xl',

  isSearchOpen: false,
  isKeyboardOpen: false,
};

const slice = createSlice({
  name: 'ui',
  initialState: init,
  reducers: {
    setSideMenuState: (state, { payload }: PayloadAction<{ isOpen?: boolean; isCollapsed?: boolean; isFloating?: boolean }>) => {
      if (!_.isUndefined(payload.isOpen)) state.isSideBarOpen = payload.isOpen;
      if (!_.isUndefined(payload.isCollapsed)) state.isSideBarCollapsed = payload.isCollapsed;
      if (!_.isUndefined(payload.isFloating)) state.isSideBarFloating = payload.isFloating;
    },
    setTopMenuState: (state, { payload }: PayloadAction<boolean>) => {
      state.isTopBarOpen = payload;
    },
    setBottomMenuState: (state, { payload }: PayloadAction<boolean>) => {
      state.isBottomBarOpen = payload;
    },
    setCurrentScreenSize: (state, { payload }: PayloadAction<Breakpoint>) => {
      state.currentSize = payload;
    },
    setPrivateUiState: (state, { payload }: PayloadAction<Partial<UiState>>) => {
      Object.entries(payload).forEach(([k, v]) => {
        (state as any)[k] = v;
      });
    },
    setIsSearchOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchOpen = payload;
    },
    setIsKeyboardOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isKeyboardOpen = payload;
    },
  },
});

export const { setPrivateUiState, setSideMenuState, setTopMenuState, setBottomMenuState, setCurrentScreenSize, setIsSearchOpen, setIsKeyboardOpen } =
  slice.actions;

export default slice.reducer;
