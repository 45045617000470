import { forwardRef, memo, useMemo, useRef, useState } from 'react';
import { FixedSizeGrid, GridChildComponentProps, areEqual } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { Box } from '@mui/material';
import { useMeasureThrottle } from '@src/hooks/useMeasureThrottle';
import React from 'react';
import ForwardBodyScrollEvents from './ForwardBodyScrollEvents';

export type InfiniteGridWrapperRef = {
  loader?: InfiniteLoader;
  grid?: FixedSizeGrid;
  height: number;
  scrollPercent: number;
};

export type InfiniteGridWrapperProps<T> = {
  items: T[];
  loadNextPage?: (startIndex: number, stopIndex: number) => Promise<void>;
  itemWidth?: number;
  itemHeight: number;
  itemComponent: (x: T) => JSX.Element;
  loadingComponent?: (child: GridChildComponentProps<any>) => JSX.Element;
  itemCount?: number;
  scrollParent?: Element | typeof window;
  columnCount?: number;
  overscanRowCount?: number;
  chunkSize?: number;
  wrapperRef?: React.MutableRefObject<InfiniteGridWrapperRef>;
  autoAdjustVerticalSpace?: boolean;
};

export function InfiniteGridWrapper<T extends unknown>({
  items,
  loadNextPage,
  itemWidth,
  itemHeight,
  itemComponent,
  loadingComponent,
  itemCount,
  scrollParent,
  columnCount,
  overscanRowCount,
  wrapperRef,
  autoAdjustVerticalSpace,
  chunkSize = 100,
}: InfiniteGridWrapperProps<T>): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const infiniteLoaderRef = useRef<InfiniteLoader>();
  const gridRef = useRef<FixedSizeGrid>();
  const itemRef = useRef<Element>();

  const wrapLoadNextPage = useMemo(() => {
    if (!loadNextPage) return null;
    return (s: number, e: number) => {
      setIsLoading(true);
      return loadNextPage(s, e).finally(() => setIsLoading(false));
    };
  }, [loadNextPage]);

  const loadMoreItems = wrapLoadNextPage;
  const isItemLoaded = (index: number) => typeof items[index] != 'undefined';
  const [m, measure_ef] = useMeasureThrottle();

  const { width, height } = m ?? { width: 0, height: 0 };

  const actualColumnCount = itemWidth ? columnCount ?? Math.max(Math.floor(width / itemWidth), 1) : 1;
  const actualItemCount = Math.max(itemCount ?? items.length + chunkSize, items.length);

  // //autoheight experiment
  // const [measureItemHeight, setMeasureItemHeight] = useState<number | undefined>(undefined);

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (itemRef.current && !measureItemHeight) {
  //     const mHeight = (itemRef.current.firstChild as any)?.getBoundingClientRect?.()?.height;
  //     if (!!mHeight && !_.isNaN(mHeight)) {
  //       console.log(mHeight);
  //       setMeasureItemHeight(mHeight);
  //     }
  //   }
  // });

  // itemHeight = itemHeight ?? measureItemHeight ?? 100;

  // Render an item or a loading indicator.
  const Item = (child: GridChildComponentProps<any>, context: any) => {
    const idx = child.rowIndex * actualColumnCount + child.columnIndex;
    if (itemCount && idx >= itemCount) {
      return null;
    }

    const f = items[idx];

    if (typeof f === 'undefined') {
      if (!isLoading) return <></>;
      return <div style={child.style}>{loadingComponent ? loadingComponent(child) : 'loading...'}</div>;
    }

    return (
      <div style={child.style} ref={itemRef as any}>
        {itemComponent(f)}
      </div>
    );
  };

  const MemItem = memo((child: GridChildComponentProps<any>, context: any) => Item(child, context), areEqual);

  const sx = { width: '100%', overflow: 'hidden', ...(scrollParent ? {} : { height: '100%' }) };

  const outerEl = {
    outerElementType: forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>((x, ref) => {
      return <ForwardBodyScrollEvents scrollParent={scrollParent} ref={ref} {...(x as any)} />;
    }),
  };

  const actualRowCount = Math.ceil(actualItemCount / actualColumnCount);

  const scrollPercent = ((gridRef.current?.state ?? ({} as any)).scrollTop + height) / (itemHeight * actualRowCount);

  if (wrapperRef) {
    wrapperRef.current = {
      grid: gridRef.current,
      loader: infiniteLoaderRef.current,
      height,
      scrollPercent,
    };
  }

  return (
    <Box ref={measure_ef as any} sx={sx} pt={0.5} display={'flex'} justifyContent={'center'}>
      {width > 0 && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={actualItemCount}
          loadMoreItems={loadMoreItems ?? (() => {})}
          ref={infiniteLoaderRef as any}
          minimumBatchSize={chunkSize}
        >
          {({ onItemsRendered, ref }) => {
            const newItemsRendered = (gridData: any) => {
              const { overscanRowStartIndex, overscanRowStopIndex } = gridData;
              const visibleStartIndex = overscanRowStartIndex * actualColumnCount;
              const visibleStopIndex = overscanRowStopIndex * actualColumnCount;
              onItemsRendered({ visibleStartIndex, visibleStopIndex } as any);
            };

            const columnWidth = width / actualColumnCount - (actualColumnCount === 1 ? 10 : 0);
            const columnExtraWidth = columnWidth - (itemWidth || 0);
            const rowHeight = autoAdjustVerticalSpace ? itemHeight + (itemWidth ? columnExtraWidth : 0) : itemHeight;

            return (
              <FixedSizeGrid
                width={width}
                height={scrollParent ? window.innerHeight : height}
                columnCount={actualColumnCount}
                columnWidth={columnWidth}
                rowHeight={rowHeight}
                rowCount={actualRowCount}
                onItemsRendered={newItemsRendered}
                overscanRowCount={overscanRowCount ?? 3}
                ref={(e) => {
                  ref(e);
                  gridRef.current = e!;
                }}
                {...(scrollParent ? outerEl : {})}
              >
                {/* (p, c) => Item(p, c) */ MemItem}
              </FixedSizeGrid>
            );
          }}
        </InfiniteLoader>
      )}
    </Box>
  );
}
