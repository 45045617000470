import _ from "lodash";
import { useEffect } from "react";
import { CDNRoot } from "../constants/urls";
import { useForceUpdate } from "../hooks/useForceUpdate";
import { SimpleEmitter, formatDuration } from "../utils/helpers";

export type PlaylistType = {
  url: string;
  imageUrl: string;
  title: string;
  artist: string;
};

const defaultState = {
  playing: false,
  readableCurrentTime: "",
  readableDuration: "",
  duration: 0,
  currentTime: 0,
  canplay: false,
  error: false,
  currentTrack: 0,
  playlist: [] as PlaylistType[],
  shuffle: false,
  repeat: false,
  playerVisible: false,
  volume: 1,
  isMiniPlayer: false,
};

export const audioEvents = [
  "ended",
  "error",
  "playing",
  "pause",
  "timeupdate",
  "canplay",
  "volumechange",
  // 'play',  // 'loadedmetadata',  // 'loadstart',
] as const;

type AudioState = typeof defaultState;
type AudioEvents = (typeof audioEvents)[number];

// interface AudioFormat {
//   packshotGUID?: string;
// }

// interface AudioTrack {
//   artistNames?: string;
//   assetTitle?: string;
//   blobGuid?: string;
// }

export class AudioplayerServiceClass {
  public state = _.cloneDeep(defaultState);
  public audioObj: HTMLAudioElement | undefined =
    typeof Audio !== "undefined" ? new Audio() : undefined;
  public onStateUpdate = new SimpleEmitter<AudioState>();
  public onTimeUpdate = new SimpleEmitter<AudioState>();

  constructor() {
    if (!this.audioObj) return;
    audioEvents.forEach((event) => {
      this.audioObj?.addEventListener(event, (e) => this.handler(e));
    });
  }

  test() {
    this.load_playlist(
      [
        {
          url: `${CDNRoot}/audio/mp3/40f57d1e-6853-4246-b1da-d529e422459b.mp3`,
          artist: "Steps",
          title: "Scared of the Dark",
          imageUrl:
            "https://images.unsplash.com/photo-1682686578842-00ba49b0a71a?q=80&w=1975&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
      ],
      0,
      true
    );
  }

  load_playlist(
    data: PlaylistType[],
    current_track = 0,
    autoplay = true,
    isMiniPlayer?: boolean
  ) {
    this.state.isMiniPlayer = false;
    if (!this.state.playerVisible) {
      this.resetState();
    }

    this.state.playlist = _.cloneDeep(data);
    this.goto(current_track, autoplay);
    if (!isMiniPlayer) {
      this.state.playerVisible = true;
    }
  }

  // prepareMiniPlayer(format?: AudioFormat, tracks?: AudioTrack[]) {
  //   if (!format || !tracks) return;
  //   const playlist = tracks.map((track) => {
  //     return {
  //       url: getAudioUrl(track.blobGuid ?? ""),
  //       artist: track.artistNames,
  //       title: track.assetTitle,
  //       packshot: format.packshotGUID,
  //     } as PlaylistType;
  //   });
  //   this.load_playlist(playlist, 0, false, true);
  //   this.state.isMiniPlayer = true;
  //   this.onStateUpdate.emit(this.state);
  // }

  handler(event: Event) {
    const et = event.type;
    this.processEvent(et as AudioEvents);
  }

  processEvent(eventType: AudioEvents) {
    if (!this.audioObj) return;
    switch (eventType) {
      case "canplay":
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = formatDuration(
          this.state.duration,
          true,
          3
        );
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        break;
      case "pause":
        this.state.playing = false;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = formatDuration(
          this.state.currentTime,
          true,
          3
        );
        break;
      case "ended":
        this.state.playing = false;
        this.next();
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
      case "volumechange":
        this.state.volume = this.audioObj.volume;
        break;
    }

    if (eventType !== "timeupdate") {
      this.onStateUpdate.emit(this.state);
    }
    this.onTimeUpdate.emit(this.state);
  }

  public now_playing() {
    return this.state.playlist[this.state.currentTrack] ?? {};
  }

  play() {
    if (!this.audioObj) return;
    this.audioObj.play();
  }

  pause() {
    if (!this.audioObj) return;
    this.audioObj.pause();
  }

  close() {
    this.pause();
    this.state.playerVisible = false;
    this.onStateUpdate.emit(this.state);
  }

  next(isMiniPlayer?: boolean) {
    this.state.isMiniPlayer = !!isMiniPlayer;
    if (this.state.repeat) {
      this.seek(0);
      this.play();
      return;
    } else if (this.state.shuffle) {
      this.state.currentTrack = Math.floor(
        Math.random() * this.state.playlist.length
      );
    } else if (this.state.currentTrack < this.state.playlist.length - 1) {
      this.state.currentTrack += 1;
    } else {
      return;
    }
    this.goto(this.state.currentTrack);
  }

  previous() {
    if (this.state.currentTrack > 0) {
      this.goto(this.state.currentTrack - 1);
    }
  }

  goto(pos: number, autoplay = true) {
    if (!this.audioObj) return;
    this.state.currentTrack = Math.max(0, pos);
    this.audioObj.src = this.now_playing().url;
    this.audioObj.load();
    if (autoplay) {
      this.play();
    }
    this.onStateUpdate.emit(this.state);
  }

  goToMiniPlayerTrack(pos: number) {
    this.state.isMiniPlayer = true;
    this.onStateUpdate.emit(this.state);
    this.goto(pos, true);
  }

  seek(position: number | null) {
    if (!this.audioObj) return;
    this.audioObj.currentTime = position ?? 0;
  }

  toggle_shuffle() {
    this.state.shuffle = !this.state.shuffle;
    this.state.repeat = false;
    this.onStateUpdate.emit(this.state);
  }

  toggle_repeat() {
    this.state.repeat = !this.state.repeat;
    this.state.shuffle = false;
    this.onStateUpdate.emit(this.state);
  }

  setVolume(val: number | null) {
    if (!this.audioObj) return;
    this.audioObj.volume = val ?? 1;
  }

  resetState() {
    this.state = _.cloneDeep(defaultState);
  }
}

const audioPlayer = new AudioplayerServiceClass();
export default audioPlayer;

export const useAudioService = (timeUpdates: boolean) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const emitter = timeUpdates
      ? audioPlayer.onTimeUpdate
      : audioPlayer.onStateUpdate;
    return emitter.subscribe(() => {
      forceUpdate();
    });
  }, [forceUpdate, timeUpdates]);

  return {
    state: audioPlayer.state,
    controls: audioPlayer,
  };
};
