import { Box, Fade, Popper } from '@mui/material';
import NotificationItem from '@src/components/layout/header/NotificationItem';
import { useAuth } from '@src/services/authService';
import { useUiSlice } from '@src/services/layoutService';

import { useNotificationService } from '@src/services/notificationService';

import { createRef, useEffect, useRef, useState } from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import style from './SnackbarWrapper.module.scss';

const SnackbarWrapper = () => {
  const ui = useUiSlice();
  const note = useNotificationService();
  const { auth } = useAuth();
  const el = useRef();
  const [open, setOpen] = useState(false);

  const unreadNotifications = note.notifications.filter((x) => !x.isRead);
  const visibleNote = open ? unreadNotifications : unreadNotifications.filter((x) => x.isFlashed);

  useEffect(() => {
    if (visibleNote.length === 0 && open) {
      setOpen(false);
    }
  }, [open, visibleNote.length]);

  function handleClick(e: React.MouseEvent) {
    note.cancelFlash();
    setOpen(!open);
  }

  if (!auth.isSignedIn) {
    return <></>;
  }

  return (
    <>
      <Box ref={el as any} sx={{ position: 'fixed', bottom: 30, right: 30 }} onClick={handleClick}></Box>
      <Popper
        open={visibleNote?.length > 0}
        anchorEl={el.current}
        placement="top-end"
        transition
        sx={{ width: '100vw', maxWidth: '500px', height: `calc(100vh - ${ui.topBarHeight + 40}px)`, overflowY: 'scroll', zIndex: 9999 }}
      >
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={350}>
              <Box className={style.snackbarContainer}>
                <TransitionGroup>
                  {/* <Stack gap={0.5}> */}
                  {visibleNote.map((n) => {
                    n.domRef = n.domRef ?? createRef();
                    return (
                      <CSSTransition key={n.id} nodeRef={n.domRef} timeout={350}>
                        <Box ref={n.domRef} sx={{ width: '100%', overflow: 'hidden', p: 0.5, borderRadius: 2 }}>
                          <Box className={style.snackbarNotification}>
                            <NotificationItem notification={n} variant="snackbar" />
                          </Box>
                        </Box>
                      </CSSTransition>
                    );
                  })}
                  {/* </Stack> */}
                </TransitionGroup>
              </Box>
            </Fade>
          );
        }}
      </Popper>
    </>
  );
};

export default SnackbarWrapper;
