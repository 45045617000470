import { useForceUpdate } from '@anthology/shared/src/hooks';
import { SimpleEmitter } from '@anthology/shared/src/utils/helpers';
import { AlertColor } from '@mui/material';
import { UserNotification, anthologyApi } from '@src/api/anthologyApi';
import { useEffectOnce } from '@src/hooks/useEffectOnce';
import { removeUtc } from '@src/utils/helpers';
import { addSeconds, differenceInSeconds, formatISO, parseISO } from 'date-fns';
import { Guid } from 'guid-typescript';
import { FcApproval, FcHighPriority, FcInfo, FcMediumPriority, FcOk, FcUpload } from 'react-icons/fc';
import { store } from '../features/store';
import authService from './authService';
import { layoutService } from './layoutService';

export const NotificationAlertIcons = {
  default: FcInfo,
  done: FcApproval,
  success: FcOk,
  warning: FcMediumPriority,
  error: FcHighPriority,
  upgrade: FcUpload,
};
export interface Notification {
  id: string;
  message: string;
  expireTime?: Date;
  flashEndTime?: Date;
  createTime: Date;
  icon?: keyof typeof NotificationAlertIcons;
  isRead: boolean;
  isFlashed: boolean;
  severity?: AlertColor;
  isBackendNote: boolean;
  domRef?: any;
  meta: any;
}
export interface NotificationEvent {
  event: string;
  notificationId?: string;
}

export class NotificationsServiceClass {
  events = new SimpleEmitter<NotificationEvent>();
  notifications: Notification[] = [];
  unreadCount = 0;
  hasFlashItems = false;
  auth = authService;
  latestMsgTime = new Date(1900, 1, 1);
  increment = 0;

  latestCheckTime = new Date(1900, 1, 1);

  constructor() {
    setInterval(() => this.updateNoteStatus(), 1000);
    setInterval(() => this.checkServerForNotifications(), 1000);
  }

  private updateNoteStatus() {
    const now = removeUtc(new Date());
    this.notifications.forEach((n) => {
      if (n.expireTime && n.expireTime < now) {
        this.delete(n.id);
      }
      if (n.flashEndTime && n.flashEndTime < now) {
        n.isFlashed = false;
      }
    });
    const hasFlash = this.notifications.filter((x) => x.isFlashed).length > 0;
    if (hasFlash !== this.hasFlashItems) {
      this.hasFlashItems = hasFlash;
      this.events.emit({ event: 'state' });
    }
  }

  init() {}

  checkServerForNotifications() {
    const sinceLast = differenceInSeconds(new Date(), this.latestCheckTime);

    const checkInterval = layoutService.appHasFocus ? 5 : 60;

    if (sinceLast < checkInterval) {
      return;
    }

    if (!this.auth.state().isSignedIn) {
      this.notifications = [];
      this.latestMsgTime = new Date(1900, 1, 1);
      return;
    }

    const currentNotes = Object.fromEntries(this.notifications.map((x) => [x.id, x]));

    store
      .dispatch(anthologyApi.endpoints.getApiNotificationGetNotifications.initiate({ afterTime: formatISO(this.latestMsgTime) }, { forceRefetch: true }))
      .unwrap()
      .finally(() => (this.latestCheckTime = new Date()))
      .then((notes: UserNotification[]) => {
        let shouldRender = false;
        notes.forEach((n) => {
          const ex = currentNotes[n.inboxId!.toString()];

          if (!!ex) {
            // maybe sync other props?
            if (ex.isRead !== n.isRead) {
              ex.isRead = n.isRead!;
              shouldRender = true;
            }

            return;
          }

          const mt = parseISO(n.timeAdded!);

          this.latestMsgTime = this.latestMsgTime > mt ? this.latestMsgTime : mt;

          let meta = {};

          try {
            meta = JSON.parse(n.metaJson);
          } catch {}

          currentNotes[n.inboxId!.toString()] = this.postNotification({
            noteProps: {
              message: n.body!,
              createTime: parseISO(n.timePublished!),
              severity: (n.severity as any) ?? 'success',
              icon: n.icon as any,
              expireTime: parseISO(n.expiryTime!),
              id: n.inboxId!.toString(),
              isBackendNote: true,
              isRead: n.isRead,
              meta,
            },
            flashForSec: n.isRead || !this.increment ? undefined : 15,
          });
        });
        if (shouldRender) this.updateUnreadCount();
        this.increment++;
      });
  }

  postNotification({ noteProps, expiresInSec, flashForSec }: { noteProps: Partial<Notification>; expiresInSec?: number; flashForSec?: number }): Notification {
    const now = removeUtc(new Date());

    const note: Notification = {
      ...(noteProps as any),
      createTime: noteProps.createTime ?? now,
      expireTime: noteProps.expireTime ?? (!!expiresInSec ? addSeconds(now, expiresInSec as any) : undefined),
      flashEndTime: noteProps.flashEndTime ?? (!!flashForSec ? addSeconds(now, flashForSec as any) : undefined),
    };

    if (!!note.flashEndTime && note.flashEndTime > now) {
      note.isFlashed = true;
      this.hasFlashItems = true;
    }

    note.id = note.id ?? Guid.create().toString();

    this.notifications.splice(0, 0, note);

    this.updateUnreadCount();
    this.events.emit({ event: 'added', notificationId: note.id });
    return note;
  }

  postFlash(message: string, severity: AlertColor = 'info', flashForSec: number = 8, icon: keyof typeof NotificationAlertIcons = 'default'): Notification {
    if (icon === 'default') icon = severity as any;

    return this.postNotification({ noteProps: { message, severity, icon }, flashForSec, expiresInSec: flashForSec });
  }

  cancelFlash() {
    this.notifications.forEach((x) => (x.isFlashed = false));
    this.hasFlashItems = false;
    this.events.emit({ event: 'state' });
  }

  updateUnreadCount() {
    this.unreadCount = this.notifications.reduce((s, n) => s + (n.isRead && n.isBackendNote ? 0 : 1), 0);
    this.events.emit({ event: 'state' });
  }

  getNote(id: string): Notification | undefined {
    return this.notifications.find((x) => x.id === id);
  }

  markRead(id: string) {
    const n = this.getNote(id);
    if (!n || n.isRead) return;
    n.isRead = true;
    this.updateUnreadCount();
    this.events.emit({ event: 'state' });
    if (n.isBackendNote) {
      store.dispatch(anthologyApi.endpoints.postApiNotificationMarkNoteAsRead.initiate({ inboxIds: id.toString() }));
    }
  }

  delete(id: string) {
    const n = this.getNote(id);
    if (!n) return;
    this.notifications = this.notifications.filter((x) => x.id !== id);
    this.updateUnreadCount();
    this.events.emit({ event: 'removed', notificationId: id });
    if (n.isBackendNote) {
      store.dispatch(anthologyApi.endpoints.postApiNotificationDeleteNotes.initiate({ inboxIds: id.toString() }));
    }
  }

  hideFlashes() {
    this.notifications.forEach((x) => (x.isFlashed = false));
    this.hasFlashItems = false;
    this.events.emit({ event: 'state' });
  }
}

const notificationService = new NotificationsServiceClass();

export default notificationService;

export const useNotificationService = () => {
  const forceUpdate = useForceUpdate();

  useEffectOnce(() => {
    return notificationService.events.subscribe(() => forceUpdate());
  });

  return notificationService;
};
