import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams, Stack, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { Ref, useEffect, useMemo, useState } from 'react';
import { TextFieldWrapper, TextFieldWrapperProps } from '../text-field-wrapper/TextFieldWrapper';

type AutoCompleteGeneric = AutocompleteProps<any, any, any, any>;

export type AutoCompleteInputProps<T> = {
  getResults?: (x: string) => Promise<T[]>;
  labelKey?: string;
  idKey?: string;
  renderItem?: (x: T) => JSX.Element;
  inputProps?: Partial<TextFieldWrapperProps>;
  inputRef?: Ref<any>;
  valueIsID?: boolean;
  label?: string;
  showOptionDescription?: boolean;
  placeholder?: string;
} & Partial<AutoCompleteGeneric>;

function AutoCompleteInput<T = any>({
  getResults,
  labelKey: userLabelKey,
  idKey: userIdKey,
  renderItem,
  inputProps,
  inputRef,
  valueIsID,
  value,
  label,
  showOptionDescription,
  loading: parentLoading,
  ...rest
}: AutoCompleteInputProps<T>) {
  if (rest.freeSolo && valueIsID) {
    throw new Error('AutoCompleteInput/FormHookDropDown: Cannot use freeSolo with valueIsID, choose one or other');
  }
  if (rest.multiple && rest.freeSolo) {
    throw new Error('AutoCompleteInput/FormHookDropDown: Cannot use freeSolo with multiple, choose one or other');
  }

  const [fetchedOptions, setFetchedOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const labelKey = useMemo(() => {
    // try to autodetct the name of the label key
    if (!!userLabelKey) return userLabelKey;
    const sample = (rest.options ?? fetchedOptions ?? [{}])[0];
    try {
      if ('text' in sample) return 'text';
      if ('label' in sample) return 'label';
    } catch {}
    return 'text';
  }, [userLabelKey, rest.options, fetchedOptions]);

  const idKey = useMemo(() => {
    // try to autodetct the name of the id key
    if (!!userIdKey) return userIdKey;
    const sample = (rest.options ?? fetchedOptions ?? [{}])[0];
    try {
      if ('id' in sample) return 'id';
      if ('key' in sample) return 'key';
    } catch {}
    return 'id';
  }, [userIdKey, rest.options, fetchedOptions]);

  const labelGetter = useMemo(() => (x: any) => x?.[labelKey] ?? (typeof x === 'string' ? x : ''), [labelKey]);
  const idGetter = useMemo(() => (x: any) => x?.[idKey] ?? (typeof x === 'string' ? x : null), [idKey]);
  const compareFn = useMemo(() => (option: T, value: T) => idGetter(value) === idGetter(option), [idGetter]);

  const throttledApiFetch = useMemo(
    () =>
      _.throttle((query: string) => {
        if (!getResults) {
          return;
        }
        setLoading(true);
        getResults!(query)
          .then((x) => {
            setFetchedOptions(x ?? []);
          })
          .finally(() => setLoading(false));
      }, 200),
    [getResults]
  );

  const fuzzSearch = useMemo(() => {
    if (!rest.options || rest.options.length === 0) {
      return (x: any) => [];
    }

    const options = {
      threshold: 0.5,
      ignoreLocation: true,
      ignoreFieldNorm: true,
      keys: [],
    };

    try {
      options.keys = Object.keys(rest.options![0]) as never[];
    } catch (e: any) {
      console.log(rest);
    }

    const fuse = new Fuse(rest.options!, options);

    return (query: string) => {
      if (query === '') return _.take(rest.options!, 300);
      let fz = _.take(fuse.search(query), 100);
      if (rest.groupBy) {
        fz = _.sortBy(fz, [(x) => rest.groupBy!(x.item), (x) => -(x.score ?? 0)]);
      }
      return fz.map((x) => x.item);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.options, rest.groupBy]);

  //call api when input changes
  useEffect(() => {
    if (inputValue !== '') {
      throttledApiFetch(inputValue);
    }
  }, [inputValue, throttledApiFetch, getResults]);

  const renderInput = useMemo(
    () =>
      rest.renderInput ??
      ((x: AutocompleteRenderInputParams) => {
        return <TextFieldWrapper label={label} {...x} {...inputProps} />;
      }),
    [rest.renderInput, inputProps, label]
  );

  const filterOptions = rest.filterOptions ?? (getResults ? (x) => x : (o, { inputValue }) => fuzzSearch(inputValue));

  const defaultRenderOption = useMemo(
    () => (props: any, option: any) => {
      const label = renderItem ? renderItem(option) : <span>{labelGetter!(option)}</span>;
      return (
        <li {...props} key={idGetter!(option)}>
          {showOptionDescription ? (
            <Stack m={1}>
              <Typography variant="h5" mb={0.5}>
                {label}
              </Typography>
              {option.description && (
                <Typography variant="bodyMedium" color="text.secondary">
                  {option.description}
                </Typography>
              )}
            </Stack>
          ) : (
            label
          )}
        </li>
      );
    },
    [renderItem, labelGetter, idGetter, showOptionDescription]
  );

  const fallbackOptions: any[] = useMemo(() => {
    if (rest.multiple && _.isArray(value)) return value.map((x: any) => ({ [idKey]: x, [labelKey]: '' }));
    return [{ [idKey]: value, [labelKey]: '' }];
  }, [idKey, labelKey, value, rest.multiple]);

  const fullOptions = useMemo(() => {
    let fullOptions = _.uniqWith([...(rest.options ?? []), ...fetchedOptions], compareFn);
    let remainingFallbacks = fallbackOptions.filter((a) => !fullOptions.some((b) => compareFn(a, b)));
    fullOptions = [...remainingFallbacks, ...fullOptions];
    return fullOptions;
  }, [fallbackOptions, fetchedOptions, rest.options, compareFn]);

  const selectedOption = useMemo(() => {
    if (rest.freeSolo) return value ?? '';
    if (rest.multiple) {
      return (valueIsID ? fullOptions.filter((x) => (value ?? []).includes(idGetter(x))) : value) ?? [];
    } else {
      return (valueIsID ? fullOptions.find((x) => idGetter(x) === value) : value) ?? fallbackOptions[0];
    }
  }, [fullOptions, idGetter, value, valueIsID, rest.multiple, rest.freeSolo, fallbackOptions]);

  const onChangeCallback = useMemo(() => {
    const cb: AutoCompleteGeneric['onChange'] = (e, v, r, d) => {
      let value: any = v;
      if (valueIsID) {
        value = rest.multiple ? v.map((x: any) => idGetter(x)) : idGetter(v);
      }
      if (rest.freeSolo) {
        value = labelGetter(v);
      }
      (rest.onChange as any)?.({ target: { value }, currentTarget: { value }, row: v }, value, r, d);
    };
    return cb;
  }, [idGetter, labelGetter, rest.freeSolo, rest.multiple, rest.onChange, valueIsID]);

  const onInputChangeCallback = useMemo(() => {
    const cb: AutoCompleteGeneric['onInputChange'] = (e, v, r) => {
      rest.onInputChange?.(e, v, r);
      setInputValue(v);

      if (rest.freeSolo) {
        (rest.onChange as any)?.({ target: { value: v }, currentTarget: { value: v } }, v, r, null);
      }
    };
    return cb;
  }, [rest]);

  return (
    <Autocomplete
      {...rest}
      ref={inputRef}
      fullWidth={typeof rest.fullWidth === 'undefined' ? true : rest.fullWidth}
      value={selectedOption}
      loading={loading || parentLoading}
      noOptionsText={''}
      options={fullOptions}
      filterOptions={filterOptions}
      getOptionLabel={labelGetter}
      renderOption={rest.renderOption ?? defaultRenderOption}
      renderInput={renderInput}
      isOptionEqualToValue={compareFn}
      onChange={onChangeCallback}
      onInputChange={onInputChangeCallback}
      onFocus={() => throttledApiFetch(rest.inputValue ?? '')}
    />
  );
}

export default AutoCompleteInput;
