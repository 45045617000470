import { ButtonWrapper, CircleIconButton } from '@anthology/shared/src/components';
import { useCssBreakpoint } from '@anthology/shared/src/hooks';
import { Box, Collapse, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useAllowedRoutes } from '@src/hooks/useAllowedRoutes';
import { Guid } from 'guid-typescript';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { BsPlus, BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import './NavballMenu.scss';

const NavballMenu = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const isMobileLayout = useCssBreakpoint('sm');
  const [showItems, setShowItems] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    if (isCollapsed) {
      setAnchorEl(event.currentTarget);
    } else {
      setShowItems(true);
    }
  };

  const handleClose = () => {
    if (isCollapsed) {
      setAnchorEl(null);
    } else {
      setShowItems(false);
    }
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  const items = useAllowedRoutes([
    { label: 'New release', path: `/create/basic/${Guid.create().toString()}`, authRules: ['catalogue.edit'] },
    { label: 'Custom slinky', path: '/slinky/customise/-1', authRules: ['slinky.edit'] },
    { label: 'Digital Report', path: '/reporting/digital?tab=6', authRules: ['clientreporting.edit'] },
  ] as any);

  if (items.length === 0) {
    return <></>;
  }

  return isMobileLayout ? (
    <></>
  ) : (
    <>
      {isCollapsed ? (
        <Stack alignItems={'center'}>
          <CircleIconButton onClick={handleClick} showBorder active>
            <BsPlus />
          </CircleIconButton>
        </Stack>
      ) : showItems ? (
        <Stack alignItems={'center'}>
          <CircleIconButton onClick={handleClose}>
            <BsX />
          </CircleIconButton>
        </Stack>
      ) : (
        <ButtonWrapper size="small" variant="contained" startIcon={<BsPlus />} onClick={handleClick} sx={{ mx: 0.5 }}>
          Create
        </ButtonWrapper>
      )}
      <Collapse in={showItems}>
        <Stack gap={1}>
          {items.map((c) => (
            <Box key={c.label} onClick={() => setShowItems(false)}>
              <ButtonWrapper startIcon={<BsPlus />} size="small" variant="text" onClick={() => navigate(c.path)}>
                <Typography variant="labelLarge">{_.startCase(c.label)}</Typography>
              </ButtonWrapper>
            </Box>
          ))}
        </Stack>
      </Collapse>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { background: 'rgba(var(--mui-palette-background-defaultChannel) / 0.5)', boxShadow: 'none', paddingLeft: '20px', borderRadius: '10px' },
        }}
      >
        {items.map((c) => (
          <MenuItem key={c.label} onClick={handleClose}>
            <ButtonWrapper size="small" variant="contained" onClick={() => navigate(c.path)}>
              {_.startCase(c.label)}
            </ButtonWrapper>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NavballMenu;
