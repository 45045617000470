import ReactDOM from 'react-dom/client';
import { AppRoot } from './app';
import reportWebVitals from './reportWebVitals';
import './sass/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<AppRoot />);

reportWebVitals();
