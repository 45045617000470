import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, Fade, IconButton, Stack, TextField, TextFieldProps, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import HelpTooltip from '../help-tooltip/HelpTooltip';

export type TextFieldWrapperProps = {
  warning?: boolean;
  clearButton?: boolean;
  loading?: boolean;
  helperTextColor?: string;
  helpTooltip?: string | React.ReactNode;
  actionButton?: React.ReactElement;
  actionButtonWidth?: number;
} & TextFieldProps;

const StyleOutlineInput = styled(TextField, {
  shouldForwardProp: (p) => p !== 'helperTextColor',
})<TextFieldWrapperProps>(({ theme, color, ...props }) => {
  const palette = (theme.palette as any)[props.helperTextColor!];

  return {
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
      ...(palette ? { color: palette.main } : {}),
    },
    '&.MuiMenuItem-root.Mui-selected': {
      backgroundColor: color,
    },
  };
});

export function TextFieldWrapper({
  warning,
  clearButton,
  loading,
  helpTooltip,
  actionButton,
  actionButtonWidth,
  onChange,
  sx,
  inputRef,
  InputProps,

  ...rest
}: TextFieldWrapperProps) {
  const props = { ...rest };

  const innerRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value ?? (props.defaultValue as any));
  }, [props.value, props.defaultValue]);

  const combinedChange = (e: any) => {
    onChange?.(e);
    setValue(e.target.value);
  };

  props.size = props.size ?? 'small';
  props.color = props.color ?? 'primary';
  props.helperText = props.helperText ?? ' ';

  if (actionButton) {
    actionButtonWidth = actionButtonWidth ?? 100;
  }

  if (props.select) {
    clearButton = false;
  }

  const handleClearClick = () => {
    if (innerRef.current) {
      var setter = Object.getOwnPropertyDescriptor(props.multiline ? window.HTMLTextAreaElement.prototype : window.HTMLInputElement.prototype, 'value')!.set!;
      setter.call(innerRef.current, '');
      innerRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  if (warning) {
    props.color = 'warning';
    props.helperTextColor = 'warning';
    props.focused = true;
  }

  const inputPropsComb = { ...(InputProps ?? {}) };

  const tt = helpTooltip ? <HelpTooltip title={helpTooltip} isTextFieldWrapper /> : null;

  let endIcon = inputPropsComb.endAdornment;

  if (!endIcon && loading) {
    endIcon = <CircularProgress size={24} color={'inherit'} />;
  }

  if (!endIcon && warning) {
    // endIcon = (
    //   <IconButton size="small" color="warning" onClick={handleClearClick}>
    //     <WarningOutlined />
    //   </IconButton>
    // );
  }

  if (!endIcon && clearButton) {
    endIcon = (
      <Fade in={clearButton && !!value}>
        <IconButton size="small" onClick={handleClearClick}>
          <ClearIcon />
        </IconButton>
      </Fade>
    );
  }

  inputPropsComb.endAdornment = (
    <>
      {endIcon}
      {tt}
    </>
  );

  if (actionButton) {
    props.helperText = (
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
        <p style={{ margin: 0, width: '100%' }}>{props.helperText}</p>
        {actionButton}
      </Stack>
    );
    props.FormHelperTextProps = {
      ...({ component: 'div' } as any),
      ...props.FormHelperTextProps,
    };
  }

  const combSx = {
    ...(helpTooltip ? { paddingRight: '55px' } : {}),
    ...sx,
  };

  if (inputRef) {
    try {
      (inputRef as any)(innerRef.current); // its a callable
    } catch {
      (inputRef as any).current = innerRef.current; // its a RefObject
    }
  }
  return <StyleOutlineInput {...props} inputRef={innerRef} InputProps={inputPropsComb} onChange={combinedChange} sx={combSx} />;
}
