import { ButtonWrapper } from '@anthology/shared/src/components';
import { Check } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useBlobStorageService } from '@services/blobService';
import { useEffectOnce } from '@src/hooks/useEffectOnce';
import { Guid } from 'guid-typescript';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdUpload } from 'react-icons/md';
import style from './FileUploader.module.scss';
import { FileUploaderProps } from './FileUploaderProps';
import environment from '@src/environment';

const FileUploader = (props: FileUploaderProps) => {
  const [file, setFile] = useState<string | undefined>(undefined);

  // Ref to file upload input
  const inputFileRef = useRef<HTMLInputElement>(null);

  // Drag state
  const [dragActive, setDragActive] = useState(false);

  useEffect(() => {
    setFile(props.value);
  }, [props.value]);

  // Handle drag
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const sessid = useMemo(() => Guid.create().toString(), []);
  const { onComplete, uploadFile, deleteFile, totalProgress } = useBlobStorageService(props.destinationBucket, sessid, props.options ?? {});

  // Triggers when any files are selected or dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const incomingFile = e.target.files[0] || e.dataTransfer?.files;
    if (!incomingFile) return;
    uploadFile(incomingFile);
  };

  useEffectOnce(() => {
    return onComplete.subscribe((x: any) => {
      setFile(`${environment.CDNRoot}/${x.remotePath}`);
      deleteFile(x);
      props.onChange?.(x);
    });
  });

  // Click the button to upload
  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const handleOpen = () => {
    if (!props.showDropzone) {
      showFileDialog();
    }
  };

  const resetOnClick = (event: any) => {
    event.target.value = null;
  };

  const input = (
    <input style={{ display: 'none' }} accept={props.accept} ref={inputFileRef} type="file" multiple={false} onChange={handleDrop} onClick={resetOnClick} />
  );

  return (
    <>
      {props.children && props.children(() => handleOpen(), file)}
      {!props.showDropzone ? (
        <div>{input}</div>
      ) : (
        <>
          <Box className={style.container}>
            <Box className={style.title}>
              <Typography variant="h4" color="text.secondary">
                {props.title}
              </Typography>
            </Box>
            {input}

            <Box className={`${style.upload} ${dragActive && style.dragActive}`} onDragEnter={handleDrag}>
              {file ? (
                <>
                  <Box className={style.title}>
                    {props.isDoc ? (
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
                        <Check color="success" /> <Typography color={'text.secondary'}>File uploaded</Typography>
                      </Stack>
                    ) : (
                      <img alt="" src={file} className={style.cover} />
                    )}
                  </Box>
                  {props.showDropzone && (
                    <Box flexDirection={'row'} className={style.buttonsContainer}>
                      <ButtonWrapper onClick={() => setFile('')} variant="outlined" size="small">
                        Reset
                      </ButtonWrapper>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {totalProgress === 0 ? (
                    <Box className={style.dropContainer}>
                      <MdUpload className={style.icon}></MdUpload>
                      <Box>Drag and drop file here or</Box>

                      <ButtonWrapper onClick={() => showFileDialog()} className={style.button} variant="outlined" size="large">
                        Choose File
                      </ButtonWrapper>

                      {dragActive && (
                        <div className={style.dropZone} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>
                      )}
                    </Box>
                  ) : (
                    <CircularProgress />
                  )}
                </>
              )}
            </Box>
            <Box className={style.footerButtons}>
              {!file && (
                <>
                  <Typography variant="bodyMedium" color="text.secondary">
                    {props.helpText}
                  </Typography>

                  <Typography variant="bodyMedium" color="text.secondary">
                    Acceptable format include: {props.accept}.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default FileUploader;
