import { ImageWrapper } from '@anthology/shared/src/components';
import { Card, Stack, Typography } from '@mui/material';
import { fileTypes, playerFileTypes } from '@src/constants/fileTypes';
import { BsFileImage, BsFileMusic, BsFilePlay, BsFileSpreadsheet, BsFileZip } from 'react-icons/bs';

const FileIcons = {
  image: BsFileImage,
  audio: BsFileMusic,
  video: BsFilePlay,
  compressed: BsFileZip,
  spreadsheet: BsFileSpreadsheet,
};

const FileViewer = ({ url, size = 'small' }: { url: string; size: 'small' | 'large' | 'full' }) => {
  const fileType = url.split('.').reverse()[0];
  const elementSize = size === 'large' ? 600 : size === 'small' ? 190 : undefined;
  const fileTypeGroup = Object.entries(fileTypes).find((value) => value[1].includes(fileType));
  const groupId: keyof typeof fileTypes = fileTypeGroup && (fileTypeGroup[0] as any);
  if (!groupId) return <></>;
  if (playerFileTypes.includes(fileType)) {
    if (groupId === 'image') {
      return <ImageWrapper src={url} width={elementSize} autoHeight fullWidth={size === 'full'} />;
    } else if (groupId === 'audio') {
      return (
        <audio controls>
          <source src={url} type={`audio/${fileType}`} />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (groupId === 'video') {
      return (
        <video width={elementSize || '100%'} height="auto" controls={size !== 'small'}>
          <source src={url} type={`video/${fileType}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <></>;
    }
  } else {
    const icon = FileIcons[groupId];
    return (
      <Stack>
        <Card variant={size === 'small' ? 'outlined' : 'elevation'}>
          <Stack alignItems={'center'}>
            <Stack alignItems={'center'} justifyContent={'center'} gap={2} p={3} minWidth={200}>
              {icon({ size: 30 })}
              <Typography variant="labelLarge" color={'text.secondary'}>
                {fileType}
              </Typography>
            </Stack>
            {size === 'full' && (
              <Typography variant="labelMedium" color={'text.secondary'} mt={2}>
                {url}
              </Typography>
            )}
          </Stack>
        </Card>
      </Stack>
    );
  }
};

export default FileViewer;
