import { ButtonWrapper } from '@anthology/shared/src/components';
import { Box, Divider, Menu, MenuItem } from '@mui/material';
import { copyToClipboard } from '@utils/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkWrapper from '../link-wrapper/LinkWrapper';
import { RightClickMenuItem, RightClickMenuWrapperProps } from './RightClickMenuWrapperProps';

const RightClickMenuWrapper = ({ children, menuItems, path, sx, className, disabled, buttonProps }: RightClickMenuWrapperProps) => {
  const [menuPosition, setMenuPosition] = useState<null | { x: number; y: number }>(null);
  const open = Boolean(menuPosition);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    event.preventDefault();
    setMenuPosition({
      x: event.pageX,
      y: event.pageY,
    });
  };
  const handleClose = () => {
    setMenuPosition(null);
  };
  const nav = useNavigate();
  const onClickItem = (item: RightClickMenuItem) => {
    if (item.linkUrl) {
      nav(item.linkUrl);
    } else if (item.onClick) {
      item.onClick();
    }
    handleClose();
  };

  const copyLink = () => {
    path && copyToClipboard(`${window.location.host}${path}`);
    handleClose();
  };

  return (
    <>
      <Box sx={sx} onContextMenu={handleClick as any} className={`${className} ${path ? 'clickable' : ''}`} onClick={path ? () => nav(path) : undefined}>
        {buttonProps ? <ButtonWrapper {...buttonProps}>{children}</ButtonWrapper> : children}
      </Box>
      {open && (
        <Menu open={open} sx={{ position: 'fixed', left: menuPosition?.x, top: menuPosition?.y }} onClose={handleClose} anchorReference="none">
          {path && (
            <>
              {/* Handle in desktop app / mobile app */}
              <MenuItem>
                <LinkWrapper to={path} target="_blank" inheritColor onClick={handleClose}>
                  Open in new tab
                </LinkWrapper>
              </MenuItem>
              <MenuItem onClick={copyLink}>Copy link</MenuItem>
            </>
          )}
          {path && menuItems && <Divider />}
          {menuItems?.map((item) => (
            <MenuItem key={item.id} onClick={() => onClickItem(item)}>
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
export default RightClickMenuWrapper;
